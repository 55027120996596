import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Complete } from '~/img/circle_check.svg';
import { ReactComponent as Inactive } from '~/img/emptycircle.svg';
import { DepositRequest, formatCurrency, TokenDisplay } from '~/modules/common';
import { PoolActionState } from '~/modules/pools/enums';
import { ChainDeposit } from '~/modules/pools/interfaces';

export interface PoolInteractionDisplayProps {
  index: number;
  req: ChainDeposit;
  depositText: string;
  poolDepositRequest: ChainDeposit[];
  allDepositItems: DepositRequest;
}

export function PoolInteractionDisplay({
  req,
  depositText,
  poolDepositRequest,
  allDepositItems,
  index,
}: PoolInteractionDisplayProps) {
  return (
    <div className="flex items-center rounded-[25px] bg-white p-5 md:w-[552px]">
      {req.deposit === PoolActionState.Inactive && (
        <div className="flex items-center">
          <Inactive />
          <div className="ml-4 flex flex-col">
            <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-gray-600">
              Approve {depositText} deposit
            </span>
            <span className="text-sm leading-5 text-gray-600">
              {index === poolDepositRequest.length - 1
                ? 'This is the last approval before we finish.'
                : 'This action will trigger the deposit of tokens.'}
            </span>
          </div>
        </div>
      )}
      {(req.deposit === PoolActionState.Pending || req.deposit === PoolActionState.Confirmed) && (
        <div className="flex w-full flex-col">
          <div className="flex">
            {req.deposit === PoolActionState.Pending ? <Active /> : <Complete className="h-8 w-8" />}
            <div className="ml-4 flex flex-col">
              <span className="font-semibold text-xs leading-4 tracking-wide text-primary">
                Approve {depositText} deposit
              </span>
              <span className="text-sm leading-5 text-gray-600">This action will trigger the deposit of tokens.</span>
              <span className="text-sm leading-5 text-gray-600">
                Deposit {index + 1} out of {poolDepositRequest.length}.
              </span>
            </div>
          </div>
          <div className="mt-2 space-y-4">
            {allDepositItems.map((item) => {
              return (
                <div
                  key={`deposit-item-${item.chainId}-${item.address}`}
                  className={`flex items-center justify-between rounded-[15px] border-2 px-4 py-5 ${
                    item.chainId === req.chainId ? 'border-primary' : 'border-gray-300'
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <TokenDisplay chainId={item.chainId} token={item.address} showSymbol={false} />
                    <span className="text-base leading-5 text-gray-600">{item.tokenSymbol}</span>
                  </div>
                  <div className="ml-4 mt-1 flex flex-col items-end">
                    <span className="font-semibold leading-[28px] md:text-[32px]">
                      {item.displayAmount.slice(0, 9)}
                    </span>
                    <span className="text-[14px] font-normal text-gray-600">{formatCurrency(item.value)}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            {req.deposit === PoolActionState.Pending ? 'Waiting for approval on your wallet' : 'Approved'}
          </div>
        </div>
      )}
    </div>
  );
}

export default PoolInteractionDisplay;
