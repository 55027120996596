import { useContext } from 'react';

import { formatValue, StoreContext, TokenDisplay, useTokenDisplay } from '~/modules/common';

interface TokenAmountDisplayProps {
  chainId: string;
  asset: string;
  amount: number;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const TokenAmountDisplay = ({ chainId, asset, amount, size = 'md' }: TokenAmountDisplayProps) => {
  const { catalyst: catalystStore } = useContext(StoreContext);
  const { symbol } = useTokenDisplay({
    chainId,
    token: asset,
    catalystStore,
  });

  return (
    <div className="flex items-center justify-start">
      <TokenDisplay size={size} showSymbol={false} chainId={chainId} token={asset} />
      <span>{`${formatValue(amount, 4)} ${symbol}`}</span>
    </div>
  );
};
