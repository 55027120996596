import { TokenInfo } from '@uniswap/token-lists';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';

import { formatBalance, TokenBalance } from '~/config';
import { ReactComponent as SourcePlaceholder } from '~/img/source-placeholder.svg';
import { formatCurrency, StoreContext } from '~/modules/common';
import { Input } from '~/modules/common/components/Input';

interface TokenAmountInputProps {
  token: TokenInfo;
  amount?: number;
  onChange: (amount: number) => void;
  disabled?: boolean;
  error?: string;
  balance: TokenBalance;
}

export const TokenAmountInput = observer(({ amount, token, balance, onChange, disabled }: TokenAmountInputProps) => {
  const { catalyst } = useContext(StoreContext);
  const [inputAmount, setInputAmount] = useState('');
  const tokenPrice = catalyst.getPrice(token);
  const tokenValue = tokenPrice * Number(inputAmount);

  const handleClickMax = () => {
    const amount = formatBalance(balance.amount, token.decimals);
    setInputAmount(amount.toString());
    onChange(amount);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value;
    setInputAmount(amount);
    if (Number(amount)) {
      onChange(Number(amount));
    }
  };

  useEffect(() => {
    if (amount === undefined) {
      return;
    }
    setInputAmount(amount.toString());
  }, [amount]);

  return (
    <div key={JSON.stringify(token)} className="flex">
      <div className="border-gray flex h-[84px] w-full items-center rounded-[12px] border">
        <div className="mx-4 flex w-full items-center gap-2 text-lg">
          <div className="flex min-w-fit flex-col items-start gap-2 text-lg">
            <div className="flex">
              <img className="h-10 w-10 rounded-full" src={`/img/${token.chainId}.webp`} />
              {token?.logoURI ? (
                <img className="-ml-2 h-10 w-10 rounded-full" src={token?.logoURI} />
              ) : (
                <div className="-ml-2 h-10 w-10 rounded-full">{<SourcePlaceholder />}</div>
              )}
            </div>
            <div className="flex flex-row items-end">
              <span className="mb-1 pr-1 text-xs font-normal leading-[10px] text-grey-500">
                Balance{' '}
                {formatBalance(balance.amount, token.decimals).toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                })}
              </span>

              <div
                className={`rounded-10 flex h-5 w-10 items-center justify-center rounded-full bg-primary bg-opacity-10 px-1 font-semibold text-[10px] text-grey-900 ${
                  disabled ? 'cursor-pointer' : 'cursor-default'
                }`}
              >
                <div
                  onClick={handleClickMax}
                  className={`${disabled ? 'opacity-70' : 'opacity-50'} cursor-pointer text-[10px]`}
                >
                  MAX
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col gap-2">
            <Input
              numeric
              className="bg-transparent text-right text-2xl text-gray-800"
              step="0.01"
              lang="en"
              onWheel={(e) => e.currentTarget.blur()}
              onChange={handleOnChange}
              placeholder="0.00"
              value={inputAmount}
            />
            <span className="mb-1 pr-1 text-right text-xs font-normal leading-[10px] text-grey-500">
              {formatCurrency(tokenValue)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TokenAmountInput;
