import type { InteroperabilityProtocol } from '~/modules/pools';

export const protocols: InteroperabilityProtocol[] = [
  {
    name: 'IBC',
    id: 'IBC',
    description: 'Lower transaction fees',
  },
  {
    name: 'Hyperlane',
    id: 'Hyperlance',
    description: 'Fast transactions',
  },
];
