import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Caret } from '~/img/caret.svg';

import { CenteredConnectWallet } from '../CenteredConnectWallet';

export function Layout({
  children,
  shouldShowConnectWalletPrompt,
}: {
  children: ReactNode;
  shouldShowConnectWalletPrompt: boolean;
}) {
  const blurStyles = 'blur-[10px] opacity-60';

  return (
    <div className="relative flex h-full w-full flex-col gap-5 rounded-3xl bg-white p-6">
      <div className={clsx(`flex flex-col gap-2`, shouldShowConnectWalletPrompt && blurStyles)}>
        <div className="font-semibold text-lg">Create and share your referral link</div>
        <span className="max-w-[800px] text-sm text-grey-500">Refer users to earn Eggs.</span>
        <Link
          to="https://docs.catalyst.exchange/resources/eggs-rewards/"
          className="flex items-center gap-2 font-bold text-sm text-primary-700"
        >
          Learn how it works
          <Caret className="rotate-180 text-primary-700" />
        </Link>
      </div>
      {shouldShowConnectWalletPrompt && <CenteredConnectWallet />}
      <div
        className={clsx(
          `flex w-full flex-col gap-1 rounded-xl bg-grey-100 p-5`,
          shouldShowConnectWalletPrompt && blurStyles,
        )}
      >
        {children}
      </div>
    </div>
  );
}
