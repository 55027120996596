import { InformationCircleIcon } from '@heroicons/react/20/solid';
import * as HoverCard from '@radix-ui/react-hover-card';
import { useState } from 'react';

import { CreateLinkValidationItemStatus } from './OwnReferralLink/CreateOwnReferralLink';
import { ValidationIndicator } from './ValidationIndicator';

export function InputValidationPopover({
  unique,
  onlyLetters,
  max12Chars,
  noSpecialChar,
}: {
  unique: CreateLinkValidationItemStatus;
  onlyLetters: CreateLinkValidationItemStatus;
  max12Chars: CreateLinkValidationItemStatus;
  noSpecialChar: CreateLinkValidationItemStatus;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const hasAnyError = [unique, onlyLetters, max12Chars, noSpecialChar].some((status) => status === 'error');

  return (
    <HoverCard.Root openDelay={100} open={isOpen} onOpenChange={(val) => setIsOpen(val)}>
      <HoverCard.Trigger>
        <InformationCircleIcon
          className={`h-[20px] w-[20px] transition-colors duration-200 ${
            isOpen ? 'text-primary' : hasAnyError ? 'text-red-500' : 'text-gray-400'
          }`}
        />
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          side="top"
          align="end"
          className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
        >
          <div className="flex flex-col gap-2 rounded-3xl bg-white p-5 text-sm shadow-xl ">
            <div className="flex items-center gap-2">
              <ValidationIndicator status={unique} />
              <span className="text-gray-500">{unique === 'loading' ? 'Validating...' : 'Unique link'}</span>
            </div>
            <div className="flex items-center gap-2">
              <ValidationIndicator status={onlyLetters} />
              <span className="text-gray-500">Only letters</span>
            </div>
            <div className="flex items-center gap-2">
              <ValidationIndicator status={max12Chars} />
              <span className="text-gray-500">Max 12 characters</span>
            </div>
            <div className="flex items-center gap-2">
              <ValidationIndicator status={noSpecialChar} />
              <span className="text-gray-500">No special character</span>
            </div>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
