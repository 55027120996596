import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';

import { formatBalance } from '~/config/utils/tokens.utils';
import { Modal } from '~/modules/common';
import { Input } from '~/modules/common/components/Input';
import { StoreContext } from '~/modules/common/store/context';
import { SwapSlippage } from '~/modules/swap/enums';

export interface SlippageOptionProps {
  setSlippage: (slippage: number, swapSlippage: SwapSlippage) => void;
  slippage: number;
  swapSlippage: SwapSlippage;
  active: boolean;
}

export function SlippageOption({ slippage, swapSlippage, active, setSlippage }: SlippageOptionProps) {
  return (
    <button
      className={`h-[58px] cursor-pointer rounded-lg border bg-white px-6 py-4 hover:border-2 hover:border-primary md:w-[160px] ${
        active ? 'border-2 border-primary' : 'border-gray'
      }`}
      onClick={() => setSlippage(slippage, swapSlippage)}
    >
      <div className="flex text-[18px]">
        <div className="flex">
          <span>{slippage}%</span>
        </div>
      </div>
    </button>
  );
}

export interface SwapSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const SwapSettings = observer(({ open, onClose }: SwapSettingsProps) => {
  const { swap, catalyst, pool } = useContext(StoreContext);
  const { maxSlippage, lastQuote, targetAsset, swapSlippage } = swap;

  const [selectedSwapSlippage, setSelectedSwapSlippage] = useState(swapSlippage);
  const [selectedSlippage, setSelectedSlippage] = useState(maxSlippage);
  const [slippageDisplay, setSlippageDisplay] = useState<string>(
    swapSlippage === SwapSlippage.CUSTOM ? maxSlippage.toString() : '0.1',
  );
  const [showSlippageInfo, setShowSlippageInfo] = useState(false);

  useEffect(() => {
    return () => {
      setSelectedSlippage(swapSlippage);
    };
  }, []);

  function handleSlippage(e: React.ChangeEvent<HTMLInputElement>) {
    setSlippageDisplay(e.target.value);
    const maxSlippage = Number(e.target.value);
    if (isNaN(maxSlippage)) {
      return;
    }
    setSelectedSlippage(maxSlippage);
  }

  function setSlippage(slippage: number, newSwapSlippage: SwapSlippage) {
    setSelectedSlippage(slippage);
    setSelectedSwapSlippage(newSwapSlippage);
  }

  function handleSelect() {
    runInAction(() => {
      swap.maxSlippage = selectedSlippage;
      swap.swapSlippage = selectedSwapSlippage;
      pool.depositStore.depositSlippage = selectedSlippage;
    });
    onClose();
  }

  if (!open) {
    return null;
  }

  function SlippageBreakdown() {
    if (lastQuote && targetAsset) {
      const targetAssetPrice = catalyst.getPrice(targetAsset);
      const minOut = formatBalance(lastQuote.netMinOut, targetAsset.decimals);

      const initialDifference = lastQuote.amountOut - minOut;
      const slippageDiffScalar = selectedSlippage / maxSlippage;
      const displayMinOut = Math.max(lastQuote.amountOut - initialDifference * slippageDiffScalar, 0);

      return (
        <div className="flex flex-col gap-2">
          <div className="bggrey-100 flex items-center justify-between rounded-[15px] bg-grey-100 px-4 py-3 text-sm md:w-[512px]">
            <span className="text-grey-500">Min output</span>
            <div className="flex flex-col items-end gap-1 md:flex-row md:gap-4">
              <span className="tracking-wide text-grey">
                {displayMinOut.toLocaleString(undefined, { minimumFractionDigits: 4 })} {targetAsset.symbol}
              </span>
              <span className="tracking-wide text-grey">
                ${(displayMinOut * targetAssetPrice).toLocaleString(undefined, { minimumFractionDigits: 4 })}
              </span>
            </div>
          </div>
          <div className="bggrey-100 flex items-center justify-between rounded-[15px] bg-grey-100 px-4 py-3 text-sm md:w-[512px]">
            <span className="text-grey-500">Expected output</span>
            <div className="flex flex-col items-end gap-1 md:flex-row md:gap-4">
              <span className="tracking-wide text-grey">
                {lastQuote.amountOut.toLocaleString(undefined, { minimumFractionDigits: 4 })} {targetAsset.symbol}
              </span>
              <span className="tracking-wide text-grey">
                ${(lastQuote.amountOut * targetAssetPrice).toLocaleString(undefined, { minimumFractionDigits: 4 })}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <div className="mx-3 flex w-full flex-col md:w-fit">
          <div className="mb-4 font-bold text-2xl">Choose Slippage</div>
          <div className="h-max-[234px] flex flex-col gap-6 overflow-auto rounded-3xl bg-white p-5 md:w-[552px]">
            <div className="flex flex-col gap-4 md:flex-row">
              <SlippageOption
                slippage={0.1}
                setSlippage={setSlippage}
                swapSlippage={SwapSlippage.LOW}
                active={selectedSwapSlippage === SwapSlippage.LOW}
              />
              <SlippageOption
                slippage={0.3}
                setSlippage={setSlippage}
                swapSlippage={SwapSlippage.MID}
                active={selectedSwapSlippage === SwapSlippage.MID}
              />
              <button
                onClick={() =>
                  runInAction(() => {
                    if (selectedSwapSlippage !== SwapSlippage.CUSTOM) {
                      setSelectedSwapSlippage(SwapSlippage.CUSTOM);
                      if (slippageDisplay) {
                        setSlippageDisplay('');
                      }
                    }
                  })
                }
                className={`border-gray group flex h-[58px] cursor-pointer items-center rounded-lg border bg-white hover:border-2 hover:border-primary md:w-[160px] ${
                  selectedSwapSlippage === SwapSlippage.CUSTOM ? 'border-2 border-primary' : 'border-gray'
                }`}
              >
                <div className="border-gray flex h-[54px] w-[77px] items-center justify-center rounded-l-lg border-r bg-grey-50 p-2 text-grey-500 group-hover:h-[54px] group-hover:text-primary">
                  <span>Custom</span>
                </div>
                <div
                  className={`flex h-full items-center p-2 text-[18px] ${
                    selectedSwapSlippage === SwapSlippage.CUSTOM ? 'text-gray-800' : 'bg-opacity-15three text-primary'
                  }`}
                >
                  <Input
                    numeric
                    className={`mr-1 h-full w-full bg-transparent text-right`}
                    onChange={(e) => handleSlippage(e)}
                    value={slippageDisplay}
                    min={0.1}
                  />
                  <span className="text-[18px]">%</span>
                </div>
              </button>
            </div>
            <SlippageBreakdown />
          </div>
          <div
            onClick={handleSelect}
            className="mt-6 flex h-[78px] cursor-pointer items-center justify-center rounded-full bg-primary px-12 py-8 text-white md:w-[552px]"
          >
            <span>Select</span>
          </div>
        </div>

        <div className="absolute bottom-10 left-10 hidden md:block">
          <div
            className={`flex w-[242px] flex-col rounded-[25px] bg-white p-6 text-sm font-normal ${
              !showSlippageInfo && 'h-[164px]'
            }`}
          >
            <div
              className={`${
                showSlippageInfo
                  ? 'text-grey-500'
                  : 'overflow-hidden bg-gradient-to-b from-grey-500 to-white bg-clip-text text-transparent'
              }`}
            >
              Slippage is when the price you expect to pay for an asset is different from the final price at which the
              trade is executed. This can happen in markets where prices can fluctuate rapidly. To mitigate the risk of
              slippage, traders can set a maximum percentage they're willing to pay if the token price changes during
              the transaction.
            </div>
            <div className={`flex ${showSlippageInfo && 'mt-4'}`}>
              <div
                onClick={() => setShowSlippageInfo(!showSlippageInfo)}
                className="flex cursor-pointer items-center rounded-[18px] bg-primary text-white"
              >
                <span className="px-2 py-1 font-semibold text-xs">{showSlippageInfo ? 'Got it' : 'Read more'}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
});

export default SwapSettings;
