import { Swap, SwapState } from '@catalabs/catalyst-api-client';
import { getToken } from '@catalabs/catalyst-token-lists';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { CatalystNetwork } from '~/config/network/catalyst-network';
import { ReactComponent as Close } from '~/img/close.svg';
import { StoreContext, TokenDisplay } from '~/modules/common';
import { SwapStep } from '~/modules/swap';
import { SwapQuote } from '~/modules/swap/interfaces';

export interface SwapCompletionProps {
  swap: Swap;
  view: () => void;
  remove: () => void;
  quote: SwapQuote;
}

export function SwapCompletion({ swap, view, remove, quote }: SwapCompletionProps) {
  const { input, output } = swap;
  const { fromAsset, toAsset, toChainId, fromChainId } = quote;

  const {
    config: { name: fromName },
  } = CatalystNetwork.getCatalystNetwork(fromChainId);
  const toChain = CatalystNetwork.getCatalystNetwork(toChainId);
  const {
    config: { name: toName },
  } = toChain;

  const fromToken = getToken(fromChainId, fromAsset);
  const toToken = getToken(toChainId, toAsset);

  const isError = swap.state === SwapState.TIMED_OUT || swap.state === SwapState.REVERTED;

  const hash = swap.toHash !== '' ? swap.toHash : swap.swapHash;

  return (
    <div className="flex w-[274px] flex-col rounded-[25px] bg-white shadow">
      <div className="flex w-full justify-end px-5 pb-2 pt-5">
        <div className="z- cursor-pointer" onClick={remove}>
          <Close className="text-grey-400" />
        </div>
      </div>
      <div className="flex flex-col items-center px-6 pb-8">
        {!isError && (
          <>
            <div className="text-[20px] leading-[17px]">Swap Completed</div>
            <div className="flex w-full justify-between pb-6 pt-8">
              <div className="flex flex-col items-start text-sm leading-5">
                <div className="pb-2">
                  <TokenDisplay chainId={fromChainId} token={fromAsset} showSymbol={false} showTooltip={false} />
                </div>
                <div className="font-semibold">
                  {input.toLocaleString(undefined, { maximumFractionDigits: 8 })} {fromToken.symbol}
                </div>
                <div className="font-normal text-gray-600">{fromName}</div>
              </div>
              <div className="flex flex-col items-end text-sm leading-5">
                <div className="pb-2">
                  <TokenDisplay chainId={toChainId} token={toAsset} showSymbol={false} showTooltip={false} />
                </div>
                <div className="font-semibold">
                  {output.toLocaleString(undefined, { maximumFractionDigits: 8 })} {toToken.symbol}
                </div>
                <div className="font-normal text-gray-600">{toName}</div>
              </div>
            </div>
          </>
        )}
        {isError && (
          <div className="mb-6 flex flex-col items-center gap-4 p-4">
            <div className="text-[20px] leading-[17px] text-red-600">Swap Failed</div>
            <div className="text-sm">Funds will be refunded on the source chain in ~1 hour.</div>
          </div>
        )}
        <div
          onClick={() => window.open(toChain.getTransactionUrl(hash))}
          className="mb-2 flex w-full cursor-pointer justify-center rounded-[18px] bg-primary py-3  text-[12px] leading-[10px] text-white"
        >
          View Transactions
        </div>
        <div
          onClick={view}
          className="flex w-full cursor-pointer justify-center rounded-[18px] bg-primary py-3  text-[12px] leading-[10px] text-white"
        >
          Open Receipt
        </div>
      </div>
    </div>
  );
}

export const SwapCompletionTracker = observer(() => {
  const { swap } = useContext(StoreContext);
  const { completedSwaps } = swap;

  if (completedSwaps.length === 0) {
    return null;
  }

  function removeItem(index: number) {
    runInAction(() => {
      swap.completedSwaps = swap.completedSwaps.splice(0, index).concat(swap.completedSwaps.splice(index + 1));
    });
  }

  function viewItem(completedSwap: Swap, quote: SwapQuote, index: number) {
    let swapStep: SwapStep;

    switch (completedSwap.state) {
      case SwapState.REVERTED:
      case SwapState.TIMED_OUT:
        swapStep = SwapStep.Failure;
        break;
      default:
        swapStep = SwapStep.Confirmation;
        break;
    }
    runInAction(() => {
      swap.swapStep = swapStep;
      swap.swapInfo = completedSwap;
      swap.lastQuote = quote;
    });
    removeItem(index);
  }
  return completedSwaps.map(({ swap, quote }, i) => (
    <SwapCompletion quote={quote} swap={swap} remove={() => removeItem(i)} view={() => viewItem(swap, quote, i)} />
  ));
});

export default SwapCompletionTracker;
