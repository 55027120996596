import { createBrowserRouter } from 'react-router-dom';

import CatalystApp from '~/CatalystApp';
import { SHOW_POOL_CREATION, SHOW_TRANSACTION_HISTORY, SHOW_USER_PORTFOLIO } from '~/config/environment';
import { routes } from '~/config/routes';
import NotFound from '~/modules/common/pages/404';
import GeoFencing from '~/modules/common/pages/GeoFencing';
import PrivacyPolicy from '~/modules/common/pages/PrivacyPolicy';
import TermsOfService from '~/modules/common/pages/TermsOfService';
import { PoolCreatePage, PoolPage, PoolsList } from '~/modules/pools';
import { PortfolioPage } from '~/modules/portfolio';
import { RewardsPage } from '~/modules/rewards/pages/RewardsPage';
import { SwapPage } from '~/modules/swap';
import { TransactionsPage } from '~/modules/transactions';

const router = createBrowserRouter([
  {
    path: routes.root,
    element: <CatalystApp />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <SwapPage />,
      },
      {
        path: routes.swap,
        element: <SwapPage />,
      },
      {
        path: routes.restricted,
        element: <GeoFencing />,
      },
      {
        path: routes.pools,
        element: <PoolsList />,
      },
      {
        path: routes.pool,
        element: <PoolPage />,
      },
      ...(SHOW_POOL_CREATION
        ? [
            {
              path: routes.poolCreate,
              element: <PoolCreatePage />,
            },
          ]
        : []),
      {
        path: routes.join,
        element: <RewardsPage />,
        children: [
          {
            path: '*',
            element: <RewardsPage />,
          },
        ],
      },
      {
        path: routes.rewards,
        element: <RewardsPage />,
      },
      ...(SHOW_USER_PORTFOLIO
        ? [
            {
              path: routes.userPortfolio,
              element: <PortfolioPage />,
            },
          ]
        : []),
      ...(SHOW_TRANSACTION_HISTORY
        ? [
            {
              path: routes.transactionHistory,
              element: <TransactionsPage />,
            },
          ]
        : []),
      {
        path: routes.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: routes.termsOfService,
        element: <TermsOfService />,
      },
    ],
  },
]);

export default router;
