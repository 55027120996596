import { PoolAsset } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { useState } from 'react';

import { AmountInput, formatCurrency, formatPercentage, formatValue } from '~/modules/common';
import { BalanceDisplay } from '~/modules/common/components/BalanceDisplay';

import { getAssetKey } from '../utils';
import { AssetInputOptionsDropdown } from './AssetInputOptionsDropdown';

interface WithdrawBalancePaneInputProps {
  asset: PoolAsset;
  amount: string;
  value: number;
  balance: number;
  handleUseGasToken: (asset: PoolAsset, useGasToken: boolean) => void;
  handleChange: (asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDepositPaneClick: (asset: PoolAsset) => void;
  handleBlur: (asset: PoolAsset) => void;
  handleClickMax: (asset: PoolAsset) => void;
}

export function WithdrawBalancedPane({
  amount,
  asset,
  value,
  balance,
  handleUseGasToken,
  handleChange,
  handleDepositPaneClick,
  handleBlur,
  handleClickMax,
}: WithdrawBalancePaneInputProps) {
  const [hasFocus, setHasFocus] = useState(false);
  const [useGasToken, setUseGasToken] = useState(true);

  const assetKey = getAssetKey(asset);
  const gasAssetKey = getAssetKey({ chainId: asset.chainId, address: GAS_TOKEN_IDENTIFIER });
  const assetOptions = [gasAssetKey, assetKey];
  const formattedAmount = formatValue(Number(amount), 8);

  const formattedBalance = formatValue(balance, 8);
  const hasError = Number(formattedAmount) > Number(formattedBalance);

  const weight = asset.weight;

  const getBorderClass = (hasError: boolean, hasFocus: boolean) => {
    return hasError ? 'border-red-600' : hasFocus ? 'border-primary-600' : 'border-gray';
  };

  return (
    <div className="flex flex-col gap-3 md:flex-row md:gap-4">
      <div
        className={`flex w-full flex-col items-start justify-between gap-6 rounded-[12px] border border-gray-300 px-4 py-3 md:flex-row md:gap-0 ${getBorderClass(
          hasError,
          hasFocus,
        )} cursor-pointer`}
      >
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-2">
            <AssetInputOptionsDropdown
              assetKeys={assetOptions}
              onChange={(assetKey) => {
                setUseGasToken(assetKey === gasAssetKey);
                handleUseGasToken(asset, assetKey === gasAssetKey);
              }}
              selected={useGasToken ? gasAssetKey : assetKey}
            />
            <BalanceDisplay balance={balance} error={false} showMax={true} onClick={() => handleClickMax(asset)} />
          </div>

          <div className="flex flex-col items-end justify-between py-[3px]">
            <AmountInput
              className={`font-subheader ${
                hasError ? 'text-red-600' : 'text-gray-800'
              }  h-[28px] bg-transparent text-right text-[32px]`}
              value={formattedAmount}
              onChange={(e) => handleChange(asset, e)}
              onFocus={() => setHasFocus(true)}
              onBlur={() => {
                setUseGasToken(false);
                handleBlur(asset);
              }}
              onClick={() => handleDepositPaneClick(asset)}
            />
            <span className={`mt-2 text-[14px] leading-[12px] text-gray-500`}>{formatCurrency(value)}</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between rounded-[12px] bg-gray-100 px-2 py-1 md:min-w-[116px] md:flex-col md:justify-center md:px-3 md:py-2">
        <span className={`text-[12px] leading-[12px] text-gray-500`}>Token Weight</span>
        <div className="flex">
          <span
            className="flex
                     w-full min-w-0 text-right text-gray-500 focus:outline-none "
          >
            {formatPercentage(weight, 2)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default WithdrawBalancedPane;
