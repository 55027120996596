import { ethers } from 'ethers';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';

import { ReactComponent as Exclamation } from '~/img/exclamation.svg';
import { Modal } from '~/modules/common';
import { Input } from '~/modules/common/components/Input';
import { StoreContext } from '~/modules/common/store/context';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';

export interface AddressInputProps {
  open: boolean;
  onClose: () => void;
}

export const AddressInput = observer(({ onClose, open }: AddressInputProps) => {
  const { swap } = useContext(StoreContext);
  const { displayAddress } = useConnectButton();

  const [addressAlert, setAddressAlert] = useState(false);
  const [address, setAddress] = useState('');

  if (!open) {
    return null;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = event.target.value;
    setAddress(newAddress);
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    if (!addressRegex.test(newAddress)) {
      setAddressAlert(true);
    } else {
      setAddressAlert(false);
    }
    // TODO: this will need to change with cosmos onboarding
    try {
      ethers.getAddress(newAddress);
    } catch {
      setAddressAlert(true);
    }
  };

  function setToAccount() {
    if (!addressAlert) {
      if (address.length > 0) {
        runInAction(() => {
          swap.toAccount = ethers.getAddress(address);
        });
      }
      onClose();
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="mx-3 flex w-full flex-col md:mx-auto md:w-fit">
        <div className="mb-4 font-semibold text-xl">Recipient address</div>
        <div className="flex flex-col gap-2 rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="text-sm text-grey-700">Type the address you want to receive your tokens</div>
          <div
            className={`border-1 flex h-[38px] w-full rounded-lg border bg-white md:w-[504px] ${
              addressAlert ? 'border-red-700 pr-2 text-red-700' : 'border-grey-300'
            }`}
          >
            <Input
              onChange={handleInputChange}
              className="mx-1 ml-[13px] text-sm md:w-[460px]"
              placeholder={displayAddress ?? 'Type here'}
            />
            {addressAlert && <Exclamation />}
          </div>
          {addressAlert ? <span className="text-grey-500">Input a valid address</span> : ''}
        </div>
        <div
          onClick={setToAccount}
          className="mt-6 flex h-[78px] cursor-pointer items-center justify-center rounded-full bg-primary px-12 py-8 text-white md:w-[552px]"
        >
          <span>Save</span>
        </div>
      </div>
    </Modal>
  );
});

export default AddressInput;
