import { ChainConfig } from '@catalabs/catalyst-chain-lists';
import { getTokenList } from '@catalabs/catalyst-token-lists';
import { TokenInfo, TokenList } from '@uniswap/token-lists';

import { ENVIRONMENT } from '~/config/environment';
import { CatalystNetwork } from '~/config/network';
import { buildNetworkConfig } from '~/config/utils';

export class EvmNetwork extends CatalystNetwork {
  readonly tokenList: TokenList;

  constructor(readonly chain: ChainConfig) {
    super(buildNetworkConfig(chain), ENVIRONMENT);
    try {
      this.tokenList = getTokenList(chain.id.toString());
    } catch {
      this.tokenList = {
        name: chain.name,
        version: {
          major: 0,
          minor: 0,
          patch: 0,
        },
        timestamp: new Date().toDateString(),
        tokens: [],
      };
    }
  }

  getSupportedAssets(): TokenInfo[] {
    return this.tokenList.tokens;
  }

  getAccountUrl(address: string): string {
    return this.config.explorerUrl.concat('/address/').concat(address);
  }

  getTransactionUrl(hash: string): string {
    return this.config.explorerUrl.concat('/tx/').concat(hash);
  }
}
