import { getToken } from '@catalabs/catalyst-token-lists';

import { SwapReviewLine, SwapSlippage } from '~/modules/common';
import { shortenAddress } from '~/modules/common/utils';
import SwapCosts from '~/modules/swap/components/SwapCosts';
import SwapExchangeRate from '~/modules/swap/components/SwapExchangeRate';
import SwapReviewCard from '~/modules/swap/components/SwapReviewCard';
import { SwapCardType } from '~/modules/swap/enums';

import { SwapQuote } from '../interfaces';

export interface SwapReviewProps {
  quote: SwapQuote;
  tokenPrice: number;
}

export function SwapReview({ quote, tokenPrice }: SwapReviewProps): JSX.Element {
  const fromAsset = getToken(quote.fromChainId, quote.fromAsset);
  const toAsset = getToken(quote.toChainId, quote.toAsset);
  return (
    <div className="mt-4 flex w-full flex-col md:mt-10">
      <div className="flex w-full flex-col gap-4 md:flex-row">
        <SwapReviewCard
          cardType={SwapCardType.Input}
          chainId={quote.fromChainId}
          token={quote.fromAsset}
          amount={quote.fromAmount}
          value={quote.fromValue}
        />
        <SwapReviewCard
          cardType={SwapCardType.Output}
          chainId={quote.toChainId}
          token={quote.toAsset}
          amount={quote.amountOut}
          value={quote.toValue}
        />
      </div>
      <div className="mt-5">
        <SwapReviewLine title="Recipient Address" value={shortenAddress(quote.toAccount)} />
      </div>
      <div className="mb-5 mt-5 flex space-x-4">
        <SwapExchangeRate
          fromAsset={fromAsset}
          toAsset={toAsset}
          exchangeRate={quote.exchangeRate}
          tokenPrice={tokenPrice}
          fullWidth={true}
        />
      </div>
      <SwapSlippage />
      <div className="mt-5">
        <SwapReviewLine
          title="Price Impact"
          value={`${quote.priceImpact.toLocaleString(undefined, { minimumFractionDigits: 4 })}%`}
        />
      </div>
      <div className="mt-5">
        <SwapCosts feeDetails={quote.feeDetails} />
      </div>
    </div>
  );
}

export default SwapReview;
