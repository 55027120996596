import { TokenInfo } from '@uniswap/token-lists';

import { formatCurrency } from '~/modules/common/utils';

export interface SwapExchangeRateProps {
  fromAsset: TokenInfo;
  toAsset: TokenInfo;
  exchangeRate: number;
  tokenPrice: number;
  fullWidth?: boolean;
}

export function SwapExchangeRate({
  fromAsset,
  toAsset,
  exchangeRate,
  tokenPrice,
  fullWidth = false,
}: SwapExchangeRateProps): JSX.Element {
  return (
    <div
      className={`flex justify-between rounded-[20px] bg-white px-4 py-2 text-[14px] ${fullWidth ? 'w-full' : 'w-1/2'}`}
    >
      <span className="uppercase text-gray-600">
        1 {fromAsset.symbol} = {exchangeRate.toFixed(6)} {toAsset.symbol}
      </span>
      <span className="font-semibold font-subheader text-gray-800">{formatCurrency(tokenPrice)}</span>
    </div>
  );
}

export default SwapExchangeRate;
