import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';

import { isWrapUnwrap, SwapReviewLine, SwapSlippage } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { formatPercentage, getAnimationState, shortenAddress } from '~/modules/common/utils/display.utils';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';
import AddressInput from '~/modules/swap/components/AddressInput';
import SwapCosts from '~/modules/swap/components/SwapCosts';
import SwapDurationEstimate from '~/modules/swap/components/SwapDurationEstimate';
import SwapExchangeRate from '~/modules/swap/components/SwapExchangeRate';
import { SwapError } from '~/modules/swap/enums';

import { SwapSpeedInput } from './SwapSpeedInput';

export interface SwapButtonProps {
  buttonText: string;
  hasError: boolean;
  tooltipText?: string;
  initiateSwap?: () => void;
}

export function SwapButton({ buttonText, initiateSwap, hasError, tooltipText }: SwapButtonProps): JSX.Element {
  const [showTooltip, setShowTooltip] = useState(false);

  function handleTooltip(e: React.MouseEvent<HTMLButtonElement>) {
    if (!showTooltip || !tooltipText) {
      return;
    }
    const swapTooltip = document.getElementById('swap-tooltip');
    const swapButton = document.getElementById('swap-button');
    if (swapTooltip && swapButton) {
      const bounds = swapButton.getBoundingClientRect();
      swapTooltip.style.left = e.clientX - bounds.left - 125 + 'px';
      swapTooltip.style.top = e.clientY - bounds.top - 65 + 'px';
    }
  }

  return (
    <button
      id="swap-button"
      onMouseEnter={() => {
        if (tooltipText) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => {
        if (tooltipText) {
          setShowTooltip(false);
        }
      }}
      onMouseMove={handleTooltip}
      onClick={initiateSwap}
      className={`relative mt-6 flex h-16 w-full items-center justify-center rounded-full px-12 py-8 text-white md:mt-[56px] md:w-[760px] ${
        hasError ? 'bg-primary bg-opacity-30' : 'cursor-pointer bg-primary hover:bg-primary-800'
      }`}
      data-testid="swap-button"
      role="button"
      disabled={hasError}
    >
      <div
        id="swap-tooltip"
        className={`absolute z-20 w-[249px] rounded-[25px] bg-white p-6 text-center text-sm font-normal text-gray-600 shadow ${
          showTooltip ? 'visible' : 'invisible'
        }`}
      >
        {tooltipText}
      </div>
      <span className="font-subheader tracking-wide" data-testid="swap-button-text">
        {buttonText}
      </span>
    </button>
  );
}

export const SwapDetails = observer(() => {
  const { swap, wallet, catalyst } = useContext(StoreContext);
  const {
    lastQuote,
    targetAsset,
    sourceAsset,
    toAccount,
    securityLimitActivated,
    hasSufficientTokens,
    hasSufficientGas,
    swapError,
  } = swap;
  const { address, isConnected } = wallet;

  const [recipient, setRecipient] = useState(toAccount ?? address);
  const [recipientModal, setRecipientModal] = useState(false);
  const recipientMandatory = false;
  const [expanded, setExpanded] = useState(false);
  const { connect } = useConnectButton();

  useEffect(() => {
    setRecipient(toAccount ?? address);
  }, [toAccount, address]);

  // if wallet is not connect, connect to chain
  if (!isConnected) {
    const handleConnect = () => {
      connect();
    };
    return <SwapButton buttonText="Connect Wallet" hasError={false} initiateSwap={handleConnect} />;
  }
  // if asset is not selected or last quote is not available, show swap button but disable it
  if (!sourceAsset || !targetAsset || !lastQuote || !recipient) {
    if (isConnected && swapError !== undefined && swapError === SwapError.NO_ROUTE) {
      return <SwapButton buttonText="No Route Available" hasError={true} />;
    } else {
      return <SwapButton buttonText="Swap" hasError={true} />;
    }
  }

  const { feeDetails } = lastQuote;
  const recipientAddress = shortenAddress(recipient);
  const sourceTokenPrice = catalyst.getPrice(sourceAsset);

  const isWrap = isWrapUnwrap(lastQuote);
  const isWrapOperation = isWrap && targetAsset.address !== GAS_TOKEN_IDENTIFIER;
  const hasError = securityLimitActivated || !hasSufficientTokens || !hasSufficientGas || swapError !== undefined;

  let tooltipText = undefined;
  let buttonText = 'Swap';
  if (securityLimitActivated) {
    tooltipText = 'Catalyst has a Security Limit that flags anamolous transactions.';
    buttonText = 'Security Limit Activated';
  } else if (!hasSufficientTokens && !hasSufficientGas) {
    buttonText = 'Insufficient Tokens and Gas';
  } else if (!hasSufficientTokens) {
    buttonText = 'Insufficient Tokens';
  } else if (!hasSufficientGas) {
    buttonText = 'Insufficient Gas';
  } else if (swapError) {
    buttonText = 'Error';
  } else if (isWrap) {
    buttonText = isWrapOperation ? 'Wrap' : 'Unwrap';
  }

  function initiateSwap() {
    if (!hasError) {
      swap.swap();
    }
  }

  return (
    <div className="w-full md:mx-auto md:w-fit">
      <SwapButton buttonText={buttonText} initiateSwap={initiateSwap} hasError={hasError} tooltipText={tooltipText} />
      <AddressInput open={recipientModal} onClose={() => setRecipientModal(false)} />
      <div className="flex flex-col gap-[20px]">
        <div className="mt-6 flex flex-col gap-4 md:flex-row md:gap-2  ">
          <div
            className={`flex w-full items-center justify-between rounded-[20px] bg-white py-2  ${
              !isWrap && 'md:w-1/2'
            }`}
          >
            <span className="ml-4 text-[14px] leading-5 text-gray-600">Recipient Address</span>
            <div className="mr-2 flex">
              {recipientMandatory ? (
                <div
                  onClick={() => setRecipientModal(true)}
                  className="flex h-5 w-[89px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-white"
                >
                  <span className="text-xs">Add address</span>
                </div>
              ) : (
                <>
                  <span className="font-subheader text-[14px] leading-5 text-gray-800">{recipientAddress}</span>
                  <div
                    onClick={() => setRecipientModal(true)}
                    className="ml-2 flex cursor-pointer items-center rounded-[18px] bg-primary px-2 py-[5px] text-[12px] leading-[10px] text-white"
                  >
                    Edit
                  </div>
                </>
              )}
            </div>
          </div>

          {!isWrap && <SwapSpeedInput />}
        </div>

        <div className="flex w-full space-x-4">
          <SwapExchangeRate
            fromAsset={sourceAsset}
            toAsset={targetAsset}
            exchangeRate={lastQuote.exchangeRate}
            tokenPrice={sourceTokenPrice}
            fullWidth
          />
        </div>

        <SwapCosts feeDetails={feeDetails} sufficientGas={hasSufficientGas} />

        {expanded ? (
          <div className="flex flex-col gap-[20px]">
            <SwapSlippage allowEdit={true} />
            <div>
              <SwapReviewLine title="Price Impact" value={formatPercentage(lastQuote.priceImpact * 100)} />
            </div>

            <div className="flex w-full">
              <SwapDurationEstimate quote={lastQuote} fullWidth swapSpeed={swap.swapSpeed} />
            </div>
          </div>
        ) : null}

        <div
          className="flex w-full cursor-pointer items-center justify-center gap-2"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <span>{expanded ? 'Show Less' : 'Show More'}</span>
          <ChevronDownIcon className={`h-5 w-5 text-gray-600 ${getAnimationState(!expanded)}`} />
        </div>
      </div>
    </div>
  );
});

export default SwapDetails;
