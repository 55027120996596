export interface PendingPoolCreationDepositBarProps {
  handleWithdraw: () => void;
  handleResume: () => void;
}

export const PendingPoolCreationDepositBar = ({ handleWithdraw, handleResume }: PendingPoolCreationDepositBarProps) => {
  return (
    <>
      <div className="flex w-full flex-shrink-0 items-center justify-center gap-6 bg-red-500 py-4">
        <p className="text-white">Remember to complete your deposit to finish your pool creation.</p>
        <button
          onClick={handleWithdraw}
          className="flex items-center justify-center rounded-full border-[1px] border-white bg-red-500 px-[12px] py-[4px] text-xs text-white"
        >
          Withdraw Funds
        </button>
        <button
          onClick={handleResume}
          className="flex items-center justify-center rounded-full bg-white px-[12px] py-[4px] text-xs text-gray-900"
        >
          Resume Progress
        </button>
      </div>
    </>
  );
};

export default PendingPoolCreationDepositBar;
