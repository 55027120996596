import { FeeDetail, Pool } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER, prepareWithdrawWithLiquiditySwap } from '@catalabs/catalyst-sdk';
import { formatUnits } from 'ethers';

import { CatalystNetwork, formatBalance } from '~/config';
import { ChainGasFeeData, WithdrawRequest, WithdrawWithLiquiditySwapInputs } from '~/modules/common';

import { ChainWithdraw } from '../interfaces';
import { getAssetKey } from './pools.utils';
import { sortAndfilterOutZeroWithdrawRequests } from './withdraw.utils';

export const getBaseWithdrawFeeDetails = ({
  sortedRequests,
  gasData,
  gasTokenPrices,
}: {
  sortedRequests: ChainWithdraw[];
  gasData: ChainGasFeeData;
  gasTokenPrices: Map<string, number>;
}): FeeDetail[] => {
  const feeDetails: FeeDetail[] = [];
  let executionFee: number = 0;
  for (const withdrawRequest of sortedRequests) {
    const chainDetails = CatalystNetwork.getCatalystNetwork(withdrawRequest.chainId);
    const baseRequest = withdrawRequest.request[0];
    const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = gasData[baseRequest.chainId];
    const supportsEip1559 = maxPriorityFeePerGas !== null || maxPriorityFeePerGas !== 0n;
    const feeInGas = supportsEip1559 ? maxFeePerGas : gasPrice;
    const gasTokenPrice =
      gasTokenPrices.get(
        getAssetKey({
          address: GAS_TOKEN_IDENTIFIER,
          chainId: chainDetails.config.chainId,
        }),
      ) || 1;
    const totalGasPrice = BigInt('190000') * BigInt(feeInGas);
    const feeInUSD = Number(formatUnits(totalGasPrice)) * gasTokenPrice;
    executionFee += feeInUSD;
  }
  feeDetails.push({
    amount: executionFee,
    currency: 'USD',
    name: 'Execution Fee',
    value: executionFee,
  } as FeeDetail);
  return feeDetails;
};

export const getWithdrawFeeDetails = ({
  withdrawRequest,
  gasData,
  gasTokenPrices,
}: {
  withdrawRequest: WithdrawRequest;
  gasData: ChainGasFeeData;
  gasTokenPrices: Map<string, number>;
  pool: Pool;
}): FeeDetail[] => {
  const sortedRequests = sortAndfilterOutZeroWithdrawRequests({
    request: withdrawRequest,
  });
  return getBaseWithdrawFeeDetails({
    sortedRequests,
    gasData,
    gasTokenPrices,
  });
};

export const getWithdrawWithLiquiditySwapsFeeDetails = ({
  txInputs,
  withdrawRequest,
  gasTokenPrices,
  gasData,
}: {
  withdrawRequest: WithdrawRequest;
  gasData: ChainGasFeeData;
  gasTokenPrices: Map<string, number>;
  pool: Pool;
  txInputs: WithdrawWithLiquiditySwapInputs;
  address: string;
}): FeeDetail[] => {
  const {
    userVaultTokens,
    userWithdrawals,
    chains,
    vaults,
    vaultAddresses,
    assetsAddresses,
    userAddresses,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    refundGasTo,
    routerAddresses,
    unWrapGas,
  } = txInputs;

  const responses = prepareWithdrawWithLiquiditySwap(
    userVaultTokens,
    userWithdrawals,
    chains,
    vaults,
    vaultAddresses,
    assetsAddresses,
    userAddresses,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    refundGasTo,
    routerAddresses,
    unWrapGas,
  );

  const sortedRequestsByChain = sortAndfilterOutZeroWithdrawRequests({
    request: withdrawRequest,
    isLiquiditySwap: true,
  });
  const feeDetails: FeeDetail[] = getBaseWithdrawFeeDetails({
    sortedRequests: sortedRequestsByChain,
    gasData,
    gasTokenPrices,
  });
  let routingFee: number = 0;
  for (const request of sortedRequestsByChain) {
    const response = responses.find((_, i) => chains[i].chainId.toString() === request.chainId);

    if (!response) {
      throw new Error('Invalid routerArgs, gasUsage, or transferDetails');
    }
    const { routerArgs, gasUsage, transferDetails } = response;
    if (!routerArgs) {
      continue;
    }
    const { executionInstructions } = routerArgs.transferWithPermitForWithdrawWithLiquiditySwap(
      transferDetails,
      gasUsage,
    );
    const { estimatedRoutingPayment } = executionInstructions.gas;

    const sourceGasTokenKey = getAssetKey({
      address: GAS_TOKEN_IDENTIFIER,
      chainId: request.chainId,
    });
    const sourceGasTokenPrice = gasTokenPrices.get(sourceGasTokenKey) || 1;

    const formattedSwapFeeAmount = formatBalance((estimatedRoutingPayment * 12n) / 10n);
    routingFee += sourceGasTokenPrice * formattedSwapFeeAmount;
  }
  feeDetails.push({
    amount: routingFee,
    currency: 'USD',
    name: 'Routing Fee',
    value: routingFee,
  } as FeeDetail);
  return feeDetails;
};
