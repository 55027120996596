import { swapByRouteViaPermit } from '@catalabs/catalyst-sdk';
import { runInAction } from 'mobx';
import { useContext } from 'react';

import { formatBalance, getAssetLookupAddress, isGasToken, parseBalance, toUnits } from '~/config/utils/tokens.utils';
import { getMaxFeePerGas, getMaxGasFee, isCrossChainSwap } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';

export function useQuote() {
  const { swap, wallet, client, catalyst } = useContext(StoreContext);
  const { sourceNetwork, targetNetwork, sourceAsset } = swap;

  const lookupConvertedAddress = sourceAsset ? getAssetLookupAddress(sourceAsset) : undefined;

  async function triggerQuote(amount: string): Promise<void> {
    if (!lookupConvertedAddress || !sourceNetwork) {
      return;
    }
    const quoteBalance = wallet.getBalance(sourceNetwork, lookupConvertedAddress);
    const parsedAmount = Number(amount);
    if (quoteBalance && parsedAmount > 0) {
      await swap.quote(toUnits(amount, quoteBalance.decimals));
    } else {
      runInAction(() => {
        swap.fetchingQuote = false;
        swap.lastQuote = undefined;
      });
    }
  }

  async function getMaxSwapAmount(userBalance: number): Promise<string> {
    try {
      if (!sourceNetwork || !targetNetwork || !sourceAsset) {
        throw new Error('Invalid sourceNetwork, targetNetwork, or sourceAsset detected.');
      }

      runInAction(() => {
        swap.fetchingQuote = true;
      });

      let maxSwapAmount = parseBalance(userBalance.toString());

      if (isGasToken(sourceAsset)) {
        const { address } = wallet;

        if (!swap.sourceNetwork || !address) {
          throw new Error('Invalid sourceNetwork or wallet address detected, make sure wallet is connected.');
        }

        if (!swap.sourceAsset || !swap.targetAsset || maxSwapAmount <= 0n) {
          throw new Error('Invalid source or target asset detected, make sure the amount is greater than 0.');
        }

        // generate quote
        const quote = await client.getQuote({
          fromChainId: swap.sourceAsset.chainId.toString(),
          fromAsset: swap.sourceAsset.address,
          toChainId: swap.targetAsset.chainId.toString(),
          toAsset: swap.targetAsset.address,
          fromAmount: maxSwapAmount.toString(),
          toAccount: swap.toAccount ?? address,
          depth: 4,
          slippage: swap.maxSlippage / 100,
          fastQuote: true,
          underwriterFeePercent: swap.fastSwapFee,
        });

        await wallet.connectNetwork(swap.sourceNetwork);

        const {
          toAccount,
          fromAsset,
          toAsset,
          toChainId,
          fromChainId,
          channelId,
          route,
          toAssetIndex,
          netMinOut,
          amount: amountFromQuote,
          priceOfDeliveryGas,
          targetDelta,
          additionalCosts,
        } = quote;

        const messageVerifyGasCost = additionalCosts.amount;

        const gasFeeDataByChainId = await catalyst.getGasFeeData([swap.sourceNetwork]);
        const maxGasFee = getMaxGasFee(gasFeeDataByChainId[swap.sourceNetwork]);
        const priceOfAckGas = getMaxFeePerGas(gasFeeDataByChainId[swap.sourceNetwork]);

        let estimatedRoutingPayment = 0n;
        let underwritingIncentive = 0n;
        if (isCrossChainSwap(quote)) {
          // underwriter incentive is a percentage of the swap amount
          const incentiveAmount = (1 << 16) * (swap.fastSwapFee / 100);
          underwritingIncentive = BigInt(Math.floor(incentiveAmount));

          // call swapByRouteViaPermit to get estimatedRoutingPayment
          const {
            executionInstructions: { gas },
          } = swapByRouteViaPermit({
            toAccount,
            fromAsset,
            toAsset,
            toChainId,
            fromChainId,
            channelId,
            route,
            toAssetIndex,
            minOut: BigInt(netMinOut),
            amount: BigInt(amountFromQuote),
            refundGasTo: address,
            priceOfDeliveryGas: BigInt(priceOfDeliveryGas),
            messageVerifyGasCost: BigInt(messageVerifyGasCost),
            targetDelta: BigInt(targetDelta),
            priceOfAckGas,
            underwritingIncentive,
          });
          estimatedRoutingPayment = gas.estimatedRoutingPayment;
        }

        maxSwapAmount = maxSwapAmount - maxGasFee;
        maxSwapAmount = maxSwapAmount - underwritingIncentive;
        maxSwapAmount = maxSwapAmount - (estimatedRoutingPayment * 12n) / 10n;
      } else {
        maxSwapAmount = maxSwapAmount - parseBalance('1', 'gwei');
      }

      if (maxSwapAmount < 0n) {
        throw new Error('Insufficient balance to proceed with the swap.');
      }

      return formatBalance(maxSwapAmount).toString();
    } catch (err) {
      runInAction(() => {
        swap.fetchingQuote = false;
      });

      // return 0.00 if there is an error
      return '0.00';
    }
  }

  return {
    triggerQuote,
    getMaxSwapAmount,
  };
}

export default useQuote;
