import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { openNewTabOrNavigate } from '~/config';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { NAV_ITEMS, NAV_LINKS } from '~/modules/navigation/constants/nav.constants';

export const MobileNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentPath = NAV_ITEMS.findIndex((item) => matchPath(item.href, pathname));
  const [selected, setSelected] = useState(currentPath == -1 ? 0 : currentPath);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown.Root open={isOpen} onOpenChange={(val) => setIsOpen(val)}>
      <Dropdown.Trigger className="focus:outline-none">
        <div className="relative flex cursor-pointer items-center gap-2 rounded-full bg-primary bg-opacity-15 p-3 font-semibold text-sm text-primary">
          {NAV_ITEMS[selected].title}
          <Caret className="-rotate-90 text-primary" />
        </div>
      </Dropdown.Trigger>

      <Dropdown.Content sideOffset={12}>
        <div className="relative z-10 mx-3 flex w-[calc(100vw-24px)] cursor-pointer flex-col items-center gap-6 rounded-3xl bg-white py-5 md:w-[300px]">
          <div className="flex w-full flex-col gap-2 px-5">
            {NAV_ITEMS.map((item, index) => (
              <div
                key={item.title}
                className="w-full rounded-3xl bg-primary py-3 text-center text-white"
                onClick={() => {
                  navigate(item.href);
                  setIsOpen(false);
                  setSelected(index);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
          <hr className="w-full text-grey-200" />
          <div className="flex w-full flex-col justify-start gap-4 px-5 text-grey-800">
            {NAV_LINKS.map((link) => {
              return (
                <div
                  key={link.title}
                  className="cursor-pointer"
                  onClick={() => openNewTabOrNavigate(link.href, navigate)}
                >
                  {link.title}
                </div>
              );
            })}
          </div>
        </div>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
