import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { CatalystNetwork } from '~/config/network/catalyst-network';
import { ReactComponent as Contract } from '~/img/contract.svg';
import { ReactComponent as Explorer } from '~/img/explorer.svg';
import { formatCurrency, formatHash, formatValue, Modal, TokenDisplay } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { SwapError, SwapStep } from '~/modules/swap/enums';
import { useQuote } from '~/modules/swap/hooks';

export const SwapFailure = observer(() => {
  const store = useContext(StoreContext);
  const { swap } = store;
  const { swapInfo, lastQuote } = swap;

  const { triggerQuote } = useQuote();

  const open = swap.swapStep === SwapStep.Failure;

  function onClose() {
    swap.resetSwap();
  }

  function handleTransactionClick() {
    if (!swapInfo) {
      return;
    }
    const explorerChain = swapInfo.toHash ? swapInfo.toChainId : swapInfo.fromChainId;
    const explorerChainObject = CatalystNetwork.getCatalystNetwork(explorerChain);
    window.open(explorerChainObject.getTransactionUrl(swapInfo.toHash ? swapInfo.toHash : swapInfo.fromHash));
  }

  function handleNewQuoteClick() {
    runInAction(() => {
      swap.swapStep = SwapStep.Quoting;
      if (swap.swapInput) {
        triggerQuote(swap.swapInput);
      }
    });
  }

  const errorText = () => {
    switch (swap.swapError) {
      case SwapError.REVERTED:
        return 'Assets will be refunded on the source chain in ~ 1 hour';
      default:
        return 'Your swap transaction has failed. Please try again later.';
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col items-center">
        <div className="relative mx-auto flex w-full flex-col rounded-[25px] bg-white p-5 md:min-w-[600px]">
          <span className="font-semibold text-sm uppercase text-red-600">Swap Failed</span>
          <span className="mt-2 text-sm font-medium text-gray-800">{errorText()}</span>

          {swapInfo && lastQuote && (
            <>
              <div className="mt-5 flex items-center justify-between gap-4 rounded-[12px] bg-gray-100 px-3 py-2 text-sm font-medium text-gray-600">
                <TokenDisplay
                  token={lastQuote.fromAsset}
                  chainId={lastQuote.fromChainId}
                  size="md"
                  showChain
                  showSymbol
                />
                <div className="gap flex flex-col items-end">
                  <span className="font-semibold text-2xl text-gray-950">{formatValue(lastQuote.fromAmount, 5)}</span>
                  <span className="text-sm font-normal text-gray-500">{formatCurrency(lastQuote.fromValue, 2)}</span>
                </div>
              </div>

              <div className="mt-4 flex items-center justify-between rounded-[25px] bg-gray-100 px-3 py-2 text-sm font-medium text-gray-600">
                <div className="flex">
                  <Contract />
                  <span className="ml-2">
                    {swapInfo.toHash ? formatHash(swapInfo.toHash) : formatHash(swapInfo.fromHash)}
                  </span>
                </div>
                <div className="cursor-pointer" onClick={handleTransactionClick}>
                  <Explorer />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="mx-auto mt-10 flex h-[56px] w-full cursor-pointer items-center justify-center rounded-full bg-primary px-4 text-white"
          onClick={handleNewQuoteClick}
        >
          Get a new quote
        </div>
      </div>
    </Modal>
  );
});

export default SwapFailure;
