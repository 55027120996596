import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { getPoolRoute } from '~/config/utils/routes.utils';
import { formatCurrency, TokenDisplay } from '~/modules/common';
import { getAssetKey } from '~/modules/pools';
import PoolWithdrawModal from '~/modules/pools/components/PoolWithdrawModal';

import { PendingTxn } from '../interfaces';

interface PendingWithdrawCardProps {
  info?: string;
  txnDetails: PendingTxn;
  flat?: boolean;
}

export const PendingWithdrawCard: React.FC<PendingWithdrawCardProps> = ({
  info,
  txnDetails,
  flat,
}: PendingWithdrawCardProps) => {
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { withdrawalDetails } = txnDetails;
  if (!withdrawalDetails) {
    return null;
  }

  const { poolAssets, requests, withLiquiditySwap, progress, feeDetails, poolId, priceImpact } = withdrawalDetails;

  if (!requests || !requests.length) {
    return null;
  }

  const totalAmount = requests.reduce((acc, txn) => {
    return (
      acc +
      txn.request.reduce((acc, req) => {
        return acc + req.value;
      }, 0)
    );
  }, 0);

  const handleCloseModal = () => {
    setIsDetailsOpen(false);
  };

  const poolWithdrawRequest = requests;

  return (
    <>
      <div className={`flex w-full justify-center rounded-[20px] bg-white px-6 py-4 ${flat ? '' : 'shadow-2xl'}`}>
        <div className="flex w-full flex-col gap-3">
          <div className="flex justify-between ">
            <p className="text-xs text-gray-500"> Withdraw in progress</p>
            <div
              onClick={() => {
                setIsDetailsOpen(true);
              }}
              className="flex h-[20px] w-[47px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-xs text-white"
            >
              Open
            </div>
          </div>

          <div className="flex flex-col">
            <div className="relative mb-2 h-[2px] w-full rounded-full bg-grey-200">
              <progress className="absolute h-[2px] w-full rounded-full" value={progress} max={100} />
            </div>
            <div className="flex justify-between text-[14px] text-grey">
              <div className="gap flex text-xs">
                {poolAssets.map((token) => (
                  <TokenDisplay
                    chainId={token.chainId}
                    key={getAssetKey(token)}
                    token={token.address}
                    size="sm"
                    showSymbol={false}
                  />
                ))}
              </div>
              <div className="text-xs text-gray-800">{formatCurrency(totalAmount, 2)} </div>
            </div>
          </div>
          {info && <p className="rounded-xl bg-gray-100 px-4 py-2 text-xs text-gray-500 ">{info}</p>}
        </div>
      </div>
      {createPortal(
        <PoolWithdrawModal
          poolWithdrawRequest={poolWithdrawRequest}
          open={isDetailsOpen}
          handleCloseModal={handleCloseModal}
          withLiquiditySwap={Boolean(withLiquiditySwap)}
          handleCompleteWithdraw={() => {
            navigate(getPoolRoute(poolId));
            handleCloseModal();
          }}
          feeDetails={feeDetails}
          priceImpact={priceImpact}
          canCloseModal={true}
          withdrawValue={totalAmount}
        />,
        document.body,
      )}
    </>
  );
};
