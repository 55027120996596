import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { Modal } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { SwapReceipt } from '~/modules/swap/components';
import { SwapStep } from '~/modules/swap/enums';

import { SwapReceiptData } from '../interfaces';

export const SwapOverview = observer(() => {
  const store = useContext(StoreContext);
  const { swap: swapStore } = store;
  const { swapInfo, swapStep, lastQuote } = swapStore;

  const open = !(swapStep !== SwapStep.Confirmation || !lastQuote || !swapInfo);

  function onClose() {
    swapStore.resetSwap();
  }

  if (!open) {
    return null;
  }

  const receiptData: SwapReceiptData = {
    ...swapInfo,
    exchangeRate: lastQuote.exchangeRate,
    feeDetails: lastQuote.feeDetails,
    toAccount: lastQuote.toAccount,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <SwapReceipt receiptData={receiptData} />
    </Modal>
  );
});

export default SwapOverview;
