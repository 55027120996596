import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { TokenAmountDisplaySkeleton } from '~/modules/common';

export const SwapListItemSkeleton = () => {
  return (
    <SkeletonTheme borderRadius={100} height={18}>
      <div className="border-gray grid h-20 grid-cols-8 rounded-b-3xl border-t px-5">
        <span className="col-span-1 flex items-center">
          <Skeleton width={80} />
        </span>
        <span className="col-span-2 flex items-center">
          <TokenAmountDisplaySkeleton />
        </span>
        <span className="col-span-1 flex items-center">
          <Skeleton width={60} />
        </span>
        <span className="col-span-2 flex items-center">
          <TokenAmountDisplaySkeleton />
        </span>
        <span className="col-span-1 flex items-center">
          <Skeleton width={60} />
        </span>
        <span className="col-span-1 flex items-center gap-2">
          <Skeleton width={120} />
        </span>
      </div>
    </SkeletonTheme>
  );
};
