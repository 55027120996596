import * as RTooltip from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';

export const Tooltip = ({
  tooltip,
  children,
  tooltipStyle,
  className,
  ...props
}: {
  tooltip?: string | JSX.Element;
  tooltipStyle?: string;
  children: JSX.Element;
  className?: string;
}): JSX.Element => {
  return (
    <div className={`group relative flex ${className}`} {...props}>
      {children}
      {tooltip && (
        <span className={`z-20 scale-0 transition-all group-hover:scale-100`}>
          {typeof tooltip === 'string' ? (
            <span
              className={`absolute bottom-10 left-1/2 min-w-[240px] -translate-x-1/2 whitespace-break-spaces bg-white p-4 text-xs leading-5 text-gray-600 shadow-2xl ${tooltipStyle}`}
            >
              {tooltip}
            </span>
          ) : (
            tooltip
          )}
        </span>
      )}
    </div>
  );
};

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
}

export function ProfileDropdownTooltip({ children, content }: TooltipProps) {
  return (
    <RTooltip.Provider delayDuration={500}>
      <RTooltip.Root>
        <RTooltip.Trigger asChild>
          <div className="group relative flex">{children}</div>
        </RTooltip.Trigger>
        <RTooltip.Portal>
          <RTooltip.Content
            className="z-50 rounded-[6px] bg-white px-2 py-1 text-xs leading-5 text-gray-600 shadow-tooltip"
            sideOffset={5}
          >
            {content}
          </RTooltip.Content>
        </RTooltip.Portal>
      </RTooltip.Root>
    </RTooltip.Provider>
  );
}
