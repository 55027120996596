import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

import { SortDirection } from '~/modules/common/enums';

export const Table = ({
  title,
  action,
  header,
  headersPanelStyle,
  children,
}: {
  title?: JSX.Element | string;
  action?: JSX.Element;
  header: JSX.Element;
  headersPanelStyle?: string;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <div className="flex w-full min-w-[925px] flex-col rounded-3xl bg-white">
      {title && (
        <div className="flex justify-between px-5 pb-4 pt-5 font-semibold text-[18px]">
          {title}
          <div className="flex items-center">{action && action}</div>
        </div>
      )}

      <div className={`${headersPanelStyle} h-[56px] w-full items-center bg-black px-6 text-[12px] text-white`}>
        {header}
      </div>

      {children}
    </div>
  );
};

interface TableHeaderProps<T> {
  title: string;
  className?: string;
  isActive: boolean;
  sort: T;
  setSort: (sort: T) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
}

export function TableHeader<T>({
  title,
  className,
  isActive,
  sort,
  setSort,
  sortDirection,
  setSortDirection,
}: TableHeaderProps<T>): JSX.Element {
  function handleClick() {
    if (!isActive) {
      setSort(sort);
    } else {
      setSortDirection(sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
    }
  }

  return (
    <div className={`${className} flex items-center uppercase`}>
      <div className="flex cursor-pointer" onClick={handleClick}>
        <span className={isActive ? `font-bold` : ''}>{title}</span>
        {isActive && sortDirection === SortDirection.Descending && <ArrowDownIcon className="w-[18px ml-1 h-[18px]" />}
        {isActive && sortDirection === SortDirection.Ascending && <ArrowUpIcon className="w-[18px ml-1 h-[18px]" />}
      </div>
    </div>
  );
}

export default Table;
