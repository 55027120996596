import { getChainGasToken } from '@catalabs/catalyst-chain-lists';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';

export function isWrapUnwrap({
  fromChainId,
  fromAsset,
  toChainId,
  toAsset,
}: {
  fromChainId: string;
  fromAsset: string;
  toChainId: string;
  toAsset: string;
}): boolean {
  if (fromChainId !== toChainId) {
    return false;
  }
  return (
    (fromAsset === GAS_TOKEN_IDENTIFIER && toAsset === getChainGasToken(toChainId)) ||
    (toAsset === GAS_TOKEN_IDENTIFIER && fromAsset === getChainGasToken(fromChainId))
  );
}

export const isCrossChainSwap = ({ fromChainId, toChainId }: { fromChainId: string; toChainId: string }): boolean => {
  return fromChainId !== toChainId;
};
