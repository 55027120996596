import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { routes } from '~/config';
import { CatalystStore } from '~/modules/common';
import { WalletStore } from '~/modules/common/store/wallet-store';
import { PoolStore } from '~/modules/pools/store/pool-store';

export function usePoolData(poolStore: PoolStore, walletStore: WalletStore, catalystStore: CatalystStore) {
  const navigate = useNavigate();

  const { id } = useParams();
  const { poolAction, poolAccountBalance, pool } = poolStore;
  const { address } = walletStore;

  const [hasError, setHasError] = useState(false);

  const [poolTokensInfo, setPoolTokensInfo] = useState<Map<string, TokenInfo>>(new Map());
  const [poolTokenPercentages, setPoolTokenPercentages] = useState<number[]>([]);

  function navigateToPoolList() {
    runInAction(() => {
      poolStore.pool = undefined;
      poolStore.poolAccountBalance = new Map();
    });
    navigate(routes.pools);
  }

  useEffect(() => {
    if (!id) {
      return;
    }
    poolStore.updatePool(Number(id)).then((res) => {
      if (!res) {
        runInAction(() => {
          setHasError(true);
        });
      } else {
        walletStore.updatePoolBalances(res);
        poolStore.updatePoolAccountBalance(res.id);
      }
    });
  }, [id]);

  useEffect(() => {
    if (pool) {
      walletStore.updatePoolBalances(pool);
      poolStore.updatePoolAccountBalance(pool.id);
    }
  }, [address]);

  useEffect(() => {
    async function getPoolTokenInfo() {
      const poolTokens = new Map<string, TokenInfo>();
      if (pool) {
        for (const asset of pool.assets) {
          const tokenInfo = await catalystStore.getToken(asset.chainId, asset.address);
          const assetKey = `${asset.chainId}-${asset.address}`;
          poolTokens.set(assetKey, tokenInfo);
        }
      }
      setPoolTokensInfo(poolTokens);
    }
    getPoolTokenInfo();
  }, [pool]);

  useEffect(() => {
    if (pool) {
      const poolTokenPercentages = [];
      for (const asset of pool.assets) {
        const percentage = (asset.value / pool.tvl) * 100;
        poolTokenPercentages.push(Number(percentage.toFixed(2)));
      }
      setPoolTokenPercentages(poolTokenPercentages);
    }
  }, [poolTokensInfo, pool]);

  useEffect(() => {
    if (!address || !pool) {
      return;
    }

    if (pool) {
      poolStore.updatePoolAccountBalance(pool.id).then((res) => {
        if (!res) {
          setHasError(true);
        }
      });
    }
  }, [address]);

  let aprDisplay = '-';

  if (pool) {
    const baseApr = pool.apr.toLocaleString(undefined, { maximumFractionDigits: 2 });
    const minApr = pool.minApr.toLocaleString(undefined, { maximumFractionDigits: 2 });
    const maxApr = pool.maxApr.toLocaleString(undefined, { maximumFractionDigits: 2 });
    aprDisplay = pool.hasRange ? `${minApr}% - ${maxApr}%` : `${baseApr}%`;
  }

  return {
    poolTokensInfo,
    poolTokenPercentages,
    poolAction,
    poolAccountBalance,
    aprDisplay,
    pool,
    hasError,
    navigateToPoolList,
  };
}

export default usePoolData;
