import { ChainConfig, mumbai } from '@catalabs/catalyst-chain-lists';

import { EvmNetwork } from '~/config/network';

const CustomMumbaiChain: ChainConfig = {
  ...mumbai,
  rpcUrls: { ...mumbai.rpcUrls, default: { http: ['https://polygon-mumbai-pokt.nodies.app'] } },
};

export class Mumbai extends EvmNetwork {
  constructor() {
    super(CustomMumbaiChain);
  }
}
