import { getChainConfig } from '@catalabs/catalyst-chain-lists';

import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Complete } from '~/img/circle_check.svg';
import { ReactComponent as Inactive } from '~/img/emptycircle.svg';
import { PoolActionState } from '~/modules/pools/enums';
import { SetVaultChannelsRequest } from '~/modules/pools/interfaces';

export interface PoolSetChannelProps {
  requests: SetVaultChannelsRequest[];
}

export function PoolCreateSetChannel({ requests }: PoolSetChannelProps) {
  const allRequestsAreInactive = requests.every((r) => r.requestStatus === PoolActionState.Inactive);
  const allRequestsAreConfirmed = requests.every((r) => r.requestStatus === PoolActionState.Confirmed);
  const anyRequestsArePending = requests.some((r) => r.requestStatus === PoolActionState.Pending);
  const allRequestsAreCompleted = requests.every((r) => r.requestStatus === PoolActionState.Completed);

  if (allRequestsAreCompleted) {
    return null;
  }

  const originChain = getChainConfig(requests[0].chainId);
  const vaultTitleText = originChain.name;
  const titleText = `Complete setup of your ${vaultTitleText} vaults`;

  return (
    <div
      key={`deposit-approval-${originChain.id}`}
      className="flex flex-col items-center rounded-[25px] bg-white p-5 shadow md:w-[552px]"
    >
      {allRequestsAreInactive && (
        <div className="flex w-full items-center">
          <Inactive />
          <span
            className={`${
              allRequestsAreInactive ? 'text-gray-600' : 'text-primary'
            } ml-4 font-semibold text-xs uppercase leading-4 tracking-wide`}
          >
            {titleText}
          </span>
        </div>
      )}
      {(anyRequestsArePending || allRequestsAreConfirmed) && (
        <div className="flex flex-col">
          <div className="flex">
            {anyRequestsArePending ? <Active /> : <Complete className="h-8 w-8" />}
            <div className="ml-4 flex flex-col">
              <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">{titleText}</span>
              <span className="w-3/4 text-sm leading-5 text-gray-600">
                We need your approval to set up your new Pool. You would need to approve many transactions.
              </span>
            </div>
          </div>
          <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            {anyRequestsArePending ? 'Setting up pool on your wallet' : 'Setup completed'}
          </div>
        </div>
      )}
    </div>
  );
}

export default PoolCreateSetChannel;
