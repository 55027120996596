import { VaultInteraction, VaultInteractionType } from '@catalabs/catalyst-api-client';
import { getToken } from '@catalabs/catalyst-token-lists';

import { CatalystNetwork } from '~/config';
import { formatCurrency, formatPercentage, formatValue, SwapReviewLine, TokenDisplay } from '~/modules/common';
import PoolInteractionProgressBar from '~/modules/pools/components/PoolInteractionProgressBar';
import { PoolActionState } from '~/modules/pools/enums';
import { getDepositText } from '~/modules/pools/utils';

interface VaultInteractionReceiptProps {
  receiptData: VaultInteraction;
}

export const VaultInteractionReceipt = ({ receiptData }: VaultInteractionReceiptProps) => {
  const allAssets = receiptData.transactions.flatMap((t) => t.assets);
  const totalLiquidity = allAssets.reduce((sum, { value }) => sum + value, 0);

  const isDeposit = receiptData.interactionType === VaultInteractionType.Deposit;
  const isWithdraw = receiptData.interactionType === VaultInteractionType.Withdraw;
  const isReceiveLiquidity = receiptData.interactionType === VaultInteractionType.ReceiveLiquidity;
  const isSendLiquidity = receiptData.interactionType === VaultInteractionType.SendLiquidity;

  let statusText = '';
  if (isDeposit) {
    statusText = 'Deposit Completed';
  } else if (isWithdraw) {
    statusText = 'Withdraw Completed';
  } else if (isReceiveLiquidity) {
    statusText = 'Receive Liquidity Completed';
  } else if (isSendLiquidity) {
    statusText = 'Send Liquidity Completed';
  }

  return (
    <div className="flex flex-col items-center md:w-[552px]">
      <div className="flex w-full flex-col items-center rounded-[25px] bg-white p-5 md:w-[552px]">
        <span className="mb-4 w-full font-semibold text-xs uppercase leading-4 tracking-wide text-gray-800">
          {statusText}
        </span>
        <div className="flex w-full flex-col space-y-2">
          {receiptData.transactions.map(({ chain: chainId, assets, hash }) => {
            const targetNetwork = CatalystNetwork.getCatalystNetwork(chainId);
            const tokenSymbols = assets.map((asset) => {
              const token = getToken(asset.chain, asset.token);
              return token.symbol;
            });
            const explorerUrl = hash ? targetNetwork.getTransactionUrl(hash) : undefined;
            return (
              <PoolInteractionProgressBar
                key={`vault-interaction-receipt-progress-bar-${hash}`}
                interactionText={`${isDeposit ? 'Deposit' : 'Withdraw'} ${getDepositText(tokenSymbols)} on ${
                  targetNetwork.config.name
                }`}
                state={PoolActionState.Completed}
                explorerUrl={explorerUrl}
              />
            );
          })}
        </div>
      </div>
      <div className="mt-5 flex w-full flex-col gap-4 md:w-[552px]">
        <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
          {allAssets.map(({ amount, value, chain: chainId, token }) => {
            return (
              <div key={`vault-interaction-completed-display-${chainId}-${token}`} className="flex space-x-4">
                <div className="flex w-3/4 justify-between rounded-[15px] border border-gray-200 p-5">
                  <div className="flex items-center gap-2">
                    <TokenDisplay showSymbol={false} chainId={chainId} token={token} />
                    <span>{getToken(chainId, token).symbol}</span>
                  </div>
                  <div className="ml-4 mt-1 flex flex-col items-end">
                    <span className="font-semibold leading-[28px] text-gray-800 md:text-[32px]">
                      {formatValue(amount, 6)}
                    </span>
                    <span className="text-[14px] font-normal text-gray-600">{formatCurrency(value, 6)}</span>
                  </div>
                </div>
                <div className="flex w-1/4 items-center justify-center rounded-[10px] bg-gray-100">
                  <span className="font-medium leading-6 text-gray-600">
                    {formatPercentage((value / totalLiquidity) * 100)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <SwapReviewLine title="Total Liquidity" value={formatCurrency(totalLiquidity)} />
      </div>
    </div>
  );
};

export default VaultInteractionReceipt;
