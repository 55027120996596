import { SwapState } from '@catalabs/catalyst-api-client';
import { getToken } from '@catalabs/catalyst-token-lists';
import { BoltIcon } from '@heroicons/react/24/solid';
import { TokenInfo } from '@uniswap/token-lists';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '~/config';
import { CatalystNetwork } from '~/config/network/catalyst-network';
import { CircleLoader, Modal } from '~/modules/common/components';
import { StoreContext } from '~/modules/common/store/context';
import { isWrapUnwrap } from '~/modules/common/utils';
import { InlineLink } from '~/modules/pools';
import SwapProgressBar from '~/modules/swap/components/SwapProgressBar';
import { SwapSpeed, SwapStep } from '~/modules/swap/enums';

import { FAST_SWAP_DETAILS_URL } from '../constants';

export const SwapProgress = observer(() => {
  const navigate = useNavigate();
  const { swap } = useContext(StoreContext);
  const { swapInfo, lastQuote, swapStep, swapTransactionHash, pendingSwaps, recentPendingSwap } = swap;
  const pendingSwap = swapTransactionHash ? pendingSwaps.get(swapTransactionHash) : recentPendingSwap;

  function onClose() {
    swap.resetSwap();
  }

  const open = !(swapStep !== SwapStep.InProgress || (!swapInfo && !lastQuote) || !pendingSwap);

  if (!open) {
    return null;
  }

  let isLocalSwap = false;
  let isWrap = false;

  const maxDuration = lastQuote?.durationEstimate.max ?? 0;
  const durationValue = pendingSwap.totalDuration;
  const fromDuration = maxDuration > durationValue ? maxDuration - durationValue : undefined;
  let toDuration = swapInfo && maxDuration > durationValue ? maxDuration - durationValue : undefined;

  let fromProgress = true;
  let toProgress = false;

  let fromComplete = false;
  let toComplete = false;

  let fromChain: CatalystNetwork;
  let toChain: CatalystNetwork;

  let fromToken: TokenInfo | undefined = undefined;
  let toToken: TokenInfo | undefined = undefined;

  let fromLink = undefined;
  let toLink = undefined;

  const isFastSwap = swap.swapSpeed === SwapSpeed.FAST;

  if (lastQuote) {
    isLocalSwap = lastQuote.fromChainId === lastQuote.toChainId;
    fromChain = CatalystNetwork.getCatalystNetwork(lastQuote.fromChainId);
    toChain = CatalystNetwork.getCatalystNetwork(lastQuote.toChainId);
    toProgress = isLocalSwap ? true : false;
    toDuration = isLocalSwap ? fromDuration : undefined;

    fromToken = getToken(lastQuote.fromChainId, lastQuote.fromAsset);
    toToken = getToken(lastQuote.toChainId, lastQuote.toAsset);
    isWrap = isWrapUnwrap(lastQuote);
  }

  if (swapInfo) {
    fromComplete = true;
    toComplete = swapInfo.state === SwapState.CONFIRMED || swapInfo.state === SwapState.COMPLETED;
    fromProgress = false;
    toProgress = !toComplete;

    fromChain = CatalystNetwork.getCatalystNetwork(swapInfo.fromChainId);
    toChain = CatalystNetwork.getCatalystNetwork(swapInfo.toChainId);

    fromLink = fromChain.getTransactionUrl(swapInfo.fromHash);
    toLink = swapInfo.toHash ? toChain.getTransactionUrl(swapInfo.toHash) : undefined;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <div className="mx-3 flex flex-col items-center">
        {isFastSwap && !isWrap && (
          <div className="w-full rounded-[25px] bg-white p-4 md:w-[552px]">
            <div className="flex flex-col">
              <span className=" text-[14px] text-primary">Fast Swap: Waiting for underwriter...</span>
              <div className="flex items-center">
                <span className="text-[14px] text-gray-800">
                  This can take a while. If swap is not underwritten, it will proceed at regular speed and you will not
                  be charged. <InlineLink href={FAST_SWAP_DETAILS_URL} text="Learn more." />
                </span>
                <div className="relative flex items-center justify-center text-primary">
                  <CircleLoader loading className="text-gray-200" />
                  <BoltIcon className="absolute h-4 w-4" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-6 w-full rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="flex flex-col">
            <span className="font-semibold text-[12px] uppercase text-gray-800">Swap in progress</span>
            <div className="mt-4 space-y-2">
              <SwapProgressBar
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                token={fromToken}
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                network={fromChain!.config.name}
                completed={fromComplete}
                explorerUrl={fromLink}
                from={true}
                inProgress={fromProgress}
                duration={fromDuration}
                isLocal={isLocalSwap}
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                targetToken={toToken}
                isWrap={isWrap}
              />
              {!isLocalSwap && (
                <SwapProgressBar
                  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                  token={toToken}
                  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                  network={toChain!.config.name}
                  completed={toComplete}
                  explorerUrl={toLink}
                  from={false}
                  inProgress={toProgress}
                  duration={toDuration}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 w-full rounded-[25px] bg-white p-4 md:w-[552px]">
          <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
            <div className="text-[14px] text-grey-500">Continue to navigate while we finish here...</div>
            <div className="flex h-6 gap-2 text-[12px] text-white">
              <div
                onClick={() => {
                  swap.resetSwap();
                  navigate(routes.swap);
                }}
                className="flex cursor-pointer items-center rounded-xl bg-primary px-3"
              >
                New swap
              </div>
              <div
                onClick={() => {
                  swap.resetSwap();
                  navigate(routes.pools);
                }}
                className="flex cursor-pointer items-center rounded-xl bg-primary px-3"
              >
                Pools
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default SwapProgress;
