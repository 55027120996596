import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { TokenDisplaySkeleton } from '~/modules/common';

interface TokenAmountDisplaySkeletonProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const TokenAmountDisplaySkeleton = ({ size = 'md' }: TokenAmountDisplaySkeletonProps) => {
  return (
    <SkeletonTheme borderRadius={100} height={18}>
      <div className="flex items-center justify-start">
        <TokenDisplaySkeleton size={size} showSymbol={false} />
        <span>
          <Skeleton width={90} />
        </span>
      </div>
    </SkeletonTheme>
  );
};
