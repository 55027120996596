import { formatPercentage } from '../utils';

export interface PriceImpactProps {
  title?: string;
  value: number;
}

export function PriceImpact({ title, value }: PriceImpactProps): JSX.Element {
  const label = value && value > 0.1 ? 'High' : undefined;
  const type = value && value > 0.1 ? 'error' : undefined;
  const getLabelColors = (type?: string) => {
    switch (type) {
      case 'info':
        return 'bg-blue-100 text-blue-600';
      case 'warning':
        return 'bg-yellow-100 text-yellow-600';
      case 'error':
        return 'bg-red-100 text-red-600';
      case 'success':
        return 'bg-green-100 text-green-600';
      default:
        return 'bg-blue-100 text-blue-600';
    }
  };
  return (
    <div className={`flex h-[36px] w-full justify-between rounded-[20px] bg-white px-4 py-2 text-[14px] leading-5`}>
      <span className="text-gray-600">{title || 'Price Impact'}</span>
      <div className="flex gap-2">
        <span className="font-subheader">{formatPercentage(value)}</span>
        {label && <span className={`rounded-lg px-3 ${getLabelColors(type)} `}>{label}</span>}
      </div>
    </div>
  );
}

export default PriceImpact;
