import type { CatalystNetwork as Environment } from '@catalabs/catalyst-sdk';
import { CatalystSDK, EvmSDK } from '@catalabs/catalyst-sdk';
import { TokenInfo } from '@uniswap/token-lists';

import { NetworkNotFound } from '~/config/errors';
import { NetworkConfig } from '~/config/network/interfaces';

export abstract class CatalystNetwork {
  static suportedNetworks: Map<string, CatalystNetwork> = new Map();

  private initialized = false;
  private assetLookup: Map<string, TokenInfo> = new Map();

  readonly sdk: CatalystSDK;

  constructor(readonly config: NetworkConfig, catalystEnvironment: Environment) {
    CatalystNetwork.register(config.chainId, this);
    this.sdk = new EvmSDK({ provider: config.rpcUrl, catalystNetwork: catalystEnvironment });
  }

  static register(chainId: string, catalystNetwork: CatalystNetwork) {
    CatalystNetwork.suportedNetworks.set(chainId, catalystNetwork);
  }

  static getCatalystNetwork(chainId: string): CatalystNetwork {
    const entry = CatalystNetwork.suportedNetworks.get(chainId);
    if (!entry) {
      throw new NetworkNotFound(chainId);
    }
    return entry;
  }

  abstract getSupportedAssets(): TokenInfo[];

  abstract getAccountUrl(address: string): string;

  abstract getTransactionUrl(hash: string): string;

  getSdk<T extends CatalystSDK>(): T {
    return this.sdk as T;
  }

  getAsset(address: string): TokenInfo {
    if (!this.initialized) {
      const assets = this.getSupportedAssets();
      for (const asset of assets) {
        this.assetLookup.set(asset.address, asset);
      }
      this.initialized = true;
    }

    const maybeAsset = this.assetLookup.get(address);
    if (!maybeAsset) {
      throw new Error('Requested asset does not exist!');
    }
    return maybeAsset;
  }
}
