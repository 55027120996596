import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';

import { ALLOW_POOL_INTERACTION, ALLOW_POOL_WITHDRAW } from '~/config';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { formatCurrency, formatTokenBalance, StoreContext, TokenDisplay } from '~/modules/common';
import { PendingDepositCard } from '~/modules/lobby';
import { PendingWithdrawCard } from '~/modules/lobby/components/PendingWithdrawCard';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';
import { PoolActionType, usePoolData } from '~/modules/pools';

export const PoolInteractionPane = observer(() => {
  const { pool: poolStore, wallet, catalyst, lobby } = useContext(StoreContext);
  const { pool, poolAccountBalance } = poolStore;
  const { isConnected } = wallet;
  const { poolTokensInfo } = usePoolData(poolStore, wallet, catalyst);
  const [expanded, setExpanded] = useState(true);
  const { connect } = useConnectButton();

  if (!pool) {
    return null;
  }

  const targetPoolBalance = poolAccountBalance?.get(pool.id);

  let poolValue = 0;
  if (targetPoolBalance) {
    poolValue = targetPoolBalance.value;
  }

  function triggerDeposit() {
    if (!isConnected) {
      connect();
      return;
    }

    if (!ALLOW_POOL_INTERACTION) {
      return;
    }
    runInAction(() => (poolStore.poolAction = PoolActionType.Deposit));
  }

  const hasDeposits = targetPoolBalance
    ? Object.values(targetPoolBalance.balances)
        .flatMap((c) => Object.values(c))
        .some((a) => a.amount > 0)
    : false;

  const pendingPoolDeposits = lobby.getPendingPoolDeposits(pool.id);

  const pendingPoolWithdraws = lobby.getPendingPoolWithdrawals(pool.id);

  return (
    <div className="mt-5 flex w-full flex-col rounded-[25px] bg-white shadow md:w-[419px]">
      <div
        onClick={() => setExpanded(!expanded)}
        className="flex h-[56px] w-full cursor-pointer items-center justify-between rounded-t-[25px] bg-black px-6 font-semibold text-sm leading-[14px] text-white"
      >
        <div className="flex items-center gap-2">
          <span className="font-semibold uppercase tracking-wider">Your Position</span>
          {hasDeposits && <Caret className={`${expanded ? 'rotate-90' : '-rotate-90'}`} />}
        </div>
        <span className="leading-[24px]">{formatCurrency(poolValue)}</span>
      </div>

      {ALLOW_POOL_INTERACTION &&
        isConnected &&
        pendingPoolDeposits.length > 0 &&
        pendingPoolDeposits.map(
          (d) =>
            d && (
              <div className="border-gray w-full border-b" key={`pending-deposit-card-${d.hash}`}>
                <PendingDepositCard
                  info="Your position will be updated after deposit is complete."
                  txnDetails={d}
                  flat
                />
              </div>
            ),
        )}

      {ALLOW_POOL_INTERACTION &&
        isConnected &&
        pendingPoolWithdraws.length > 0 &&
        pendingPoolWithdraws.map(
          (d) =>
            d && (
              <div className="border-gray w-full border-b" key={`pending-withdraw-card-${d.hash}`}>
                <PendingWithdrawCard
                  info="Your position will be updated after withdrawal is complete."
                  txnDetails={d}
                  flat
                />
              </div>
            ),
        )}

      {!ALLOW_POOL_INTERACTION && (
        <div className="mt-8 flex flex-col items-center pb-10">
          <div className="flex justify-center text-center">
            <span className="text-lg leading-5">Deposit Coming Soon</span>
          </div>
          <button
            disabled
            onClick={triggerDeposit}
            className={`mt-4 flex h-[52px] w-[240px] items-center justify-center rounded-3xl bg-primary bg-opacity-30 text-sm text-white`}
          >
            <span className={`text-lg leading-[14px]`}>{isConnected ? 'Deposit Now' : 'Connect Wallet'}</span>
          </button>
        </div>
      )}

      {ALLOW_POOL_INTERACTION && (!hasDeposits || !isConnected) && (
        <div className="mt-8 flex flex-col items-center pb-10">
          <div className="flex justify-center text-center">
            <span className="text-lg leading-5">
              <span>Make your first deposit</span>
              <br />
              <span>and start earning.</span>
            </span>
          </div>
          <button
            onClick={triggerDeposit}
            className={`mt-4 flex h-[52px] w-[240px] items-center justify-center rounded-3xl text-sm text-white ${'bg-primary'}`}
          >
            <span className={`text-lg leading-[14px]`}>{isConnected ? 'Deposit Now' : 'Connect Wallet'}</span>
          </button>
        </div>
      )}

      {ALLOW_POOL_INTERACTION && targetPoolBalance && hasDeposits && isConnected && (
        <>
          <div className={expanded ? '' : 'hidden'}>
            {pool.assets.map((asset, i) => {
              const tokenBalance = targetPoolBalance?.balances[asset.chainId][asset.address];
              return (
                <div
                  key={`${pool}-${i}`}
                  className="border-gray flex h-[56px] w-full items-center border-b px-6 md:w-[419px]"
                >
                  <div className="flex items-center">
                    <TokenDisplay chainId={asset.chainId} token={asset.address} showSymbol={false} showChain={false} />
                  </div>
                  <div className="flex w-full justify-between">
                    <span className="text-xs text-grey">
                      {formatTokenBalance(tokenBalance.amount, true, 6)}
                      {poolTokensInfo.get(asset.address)?.symbol || ''}
                    </span>

                    <span className="text-xs text-grey-900">{formatCurrency(tokenBalance.value)}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row justify-center gap-2 p-6">
            <button
              onClick={() =>
                ALLOW_POOL_WITHDRAW && runInAction(() => (poolStore.poolAction = PoolActionType.Widthdraw))
              }
              className={`flex h-10 w-full items-center justify-center rounded-[25px] border border-primary bg-white text-sm text-white md:w-[180px] ${
                ALLOW_POOL_WITHDRAW ? '' : ' cursor-default opacity-30'
              }`}
              data-testid="go-to-withdraw-button"
            >
              <span className="text-lg leading-[14px] text-primary">Withdraw</span>
            </button>
            <button
              onClick={() => runInAction(() => (poolStore.poolAction = PoolActionType.Deposit))}
              className="flex h-10 w-full items-center justify-center rounded-[25px] bg-primary text-sm text-white md:w-[180px]"
            >
              <span className="text-lg leading-[14px]">Deposit</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
});

export default PoolInteractionPane;
