import { TokenInfo } from '@uniswap/token-lists';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { formatCurrency } from '~/modules/common';
import { PoolComposition } from '~/modules/pools/components/PoolComposition';

export const PoolCreateSummary = ({
  selectedTokens,
  selectedWeights,
  totalLiq,
}: {
  selectedTokens: [TokenInfo, string][];
  selectedWeights: [TokenInfo, number][];
  totalLiq: number;
}) => {
  return (
    <div className="flex w-full flex-col items-center gap-4">
      <div className="flex h-[36px] w-full items-center justify-between rounded-[25px] bg-white p-6 md:w-[416px]">
        <span className="bg-opacity-15two flex items-center font-semibold font-subheader text-sm uppercase tracking-wide text-grey-900">
          TOTAL LIQUIDITY
        </span>
        <span>{formatCurrency(totalLiq)}</span>
      </div>
      <div className="flex h-[36px] w-full items-center justify-between rounded-[25px] bg-white p-6 md:w-[416px]">
        <div className="flex gap-4">
          <span className="bg-opacity-15two  flex items-center font-semibold font-subheader text-sm tracking-wide text-grey-900">
            TOTAL COST
          </span>
          <div className="flex items-center">
            <Caret className="-rotate-90 text-grey-700" />
          </div>
        </div>
        <span className="">0</span>
      </div>
      <PoolComposition
        tokens={selectedTokens.map(([token]) => token)}
        percentages={selectedWeights.map(([_, weight]) => weight)}
      />
    </div>
  );
};
