import { SwapState } from '@catalabs/catalyst-api-client';
import { getToken } from '@catalabs/catalyst-token-lists';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';

import { CatalystNetwork } from '~/config/network/catalyst-network';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { ReactComponent as Completed } from '~/img/circle_check.svg';
import { isWrapUnwrap, SwapReviewLine } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { getAnimationState, shortenAddress } from '~/modules/common/utils';
import { SwapCosts, SwapDuration, SwapExchangeRate, SwapProgressBar, SwapReviewCard } from '~/modules/swap/components';
import { SwapCardType } from '~/modules/swap/enums';
import type { SwapReceiptData } from '~/modules/swap/interfaces';

interface SwapReceiptProps {
  receiptData: SwapReceiptData;
}

export const SwapReceipt = observer(({ receiptData }: SwapReceiptProps) => {
  const store = useContext(StoreContext);
  const { catalyst } = store;

  const [showDetails, setShowDetails] = useState(false);

  // TODO: change back to the swap info once WETH <> ETH has been fixed
  const fromToken = getToken(receiptData.fromChainId, receiptData.fromAsset);
  const toToken = getToken(receiptData.toChainId, receiptData.toAsset);

  const fromChain = CatalystNetwork.getCatalystNetwork(receiptData.fromChainId);
  const toChain = CatalystNetwork.getCatalystNetwork(receiptData.toChainId);

  const fromLink = fromChain.getTransactionUrl(receiptData.fromHash);
  const toLink = receiptData.toHash ? toChain.getTransactionUrl(receiptData.toHash) : undefined;

  const isLocal = receiptData.fromChainId === receiptData.toChainId;
  const isWrap = isWrapUnwrap(receiptData);
  const fromPrice = catalyst.getPrice(fromToken);

  const isSwapCompleted = receiptData.state === SwapState.COMPLETED || receiptData.state === SwapState.CONFIRMED;
  const isSwapInProgress = receiptData.state === SwapState.PENDING;
  const isSwapFailed = receiptData.state === SwapState.REVERTED || receiptData.state === SwapState.TIMED_OUT;

  let statusText = '';
  if (isSwapCompleted) {
    statusText = 'Swap Completed';
  } else if (isSwapInProgress) {
    statusText = 'Swap In Progress';
  } else if (isSwapFailed) {
    statusText = 'Swap Failed';
  }

  return (
    <div className="mx-3 flex w-full md:mx-auto md:w-[552px]">
      <div className="flex w-full flex-col">
        <div className="flex flex-col items-center justify-between rounded-[25px] bg-white px-4 py-5">
          <div
            onClick={() => setShowDetails(showDetails === undefined ? true : !showDetails)}
            className="flex w-full items-center"
          >
            <div className="flex w-full cursor-pointer items-center">
              <span className={`font-semibold text-[14px] uppercase tracking-wider`} data-testid="swap-overview-title">
                {statusText}
              </span>
              <div
                className={`${getAnimationState(showDetails)} ml-2 cursor-pointer`}
                onClick={() => setShowDetails(showDetails === undefined ? true : !showDetails)}
              >
                <Caret className="rotate-90" />
              </div>
            </div>

            {isSwapFailed || isSwapInProgress ? (
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#4553D3" />
                <circle cx="8" cy="8" r="6" fill="white" />
                <circle cx="8" cy="8" r="4" fill="#4553D3" />
              </svg>
            ) : (
              <Completed />
            )}
          </div>
          {showDetails && (
            <div className="mt-4 w-full space-y-2">
              <SwapProgressBar
                token={fromToken}
                network={fromChain.config.name}
                completed={true}
                explorerUrl={fromLink}
                from={true}
                inProgress={false}
                isLocal={isLocal}
                isWrap={isWrap}
                targetToken={toToken}
              />
              {!isLocal && (
                <SwapProgressBar
                  token={toToken}
                  network={toChain.config.name}
                  completed={toLink !== undefined}
                  explorerUrl={toLink}
                  from={false}
                  inProgress={false}
                />
              )}
            </div>
          )}
        </div>
        <div className="mt-6 flex w-full flex-col gap-4 md:flex-row">
          <SwapReviewCard
            cardType={SwapCardType.Input}
            chainId={receiptData.fromChainId}
            token={receiptData.fromAsset}
            amount={receiptData.input}
            value={receiptData.inputValue}
            completed={true}
          />
          <SwapReviewCard
            cardType={SwapCardType.Output}
            chainId={receiptData.toChainId}
            token={receiptData.toAsset}
            amount={receiptData.output}
            value={receiptData.outputValue}
            completed={true}
          />
        </div>
        <div className="mt-5 flex flex-col gap-4 md:flex-row">
          {receiptData.exchangeRate && (
            <SwapExchangeRate
              fromAsset={fromToken}
              toAsset={toToken}
              exchangeRate={receiptData.exchangeRate}
              tokenPrice={fromPrice}
              fullWidth
            />
          )}
          <SwapDuration swap={receiptData} fullWidth={receiptData.exchangeRate === undefined} />
        </div>
        {receiptData.feeDetails && (
          <div className="mt-5">
            <SwapCosts feeDetails={receiptData.feeDetails} />
          </div>
        )}
        {receiptData.toAccount && (
          <div className="mt-5">
            <SwapReviewLine title="Recipient Address" value={shortenAddress(receiptData.toAccount)} />
          </div>
        )}
      </div>
    </div>
  );
});
