import { Pool, Vault } from '@catalabs/catalyst-api-client';
import { getChainGasToken } from '@catalabs/catalyst-chain-lists';
import { findOptimalDepositValues, findOptimalLiquiditySwaps } from '@catalabs/catalyst-sdk';
import { TokenInfo } from '@uniswap/token-lists';
import { formatUnits, parseUnits } from 'ethers';

import { TokenBalance } from '~/config';

import { ChainFeeData, DepositRequest, formatValue, isValidNumeric } from '../../common';
import { PoolTokenDepositRef } from '../components/PoolTokenDepositPane';
import { PoolActionState } from '../enums';
import {
  ChainDeposit,
  DepositLiquiditySwapInfoItem,
  DepositWithLiquiditySwapInputs,
  LiquiditySwap,
} from '../interfaces';
import { formatVaultInfoDeposit, getAssetKey } from '../utils';

export const getDefaultTokenInfo = (assetKey: string) => {
  const [address, chainId] = assetKey.split('-');
  return {
    chainId: Number(chainId),
    address,
    name: '',
    symbol: '',
    decimals: 18,
  } as TokenInfo;
};

export const formatLiquiditySwapDetails = ({
  quote,
  userDeposits,
  vaults,
  tokenInfos,
}: {
  quote: ReturnType<typeof findOptimalLiquiditySwaps>;
  userDeposits: bigint[][];
  vaults: Vault[];
  tokenInfos: Map<string, TokenInfo>;
}) => {
  const initialDeposit: Map<string, number> = new Map();
  const finalDeposit: Map<string, number> = new Map();
  const swapDetails: Map<string, DepositLiquiditySwapInfoItem> = new Map();

  // calculate the initial and final deposit amounts from each vaults
  for (let index = 0; index < vaults.length; index++) {
    const vaultAssets = vaults[index].assets;
    const vaultDeposits = userDeposits[index];
    vaultDeposits.map((deposit, index) => {
      const asset = vaultAssets[index];
      const assetKey = getAssetKey({ address: asset.token, chainId: asset.chain });
      const tokenInfo = tokenInfos.get(assetKey) || getDefaultTokenInfo(assetKey);
      initialDeposit.set(assetKey, Number(formatUnits(deposit, tokenInfo?.decimals ?? 18)));
    });

    quote.postBalance[index].map((balance, index) => {
      const asset = vaultAssets[index];
      const assetKey = getAssetKey({ address: asset.token, chainId: asset.chain });
      const tokenInfo = tokenInfos.get(assetKey) || getDefaultTokenInfo(assetKey);
      finalDeposit.set(assetKey, Number(formatUnits(balance, tokenInfo?.decimals ?? 18)));
    });
  }

  // calculate the swap details for each vault
  for (const [_, tokenSwap] of quote.vaultTokenSwaps.entries()) {
    if (!tokenSwap) {
      continue;
    }

    const fromVault = vaults[tokenSwap.from];
    const channel = fromVault.arbitraryMessagingBridge;

    const inputTokens = tokenSwap.equivalentInput.map((input, index) => {
      const vault = vaults[tokenSwap.from];
      const asset = vault.assets[index];
      const assetKey = getAssetKey({ address: asset.token, chainId: asset.chain });
      const tokenInfo = tokenInfos.get(assetKey) || getDefaultTokenInfo(assetKey);
      return {
        token: tokenInfo,
        amount: Number(formatUnits(input, tokenInfo?.decimals ?? 18)),
      };
    });

    const outputTokens = tokenSwap.equivalentOutput
      .filter((_, index) => {
        const vault = vaults[tokenSwap.to];
        const asset = vault.assets[index];
        return !!asset;
      })
      .map((output, index) => {
        const vault = vaults[tokenSwap.to];
        const asset = vault.assets[index];
        const assetKey = getAssetKey({ address: asset.token, chainId: asset.chain });
        const tokenInfo = tokenInfos.get(assetKey) || getDefaultTokenInfo(assetKey);
        return {
          token: tokenInfo,
          amount: Number(formatUnits(output, tokenInfo?.decimals ?? 18)),
        };
      });

    const swap: LiquiditySwap = {
      swap: {
        from: inputTokens,
        to: outputTokens,
      },
      channel,
      vault: fromVault,
      vaultAmount: Number(formatUnits(tokenSwap.vaultTokensSent)),
    };
    if (swapDetails.has(fromVault.chain)) {
      const existingSwap = swapDetails.get(fromVault.chain);
      if (existingSwap) {
        existingSwap.quoteDetails.push(swap);
        swapDetails.set(fromVault.chain, existingSwap);
      }
    } else {
      const formattedQuoteItem: DepositLiquiditySwapInfoItem = {
        initialDeposit,
        finalDeposit,
        quoteDetails: [swap],
      };

      swapDetails.set(fromVault.chain, formattedQuoteItem);
    }
  }
  return swapDetails;
};

export const getLiquiditySwapTxInputs = ({
  address,
  feeData,
  vaults,
  depositAmounts,
  pool,
  assetBalances,
  gasTokenBalances,
  isAssetReplacedWithGasToken,
  tokenPrices,
}: {
  address: string;
  feeData: ChainFeeData;
  vaults: Vault[];
  depositAmounts: Map<string, string>;
  pool: Pool;
  isAssetReplacedWithGasToken: Map<string, boolean>;
  gasTokenBalances: Map<string, TokenBalance>;
  assetBalances: Map<string, TokenBalance>;
  tokenPrices: Map<string, number>;
}): DepositWithLiquiditySwapInputs => {
  const { vaultsInfo, userDeposits } = formatVaultInfoDeposit({
    depositAmounts,
    isAssetReplacedWithGasToken,
    vaults,
    assetBalances,
    gasTokenBalances,
  });

  const vaultAddresses = vaults.map((vault) => vault.address);

  const assetsAddresses = vaults.map((vault) => vault.assets.map((asset) => asset.token));

  const userAddresses = vaults.map(() => address);

  const chains = vaults.map((vault, index) => {
    const chainId = vault.chain;
    const otherChainId = vaults[(index + 1) % vaults.length].chain;
    const channelId = pool.channels[otherChainId][chainId];
    return { chainId, channelId };
  });

  const messageVerifyGasCosts: bigint[][] = [];
  const priceOfDeliveryGas: bigint[][] = [];
  const priceOfAckGas: bigint[] = [];

  for (const vault of vaults) {
    if (!feeData[vault.chain]) {
      throw new Error(`Gas data for chain ${vault.chain} not found`);
    }

    const { gasPrice, cciFeeData, maxFeePerGas, maxPriorityFeePerGas } = feeData[vault.chain];

    // get the gas price, AMB cost to deliver the token to other vaults
    const messageVerifyGasCostsForVault: bigint[] = [];
    const priceOfDeliveryGasForVault: bigint[] = [];
    for (const otherVault of vaults) {
      const messageVerifyGasCostToOtherVault = BigInt(cciFeeData[otherVault.chain].messageVerifyGasCost.amount);
      messageVerifyGasCostsForVault.push(messageVerifyGasCostToOtherVault);

      const sourceVaultGasTokenKey = getAssetKey({ address: getChainGasToken(vault.chain), chainId: vault.chain });
      const sourceVaultGasTokenPrice = tokenPrices.get(sourceVaultGasTokenKey) || 0;

      const destinationVaultGasTokenKey = getAssetKey({
        address: getChainGasToken(otherVault.chain),
        chainId: otherVault.chain,
      });
      const destinationVaultGasTokenPrice = tokenPrices.get(destinationVaultGasTokenKey) || 0;

      const priceAccuracy = 100_000;
      const toGasTokenPrice = BigInt(Math.floor(destinationVaultGasTokenPrice * priceAccuracy));
      const fromGasTokenPrice = BigInt(Math.floor(sourceVaultGasTokenPrice * priceAccuracy));
      const toChainGasPrice = BigInt(gasPrice ?? '0');

      const priceOfDeliveryGasToOtherVault = (toChainGasPrice * toGasTokenPrice) / fromGasTokenPrice;
      priceOfDeliveryGasForVault.push(priceOfDeliveryGasToOtherVault);
    }
    messageVerifyGasCosts.push(messageVerifyGasCostsForVault);
    priceOfDeliveryGas.push(priceOfDeliveryGasForVault);

    // get the gas price to acknowledge the delivery
    const supportsEip1559 = BigInt(maxPriorityFeePerGas) !== 0n;
    const baseGasCost = supportsEip1559 ? maxFeePerGas : gasPrice;
    const priorityFee = supportsEip1559 ? maxPriorityFeePerGas : BigInt(parseUnits('1', 'gwei').toString());

    const totalGas = BigInt(baseGasCost) + BigInt(priorityFee);
    const priceOfAckGasForVault = (totalGas * 12n) / 10n + BigInt(parseUnits('1', 'wei').toString());
    priceOfAckGas.push(priceOfAckGasForVault);
  }

  const refundGasTo = address;

  const wrapGasValues = vaults.map((vault) => {
    const assetGasValues = vault.assets.map((asset) => {
      const assetKey = getAssetKey({ address: asset.token, chainId: asset.chain });
      const useGasToken = isAssetReplacedWithGasToken.get(assetKey);
      if (!useGasToken) {
        return 0n;
      }
      const depositAmount = depositAmounts.get(assetKey)?.trim() || '0';
      return BigInt(parseUnits(depositAmount.toString()).toString());
    });
    return assetGasValues.reduce((acc, value) => acc + value, 0n);
  });

  return {
    vaults: vaultsInfo,
    vaultAddresses,
    assetsAddresses,
    userDeposits,
    userAddresses,
    chains,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    refundGasTo,
    feeData,
    wrapGasValues,
  };
};

export const findLiquiditySwapRoute = ({
  depositAmounts,
  vaults,
  isAssetReplacedWithGasToken,
  assetBalances,
  gasTokenBalances,
  poolTokenInfos,
}: {
  depositAmounts: Map<string, string>;
  isAssetReplacedWithGasToken: Map<string, boolean>;
  assetBalances: Map<string, TokenBalance>;
  gasTokenBalances: Map<string, TokenBalance>;
  poolTokenInfos: Map<string, TokenInfo>;
  vaults: Vault[];
}): {
  formattedQuote: Map<string, DepositLiquiditySwapInfoItem>;
  finalDeposit: Map<string, number>;
} => {
  // if all deposit amounts are not valid or 0, return
  if (Array.from(depositAmounts.values()).every((value) => !isValidNumeric(value) || Number(value) === 0)) {
    throw new Error('Invalid deposit amounts');
  }
  const { vaultsInfo, userDeposits } = formatVaultInfoDeposit({
    depositAmounts,
    isAssetReplacedWithGasToken,
    vaults,
    assetBalances,
    gasTokenBalances,
  });
  const quote = findOptimalLiquiditySwaps(vaultsInfo, userDeposits);
  const formattedQuote = formatLiquiditySwapDetails({
    quote,
    userDeposits,
    vaults,
    tokenInfos: poolTokenInfos,
  });

  // set the finalDepositAmounts for the UI
  const finalDeposit = new Map<string, number>();
  const swap = Array.from(formattedQuote.values())[0];
  if (swap) {
    for (const [assetKey, amount] of swap.finalDeposit.entries()) {
      finalDeposit.set(assetKey, amount);
    }
  }

  return {
    formattedQuote,
    finalDeposit,
  };
};

export const optimizeDepositAmounts = ({
  depositAmounts,
  vaults,
  isAssetReplacedWithGasToken,
  assetBalances,
  gasTokenBalances,
  pool,
  depositInputRefs,
}: {
  depositAmounts: Map<string, string>;
  isAssetReplacedWithGasToken: Map<string, boolean>;
  vaults: Vault[];
  assetBalances: Map<string, TokenBalance>;
  gasTokenBalances: Map<string, TokenBalance>;
  pool: Pool;
  depositInputRefs: Map<string, React.RefObject<PoolTokenDepositRef>>;
}) => {
  // if all deposit amounts are not valid or 0, return
  if (Array.from(depositAmounts.values()).every((value) => isValidNumeric(value))) {
    const { vaultsInfo, userBalances, userDeposits } = formatVaultInfoDeposit({
      depositAmounts,
      isAssetReplacedWithGasToken,
      vaults,
      assetBalances,
      gasTokenBalances,
    });

    const { newUserInputs: optimalValues, restrictingBalanceIndex } = findOptimalDepositValues(
      vaultsInfo,
      userBalances,
      userDeposits,
    );

    const [vaultIndex, assetIndex] = restrictingBalanceIndex;
    const restrictingAsset = vaultIndex > -1 ? vaults[vaultIndex]?.assets[assetIndex] : undefined;
    let restrictingAssetKey: string | undefined;
    if (restrictingAsset !== undefined) {
      restrictingAssetKey = getAssetKey({ address: restrictingAsset.token, chainId: restrictingAsset.chain });

      // get the inputs for each restricting asset, and blink the input

      const restrictingAssetInput = depositInputRefs.get(restrictingAssetKey);
      if (restrictingAssetInput) {
        restrictingAssetInput.current?.shake();
      }
    }

    const vaultMap = new Map<string, Vault>(Object.entries(pool.vaults));

    const newDepositAmounts = new Map<string, string>();

    let index = 0;
    for (const [_, vault] of vaultMap) {
      const values = optimalValues[index];
      for (const asset of vault.assets) {
        const poolAsset = pool.assets.find((a) => a.address === asset.token && a.chainId === asset.chain);
        if (!poolAsset) {
          return;
        }

        const value = values[asset.assetIndex];
        const formatedValue = formatValue(Number(formatUnits(value)), 6);
        const assetKey = getAssetKey(poolAsset);

        newDepositAmounts.set(assetKey, formatedValue);
      }
      index++;
    }
    return newDepositAmounts;
  }
};

export const sortAndfilterOutZeroDepositRequests = ({
  request,
  pool,
  sourceNetwork,
}: {
  request: DepositRequest;
  pool?: Pool;
  sourceNetwork?: string;
  hasLiquiditySwap?: boolean;
}): ChainDeposit[] => {
  if (request.length === 0) {
    return [];
  }

  const hasLiquiditySwap = request.some((r) => r.withLiquiditySwap);
  const initialNetwork = sourceNetwork || request[0].chainId;
  // this operation creates request chunks by chainId
  // create a temp store for requests
  const requestMap = new Map<string, DepositRequest>();
  // for each request
  request.forEach((r) => {
    // get a chunk of the request from the temp store based on the chainId
    const requestChunk = requestMap.get(r.chainId);
    if (!requestChunk) {
      // if there's no chunk, create one
      requestMap.set(r.chainId, [r]);
    } else {
      requestChunk.push(r);
    }
  });

  // sort the request chunks by chainId
  const sortedRequests: ChainDeposit[] = Array.from(requestMap.entries())
    .sort((a, b) => {
      const [chainIdA, _a] = a;
      const [chainIdB, _b] = b;
      if (chainIdA === initialNetwork) {
        return -1;
      }
      if (chainIdB === initialNetwork) {
        return 1;
      }
      return chainIdA.localeCompare(chainIdB);
    })
    // filter out any chunks that have no deposits
    .filter((e) => e[1].some((r) => r.amount > 0n))
    .map(([key, request]) => ({
      chainId: key,
      chainSwap: PoolActionState.Inactive,
      deposit: PoolActionState.Inactive,
      request: request,
      poolId: pool?.id,
      liquiditySwap: hasLiquiditySwap,
    }));

  return sortedRequests;
};
