export const DUMMY_PERMIT_DATA = {
  signature:
    '0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
  permit: {
    details: {
      token: '0x0000000000000000000000000000000000000000',
      amount: 0n,
      expiration: 0n,
      nonce: 0n,
    },
    spender: '0x0000000000000000000000000000000000000000',
    sigDeadline: 0n,
  },
};
