export const routes = {
  swap: '/swap',
  pools: '/pools',
  pool: '/pools/:id',
  poolCreate: '/pool/create',
  rewards: '/rewards',
  join: '/join',
  userPortfolio: '/portfolio',
  transactionHistory: '/transactions',
  privacyPolicy: '/privacy-policy',
  termsOfService: '/terms-of-service',
  root: '/',
  restricted: '/restricted',
};
