import { FeatureFlag } from '~/config';

function isActiveFeature(feature?: string): boolean {
  if (!feature) {
    return false;
  }
  return feature.toLowerCase() === 'true';
}

export const FLAGS: Record<FeatureFlag, boolean> = {
  [FeatureFlag.Testnet]: isActiveFeature(process.env.REACT_APP_USE_TEST_NET),
  [FeatureFlag.PoolCreation]: isActiveFeature(process.env.REACT_APP_POOL_CREATION),
  [FeatureFlag.PoolInteraction]: isActiveFeature(process.env.REACT_APP_POOL_INTERACTION),
  [FeatureFlag.PoolWithdraw]: isActiveFeature(process.env.REACT_APP_POOL_WITHDRAW),
  [FeatureFlag.WelcomePopUp]: isActiveFeature(process.env.REACT_APP_WELCOME_POP_UP),
  [FeatureFlag.Missions]: isActiveFeature(process.env.REACT_APP_MISSIONS),
  [FeatureFlag.Rewards]: isActiveFeature(process.env.REACT_APP_SHOW_REWARDS),
  [FeatureFlag.RewardsSeasonActive]: isActiveFeature(process.env.REACT_APP_REWARDS_SEASON_ACTIVE),
  [FeatureFlag.UserPortfolio]: isActiveFeature(process.env.REACT_APP_USER_PORTFOLIO),
  [FeatureFlag.TransactionHistory]: isActiveFeature(process.env.REACT_APP_TRANSACTION_HISTORY),
  [FeatureFlag.OmniSupport]: isActiveFeature(process.env.REACT_APP_OMNI_SUPPORT),
  [FeatureFlag.ScrollSupport]: isActiveFeature(process.env.REACT_APP_SCROLL_SUPPORT),
  [FeatureFlag.InjectiveEvmSupport]: isActiveFeature(process.env.REACT_APP_INJECTIVE_EVM_SUPPORT),
  [FeatureFlag.BSCSupport]: isActiveFeature(process.env.REACT_APP_OPBNB_SUPPORT),
  [FeatureFlag.OpBNBSupport]: isActiveFeature(process.env.REACT_APP_BSC_SUPPORT),
  [FeatureFlag.ArbitrumSupport]: isActiveFeature(process.env.REACT_APP_ARBITRUM_SUPPORT),
};

// Flag Accessors
export const SHOW_POOL_CREATION = FLAGS[FeatureFlag.PoolCreation];
export const ALLOW_POOL_INTERACTION = FLAGS[FeatureFlag.PoolInteraction];
export const ALLOW_POOL_WITHDRAW = FLAGS[FeatureFlag.PoolWithdraw];
export const SHOW_WELCOME_POP_UP = FLAGS[FeatureFlag.WelcomePopUp];
export const SHOW_MISSIONS = FLAGS[FeatureFlag.Missions];
export const SHOW_REWARDS = FLAGS[FeatureFlag.Rewards];
export const REWARDS_SEASON_ACTIVE = FLAGS[FeatureFlag.RewardsSeasonActive];
export const SHOW_USER_PORTFOLIO = FLAGS[FeatureFlag.UserPortfolio];
export const SHOW_TRANSACTION_HISTORY = FLAGS[FeatureFlag.TransactionHistory];

// Chain Support
export const OMNI_SUPPORT = FLAGS[FeatureFlag.OmniSupport];
export const SCROLL_SUPPORT = FLAGS[FeatureFlag.ScrollSupport];
export const INJECTIVE_EVM_SUPPORT = FLAGS[FeatureFlag.InjectiveEvmSupport];
export const BSC_SUPPORT = FLAGS[FeatureFlag.BSCSupport];
export const OPBNB_SUPPORT = FLAGS[FeatureFlag.OpBNBSupport];
export const ARBITRUM_SUPPORT = FLAGS[FeatureFlag.ArbitrumSupport];
