import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Complete } from '~/img/circle_check.svg';
import { ReactComponent as Inactive } from '~/img/emptycircle.svg';
import { ApprovalRequest, getDepositText, PoolActionState } from '~/modules/pools';

export interface PoolApprovalProps {
  requests: ApprovalRequest[];
}

export function PoolCreateApproval({ requests }: PoolApprovalProps) {
  const allRequestsAreInactive = requests.every((r) => r.approval === PoolActionState.Inactive);
  const allRequestsAreConfirmed = requests.every((r) => r.approval === PoolActionState.Confirmed);
  const anyRequestsArePending = requests.some((r) => r.approval === PoolActionState.Pending);
  const allRequestsAreCompleted = requests.every((r) => r.approval === PoolActionState.Completed);

  if (allRequestsAreCompleted) {
    return null;
  }

  const tokensTitleText = getDepositText(requests.map((req) => req.asset.symbol));
  const titleText = `Approve access to your ${tokensTitleText} tokens`;

  return (
    <div
      key={`deposit-approval-${requests[0].chainId}`}
      className="flex flex-col items-center rounded-[25px] bg-white p-5 shadow md:w-[552px]"
    >
      {allRequestsAreInactive && (
        <div className="flex w-full items-center">
          <Inactive />
          <span
            className={`${
              allRequestsAreInactive ? 'text-gray-600' : 'text-primary'
            } ml-4 font-semibold text-xs uppercase leading-4 tracking-wide`}
          >
            {titleText}
          </span>
        </div>
      )}
      {(anyRequestsArePending || allRequestsAreConfirmed) && (
        <div className="flex flex-col">
          <div className="flex">
            {anyRequestsArePending ? <Active /> : <Complete className="h-8 w-8" />}
            <div className="ml-4 flex flex-col">
              <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">{titleText}</span>
              <span className="w-3/4 text-sm leading-5 text-gray-600">
                Smart contracts need permission to access your tokens. You only need to approve once per token.
              </span>
            </div>
          </div>
          <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            {anyRequestsArePending ? 'Waiting for approval on your wallet' : 'Approved'}
          </div>
        </div>
      )}
    </div>
  );
}

export default PoolCreateApproval;
