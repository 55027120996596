import { Referral } from '@catalabs/catalyst-api-client';
import * as Popover from '@radix-ui/react-popover';
import { useContext, useState } from 'react';

import { ReactComponent as Spinner } from '~/img/spinner.svg';
import { Button, Input, StoreContext } from '~/modules/common';

import { useUpdateSharePercentage } from '../../../hooks/useUpdateSharePercentage';
import { ValidationIndicator } from '../../ValidationIndicator';

export function UpdateSharePercentage({ ownReferral }: { ownReferral: Referral }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={(val) => {
        return setIsOpen(val);
      }}
    >
      <Popover.Trigger
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div className="hover:[not(:focus-within):border-gray-400] group relative flex w-full items-center rounded-lg  border border-gray-300 focus-within:border-indigo-500 md:w-[65px]">
          <div className="w-full">
            <Input
              type="search"
              className={'peer order-2 h-[42px] w-full rounded-lg px-4 py-3 font-light'}
              value={`${ownReferral.sharePercentage}%`}
              required
            />
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="top"
          className="mb-3 max-w-[235px] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
        >
          <div className="flex h-[235px] w-[234px] flex-col gap-5 rounded-3xl bg-white p-5 text-sm shadow-xl">
            <Content close={() => setIsOpen(false)} />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function Content({ close }: { close: () => void }) {
  const { referral: referralStore, wallet: walletStore } = useContext(StoreContext);
  const { updateSharePercentage, isLoading, error } = useUpdateSharePercentage(referralStore, walletStore);
  const [updatedSharePercentage, setUpdatedSharePercentage] = useState<number | null>(null);

  const ownReferral = referralStore.ownReferral;
  if (!ownReferral) return null;

  const isSameAsExisting =
    updatedSharePercentage === null ? null : updatedSharePercentage === ownReferral.sharePercentage;
  const isBelowMinimum =
    updatedSharePercentage === null ? null : updatedSharePercentage < ownReferral.sharePercentage + 1;
  const isAboveMaximum =
    updatedSharePercentage === null ? null : updatedSharePercentage > ownReferral.maxSharePercentage;
  const hasError = isSameAsExisting || isBelowMinimum || isAboveMaximum;

  const [step, setStep] = useState<'confirmation' | 'form'>('form');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (hasError) return;

    setStep('confirmation');
  };

  const onConfirmation = async () => {
    if (!updatedSharePercentage) return;
    await updateSharePercentage(updatedSharePercentage);
    setUpdatedSharePercentage(null);
    close();
  };

  if (isLoading) return <Loading />;

  if (step === 'confirmation')
    return (
      <Confirmation
        updatedSharePercentage={updatedSharePercentage}
        onConfirm={onConfirmation}
        onCancel={() => setStep('form')}
      />
    );

  if (error)
    return (
      <div className="flex flex-col gap-5 rounded-3xl bg-white p-5 text-sm shadow-xl">
        <span className="uppercase">Update Reward</span>
        <span className="text-red-500">Error</span>
      </div>
    );

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
      <span className="uppercase">Update Reward</span>
      <div className="flex flex-col gap-2">
        <div className="hover:[not(:focus-within):border-gray-400] group relative flex w-[190px] items-center  rounded-lg border border-gray-300 focus-within:border-indigo-500">
          <div className="w-full">
            <Input
              autoFocus={true}
              type="number"
              name="sharePercentage"
              className={'peer order-2 h-[42px] w-full rounded-lg px-4 py-3 font-light'}
              required
              value={updatedSharePercentage ?? ''}
              onChange={(val) => {
                if (val.target.value === '') {
                  setUpdatedSharePercentage(null);
                  return;
                }
                const isNumber = /^\d+$/.test(val.target.value);
                if (!isNumber) return;
                setUpdatedSharePercentage(parseInt(val.target.value, 10));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="flex items-center gap-2">
            <ValidationIndicator status={isBelowMinimum === null ? 'idle' : isBelowMinimum ? 'error' : 'success'} />
            <span className="text-gray-500">Min {ownReferral.sharePercentage + 1}%</span>
          </div>
          <div className="flex items-center gap-2">
            <ValidationIndicator status={isAboveMaximum === null ? 'idle' : isAboveMaximum ? 'error' : 'success'} />
            <span className="text-gray-500">Max {ownReferral.maxSharePercentage}%</span>
          </div>
        </div>
      </div>
      <Button size="small" className="mt-0 w-full" type="submit">
        {hasError ? 'Invalid' : 'Update'}
      </Button>
    </form>
  );
}

function Loading() {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner className="animate-spin" />
    </div>
  );
}

function Confirmation({
  updatedSharePercentage,
  onConfirm,
  onCancel,
}: {
  updatedSharePercentage: number | null;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  if (!updatedSharePercentage) return null;

  return (
    <div className="flex h-full flex-col items-center justify-between gap-3 text-center">
      <div className="flex flex-col gap-2 py-6">
        <span className="font-semibold">
          Are you sure you want to update your referral rewards to {updatedSharePercentage}%?
        </span>
        <span className="text-gray-500">You can never decrease it.</span>
      </div>
      <div className="flex w-full gap-4">
        <Button size="small" className="mt-0 w-full" onClick={onCancel} variant="outline">
          Cancel
        </Button>
        <Button size="small" className="mt-0 w-full" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}
