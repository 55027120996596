import clsx from 'clsx';

import { CreateLinkValidationItemStatus } from './OwnReferralLink/CreateOwnReferralLink';

export function ValidationIndicator({ status }: { status: CreateLinkValidationItemStatus }) {
  return (
    <div
      className={clsx('h-2 w-2 rounded-full', {
        'bg-gray-300': status === 'idle',
        'bg-green-500': status === 'success',
        'bg-red-500': status === 'error',
        'animate-pulse bg-orange-400': status === 'loading',
      })}
    ></div>
  );
}
