import { Swap } from '@catalabs/catalyst-api-client';

import { ReactComponent as SwapTime } from '~/img/time.svg';
import { cn } from '~/modules/common';

export interface SwapDurationProps {
  swap: Swap;
  fullWidth?: boolean;
}

export function SwapDuration({ swap, fullWidth = false }: SwapDurationProps): JSX.Element {
  if (!swap.startedAt || !swap.confirmedAt) {
    return <></>;
  }
  const duration = new Date(swap.confirmedAt).getTime() - new Date(swap.startedAt).getTime();
  const durationMinutes = duration / (60 * 1_000);
  return (
    <div
      className={cn('flex justify-between rounded-[20px] bg-white px-4 py-2 font-semibold text-[14px] md:w-1/2', {
        'md:w-full': fullWidth,
      })}
    >
      <SwapTime />
      <span>{`${durationMinutes.toFixed(3)} MIN`}</span>
    </div>
  );
}

export default SwapDuration;
