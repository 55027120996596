import { PoolAsset } from '@catalabs/catalyst-api-client';
import { estimateImbalanceLossDeposit, GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { parseUnits } from '@ethersproject/units';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import React, { createRef, useCallback, useEffect, useState } from 'react';

import { formatBalance, parseBalance, TokenBalance, toUnits } from '~/config';
import {
  CatalystStore,
  DepositRequestItem,
  formatValue,
  getMaxGasFee,
  isValidNumeric,
  WalletStore,
} from '~/modules/common';
import {
  DepositLiquiditySwapInfoItem,
  findLiquiditySwapRoute,
  getAssetKey,
  getDefaultTokenInfo,
  getLiquiditySwapTxInputs,
  optimizeDepositAmounts,
  PoolActionType,
  PoolApprovalState,
  PoolInteractionStep,
  PoolStore,
} from '~/modules/pools';

import { PoolTokenDepositRef } from '../components/PoolTokenDepositPane';
import {
  formatVaultInfoDeposit,
  getDepositFeeDetails,
  getDepositGasCostWithLiquiditySwap,
  getDepositWithLiquiditySwapFeeDetail,
} from '../utils';

export interface PoolDepositHelpers {
  depositAmounts: Map<string, string>;
  depositWeights: Map<string, number>;
  depositValues: Map<string, number>;
  depositPercentages: number[];
  poolAssets: PoolAsset[];
  poolTokenInfos: TokenInfo[];
  selectedTokenInfos: TokenInfo[];
  gasTokenInfos: Map<string, TokenInfo>;
  poolShare: number;
  depositValue: number;
  optimalDepositEnabled: boolean;
  liquiditySwapsEnabled: boolean;
  depositInputRefs: Map<string, React.RefObject<PoolTokenDepositRef>>;
  liquiditySwapRoute: Map<string, DepositLiquiditySwapInfoItem>;
  liquiditySwapFinalDepositAmounts: Map<string, number>;
  insufficientBalanceError: boolean;
  isAssetReplacedWithGasToken: Map<string, boolean>;
  gasTokenBalances: Map<string, TokenBalance>;
  assetBalances: Map<string, TokenBalance>;
  handlers: {
    handleSwitchAssetToGasToken: (asset: PoolAsset, useGasToken: boolean) => void;
    handleCompleteDeposit: () => void;
    handleCancelDeposit: () => void;
    handleDepositInputChange: (asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleMaxClick: (asset: PoolAsset) => void;
    handleDeposit: () => void;
    handleDepositPaneClick: (asset: PoolAsset) => void;
    handleDepositPanelBlur: (asset: PoolAsset) => void;
    handleTryAgain: () => void;
    handleOptimalDepositChange: (enabled: boolean) => void;
    handleLiquiditySwapsChange: (enabled: boolean) => void;
  };
}

export function usePoolDeposit(
  catalystStore: CatalystStore,
  walletStore: WalletStore,
  poolStore: PoolStore,
): PoolDepositHelpers | null {
  /**
   * Props:
   *
   * All props or values derived from props should be defined in this section of the file.
   *
   */
  const { pool } = poolStore;
  const { address } = walletStore;

  if (!pool) {
    return null;
  }
  const targetPool = pool;

  const poolAssets = targetPool.assets;

  // End Props

  /**
   * State
   *
   * All internal state values should be defined in this section of the file.
   *
   */
  const [depositAmounts, setDepositAmounts] = useState<Map<string, string>>(new Map());
  const [depositRequests, setDepositRequests] = useState<Map<string, DepositRequestItem>>(new Map());
  const [depositValues, setDepositValues] = useState<Map<string, number>>(new Map());
  const [depositWeights, setDepositWeights] = useState<Map<string, number>>(new Map());
  const [optimalDepositEnabled, setOptimalDepositEnabled] = useState<boolean>(true);
  const [liquiditySwapsEnabled, setLiquiditySwapsEnabled] = useState<boolean>(false);
  const [poolShare, setPoolShare] = useState(0);
  const [depositValue, setDepositValue] = useState(0);
  const [poolTokenInfos, setPoolTokenInfos] = useState<Map<string, TokenInfo>>(new Map());
  const [gasTokenInfos, setGasTokenInfos] = useState<Map<string, TokenInfo>>(new Map());
  const [liquiditySwapRoute, setLiquiditySwapRoute] = useState<Map<string, DepositLiquiditySwapInfoItem>>(new Map());
  const [tokenPrices, setTokenPrices] = useState<Map<string, number>>(new Map());
  const [isAssetReplacedWithGasToken, setIsAssetReplacedWithGasToken] = useState<Map<string, boolean>>(new Map());
  const [assetBalances, setAssetBalances] = useState<Map<string, TokenBalance>>(new Map());
  const [gasTokenBalances, setGasTokenBalances] = useState<Map<string, TokenBalance>>(new Map());

  const [liquiditySwapFinalDepositAmounts, setLiquiditySwapFinalDepositAmounts] = useState<Map<string, number>>(
    new Map(),
  );

  const [liquiditySwapFinalDepositValues, setLiquiditySwapFinalDepositValues] = useState<Map<string, number>>(
    new Map(),
  );

  const [depositInputRefs, setDepositInputRefs] = useState<Map<string, React.RefObject<PoolTokenDepositRef>>>(
    new Map(),
  );

  const depositAmountsValues = Array.from(depositAmounts.values());
  const isEveryValueZero = depositAmountsValues.every((value) => Number(value) === 0);
  const isAnyValueNotNumeric = depositAmountsValues.some((value) => !isValidNumeric(value));

  const isDepositAmountNotValid = isEveryValueZero || isAnyValueNotNumeric;

  const depositPercentages = poolAssets.map((asset) => {
    const assetKey = getAssetKey(asset);
    // if there is a liquidity swap show deposit values post deposit
    let poolAssetValue = depositValues.get(assetKey) || 0;
    if (liquiditySwapsEnabled && liquiditySwapRoute.size > 0) {
      const finalDeposit = liquiditySwapFinalDepositAmounts.get(assetKey) || 0;
      const tokenPrice = tokenPrices.get(assetKey) || 0;
      poolAssetValue = tokenPrice * finalDeposit || 0;
    }

    const weightOfAmount = (poolAssetValue / depositValue) * 100;
    return !Number.isFinite(weightOfAmount) ? 0 : weightOfAmount;
  });

  const insufficientBalanceError = Array.from(depositAmounts.entries()).some(([assetKey, amount]) => {
    if (amount === '') {
      return false;
    }
    const useGasToken = isAssetReplacedWithGasToken.get(assetKey);
    const userBalance = useGasToken ? gasTokenBalances.get(assetKey) : assetBalances.get(assetKey);
    if (!userBalance?.amount) {
      return false;
    }
    return BigInt(parseUnits(amount).toString()) > userBalance.amount;
  });

  const selectedTokenInfos = poolAssets.map((asset) => {
    const assetKey = getAssetKey({ address: asset.address, chainId: asset.chainId.toString() });
    if (isAssetReplacedWithGasToken.has(assetKey)) {
      return (
        gasTokenInfos.get(asset.chainId.toString()) || poolTokenInfos.get(assetKey) || getDefaultTokenInfo(assetKey)
      );
    }
    return poolTokenInfos.get(assetKey) || getDefaultTokenInfo(assetKey);
  });

  // End State

  /**
   * Hooks
   *
   * All hooks should be defined in this section of the file.
   *
   */
  // create refs for each deposit input, so we can focus on the first empty input when the user clicks on the deposit button
  useEffect(() => {
    if (depositInputRefs.size === 0) {
      poolAssets.forEach((asset) => {
        const ref = depositInputRefs.get(getAssetKey(asset)) || createRef<PoolTokenDepositRef>();
        depositInputRefs.set(getAssetKey(asset), ref);
      });
    }
    setDepositInputRefs(new Map(depositInputRefs));
  }, [poolAssets]);

  // if the user disconnects their wallet, clear the deposit state
  useEffect(() => {
    if (!address) {
      handleCompleteDeposit();
    }
  }, [address]);

  // if the user changes the deposit amounts, update the deposit values
  useEffect(() => {
    async function updateDepositValues() {
      for (const [assetKey, amount] of depositAmounts.entries()) {
        const tokenPrice = tokenPrices.get(assetKey) || 0;

        setDepositValues((prev) => {
          const tempDepositValueMap = new Map(prev);
          const depositValue = amount === '' ? 0 : tokenPrice * Number(amount);
          tempDepositValueMap.set(assetKey, depositValue);
          return tempDepositValueMap;
        });
      }
    }

    updateDepositValues();
  }, [depositAmounts, tokenPrices]);

  useEffect(() => {
    const totalDeposit: number = Array.from(depositValues.values()).reduce((total, value) => (total += value), 0);
    const poolShare = (totalDeposit / (targetPool.tvl + totalDeposit)) * 100;
    setDepositValue(totalDeposit);
    setPoolShare(poolShare);
    setDepositWeights((prev) => {
      const tempDepositWeightMap = new Map(prev);
      Array.from(depositValues.entries()).forEach((e) => {
        const [key, value] = e;
        const weight = totalDeposit <= 0 || value <= 0 ? 0 : (value / totalDeposit) * 100;
        tempDepositWeightMap.set(key, weight);
      });
      return tempDepositWeightMap;
    });
  }, [depositValues]);

  // if the liquidity swap amount changes, update the deposit values
  useEffect(() => {
    async function updateLiquiditySwapFinalDepositValues() {
      for (const [assetKey, amount] of liquiditySwapFinalDepositAmounts.entries()) {
        const tokenPrice = tokenPrices.get(assetKey) || 0;

        setLiquiditySwapFinalDepositValues((prev) => {
          const tempDepositValueMap = new Map(prev);
          const depositValue = amount === 0 ? 0 : tokenPrice * Number(amount);
          tempDepositValueMap.set(assetKey, depositValue);
          return tempDepositValueMap;
        });
      }
    }

    updateLiquiditySwapFinalDepositValues();
  }, [liquiditySwapFinalDepositAmounts, tokenPrices]);

  // if the deposit values change, update the deposit requests
  useEffect(() => {
    if (!poolStore.depositStore.showPendingDeposit) {
      generateDepositRequests();
    }
  }, [depositValues, depositAmounts, isAssetReplacedWithGasToken, liquiditySwapsEnabled, depositValue]);

  // if the deposit requests change, update the deposit percentages
  useEffect(() => {
    fetchTokenPrices();
    fetchAllPoolAssetInfo();
  }, [poolAssets]);

  useEffect(() => {
    fetchBalances();
  }, [poolAssets, liquiditySwapsEnabled]);

  // if the user enables liquidity swaps, find the optimal route for every deposit amount change
  useEffect(() => {
    const isInValidDepositAmounts = Array.from(depositAmounts.values()).every(
      (value) => !isValidNumeric(value) || Number(value) === 0,
    );
    if (!liquiditySwapsEnabled || isInValidDepositAmounts) {
      setLiquiditySwapRoute(new Map());
      return;
    }
    const { formattedQuote, finalDeposit } = findLiquiditySwapRoute({
      depositAmounts,
      vaults: Object.values(targetPool.vaults),
      isAssetReplacedWithGasToken,
      assetBalances,
      gasTokenBalances,
      poolTokenInfos,
    });
    setLiquiditySwapRoute(formattedQuote);
    setLiquiditySwapFinalDepositAmounts(finalDeposit);
  }, [liquiditySwapsEnabled, depositAmounts]);

  useEffect(() => {
    const isValidAmounts = Array.from(depositAmounts.values()).every((value) => isValidNumeric(value));
    if (optimalDepositEnabled && isValidAmounts) {
      const newDepositAmounts = optimizeDepositAmounts({
        depositAmounts,
        assetBalances,
        gasTokenBalances,
        depositInputRefs,
        isAssetReplacedWithGasToken,
        pool: targetPool,
        vaults: Object.values(targetPool.vaults),
      });
      if (newDepositAmounts) {
        setDepositAmounts(newDepositAmounts);
      }
      setLiquiditySwapsEnabled(false);
    } else {
      setLiquiditySwapsEnabled(true);
    }
  }, [optimalDepositEnabled]);

  useEffect(() => {
    if (!poolStore.depositStore.partialDeposit && !poolStore.depositStore.runningDeposit) {
      poolStore.depositStore.reset();
    }
  }, []);

  // End Hooks

  /**
   * Callback functions
   *
   * All functions that are passed as props to child components should be defined in this section of the file.
   *
   *
   */

  const fetchAllPoolAssetInfo = useCallback(async () => {
    const tokenInfoPromises = poolAssets.map((asset) => {
      return catalystStore.getToken(asset.chainId, asset.address);
    });
    const chainIds = Array.from(new Set(poolAssets.map((asset) => asset.chainId.toString())));
    const chainGasTokenInfoPromises = chainIds.map((chainId) => {
      return catalystStore.getToken(chainId, GAS_TOKEN_IDENTIFIER);
    });
    const gasTokenInfos = await Promise.all(chainGasTokenInfoPromises);
    const tokenInfoData = await Promise.all(tokenInfoPromises);

    poolAssets.forEach((asset, i) => {
      const chainId = asset.chainId.toString();
      const gasTokenInfo = gasTokenInfos[i];
      const tokenInfo = tokenInfoData[i];
      if (tokenInfo) {
        setPoolTokenInfos((prev) => {
          const tempTokenInfoMap = new Map(prev);
          tempTokenInfoMap.set(getAssetKey(asset), tokenInfo);
          return tempTokenInfoMap;
        });
      }
      if (gasTokenInfo) {
        setGasTokenInfos((prev) => {
          const tempGasTokenInfoMap = new Map(prev);
          tempGasTokenInfoMap.set(chainId, gasTokenInfo);
          return tempGasTokenInfoMap;
        });
      }
    });
  }, [poolAssets]);

  // fetch all token Prices
  const fetchTokenPrices = useCallback(async () => {
    const tokenInfoPromises = poolAssets.map((asset) => {
      return catalystStore.getToken(asset.chainId, asset.address);
    });
    const gasTokenInfoPromises = Array.from(new Set(poolAssets.map((asset) => asset.chainId.toString()))).map(
      (chainId) => {
        return catalystStore.getToken(chainId, GAS_TOKEN_IDENTIFIER);
      },
    );
    const tokenInfos = await Promise.all([...tokenInfoPromises, ...gasTokenInfoPromises]);
    const tokenPrices = tokenInfos.map((tokenInfo) => {
      return catalystStore.getPrice(tokenInfo);
    });
    const tokenPriceMap = new Map<string, number>();
    tokenInfos.forEach((tokenInfo, index) => {
      tokenPriceMap.set(
        getAssetKey({
          address: tokenInfo.address,
          chainId: tokenInfo.chainId.toString(),
        }),
        tokenPrices[index],
      );
    });
    setTokenPrices(tokenPriceMap);
  }, [poolAssets]);

  const fetchBalances = useCallback(async () => {
    const newAssetBalance = new Map<string, TokenBalance>();
    const newGasTokenBalance = new Map<string, TokenBalance>();
    const chainIds = Array.from(new Set(poolAssets.map((asset) => asset.chainId.toString())));
    const ambVersion = pool.arbitraryMessagingBridge;
    const feeData = await catalystStore.getFeeData(ambVersion, chainIds);
    for (const asset of poolAssets) {
      const chainId = asset.chainId.toString();
      const balance = walletStore.getBalance(asset.chainId.toString(), asset.address);
      const gasTokenBalance = walletStore.getBalance(asset.chainId.toString(), GAS_TOKEN_IDENTIFIER);
      const maxGasFee = getMaxGasFee(feeData[chainId]);

      // get the gas price for the chain, and subtract the gas cost from the gas token balance
      const totalGasCost = liquiditySwapsEnabled
        ? getDepositGasCostWithLiquiditySwap({
            chainId,
            feeData,
            pool: targetPool,
            address: address || '',
            tokenPrices,
            maxGasFee,
          })
        : maxGasFee;

      const maxGasTokenBalance = gasTokenBalance.amount - totalGasCost;

      // if the gas token balance is negative, set it to 0
      gasTokenBalance.amount = maxGasTokenBalance < 0n ? 0n : maxGasTokenBalance;

      newAssetBalance.set(getAssetKey(asset), balance);
      newGasTokenBalance.set(getAssetKey(asset), gasTokenBalance);
    }
    setAssetBalances(newAssetBalance);
    setGasTokenBalances(newGasTokenBalance);
  }, [poolAssets, liquiditySwapsEnabled, tokenPrices]);

  // generate deposit requests
  const generateDepositRequests = useCallback(async () => {
    if (!address || !targetPool || isDepositAmountNotValid) {
      return;
    }

    const newDepositRequests = new Map<string, DepositRequestItem>(depositRequests);

    Array.from(depositValues.entries()).forEach(async (e) => {
      const [key, value] = e;
      const amount = depositAmounts.get(key);
      if (value === 0 || amount === '' || amount === undefined) {
        newDepositRequests.delete(key);
        return;
      }
      const useGasToken = isAssetReplacedWithGasToken.get(key);
      const [assetAddress, chainId] = key.split('-');
      const tokenInfo = await catalystStore.getToken(chainId, useGasToken ? GAS_TOKEN_IDENTIFIER : assetAddress);
      const formattedValue = formatValue(Number(amount));
      const poolAsset = targetPool.assets.find((a) => a.address === address && a.chainId === chainId);
      newDepositRequests.set(key, {
        address: assetAddress,
        vault: targetPool.vaults[chainId].address,
        chainId,
        displayAmount: formattedValue,
        amount: toUnits(Number(amount), tokenInfo.decimals),
        value,
        index: poolAsset?.index ?? 0,
        approval: PoolApprovalState.Inactive,
        poolId: targetPool.id,
        tokenSymbol: tokenInfo.symbol,
        withLiquiditySwap: liquiditySwapsEnabled,
        finalLiquiditySwapAmount: liquiditySwapFinalDepositAmounts.has(key)
          ? formatValue(liquiditySwapFinalDepositAmounts.get(key) || 0, 6)
          : undefined,
        useGasToken: Boolean(useGasToken),
      });
    });
    setDepositRequests(newDepositRequests);

    const chainIds = Array.from(new Set(poolAssets.map((asset) => asset.chainId.toString())));
    const ambVersion = pool.arbitraryMessagingBridge;
    const feeData = await catalystStore.getFeeData(ambVersion, chainIds);
    let totalCost = [];

    if (liquiditySwapsEnabled) {
      const vaults = Object.values(targetPool.vaults);
      const txInputs = getLiquiditySwapTxInputs({
        address,
        assetBalances,
        depositAmounts,
        feeData,
        isAssetReplacedWithGasToken,
        gasTokenBalances,
        pool: targetPool,
        vaults,
        tokenPrices,
      });
      const { formattedQuote } = findLiquiditySwapRoute({
        depositAmounts,
        vaults: Object.values(targetPool.vaults),
        isAssetReplacedWithGasToken,
        assetBalances,
        gasTokenBalances,
        poolTokenInfos,
      });
      totalCost = getDepositWithLiquiditySwapFeeDetail({
        address,
        depositRequest: Array.from(newDepositRequests.values()),
        gasTokenPrices: tokenPrices,
        pool: targetPool,
        txInputs,
        swapQuote: formattedQuote,
        gasData: feeData,
      });
    } else {
      totalCost = getDepositFeeDetails({
        depositRequest: Array.from(newDepositRequests.values()),
        pool: targetPool,
        gasTokenPrices: tokenPrices,
        gasData: feeData,
      });
    }

    const { vaultsInfo, userDeposits } = formatVaultInfoDeposit({
      depositAmounts,
      isAssetReplacedWithGasToken,
      assetBalances,
      gasTokenBalances,
      vaults: Object.values(targetPool.vaults),
    });
    const estimatedLoss = formatBalance(estimateImbalanceLossDeposit(vaultsInfo, userDeposits));
    const priceImpact = 100 - 100 * estimatedLoss;

    runInAction(() => {
      poolStore.depositStore.depositInfo = {
        ...poolStore.depositStore.depositInfo,
        totalLiquidity: depositValue,
        poolShare,
        priceImpact,
        totalCost,
      };
    });
  }, [depositValues, depositAmounts, isAssetReplacedWithGasToken, liquiditySwapsEnabled, depositValue]);

  // End Callback functions

  /**
   * Handlers
   *
   * All event handlers should be defined in this section of the file.
   * !IMPORTANT: Handlers should start with the word "handle" followed by the event name.
   */
  async function handleDeposit() {
    if (!pool || address === undefined || !targetPool) {
      return;
    }

    // if there is a pending deposit, return
    if (poolStore.depositStore.partialDeposit) {
      return;
    }

    // if all inputs are empty, focus on the first input
    if (Array.from(depositAmounts.values()).every((value) => value === '')) {
      const firstInput = depositInputRefs.get(getAssetKey(poolAssets[0]));
      if (firstInput) {
        firstInput.current?.focus();
      }
      return;
    }

    // if any of the inputs has an error, focus on it
    for (const [assetKey, amount] of depositAmounts.entries()) {
      const useGasToken = isAssetReplacedWithGasToken.get(assetKey);
      const gasTokenBalance = gasTokenBalances.get(assetKey);
      const assetBalance = assetBalances.get(assetKey);
      const userBalance = useGasToken ? gasTokenBalance : assetBalance;
      if (!userBalance) {
        return;
      }
      if (amount === '' || !isValidNumeric(amount) || BigInt(parseUnits(amount).toString()) > userBalance.amount) {
        const input = depositInputRefs.get(assetKey);
        if (input) {
          input.current?.focus();
        }
        return;
      }
    }

    await generateDepositRequests();

    if (liquiditySwapsEnabled) {
      const chainIds = Array.from(new Set(poolAssets.map((asset) => asset.chainId.toString())));
      const ambVersion = pool.arbitraryMessagingBridge;
      const feeData = await catalystStore.getFeeData(ambVersion, chainIds);
      const vaults = Object.values(targetPool.vaults);
      const txInputs = getLiquiditySwapTxInputs({
        address,
        assetBalances,
        depositAmounts,
        feeData,
        isAssetReplacedWithGasToken,
        gasTokenBalances,
        pool: targetPool,
        vaults,
        tokenPrices,
      });
      runInAction(() => {
        poolStore.depositStore.liquiditySwapFinalAmounts = liquiditySwapFinalDepositAmounts;
        poolStore.depositStore.liquiditySwapFinalValues = liquiditySwapFinalDepositValues;
      });
      poolStore.depositStore.depositWithLiquiditySwap({
        requests: Array.from(depositRequests.values()),
        txInputs,
      });
    } else {
      poolStore.depositStore.deposit(Array.from(depositRequests.values()));
    }
  }

  function handleDepositPaneClick(asset: PoolAsset): void {
    const assetKey = getAssetKey(asset);
    if (!depositAmounts.get(assetKey)) {
      const tempInputMap = new Map(depositAmounts);
      tempInputMap.set(assetKey, '');
      setDepositAmounts(tempInputMap);
    }
  }

  function handleDepositPanelBlur(asset: PoolAsset): void {
    const assetKey = getAssetKey(asset);
    const value = depositAmounts.get(assetKey);
    const tempInputMap = new Map();
    tempInputMap.set(assetKey, value);
    const isValidAmounts = Array.from(tempInputMap.values()).every((value) => isValidNumeric(value));
    if (isValidAmounts && optimalDepositEnabled) {
      const newDepositAmounts = optimizeDepositAmounts({
        depositAmounts: tempInputMap,
        assetBalances,
        gasTokenBalances,
        depositInputRefs,
        isAssetReplacedWithGasToken,
        pool: targetPool,
        vaults: Object.values(targetPool.vaults),
      });
      if (newDepositAmounts) {
        setDepositAmounts(newDepositAmounts);
      }
    }
  }

  function handleTryAgain() {
    runInAction(() => {
      poolStore.depositStore.poolDepositError = undefined;
    });
    poolStore.depositStore.resumeDeposit();
  }

  function handleOptimalDepositChange(isEnabled: boolean) {
    setOptimalDepositEnabled(isEnabled);
    setLiquiditySwapsEnabled(!isEnabled);
  }

  function handleLiquiditySwapsChange(isEnabled: boolean) {
    setLiquiditySwapsEnabled(isEnabled);
  }

  function handleSwitchAssetToGasToken(asset: PoolAsset, useGasToken: boolean) {
    const assetKey = getAssetKey(asset);
    if (useGasToken) {
      isAssetReplacedWithGasToken.set(assetKey, true);
    } else {
      isAssetReplacedWithGasToken.delete(assetKey);
    }

    setIsAssetReplacedWithGasToken(new Map(isAssetReplacedWithGasToken));
  }

  function handleCompleteDeposit() {
    poolStore.updatePoolAccountBalance(targetPool.id);
    poolStore.depositStore.reset();

    setDepositRequests(new Map());
    setDepositAmounts(new Map());
    setDepositValues(new Map());
    setDepositWeights(new Map());
    setPoolShare(0);
    setDepositValue(0);
    runInAction(() => {
      poolStore.poolAction = PoolActionType.None;
    });
  }

  function handleCancelDeposit() {
    runInAction(() => {
      poolStore.depositStore.poolDepositStep = PoolInteractionStep.Configure;
    });
    if (!poolStore.depositStore.partialDeposit && !poolStore.depositStore.runningDeposit) {
      poolStore.depositStore.reset();
    }
  }

  function handleDepositInputChange(asset: PoolAsset, e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;

    if (isValidNumeric(value)) {
      setDepositAmounts((prev) => {
        const tempInputMap = new Map(prev);
        const assetKey = getAssetKey(asset);
        tempInputMap.set(assetKey, value.slice(0, 18));
        return tempInputMap;
      });
    }
  }

  const handleMaxClick = useCallback(
    (asset: PoolAsset): void => {
      if (!targetPool) {
        return;
      }
      const assetKey = getAssetKey(asset);

      const useGasToken = isAssetReplacedWithGasToken.get(assetKey);
      const gasTokenBalance = gasTokenBalances.get(assetKey);
      const assetBalance = assetBalances.get(assetKey);
      if (!gasTokenBalance || !assetBalance) {
        return;
      }
      const { amount, decimals } = useGasToken ? gasTokenBalance : assetBalance;

      let maxDepositAmount;
      try {
        const adjustedAmount = amount - parseBalance('1', 'gwei');
        if (adjustedAmount < 0n) {
          throw new Error('Insufficient balance to proceed with the deposit.');
        }
        maxDepositAmount = formatBalance(adjustedAmount, decimals).toString();
      } catch (e) {
        maxDepositAmount = '0';
      }

      const tempInputMap = new Map(depositAmounts);
      tempInputMap.set(assetKey, maxDepositAmount);
      setDepositAmounts(tempInputMap);
      const isValidAmounts = Array.from(tempInputMap.values()).every((value) => isValidNumeric(value));
      if (optimalDepositEnabled && isValidAmounts) {
        const newDepositAmounts = optimizeDepositAmounts({
          depositAmounts: tempInputMap,
          assetBalances,
          gasTokenBalances,
          depositInputRefs,
          isAssetReplacedWithGasToken,
          pool: targetPool,
          vaults: Object.values(targetPool.vaults),
        });
        if (newDepositAmounts) {
          setDepositAmounts(newDepositAmounts);
        }
      }
    },
    [gasTokenBalances, assetBalances, depositAmounts, optimalDepositEnabled],
  );

  // End Handlers

  return {
    depositInputRefs,
    depositAmounts,
    depositWeights,
    selectedTokenInfos,
    isAssetReplacedWithGasToken,
    poolAssets: targetPool.assets,
    poolShare,
    depositValues,
    depositValue,
    optimalDepositEnabled,
    liquiditySwapsEnabled,
    poolTokenInfos: poolAssets.map(
      (asset) => poolTokenInfos.get(getAssetKey(asset)) || getDefaultTokenInfo(getAssetKey(asset)),
    ),
    gasTokenInfos,
    liquiditySwapRoute,
    depositPercentages,
    insufficientBalanceError,
    liquiditySwapFinalDepositAmounts,
    gasTokenBalances,
    assetBalances,
    handlers: {
      handleCompleteDeposit,
      handleCancelDeposit,
      handleDepositInputChange,
      handleDeposit,
      handleMaxClick,
      handleDepositPaneClick,
      handleDepositPanelBlur,
      handleTryAgain,
      handleOptimalDepositChange,
      handleLiquiditySwapsChange,
      handleSwitchAssetToGasToken,
    },
  };
}
