import { XCircleIcon } from '@heroicons/react/24/solid';

export interface PendingPoolWithdrawBarProps {
  handleResume: () => void;
  handleCancel: () => void;
}

export const PendingPoolWithdrawBar = ({ handleResume, handleCancel }: PendingPoolWithdrawBarProps) => {
  return (
    <>
      <div className="relative flex w-full flex-col items-center justify-center gap-2 bg-red-500 py-4 md:flex-row md:gap-6">
        <p className="text-white">You have a pending withdrawal in a Pool.</p>
        <div className="flex items-center gap-2">
          <button
            onClick={handleResume}
            className="flex items-center justify-center rounded-full bg-white px-[12px] py-[4px] text-xs text-gray-900"
          >
            Resume Progress
          </button>
        </div>
        <div className="absolute right-3 cursor-pointer">
          <span onClick={handleCancel}>
            <XCircleIcon className="h-4 w-4 text-white" />
          </span>
        </div>
      </div>
    </>
  );
};

export default PendingPoolWithdrawBar;
