import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { AmountInput, formatCurrency, PriceImpact, SwapReviewLine, SwapSlippage } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import CustomWithdrawPane from '~/modules/pools/components/CustomWithdrawPane';
import PercentageSelector from '~/modules/pools/components/PercentageSelector';
import PoolWithdrawModal from '~/modules/pools/components/PoolWithdrawModal';
import { PoolActionType, PoolInteractionStep, PoolWithdrawType } from '~/modules/pools/enums';
import {
  MAX_PERCENTAGE_WITHDRAWABLE_BALANCED,
  MAX_PERCENTAGE_WITHDRAWABLE_SINGLE,
  usePoolWithdraw,
} from '~/modules/pools/hooks/usePoolWithdraw';
import { SwapCosts } from '~/modules/swap';

export const PoolWithdraw = observer(() => {
  const { catalyst, pool: poolStore, wallet } = useContext(StoreContext);
  const { withdrawStore } = poolStore;
  const { address, isConnected } = wallet;
  const helpers = usePoolWithdraw(catalyst, wallet, poolStore);

  if (!helpers) {
    return null;
  }

  const {
    depositValue,
    withdrawValue,
    withdrawAmounts,
    withdrawValues,
    poolAssets,
    withdrawPortion,
    additionalSlippage,
    customPortion,
    customPortionValue,
    assetBalances,
    insufficientBalanceError,
    withdrawType,
    handlers: {
      handleWithdraw,
      setWithdrawPortion,
      updateCustomPortionValue,
      cancelWithdraw,
      completeWithdraw,
      handleWithdrawInputChange,
      handleWithdrawInputBlur,
      handleWithdrawInputClick,
      handleMaxClick,
      handleBalancedWithdrawClick,
      handleOneTokenClick,
      handleChangeSingleWithdrawAsset,
      handleUseGasToken,
    },
  } = helpers;
  const walletReady = address && isConnected;
  const canWithdraw =
    walletReady &&
    !insufficientBalanceError &&
    Array.from(withdrawAmounts.values()).some((v) => Number(v) !== 0) &&
    !withdrawStore.hasPendingWithdraws;

  const withdrawButtonText = () => {
    if (!walletReady) {
      return 'Connect Wallet';
    }
    if (insufficientBalanceError) {
      return 'Insufficient Balance';
    }
    return 'Withdraw';
  };

  const activeButtonClasses = 'border-gray-950 bg-gray-950 text-white';
  const inActiveButtonClasses = 'text-gray-950 border-gray-200 bg-gray-100';

  const { feeDetails, priceImpact, poolWithdrawRequest, withLiquiditySwap, runningWithdraw } = withdrawStore;

  const MAX_PERCENTAGE_WITHDRAWABLE =
    withdrawType === PoolWithdrawType.Single
      ? MAX_PERCENTAGE_WITHDRAWABLE_SINGLE
      : MAX_PERCENTAGE_WITHDRAWABLE_BALANCED;

  return (
    <div className="mt-10 flex w-full flex-col px-3 md:mx-auto md:w-[1224px] md:px-10">
      {poolWithdrawRequest ? (
        <PoolWithdrawModal
          withdrawValue={withdrawValue}
          handleCloseModal={cancelWithdraw}
          handleCompleteWithdraw={completeWithdraw}
          open={withdrawStore.poolWithdrawStep === PoolInteractionStep.Approval}
          feeDetails={feeDetails}
          priceImpact={priceImpact}
          poolWithdrawRequest={poolWithdrawRequest}
          withLiquiditySwap={withLiquiditySwap}
          canCloseModal={!runningWithdraw}
        />
      ) : null}
      <div className="flex flex-col">
        <div
          className="mr-auto flex  cursor-pointer flex-row items-center space-x-2"
          onClick={() => runInAction(() => (poolStore.poolAction = PoolActionType.None))}
        >
          <Caret />
          <div className="text-xs">BACK</div>
        </div>
      </div>

      <div className="mt-6 flex flex-col justify-between gap-6 md:flex-row">
        <div className="flex flex-col rounded-[25px] bg-white p-4 md:p-6">
          <div className=" flex flex-col ">
            <span className="mt-[10px] font-semibold text-lg text-grey-800">Withdraw Liquidity</span>
            <div className="mt-1 flex items-center text-xs text-gray-600">Balance: {formatCurrency(depositValue)}</div>
            <div className="mt-4 flex flex-col gap-4">
              <div className=" flex gap-2">
                <div
                  className={`cursor-pointer rounded-lg border  px-4 py-2 text-sm  ${
                    withdrawType === PoolWithdrawType.Balanced ? activeButtonClasses : inActiveButtonClasses
                  }`}
                  onClick={handleBalancedWithdrawClick}
                >
                  Balanced Withdraw
                </div>
                <div
                  className={`cursor-pointer rounded-lg border  px-4 py-2  text-sm ${
                    withdrawType === PoolWithdrawType.Single ? activeButtonClasses : inActiveButtonClasses
                  } `}
                  onClick={handleOneTokenClick}
                >
                  One Token
                </div>
              </div>
              <p className=" h-10 text-sm text-gray-500">
                {withdrawType === PoolWithdrawType.Balanced
                  ? 'Make a balanced withdraw across all chains. Token amounts will be based on their weights.'
                  : null}
                {withdrawType === PoolWithdrawType.Single
                  ? 'Select one token to withdraw your liquidity. Catalyst will use Liquidity Swaps to rebalance your position automatically.'
                  : null}
              </p>
            </div>
          </div>
          <div className="mt-6 flex w-full flex-col gap-4 text-center md:flex-row">
            <div className="flex gap-4">
              <PercentageSelector
                handlePercentageChange={setWithdrawPortion}
                withdrawPercentage={withdrawPortion}
                percentage={25}
              />
              <PercentageSelector
                handlePercentageChange={setWithdrawPortion}
                withdrawPercentage={withdrawPortion}
                percentage={50}
              />
              <PercentageSelector
                handlePercentageChange={setWithdrawPortion}
                withdrawPercentage={withdrawPortion}
                percentage={75}
              />
              <PercentageSelector
                handlePercentageChange={setWithdrawPortion}
                withdrawPercentage={withdrawPortion}
                percentage={MAX_PERCENTAGE_WITHDRAWABLE}
              />
            </div>
            <button
              className={`group  flex h-10 w-full cursor-pointer items-center overflow-hidden rounded-lg border bg-white hover:border-primary md:h-[58px] md:w-[164px] ${
                customPortion ? 'border-primary ' : 'border-gray'
              }`}
              onClick={() => {
                const customEvent = {
                  target: { value: `${customPortionValue}` },
                } as React.ChangeEvent<HTMLInputElement>;
                updateCustomPortionValue(customEvent);
              }}
            >
              <div className="flex h-full items-center gap-1">
                <div
                  className={`flex h-full w-[77px] items-center justify-center border-r bg-grey-100 p-4 text-grey-500 group-hover:text-primary
                  ${customPortion ? 'text-primary' : ''}
                  `}
                >
                  <span className="text-xs">Custom</span>
                </div>
                <div className="flex items-center px-2 py-4 text-lg">
                  <AmountInput
                    className={`w-full text-right`}
                    isAllowed={(values) => {
                      const { floatValue, value } = values;
                      return value === '' || (Number(floatValue) >= 0 && Number(floatValue) <= 100);
                    }}
                    placeholder="0"
                    value={customPortionValue ? customPortionValue : ''}
                    onChange={updateCustomPortionValue}
                  />
                  <span className="text-[18px]">%</span>
                </div>
              </div>
            </button>
          </div>
          {withdrawType === PoolWithdrawType.Single && withdrawPortion === 99 && (
            <div className="mt-2 flex w-full flex-row justify-between gap-2 rounded-[12px] bg-primary-600  p-4  ">
              <p className="text-sm text-white">
                To ensure the best outcome of your withdrawal, you can only withdraw 99% of your tokens with this
                method. The remaining 1% can be withdrawn using the Balanced withdrawal.
              </p>
            </div>
          )}
          <div className="mt-4">
            <CustomWithdrawPane
              assets={poolAssets}
              withdrawAmounts={withdrawAmounts}
              withdrawValues={withdrawValues}
              assetBalances={assetBalances}
              updateInputText={handleWithdrawInputChange}
              handleWithdrawPaneClick={handleWithdrawInputClick}
              handleWithdrawPanelBlur={handleWithdrawInputBlur}
              setMaxInput={handleMaxClick}
              withdrawType={withdrawType}
              handleChangeSingleWithdrawAsset={handleChangeSingleWithdrawAsset}
              handleUseGasToken={handleUseGasToken}
            />
          </div>
        </div>

        <div className="flex flex-col-reverse text-sm md:flex-col">
          <div className="flex flex-col items-center space-y-4">
            <SwapReviewLine title="Total Liquidity" value={withdrawValue === 0 ? '-' : formatCurrency(withdrawValue)} />
            <SwapSlippage allowEdit={true} />
            <PriceImpact value={additionalSlippage} />
            <SwapCosts feeDetails={withdrawStore.feeDetails || []} />
          </div>

          <div className="my-6 flex justify-center md:mt-10">
            <button
              onClick={handleWithdraw}
              className={`flex h-[78px] w-full items-center justify-center rounded-full bg-primary px-12 py-8 text-white md:w-[416px] ${
                !canWithdraw ? 'cursor-pointer bg-opacity-30' : ''
              }`}
              data-testid="withdraw-button"
              disabled={!canWithdraw}
            >
              {withdrawButtonText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PoolWithdraw;
