import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';

import { TokenDisplay } from '~/modules/common';

export interface AssetInputOptionsDropdownProps {
  onChange: (assetKey: string) => void;
  assetKeys: string[];
  selected: string;
  onClose?: () => void;
}

export const AssetInputOptionsDropdown = ({
  assetKeys,
  onChange,
  selected,
  onClose,
}: AssetInputOptionsDropdownProps) => {
  const [selectedAddress, selectedChainId] = selected.split('-');
  const [open, setOpen] = useState(false);

  const handleOptionClick = (assetKey: string) => {
    onChange(assetKey);
    setOpen(false);
    onClose?.();
  };
  return (
    <Popover.Root open={open}>
      <Popover.Trigger onClick={() => setOpen(true)} className="group">
        <div className="flex cursor-pointer items-center gap-2">
          <TokenDisplay
            chainId={selectedChainId}
            token={selectedAddress}
            showSymbol={true}
            showChain={false}
            showTooltip={false}
            showChainIcon
          />
          <div>
            <ChevronDownIcon className="h-5 w-5 text-gray-500 group-hover:text-primary" />
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content onPointerDownOutside={() => setOpen(false)} onFocusOutside={() => setOpen(false)}>
          <div className="overflow-hidden rounded-[12px] border border-grey-200 bg-white shadow-2xl">
            <div className="flex flex-col items-center">
              <div className="flex w-[156px] flex-col items-center justify-center text-primary">
                {assetKeys.map((assetKey) => {
                  const [address, chainId] = assetKey.split('-');
                  return (
                    <div
                      key={address}
                      className="flex w-full cursor-pointer items-center gap-2 px-2  py-4 hover:bg-gray-100"
                      onClick={() => handleOptionClick(assetKey)}
                    >
                      <TokenDisplay
                        chainId={chainId}
                        token={address}
                        showSymbol={true}
                        showChain={false}
                        showTooltip={false}
                        showChainIcon
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
