import { observer } from 'mobx-react-lite';

import { Button, cn, Heading } from '~/modules/common';
import { TransactionList, TransactionType, useTransactionsPage } from '~/modules/transactions';

export const TransactionsPage = observer(() => {
  const { swapCount, vaultInteractionCount, selectedTab, selectSwapsTab, selectVaultInteractionsTab } =
    useTransactionsPage();

  return (
    <div className="mx-auto flex w-11/12 flex-col xl:w-[1224px]">
      <Heading text="Transactions" className="mt-[12vh]" />
      <div className="mb-4 mt-10 flex gap-4">
        <Button
          size="small"
          className={cn('mt-0 w-fit gap-3 rounded-xl bg-white px-5 text-black max-[375px]:h-16', {
            'bg-black text-white': selectedTab === TransactionType.SWAPS,
          })}
          onClick={selectSwapsTab}
        >
          Swaps <span className="font-bold">{swapCount !== -1 && swapCount}</span>
        </Button>
        <Button
          size="small"
          className={cn('mt-0 w-fit gap-3 rounded-xl bg-white px-5 text-black max-[375px]:h-16', {
            'bg-black text-white': selectedTab === TransactionType.VAULT_INTERACTIONS,
          })}
          onClick={selectVaultInteractionsTab}
        >
          Deposits / Withdraws{' '}
          <span className="font-bold">{vaultInteractionCount !== -1 && vaultInteractionCount}</span>
        </Button>
      </div>
      <TransactionList selectedTab={selectedTab} />
    </div>
  );
});
