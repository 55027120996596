import {
  SortOrder,
  Swap,
  SwapSortOption,
  VaultInteraction,
  VaultInteractionSortOption,
} from '@catalabs/catalyst-api-client';
import { makeAutoObservable } from 'mobx';

import { compareFnForDate, RootStore, SortDirection, Store } from '~/modules/common';
import { DEFAULT_SORT_DIRECTION, TransactionSortCriteria } from '~/modules/transactions';

export class TransactionsStore implements Store {
  // Swap data

  swaps: Swap[] = [];
  /**
   * Tracks the number of swap operations performed.
   *
   * @property {number} swapCount
   * @description
   * * `-1`: Count not fetched yet. Initial value.
   * * `>=0`: Actual count of swaps performed
   */
  swapCount: number = -1;
  /**
   * Tracks the page number of fetched swap data.
   *
   * @property {number} swapPage
   * @description
   * * `-1`: No swaps have been fetched yet. Initial value.
   * * `>=0`: Swaps have been fetched up to this page number
   */
  swapPage: number = -1;
  swapSortCriteria: TransactionSortCriteria = TransactionSortCriteria.Date;
  swapSortDirection: SortDirection = DEFAULT_SORT_DIRECTION;
  isSwapsLoading: boolean = false;

  // Vault interaction data

  vaultInteractions: VaultInteraction[] = [];
  /**
   * Tracks the number of vault interactions.
   *
   * @property {number} vaultInteractionCount
   * @description
   * * `-1`: Count not fetched yet. Initial value.
   * * `>=0`: Actual count of vault interactions
   */
  vaultInteractionCount: number = -1;
  /**
   * Tracks the page number of fetched vault interaction data.
   *
   * @property {number} vaultInteractionPage
   * @description
   * * `-1`: No vault interactions have been fetched yet. Initial value.
   * * `>=0`: Vault interactions have been fetched up to this page number
   */
  vaultInteractionPage: number = -1;
  vaultInteractionSortCriteria: TransactionSortCriteria = TransactionSortCriteria.Date;
  vaultInteractionSortDirection: SortDirection = DEFAULT_SORT_DIRECTION;
  isVaultInteractionsLoading: boolean = false;

  constructor(private store: RootStore) {
    makeAutoObservable(this);
  }

  // Data fetching methods

  async fetchSwaps(): Promise<void> {
    const address = this.store.wallet.address;
    if (!address || this.isSwapsLoading) {
      return;
    }

    this.isSwapsLoading = true;

    try {
      let sortBy;
      if (this.swapSortCriteria === TransactionSortCriteria.Date) {
        sortBy = SwapSortOption.SwapDate;
      }

      const sortOrder = SortOrder.DESC;

      const swaps = await this.store.client.accounts.getSwaps(address, {
        sortBy,
        sortOrder,
        page: this.swapPage + 1,
        size: 50,
      });

      this.swapCount = swaps.count;
      if (swaps.data.length !== 0) {
        if (this.swapSortDirection === SortDirection.Descending) {
          this.swaps = [...this.swaps, ...swaps.data];
        } else {
          this.swaps = [...swaps.data.reverse(), ...this.swaps];
        }

        this.swapPage++;
      }
    } catch {
      console.error(`Unable to load swaps for ${address}.`);
    }

    this.isSwapsLoading = false;
  }

  async fetchVaultInteractions(): Promise<void> {
    const address = this.store.wallet.address;
    if (!address || this.isVaultInteractionsLoading) {
      return;
    }

    this.isVaultInteractionsLoading = true;

    try {
      let sortBy;
      if (this.vaultInteractionSortCriteria === TransactionSortCriteria.Date) {
        sortBy = VaultInteractionSortOption.InteractionDate;
      }

      const sortOrder = SortOrder.DESC;

      const vaultInteractions = await this.store.client.accounts.getVaultInteractions(address, {
        sortBy,
        sortOrder,
        page: this.vaultInteractionPage + 1,
        size: 50,
      });

      this.vaultInteractionCount = vaultInteractions.count;
      if (vaultInteractions.data.length !== 0) {
        if (this.vaultInteractionSortDirection === SortDirection.Descending) {
          this.vaultInteractions = [...this.vaultInteractions, ...vaultInteractions.data];
        } else {
          this.vaultInteractions = [...vaultInteractions.data.reverse(), ...this.vaultInteractions];
        }

        this.vaultInteractionPage++;
      }
    } catch {
      console.error(`Unable to load vaultInteractions for ${address}.`);
    }

    this.isVaultInteractionsLoading = false;
  }

  // Sorting methods

  async sortSwaps(criteria: TransactionSortCriteria, direction: SortDirection) {
    // TODO: Perform sorting on API for large lists

    if (criteria === TransactionSortCriteria.Date) {
      // TODO: Fix type mismatch - 'date' is string from API, but typed as Date
      this.swaps.sort((a, b) => compareFnForDate(new Date(a.startedAt), new Date(b.startedAt), direction));
    }
  }

  async sortVaultInteractions(criteria: TransactionSortCriteria, direction: SortDirection) {
    // TODO: Perform sorting on API for large lists

    if (criteria === TransactionSortCriteria.Date) {
      this.vaultInteractions.sort((a, b) =>
        compareFnForDate(
          // TODO: Fix type mismatch - 'date' is string from API, but typed as Date
          // TODO: Add timestamp to each vault interaction for grouping on API
          new Date(a.transactions[0].timestamp),
          new Date(b.transactions[0].timestamp),
          direction,
        ),
      );
    }
  }

  async reset() {
    this.swaps = [];
    this.swapCount = -1;
    this.swapPage = -1;
    this.swapSortCriteria = TransactionSortCriteria.Date;
    this.swapSortDirection = DEFAULT_SORT_DIRECTION;
    this.isSwapsLoading = false;

    this.vaultInteractions = [];
    this.vaultInteractionCount = -1;
    this.vaultInteractionPage = -1;
    this.vaultInteractionSortCriteria = TransactionSortCriteria.Date;
    this.vaultInteractionSortDirection = DEFAULT_SORT_DIRECTION;
    this.isVaultInteractionsLoading = false;

    this.store.wallet.reset();
  }
}
