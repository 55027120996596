import { useState } from 'react';

import { ReferralStore } from '~/modules/common/store/referral-store';

export function useIsLinkUnique(referralStore: ReferralStore) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLinkUnique, setIsLinkUnique] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  const checkIfLinkUnique = async (code: string) => {
    try {
      if (code.length === 0) {
        setIsLinkUnique(null);
        return;
      }
      setIsLoading(true);
      const referral = await referralStore.getReferralByCode(code);
      setIsLinkUnique(referral === null);
    } catch (e) {
      setError('Invalid code');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLinkUnique,
    checkIfLinkUnique,
    isLoading,
    error,
  };
}
