import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { StoreContext, TokenDisplay } from '~/modules/common';
import { formatCurrency } from '~/modules/common/utils';
import {
  POOL_BREAKDOWN_COLORS,
  PoolActionType,
  PoolDeposit,
  PoolError,
  PoolWithdraw,
  usePoolData,
} from '~/modules/pools';
import { PoolAssetItem, PoolInteractionPane } from '~/modules/pools/components';

interface PoolStatisticProps {
  title: string;
  value: string;
}

function PoolStatistic({ title, value }: PoolStatisticProps): JSX.Element {
  return (
    <div className="h-[104px] rounded-[25px] bg-white md:w-1/3">
      <div className="flex flex-col p-6">
        <div className="text-sm leading-5 text-grey-500">{title}</div>
        <div className="overflow-hidden text-ellipsis font-semibold text-[20px] leading-8">{value}</div>
      </div>
    </div>
  );
}

export const PoolPage = observer(() => {
  const { wallet, pool: poolStore, catalyst } = useContext(StoreContext);
  const { aprDisplay, hasError, navigateToPoolList, poolAction, pool, poolTokensInfo, poolTokenPercentages } =
    usePoolData(poolStore, wallet, catalyst);

  if (!pool) {
    return null;
  }

  if (poolAction === PoolActionType.Deposit) {
    return <PoolDeposit />;
  }

  if (poolAction === PoolActionType.Widthdraw) {
    return Boolean(poolStore.poolAccountBalance) && <PoolWithdraw />;
  }

  return (
    <div className="mx-3 flex flex-grow flex-col items-center md:mx-auto">
      {/* Back Button */}
      <div className="mt-8 flex w-full text-sm md:mt-[42px]">
        <div className="flex cursor-pointer" onClick={navigateToPoolList}>
          <div className="ml-2 flex cursor-pointer items-center pb-[1.5px]">
            <Caret />
          </div>
          <span className="pl-[11px]">BACK</span>
        </div>
      </div>
      {hasError && <PoolError />}
      {/* Main Page Content */}
      <div className="flex w-full flex-col-reverse md:flex-row md:gap-[104px]">
        <div className="flex w-full flex-col gap-5 md:w-[700px] md:gap-0">
          <div className="pool mt-5 flex flex-col rounded-3xl p-6 text-white md:p-10">
            <div className="font-semibold leading-5 tracking-wider">
              <span className="text-sm uppercase md:text-base">Assets and Weights</span>
            </div>

            <div className="mt-[19px] flex w-fit flex-col gap-4 md:flex-row md:flex-wrap">
              {pool.assets.map((asset, i) => {
                const assetKey = `${asset.chainId}-${asset.address}`;
                const tokenInfo = poolTokensInfo.get(assetKey);
                if (!tokenInfo) {
                  return null;
                }
                return (
                  <div
                    key={`${pool}-${i}`}
                    className="flex h-[42px] items-center justify-between gap-2 rounded-3xl bg-white px-3"
                  >
                    <div className="flex items-center">
                      <TokenDisplay
                        chainId={asset.chainId}
                        token={asset.address}
                        showSymbol={false}
                        showChain={false}
                      />
                      <span className="text-sm text-grey-500">{tokenInfo.symbol}</span>
                    </div>

                    <span className="text-sm text-grey-500">{`${asset.weight.toFixed()}%`}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-5 md:mt-5 md:flex-row">
            <PoolStatistic title="APR" value={aprDisplay} />
            <PoolStatistic title="TVL" value={pool ? formatCurrency(pool.tvl) : '-'} />
            <PoolStatistic title="24H VOLUME" value={pool ? formatCurrency(pool.dailyVolume) : '-'} />
          </div>

          <div className="flex flex-col rounded-3xl bg-white p-6 md:mt-6 md:px-6 md:py-8">
            <span className="text-sm uppercase text-grey-500">POOL COMPOSITION</span>
            <div className="mt-6 flex overflow-hidden rounded-lg">
              {Array.from(poolTokensInfo.values()).map((_, index) => {
                const percentage = poolTokenPercentages[index];
                return (
                  <div
                    key={`pool-composition-token-${index}`}
                    className={`composition-bar flex h-2 flex-row items-center justify-center transition-all`}
                    style={{ backgroundColor: POOL_BREAKDOWN_COLORS[index], width: `${percentage}%` }}
                  ></div>
                );
              })}
            </div>
            <div className="mt-5 flex flex-col gap-2 text-[14px] leading-[14px]">
              {pool.assets.map((a, i) => (
                <PoolAssetItem key={`pool-${pool.id}-asset-${i}`} pool={pool} asset={a} index={i} />
              ))}
            </div>
          </div>
        </div>
        <div>
          <PoolInteractionPane />
        </div>
      </div>
    </div>
  );
});

export default PoolPage;
