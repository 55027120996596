import { ReactComponent as Caret } from '~/img/caret.svg';

export interface TokenWeightInputLoaderProps {
  onClick: () => void;
  nextTokenNumber?: number;
}

export const TokenWeightInputLoader = ({ onClick, nextTokenNumber = 1 }: TokenWeightInputLoaderProps) => {
  return (
    <div className="flex w-full items-center gap-3 px-3 py-4 md:px-6">
      <div
        onClick={onClick}
        className="border-gray flex h-[52px] w-5/6 cursor-pointer items-center rounded-[12px] border"
      >
        <div className="flex w-full items-center pl-4 text-lg text-grey">
          <div className="flex h-10 items-center">
            <div className="h-10 w-10 rounded-full bg-grey-100"></div>
            <div className="-ml-3 h-[2.75rem] w-[2.75rem] rounded-full border-[2.8px] border-white bg-grey-200"></div>
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="ml-4 text-sm text-grey-500">Select token {nextTokenNumber} </span>
            <div className="pr-[29px]">
              <Caret className="-rotate-90 text-grey-700" />
            </div>
          </div>
        </div>
      </div>

      <div className="border-gray flex h-[52px] w-[94px] cursor-pointer items-center rounded-[12px] border">
        <div className="flex w-full items-center pl-4 text-lg text-grey">
          <div className="flex h-10 items-center">
            <span className=" ml-4 text-xl text-grey-300">0%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenWeightInputLoader;
