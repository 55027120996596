import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { StoreContext } from '~/modules/common';

import { PendingTnxType } from '../interfaces';
import { CompletedDepositCard } from './CompletedDepositCard';
import { CompletedWithdrawCard } from './CompletedWithdrawCard';
import SwapCompletionTracker from './SwapCompletionTracker';

const CompletedTxnSection = observer(() => {
  const { lobby: lobbyStore } = useContext(StoreContext);

  const allCompletedTxns = lobbyStore.allCompletedTxns;

  const handleCompletedTxn = (hash: string) => {
    lobbyStore.removeCompletedTxn(hash);
  };
  return (
    <div className="">
      <div className="pointer-events-auto h-full w-full">
        <div className="absolute bottom-10 right-10 flex flex-col-reverse gap-2">
          {allCompletedTxns.map((txn) => {
            if (txn.type === PendingTnxType.Deposit) {
              return (
                <CompletedDepositCard key={txn.hash} txnDetails={txn} handleCompletedDeposit={handleCompletedTxn} />
              );
            }
            if (txn.type === PendingTnxType.Withdraw) {
              return (
                <CompletedWithdrawCard key={txn.hash} txnDetails={txn} handleCompletedWithdraw={handleCompletedTxn} />
              );
            }
            return null;
          })}
          <SwapCompletionTracker />
        </div>
      </div>
    </div>
  );
});

export default CompletedTxnSection;
