import './LiquiditySwapQuoteModal.css';

import { getChainConfig } from '@catalabs/catalyst-chain-lists';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';

import { formatValue, Modal, TokenDisplay } from '~/modules/common';

import { DepositLiquiditySwapInfoItem } from '../../interfaces';
import { getMessagingBridgeName } from '../../utils';
import { MessagingBridgeIcon } from '../MessagingBridgeIcon';

export interface LiquiditySwapQuoteModalProps {
  open: boolean;
  onClose: () => void;
  quotes: DepositLiquiditySwapInfoItem[];
  poolAssetsKeys?: string[];
}

export function LiquiditySwapQuoteModal(props: LiquiditySwapQuoteModalProps) {
  const { open, onClose, quotes, poolAssetsKeys } = props;
  const { finalDeposit, initialDeposit } = quotes[0];
  const quoteDetails = quotes.map((quote) => quote.quoteDetails).flat();
  const handleModalClose = () => {
    onClose();
  };

  // if poolAssetKey is provided, sort the finalDeposit by the order of poolAssetKey
  // if not, sort the finalDeposit by the order of the keys
  const sortedFinalDeposit: [string, number][] =
    poolAssetsKeys?.map((key) => [key, finalDeposit.get(key) || 0]) || Array.from(finalDeposit);

  const sortedInitalDeposit: [string, number][] =
    poolAssetsKeys?.map((key) => [key, initialDeposit.get(key) || 0]) || Array.from(initialDeposit);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <div className="flex w-11/12 flex-col gap-4 md:w-full">
        <div className="font-bold text-2xl">Liquidity swaps</div>
        <div className="flex flex-col rounded-3xl bg-white md:flex-row">
          <div className="flex w-full flex-col gap-10 border-b border-r-0 border-gray-100 p-5 md:w-2/5 md:border-b-0 md:border-r">
            <div className="flex flex-col gap-4">
              <div className="h-full w-full text-sm font-medium text-black">Initial Deposit</div>
              {sortedInitalDeposit.map(([key, amount]) => {
                const [tokenAddress, chainId] = key.split('-');
                if (amount <= 0) return null;
                return (
                  <div key={key} className="flex items-center justify-start gap-3">
                    <TokenDisplay chainId={chainId} token={tokenAddress} showSymbol={false} />
                    <div>{formatValue(amount, 6)}</div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col gap-4">
              <div className="h-full w-full text-sm font-medium text-black">Final Deposit</div>
              {sortedFinalDeposit.map(([key, amount]) => {
                const [tokenAddress, chainId] = key.split('-');
                if (amount <= 0) return null;
                return (
                  <div key={key} className="flex items-center justify-start gap-3">
                    <TokenDisplay chainId={chainId} token={tokenAddress} showSymbol={false} />
                    <div>{formatValue(amount, 6)}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full md:w-3/5">
            <div className="w-full border-b-0 border-gray-100 md:border-b">
              <div className="h-full w-full p-5 text-sm font-medium text-black">
                {quoteDetails.length} Liquidity Swap{quoteDetails.length > 1 ? 's' : ''}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              {quoteDetails.map((quoteDetail, index) => {
                return (
                  <div className="flex flex-col gap-4" key={`quote-detail-${index}`}>
                    {index > 0 && <div className="w-full border-t border-gray-100"></div>}
                    <div key={index} className="flex flex-col gap-1 p-5">
                      {quoteDetail.swap.from.map(({ token, amount }, index) => {
                        const fromAmount = formatValue(amount, 6);
                        const vaultAmount = formatValue(quoteDetail.vaultAmount, 6);
                        return (
                          <div key={index} className="flex items-center justify-start gap-3">
                            <TokenDisplay chainId={token.chainId.toString()} token={token.address} showSymbol={false} />
                            <div>
                              <div className="flex flex-row items-center gap-2 text-xs">
                                {fromAmount} {token.symbol} <ArrowsRightLeftIcon className="h-3 w-3" /> {vaultAmount} VT
                              </div>
                              <div className="text-xs">Swap {token.symbol} into Vault Tokens</div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="step-divider" />
                      <div className="flex items-center gap-8 text-xs">
                        <MessagingBridgeIcon bridge={quoteDetail.channel} /> Move vault tokens via{' '}
                        {getMessagingBridgeName(quoteDetail.channel)}
                      </div>
                      <div className="step-divider" />
                      {quoteDetail.swap.to.map(({ token, amount }, index) => {
                        const toAmount = formatValue(amount, 6);
                        const vaultAmount = formatValue(quoteDetail.vaultAmount, 6);
                        const chain = getChainConfig(token.chainId.toString());
                        return (
                          <div key={index} className="flex items-center justify-start gap-3">
                            <TokenDisplay chainId={token.chainId.toString()} token={token.address} showSymbol={false} />
                            <div>
                              <div className="flex flex-row items-center gap-2 text-xs">
                                {vaultAmount} VT <ArrowsRightLeftIcon className="h-3 w-3" /> {toAmount} {token.symbol}
                              </div>
                              <div className="text-xs">Swap Vault Tokens into {token.symbol}</div>
                              <div className="text-xs">Deposit into {chain.name} Vault</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
