import { TokenInfo } from '@uniswap/token-lists';
import { useEffect, useState } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { ReactComponent as Close } from '~/img/close.svg';
import { ReactComponent as SourcePlaceholder } from '~/img/source-placeholder.svg';
import { Input } from '~/modules/common/components/Input';
import { TokenWeightInputLoader } from '~/modules/pools/components/TokenWeightInputLoader';

export interface TokenWeightInputProps {
  token: TokenInfo;
  onChange: (token: TokenInfo, amount: number) => void;
  disabled?: boolean;
  error?: string;
  onClick: () => void;
  chainId: number | string;
  onRemoveToken: (token: TokenInfo) => void;
  value?: number;
}

export const TokenWeightInput = ({
  token,
  value,
  onChange,
  chainId,
  onClick,
  onRemoveToken,
}: TokenWeightInputProps) => {
  const [weight, setWeight] = useState(value);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    setWeight(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value);
    if (number > 100 || number < 0 || isNaN(number)) return;
    setWeight(number);
    onChange(token, number);
  };

  return (
    <div className="mt-4 flex items-center gap-3 px-3 md:px-6">
      <div className="border-gray flex h-[52px] w-full items-center rounded-[12px] border">
        <div onClick={onClick} className="flex w-full cursor-pointer items-center justify-between px-4 text-grey">
          <div className="flex items-center gap-1">
            <img className="h-7 w-7 rounded-full" src={`/img/${chainId}.webp`} />
            {token?.logoURI ? (
              <img className="-ml-2 h-7 w-7 rounded-full" src={token?.logoURI} />
            ) : (
              <div className="-ml-2 h-7 w-7 rounded-full">{<SourcePlaceholder />}</div>
            )}
            <span>{token.symbol}</span>
          </div>
          <div className="">
            <Caret className="-rotate-90 text-grey-700" />
          </div>
        </div>
      </div>
      <div className="flex h-[52px] w-36 items-center gap-2">
        <div className="border-gray relative flex h-[52px] items-center rounded-[12px] border p-1 pr-3">
          <Input
            numeric
            className="border-gray h-[62px] rounded-[12px] p-2 text-right"
            max={100}
            min={0}
            placeholder="0"
            onChange={handleChange}
            value={weight}
          />
          <span className=""> % </span>
        </div>
        <div onClick={() => onRemoveToken(token)} className="cursor-pointer">
          <Close className="w-3 text-grey-500" />
        </div>
      </div>
    </div>
  );
};

TokenWeightInput.Loader = TokenWeightInputLoader;

export default TokenWeightInput;
