interface EggDataItemProps {
  title: string;
  value?: string;
}

export default function EggDataItem({ title, value }: EggDataItemProps) {
  return (
    <div className="flex flex-col">
      <div className="text-[12px] uppercase">{title}</div>
      {!value && <div className="rewards mt-2 h-5 w-3/5 animate-pulse rounded-lg p-2" />}
      {value && <div className="font-bold text-[18px] text-white">{value}</div>}
    </div>
  );
}
