import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { Input } from '../Input/Input';

interface InputProperties extends NumericFormatProps {
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const AmountInput: React.FC<InputProperties> = (props) => {
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  }

  function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
    const inputValues = new Set(e.clipboardData.getData('text').split(''));
    if (inputValues.has('e') || inputValues.has('-')) {
      e.preventDefault();
    }
  }

  return <NumericFormat customInput={Input} onKeyDown={handleKeyDown} onPaste={handlePaste} {...props} />;
};
