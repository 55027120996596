import { observer } from 'mobx-react-lite';

import { TableHeader } from '~/modules/common/components';
import { TransactionSortCriteria } from '~/modules/transactions/enums';
import { useSwapListHeader } from '~/modules/transactions/hooks';

export const SwapListHeader = observer(() => {
  const { sortCriteria, setSortCriteria, sortDirection, setSortDirection } = useSwapListHeader();

  return (
    <>
      <TableHeader
        key="date"
        title="Date"
        className="col-span-1"
        isActive={sortCriteria === TransactionSortCriteria.Date}
        sort={sortCriteria}
        setSort={setSortCriteria}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <div key="token-sent" className="col-span-2 flex items-center">
        TOKEN SENT
      </div>
      <div key="token-sent-usd" className="col-span-1 flex items-center">
        USD
      </div>
      <div key="token-received" className="col-span-2 flex items-center">
        TOKEN RECEIVED
      </div>
      <div key="token-received-usd" className="col-span-1 flex items-center">
        USD
      </div>
      <div key="status" className="col-span-1 flex items-center">
        STATUS
      </div>
    </>
  );
});
