import { Referral } from '@catalabs/catalyst-api-client';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Spinner } from '~/img/spinner.svg';
import { Button, Input, StoreContext } from '~/modules/common';

import { useAcceptReferral } from '../hooks/useAcceptReferral';
import { useUtilizedReferral } from '../hooks/useUtilizedReferral';
import { CenteredConnectWallet } from './CenteredConnectWallet';

enum ReferralError {
  SELF,
  SUB,
  INVALID,
}

const DEFAULT_TEXT = 'Paste link here';

export const UseReferralLink = observer(() => {
  const location = useLocation();
  const [selectedCode, setSelectedCode] = useState(false);
  const [code, setCode] = useState<string | undefined>();
  const [error, setError] = useState<ReferralError | null>(null);
  const { referral: referralStore, wallet: walletStore } = useContext(StoreContext);
  const { isLoading, utilizedReferral } = useUtilizedReferral(referralStore, walletStore);
  const { isLoading: isAcceptingReferralLoading, acceptReferral } = useAcceptReferral(referralStore, walletStore);
  const [referral, setReferral] = useState<Referral | null>(null);

  const isConnected = walletStore.isConnected;

  async function validateUserAcceptance(code: string): Promise<boolean> {
    const validatedReferral = await referralStore.getReferralByCode(code, true);
    if (!validatedReferral) {
      if (referralStore.ownReferral && referralStore.ownReferral.code === code) {
        setError(ReferralError.SELF);
      } else {
        setError(ReferralError.SUB);
      }
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function handleReferralLink() {
      if (location.pathname.startsWith('/join/')) {
        const targetCode = location.pathname.slice(location.pathname.lastIndexOf('/') + 1).toUpperCase();
        setCode(targetCode);
        const referral = await referralStore.getReferralByCode(targetCode);
        if (referral === null) {
          setError(ReferralError.INVALID);
        } else {
          await validateUserAcceptance(targetCode);
          setReferral(referral);
          setSelectedCode(true);
        }
      }
    }
    handleReferralLink();
  }, [walletStore.address]);

  const blurStyles = 'blur-[10px] opacity-60';

  async function handleCreateReferralSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!code || !referral) {
      return;
    }

    setSelectedCode(true);
    validateUserAcceptance(code);
  }

  async function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (error) {
      setError(null);
    }

    const input = e.target.value;

    const finalSlash = input.lastIndexOf('/');
    const newInput = input.slice(finalSlash > -1 ? finalSlash + 1 : 0).toUpperCase();

    setCode(newInput);

    if (!newInput) {
      return;
    }

    const referral = await referralStore.getReferralByCode(newInput);
    if (referral === null) {
      setError(ReferralError.INVALID);
    } else {
      setReferral(referral);
    }
  }

  if (isAcceptingReferralLoading || isLoading) {
    return (
      <div className="relative flex h-full w-full flex-col items-center justify-center rounded-3xl bg-white p-6">
        <Spinner className="animate-spin" />
      </div>
    );
  }

  let referralAcceptanceText = (
    <span>
      Are you sure you want to use this link?
      <br />
      You won't be able to change it.
    </span>
  );
  if (error === ReferralError.SUB) {
    referralAcceptanceText = (
      <span>You cannot use a referral code of a user who has been directly or indirectly referred by yourself.</span>
    );
  } else if (error === ReferralError.SELF) {
    referralAcceptanceText = (
      <span>
        Sorry, you can’t use this link.
        <br />
        Insert a link created by a different address.
      </span>
    );
  }

  if (isConnected && selectedCode && referral) {
    return (
      <div className="relative flex h-full w-full flex-col items-center justify-center rounded-3xl bg-white p-6">
        <div
          className={clsx('flex h-[50%] w-full flex-col items-center justify-center gap-4 rounded-xl bg-grey-100 p-5', {
            'bg-red-200': error !== null,
          })}
        >
          <span className="font-semibold text-lg">{referral.code.toUpperCase()}</span>
          {error === null && <span className="text-gray-500">{referral.sharePercentage}% Reward Sharing</span>}
          {error === ReferralError.SUB && (
            <span className="text-[16px] text-[#6B7280]">This link is part of your referral tree.</span>
          )}
          {error === ReferralError.SELF && (
            <span className="text-[16px] text-[#6B7280]">This link was created by you.</span>
          )}
        </div>
        <div className="flex h-[50%] w-full flex-col items-center justify-center gap-4 pt-5">
          <span className="font-sm text-center font-light text-sm text-gray-500">{referralAcceptanceText}</span>
          {error === null && (
            <div className="flex w-full justify-center gap-4">
              <Button
                size="small"
                variant="outline"
                className="mt-0"
                onClick={() => {
                  setSelectedCode(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="mt-0"
                size="small"
                onClick={async () => {
                  await acceptReferral(referral.code);
                  setReferral(null);
                }}
              >
                Use link
              </Button>
            </div>
          )}
          {error !== null && (
            <div className="flex w-full justify-center">
              <Button
                size="small"
                className="mt-0"
                onClick={() => {
                  setSelectedCode(false);
                  setCode(undefined);
                  setError(null);
                }}
              >
                Use another link
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (utilizedReferral) {
    return (
      <div className="relative flex h-full w-full flex-col items-center justify-center rounded-3xl bg-white p-6">
        <div className="flex h-full w-full flex-col items-center justify-center gap-4 rounded-xl bg-grey-100 p-5">
          <div className="flex rounded-full bg-emerald px-4 py-1">
            <span className="text-xs">Link Activated</span>
          </div>
          <span className="font-semibold text-lg">{utilizedReferral.code}</span>
          <span className="text-gray-500">{utilizedReferral.sharePercentage}% reward</span>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-between rounded-3xl bg-white p-6">
      <div
        className={clsx(`flex flex-col items-center justify-center gap-2`, (!isConnected || isLoading) && blurStyles)}
      >
        <div className="font-semibold text-lg">Use a referral link</div>
        <span className="text-center text-sm text-grey-500">
          You are still not using a referral link. Paste one and start earning rewards.
        </span>
      </div>
      {!isConnected && <CenteredConnectWallet />}
      <form
        className={clsx(
          `flex w-full flex-col gap-4 rounded-xl bg-grey-100 p-5`,
          (!isConnected || isLoading) && blurStyles,
        )}
        onSubmit={handleCreateReferralSubmit}
      >
        <div
          className={clsx(
            `hover:[not(:focus-within):border-gray-400] group flex w-full items-center rounded-lg border border-gray-300 focus-within:border-indigo-500`,
            { 'border-red-60 border-red-600 bg-red-100 focus-within:border-red-600': error !== null },
          )}
        >
          <Input
            type="search"
            className={clsx('peer order-2 h-[42px] min-w-0 rounded-lg px-4 py-3 font-light', {
              'bg-red-100 text-red-700': error !== null,
              'text-[#797E87]': !code,
            })}
            onChange={handleInputChange}
            onFocus={() => {
              if (code === undefined) {
                setCode('');
              }
            }}
            onBlur={() => {
              if (code === '') {
                setCode(undefined);
              }
            }}
            name="code"
            value={code !== undefined ? code : DEFAULT_TEXT}
          />
        </div>
        <div>
          {!error ? (
            <Button size="small" className="mt-0 w-full" type="submit">
              Add Link
            </Button>
          ) : (
            <Button size="small" className="mt-0 w-full" type="submit">
              Invalid link
            </Button>
          )}
        </div>
      </form>
    </div>
  );
});
