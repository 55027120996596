import { formatValue, TokenDisplay } from '~/modules/common';

import { PendingTxn } from '../interfaces';

interface PendingSwapCardProps {
  pendingTxn: PendingTxn;
  handleShowDetails: (txn: PendingTxn) => void;
}

export const PendingSwapCard: React.FC<PendingSwapCardProps> = ({ pendingTxn, handleShowDetails }) => {
  const { swap } = pendingTxn;
  if (!swap) {
    return null;
  }

  return (
    <div className="flex w-full justify-center rounded-[20px] bg-white px-6 py-4 shadow-2xl">
      <div className="flex w-full flex-col gap-3">
        <div className="flex justify-between ">
          <p className="text-xs text-gray-500"> Swap in progress</p>
          <div
            onClick={() => handleShowDetails(pendingTxn)}
            className="flex h-[20px] w-[47px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-xs text-white"
          >
            Open
          </div>
        </div>

        <div className="flex flex-col">
          <div className="relative mb-2 h-[2px] w-full rounded-full bg-grey-200">
            <progress className="absolute h-[2px] w-full rounded-full" value={swap.progress} max={100} />
          </div>
          <div className="flex justify-between text-[14px] text-grey">
            <div className="gap flex items-center">
              <TokenDisplay
                chainId={swap.quote.fromChainId}
                token={swap.quote.fromAsset}
                size="sm"
                showSymbol={false}
              />
              <div className="text-xs text-gray-800">{formatValue(swap.quote.fromAmount, 6)} </div>
            </div>
            <div className="gap flex items-center">
              <div className="mr-2 text-xs text-gray-800">{formatValue(swap.quote.amountOut, 6)} </div>
              <TokenDisplay chainId={swap.quote.toChainId} token={swap.quote.toAsset} size="sm" showSymbol={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
