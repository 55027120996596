import { PoolApr } from '@catalabs/catalyst-api-client';
import * as HoverCard from '@radix-ui/react-hover-card';
import { useState } from 'react';

import { ReactComponent as Breakdown } from '~/img/breakdown.svg';

interface PoolBreakdownProps {
  apr: number;
  aprSources: PoolApr[];
}

export function PoolAprBreakdown({ pool }: { pool: PoolBreakdownProps }): JSX.Element {
  const [showBreakdown, setShowBreakdown] = useState(false);

  return (
    <div className="relative flex flex-col justify-center">
      <div className="flex items-center">
        <span className="mr-2">{pool.apr.toLocaleString(undefined, { maximumFractionDigits: 2 })}%</span>
        <div>
          <HoverCard.Root
            open={showBreakdown}
            openDelay={0}
            onOpenChange={(value) => {
              return setShowBreakdown(value);
            }}
          >
            <HoverCard.Trigger
              onClick={() => {
                return setShowBreakdown(!showBreakdown);
              }}
            >
              <div className="group relative flex h-9 w-9 items-center justify-center rounded-full transition ease-in-out hover:bg-primary hover:bg-opacity-10">
                <Breakdown />
              </div>
            </HoverCard.Trigger>
            <HoverCard.Portal>
              <HoverCard.Content
                onFocusOutside={(e) => {
                  e.stopPropagation();
                }}
                side="top"
                sideOffset={8}
                align="center"
                className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
              >
                <div className="z-10 flex min-w-[162px] flex-col rounded-[25px] bg-white shadow-xl">
                  <div className="px-5 py-4">
                    <div className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                      Total APR
                    </div>
                    <div className="font-bold leading-6">
                      {pool.apr.toLocaleString(undefined, { maximumFractionDigits: 2 })}%
                    </div>
                  </div>
                  {pool.aprSources.length > 0 && (
                    <>
                      <hr />
                      <div className="px-5 py-4 text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                        {pool.aprSources.map((s) => (
                          <div key={`${s.source.toLocaleLowerCase()}`} className="flex justify-between">
                            <div>{s.source}</div>
                            <div>
                              {s.hasRange ? `` : `${s.apr.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </HoverCard.Content>
            </HoverCard.Portal>
          </HoverCard.Root>
        </div>
      </div>
    </div>
  );
}
