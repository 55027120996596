export interface SwapReviewLineProps {
  title: string;
  value: string;
}

export function SwapReviewLine({ title, value }: SwapReviewLineProps): JSX.Element {
  return (
    <div className={`flex h-[36px] w-full justify-between rounded-[20px] bg-white px-4 py-2 text-[14px] leading-5`}>
      <span className="text-gray-600">{title}</span>
      <span className="font-subheader">{value}</span>
    </div>
  );
}

export default SwapReviewLine;
