import { Pool, PoolAccountBalance, PoolsList } from '@catalabs/catalyst-api-client';
import { getChainConfig } from '@catalabs/catalyst-chain-lists';
import { CCIVersion, GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { TokenInfo } from '@uniswap/token-lists';
import { ethers } from 'ethers';
import { makeAutoObservable, runInAction } from 'mobx';

import { delay } from '~/config';
import { CatalystNetwork } from '~/config/network/catalyst-network';
import type { RootStore, Store } from '~/modules/common';
import { db } from '~/modules/common/db';
import { StorageKey } from '~/modules/common/store/enums';
import { getPendingPoolCreatePoolRequests, getSavedPoolCreateState } from '~/modules/common/utils';
import {
  CreatePoolType,
  PoolActionState,
  PoolActionType,
  PoolCreateStep,
  PoolCreateWithdrawRequest,
} from '~/modules/pools';
import { PoolCreateRequest } from '~/modules/pools/interfaces/pool-creation-interface';
import { SwapStore } from '~/modules/swap/store/swap-store';

import { PoolDepositStore } from './pool-deposit-store';
import { PoolWithdrawStore } from './pool-withdraw-store';

export class PoolStore implements Store {
  // Protocol Pools
  pools: PoolsList = {
    pools: [],
    count: 0,
    updatedAt: new Date(),
  };

  // Pool List Filters
  filteredTokens: TokenInfo[] = [];
  filteredNetworks: CatalystNetwork[] = [];

  // Specific Viewed Pool
  pool?: Pool = undefined;
  poolAccountBalance: Map<number, PoolAccountBalance> = new Map();
  poolAction = PoolActionType.None;

  // Pool Deposit
  readonly depositStore: PoolDepositStore;

  // Pool Withdraw
  readonly withdrawStore: PoolWithdrawStore;

  //Create pool
  poolCreateType: CreatePoolType | undefined;
  poolCreateStep: PoolCreateStep = PoolCreateStep.Configure;
  poolCreateSlippage = SwapStore.DEFAULT_SLIPPAGE;
  poolCreateRequests: PoolCreateRequest[] = [];
  hasPendingPoolCreateRequests = false;
  showPendingPoolConfigBanner = false;
  hasPendingDeposits = false;
  hasSavedPoolCreateState = false;
  poolWithdrawRequests: PoolCreateWithdrawRequest[] = [];

  constructor(private store: RootStore) {
    makeAutoObservable(this);
    this.depositStore = new PoolDepositStore(store);
    this.withdrawStore = new PoolWithdrawStore(store);
    this.init();
  }

  async init() {
    let showPendingPoolConfigBanner = false;
    if (sessionStorage.getItem(StorageKey.HidePendingPoolConfigBanner) === null) {
      showPendingPoolConfigBanner = true;
    } else {
      showPendingPoolConfigBanner = false;
    }

    const userAddress = this.store.wallet.address;
    if (!userAddress) {
      return;
    }

    const poolCreateRequests = await getPendingPoolCreatePoolRequests(userAddress);
    const poolCreateState = await getSavedPoolCreateState(userAddress);

    const hasPendingPoolCreateRequests = Boolean(poolCreateRequests.length > 0);

    runInAction(() => {
      this.showPendingPoolConfigBanner = showPendingPoolConfigBanner && Boolean(poolCreateState);
      this.hasSavedPoolCreateState = Boolean(poolCreateState);
      if (hasPendingPoolCreateRequests) {
        this.hasPendingPoolCreateRequests = hasPendingPoolCreateRequests;
        this.poolCreateRequests = poolCreateRequests;
        this.poolCreateStep = PoolCreateStep.Paused;
        this.createWithdrawRequests();
      }
    });
  }

  async updatePool(id: number): Promise<Pool | undefined> {
    try {
      const result = await this.store.client.getPool(id);
      runInAction(() => {
        this.pool = result;
      });
      await this.store.wallet.updatePoolBalances(result);
    } catch (error) {
      console.error('Failed to fetch data');
    }
    return this.pool;
  }

  // TODO: store a last updated at on pools to not make extraneous calls
  async updatePools() {
    try {
      const result = await this.store.client.listPools();
      runInAction(() => {
        this.pool = undefined;
        this.pools = result;
      });
    } catch (error) {
      console.error('Failed to fetch data');
    }
  }

  async updatePoolAccountBalance(id: number): Promise<PoolAccountBalance | undefined> {
    const { wallet } = this.store;
    const { address } = wallet;

    if (!address) {
      runInAction(() => {
        this.poolAccountBalance?.delete(id);
      });
      return {
        value: 0,
        poolPercentage: 0,
        liquidity: {},
        balances: {},
      };
    }

    try {
      const result = await this.store.client.pools.getAccountBalance(id, address);
      runInAction(() => {
        this.poolAccountBalance.set(id, result);
      });
      return result;
    } catch {}
  }

  private async createWithdrawRequests(): Promise<void> {
    const createRequests = this.poolCreateRequests;
    const withdrawRequests: PoolCreateWithdrawRequest[] = [];

    for (const createRequest of createRequests) {
      const { deployVaultRequest } = createRequest;
      if (!deployVaultRequest?.deployedVaultAddress) {
        continue;
      }
      const withdrawRequest: PoolCreateWithdrawRequest = {
        chainId: deployVaultRequest.chainId,
        poolAddress: deployVaultRequest.deployedVaultAddress,
        assetAmounts: deployVaultRequest.assetAmounts,
        assetValues: deployVaultRequest.assetValues,
        assets: deployVaultRequest.assets,
        requestStatus: PoolActionState.Inactive,
      };
      withdrawRequests.push(withdrawRequest);
      runInAction(() => {
        this.hasPendingDeposits = true;
      });
    }
    runInAction(() => {
      this.poolWithdrawRequests = withdrawRequests;
    });
  }

  async createPool(): Promise<void> {
    runInAction(() => {
      this.poolCreateStep = PoolCreateStep.Approval;
    });
    const { address } = this.store.wallet;
    if (!address) {
      alert('Please connect your wallet');
      runInAction(() => {
        this.poolCreateStep = PoolCreateStep.Configure;
      });
      return;
    }
    try {
      await this.clearSavedPoolCreateState();
      for (const poolCreateRequest of this.poolCreateRequests) {
        if (poolCreateRequest.actionState === PoolActionState.Completed) {
          continue;
        }
        // helper function to update the state after each step
        const updateState = () => {
          runInAction(() => {
            this.poolCreateRequests = [...this.poolCreateRequests];
            this.savePoolCreateRequests(this.poolCreateRequests);
            this.createWithdrawRequests();
          });
        };

        // get the approval requests for the current chain
        const requests = poolCreateRequest.approvalRequests;

        // connect to the current chain
        poolCreateRequest.actionState = PoolActionState.Pending;
        poolCreateRequest.chainSwap = PoolActionState.Pending;
        updateState();
        const chainId = poolCreateRequest.chainId;
        if (chainId !== this.store.swap.sourceNetwork) {
          const connected = await this.store.wallet.connectNetwork(chainId);
          if (connected) {
            runInAction(() => {
              this.store.swap.sourceNetwork = chainId;
              this.store.swap.sourceAsset = undefined;
            });
          } else {
            runInAction(() => {
              this.poolCreateStep = PoolCreateStep.Configure;
            });
            return;
          }
        }
        await delay(1000);
        poolCreateRequest.chainSwap = PoolActionState.Confirmed;
        updateState();
        await delay(2000);
        poolCreateRequest.chainSwap = PoolActionState.Completed;
        updateState();

        // get the sdk to run requests
        const { sdk } = CatalystNetwork.getCatalystNetwork(chainId);
        if (requests.length > 0) {
          poolCreateRequest.actionState = PoolActionState.Pending;
          updateState();
          // execute the approval requests
          for (const request of requests) {
            if (request.approval === PoolActionState.Completed) {
              continue;
            }
            request.requestStatus = PoolActionState.Pending;
            request.approval = PoolActionState.Pending;
            // update the state to trigger a rerender
            updateState();
            const isNativeToken = request.asset.address === GAS_TOKEN_IDENTIFIER;
            if (!isNativeToken) {
              const allowance = await sdk.checkAllowance(request.asset.address, address, request.vaultFactoryAddress);

              if (allowance < request.amount) {
                try {
                  const tx = await sdk.increaseAllowance(
                    request.asset.address,
                    request.vaultFactoryAddress,
                    BigInt(ethers.MaxUint256.toString()),
                  );
                  await tx.wait();
                  request.approval = PoolActionState.Confirmed;
                  updateState();
                  await delay(2000);
                } catch (e) {
                  console.error('Error approving token', e);
                  runInAction(() => {
                    this.poolCreateStep = PoolCreateStep.Configure;
                  });
                  return;
                }
              }
            }
            request.approval = PoolActionState.Completed;
            updateState();
            await delay(2000);
            poolCreateRequest.actionState = PoolActionState.Confirmed;
            updateState();
            await delay(2000);
          }

          // execute the deposit requests
          const deployVaultRequest = poolCreateRequest.deployVaultRequest;
          if (deployVaultRequest) {
            try {
              deployVaultRequest.requestStatus = PoolActionState.Pending;
              updateState();
              const { deployOptions, chainId } = deployVaultRequest;
              const { sdk } = CatalystNetwork.getCatalystNetwork(chainId);
              const tx = await sdk.deployVault(deployOptions);
              const vaultAddress = await tx.wait();
              deployVaultRequest.deployedVaultAddress = vaultAddress;
              deployVaultRequest.hash = tx.hash;
              deployVaultRequest.requestStatus = PoolActionState.Completed;
              updateState();
              await this.updateVaultAddresses(chainId, vaultAddress);
              await delay(2000);
            } catch (e) {
              console.error('Error making deposit', e);
              runInAction(() => {
                this.poolCreateStep = PoolCreateStep.Configure;
              });
              return;
            }
          }
          updateState();
          await delay(2000);
        }
      }
      // clear the saved requests if all are completed
      const allDeploysCompleted = this.poolCreateRequests.every(
        (r) => r.deployVaultRequest.requestStatus === PoolActionState.Completed,
      );

      if (allDeploysCompleted) {
        await this.finishVaultSetup();
        await this.clearSavedPoolCreateRequests();
      }
    } catch (e) {
      console.error('Error creating pool', e);
      runInAction(() => {
        this.poolCreateStep = PoolCreateStep.Configure;
      });
    }
  }

  async updateVaultAddresses(chainId: string, address: string) {
    const copiedPoolCreateRequests = this.poolCreateRequests.slice();
    const poolRequest = copiedPoolCreateRequests.find((r) => r.chainId === chainId);
    if (!poolRequest || !poolRequest.setChannelRequests) {
      return;
    }

    poolRequest?.setChannelRequests.forEach((r) => {
      r.originVaultAddress = address;
    });

    // for all pool requests where the chainId is the destination chainId, update the destination address
    const otherPoolRequests = copiedPoolCreateRequests.filter((r) => r.chainId !== chainId);
    for (const otherPoolRequest of otherPoolRequests) {
      const setChannelRequests = otherPoolRequest.setChannelRequests?.filter((r) => r.chainId !== chainId);
      if (!setChannelRequests) {
        continue;
      }
      for (const setChannelRequest of setChannelRequests) {
        setChannelRequest.destinationVaultAddress = address;
      }
    }

    runInAction(() => {
      this.poolCreateRequests = [...copiedPoolCreateRequests];
      this.savePoolCreateRequests(this.poolCreateRequests);
      this.createWithdrawRequests();
    });
  }

  async finishVaultSetup() {
    // helper function to update the state after each step
    const updateState = () => {
      runInAction(() => {
        this.poolCreateRequests = [...this.poolCreateRequests];
        this.savePoolCreateRequests(this.poolCreateRequests);
        this.createWithdrawRequests();
      });
    };
    try {
      for (const poolCreateRequest of this.poolCreateRequests) {
        if (!poolCreateRequest.setChannelRequests) {
          return;
        }
        const { chainId, deployVaultRequest } = poolCreateRequest;
        const { sdk } = CatalystNetwork.getCatalystNetwork(chainId);
        const { deployedVaultAddress } = deployVaultRequest;
        if (!deployedVaultAddress) {
          const chain = getChainConfig(chainId);
          throw new Error(`No deployed vault address found for ${chain?.name} chain`);
        }

        if (chainId !== this.store.swap.sourceNetwork) {
          const connected = await this.store.wallet.connectNetwork(chainId);
          if (connected) {
            runInAction(() => {
              this.store.swap.sourceNetwork = chainId;
              this.store.swap.sourceAsset = undefined;
            });
          } else {
            runInAction(() => {
              this.poolCreateStep = PoolCreateStep.Configure;
            });
            return;
          }
        }
        await delay(1000);

        for (const [index, setChannelRequest] of poolCreateRequest.setChannelRequests.entries()) {
          setChannelRequest.requestStatus = PoolActionState.Pending;
          updateState();
          const { originVaultAddress, destinationVaultAddress, channelId, isActive } = setChannelRequest;
          if (!destinationVaultAddress || !originVaultAddress) {
            const chain = getChainConfig(chainId);
            throw new Error(`Error setting channel on ${chain.name}. No destination or origin vault address found`);
          }
          const response = await sdk.setConnection(originVaultAddress, destinationVaultAddress, channelId, isActive);
          await response.wait();

          // call finish vault after the last set channel request
          const isLast = index === poolCreateRequest.setChannelRequests.length - 1;
          if (isLast) {
            const finishResponse = await sdk.finishSetup(originVaultAddress);
            await finishResponse.wait();
            setChannelRequest.hash = finishResponse.hash;
            poolCreateRequest.actionState = PoolActionState.Completed;
          }
          updateState();
          setChannelRequest.requestStatus = PoolActionState.Completed;
        }
      }
    } catch (e) {
      console.error({
        e,
      });
    }
    await this.clearSavedPoolCreateRequests();
    await this.clearSavedPoolCreateState();
    await this.clearWithdrawRequests();
  }

  async withdrawFromIncompletePool() {
    // helper function to update the state after each step
    const updateState = () => {
      runInAction(() => {
        this.poolCreateRequests = [...poolCreateRequests];
        this.savePoolCreateRequests(this.poolCreateRequests);
      });
    };

    const poolCreateRequests = this.poolCreateRequests;
    const { address } = this.store.wallet;

    if (!address) {
      alert('Please connect your wallet');
      return;
    }

    try {
      for (const poolCreateRequest of poolCreateRequests) {
        const { deployVaultRequest } = poolCreateRequest;
        if (!deployVaultRequest) {
          continue;
        }

        const { deployedVaultAddress } = deployVaultRequest;
        if (!deployedVaultAddress) {
          continue;
        }

        const { chainId } = poolCreateRequest;
        // if current chain is not the source network, connect to it
        if (chainId !== this.store.swap.sourceNetwork) {
          const connected = await this.store.wallet.connectNetwork(chainId);
          if (connected) {
            runInAction(() => {
              this.store.swap.sourceNetwork = chainId;
              this.store.swap.sourceAsset = undefined;
            });
          } else {
            runInAction(() => {
              this.poolCreateStep = PoolCreateStep.Configure;
            });
            return;
          }
        }

        const { sdk } = CatalystNetwork.getCatalystNetwork(chainId);
        poolCreateRequest.actionState = PoolActionState.Inactive;
        updateState();

        poolCreateRequest.actionState = PoolActionState.Pending;
        updateState();
        // get the balance of pool tokens a user has
        const balance = await sdk.checkBalance(deployedVaultAddress, address);

        if (BigInt(balance) === 0n) {
          poolCreateRequest.actionState = PoolActionState.Completed;
          updateState();
          continue;
        }

        const numberOfAssets = poolCreateRequest?.deployVaultRequest?.assets?.length || 1;

        const minOut = Array(numberOfAssets).fill(BigInt(0));

        const tx = await sdk.withdrawAll(deployedVaultAddress, balance, minOut);
        await tx.wait();
        poolCreateRequest.actionState = PoolActionState.Completed;
        updateState();
      }
      await this.clearSavedPoolCreateRequests();
      runInAction(() => {
        this.poolCreateStep = PoolCreateStep.Configure;
      });
    } catch (e) {
      alert('Error withdrawing');
      console.error(e);
    }
  }

  async savePoolCreateRequests(requests: PoolCreateRequest[]): Promise<void> {
    const userAddress = this.store.wallet.address;
    if (!userAddress) {
      return;
    }
    const plainPoolArray = [];
    for (const request of requests) {
      const plainObject = JSON.parse(JSON.stringify(request));
      plainPoolArray.push(plainObject);
    }
    await db.poolCreateRequests.setItem(userAddress, plainPoolArray);
    runInAction(() => {
      this.hasPendingPoolCreateRequests = true;
    });
  }

  hidePendingPoolConfigBanner() {
    runInAction(() => {
      this.showPendingPoolConfigBanner = false;
    });
    sessionStorage.setItem(StorageKey.HidePendingPoolConfigBanner, 'true');
  }

  async clearSavedPoolCreateRequests() {
    await db.poolCreateRequests.clear();
    runInAction(() => {
      this.hasPendingPoolCreateRequests = false;
    });
  }

  async clearSavedPoolCreateState() {
    await db.poolCreateConfig.clear();
    runInAction(() => {
      this.hasSavedPoolCreateState = false;
    });
  }

  async clearWithdrawRequests() {
    await db.poolWithdrawRequests.clear();
    runInAction(() => {
      this.poolWithdrawRequests = [];
      this.hasPendingDeposits = false;
    });
  }

  async fetchCCI(chainId: string, version: CCIVersion): Promise<string | undefined> {
    const { sdk } = CatalystNetwork.getCatalystNetwork(chainId);
    const registryAddress = sdk.registryModule.registryAddress();
    const CCIs = await sdk.registryModule.getCatalystCrosschainInterface(registryAddress, version);
    return CCIs;
  }

  async fetchVaultFactoryAddress(chainId: string): Promise<string> {
    const { sdk } = CatalystNetwork.getCatalystNetwork(chainId);
    const registryAddress = sdk.registryModule.registryAddress();
    const factory = await sdk.registryModule.getCatalystVaultFactory(registryAddress);
    return factory;
  }

  async reset() {
    runInAction(() => {
      this.poolCreateStep = PoolCreateStep.Configure;
      this.poolAction = PoolActionType.None;
    });
  }
}
