export function NavFooter() {
  return (
    <div>
      {/* <NavItem value="Swap" href="/swap" matches={['/']} />
      <NavItem value="Pool" href="/pool" /> */}
    </div>
  );
}

export default NavFooter;
