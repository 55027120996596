import { type Vault, VaultType } from '@catalabs/catalyst-api-client';
import { ArbitraryMessagingBridge } from '@catalabs/catalyst-channel-lists';
import type { TokenInfo } from '@uniswap/token-lists';

export interface Swap {
  from: {
    token: TokenInfo;
    amount: number;
  }[];
  to: {
    token: TokenInfo;
    amount: number;
  }[];
}

export interface LiquiditySwap {
  swap: Swap;
  channel: ArbitraryMessagingBridge;
  vault: Vault;
  vaultAmount: number;
  vaultTokensSent?: number;
  vaultTokensReceived?: number;
}

export interface WithdrawLiquiditySwapInfoItem {
  fromVault: Vault;
  toVault: Vault;
  amount: bigint;
}

export interface DepositLiquiditySwapInfoItem {
  initialDeposit: Map<string, number>;
  finalDeposit: Map<string, number>;
  quoteDetails: LiquiditySwap[];
}

export type DepositLiquiditySwapInfo = DepositLiquiditySwapInfoItem[];

export const sampleSwap: Swap = {
  from: [
    {
      token: {
        chainId: 1,
        address: '0x0',
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      amount: 1000,
    },
  ],
  to: [
    {
      token: {
        chainId: 1,
        address: '0x0',
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      amount: 1000,
    },
  ],
};

export const sampleLiquiditySwap: LiquiditySwap = {
  swap: sampleSwap,
  channel: ArbitraryMessagingBridge.Wormhole,
  vault: {
    chain: '1',
    address: '0x0',
    id: 0,
    assetsValue: 0,
    swapFee: '',
    governanceFee: '',
    poolId: 0,
    feeAdministrator: '',
    vaultType: VaultType.WEIGHTED_VOLATILE,
    amplification: '',
    totalSupply: '',
    escrowedVaultTokens: '',
    excessEscrowAt: new Date(),
    unitTracker: '',
    escrow: [],
    assets: [],
    arbitraryMessagingBridge: ArbitraryMessagingBridge.Default,
  },
  vaultAmount: 1000,
};

export const sampleDepositLiquiditySwapQuoteItem: DepositLiquiditySwapInfoItem = {
  initialDeposit: new Map([
    ['0x0', 1000],
    ['0x1', 1000],
  ]),
  finalDeposit: new Map([
    ['0x0', 1000],
    ['0x1', 1000],
  ]),
  quoteDetails: [sampleLiquiditySwap],
};

export const sampleLiquiditySwapQuote: DepositLiquiditySwapInfo = [sampleDepositLiquiditySwapQuoteItem];
