import { useNavigate } from 'react-router-dom';

import { formatBalance, routes } from '~/config';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { formatCurrency, Modal, SwapReviewLine, TokenDisplay } from '~/modules/common';
import PoolCreateWithdrawDisplay from '~/modules/pools/components/PoolCreateWithdrawDisplay';
import { PoolActionState, PoolCreateStep } from '~/modules/pools/enums';
import { PoolCreateWithdrawRequest } from '~/modules/pools/interfaces';
import { SwapCosts } from '~/modules/swap';

interface PoolCreateModalProps {
  onCancel: () => void;
  createPoolStep: PoolCreateStep;
  poolWithdrawRequests: PoolCreateWithdrawRequest[];
  onResume: () => void;
}

const calculateTotalValue = (request: PoolCreateWithdrawRequest) => {
  const totalValue = Array.from(request?.assetValues?.entries() || []).reduce((acc, value) => acc + value[1], 0);
  return totalValue;
};

const PoolCompleted = ({ poolWithdrawRequests }: { poolWithdrawRequests: PoolCreateWithdrawRequest[] }) => {
  const navigate = useNavigate();
  const totalLiquidity = poolWithdrawRequests.reduce((acc, request) => acc + calculateTotalValue(request), 0);

  const handleComplete = () => {
    navigate(routes.pools);
  };
  return (
    <>
      <div className="mt-5 flex flex-col space-y-4 md:w-[552px]">
        <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
          <span className="mb-4 w-full font-semibold text-xs uppercase leading-4 tracking-wide text-gray-900">
            Funds withdrawn from Pool
          </span>
        </div>
        <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
          {poolWithdrawRequests.map((request) => {
            return request.assets?.map((asset) => {
              if (!asset || !request.assetAmounts) return null;
              const displayAmount = formatBalance(request?.assetAmounts?.get(asset.symbol) || 0, asset.decimals);
              const totalRequestValue = calculateTotalValue(request);
              return (
                <div key={`deposit-completed-display-${request.chainId}-${asset?.address}`} className="flex space-x-4">
                  <div className="flex w-full justify-between rounded-[15px] border border-gray-200 p-5">
                    <TokenDisplay showSymbol chainId={request.chainId} token={asset.address} />
                    <div className="ml-4 mt-1 flex flex-col">
                      <span className="font-semibol text-[32px] leading-[28px]">{displayAmount}</span>
                      <span className="text-[14px] font-normal text-gray-500">{formatCurrency(totalRequestValue)}</span>
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
        <SwapReviewLine title="Total Liquidity" value={formatCurrency(totalLiquidity)} />
        <SwapCosts feeDetails={[]} />
      </div>
      <div
        onClick={handleComplete}
        className="itemx-center mt-16 flex cursor-pointer justify-center rounded-[145px] bg-primary px-12 py-8 text-white md:w-[552px]"
      >
        Go to Pools
      </div>
    </>
  );
};

export const PoolCreateWithdrawModal = ({
  onCancel,
  createPoolStep,
  poolWithdrawRequests,
  onResume,
}: PoolCreateModalProps) => {
  if (createPoolStep !== PoolCreateStep.Withdraw || poolWithdrawRequests.length === 0) {
    return null;
  }
  const isCompleted = poolWithdrawRequests.every((request) => request.requestStatus === PoolActionState.Completed);

  return (
    <>
      <Modal open={true} onClose={onCancel}>
        <div className="flex flex-col items-center gap-4">
          {isCompleted ? (
            <PoolCompleted poolWithdrawRequests={poolWithdrawRequests} />
          ) : (
            <>
              <div className="flex flex-row justify-between gap-2 rounded-[20px] bg-red-500  p-4 shadow-2xl md:w-[552px] ">
                <div className="flex flex-col gap-2 ">
                  <p className="font-subheader text-sm text-white">You are about to withdraw your funds.</p>
                  <p className="text-sm text-white">Continue here or resume your progress to finish your deposit.</p>
                  <div>
                    <button
                      onClick={onResume}
                      className="rounded-full border-[1px] border-white bg-white px-[12px] py-[4px] text-xs text-grey-900"
                    >
                      Resume Deposit
                    </button>
                  </div>
                </div>
              </div>
              {poolWithdrawRequests.map((poolWithdrawRequest, index) => (
                <PoolCreateWithdrawDisplay
                  request={poolWithdrawRequest}
                  key={`pool-create-withdraw-request-${index}`}
                />
              ))}
              <div className="flex flex-col">
                <div className="mt-5 flex flex-col space-y-4 md:w-[552px]">
                  <div className="flex h-[36px] w-full items-center justify-between rounded-[25px] bg-white p-6">
                    <div className="flex gap-4">
                      <span className="bg-opacity-15two  flex items-center font-semibold font-subheader text-sm tracking-wide text-grey-900">
                        TOTAL LIQUIDITY
                      </span>
                      <div className="flex items-center">
                        <Caret className="-rotate-90 text-grey-700" />
                      </div>
                    </div>
                    <span className="">0</span>
                  </div>
                </div>
                <div className="mt-5 flex flex-col space-y-4 md:w-[552px]">
                  <div className="flex h-[36px] w-full items-center justify-between rounded-[25px] bg-white p-6">
                    <div className="flex gap-4">
                      <span className="bg-opacity-15two  flex items-center font-semibold font-subheader text-sm tracking-wide text-grey-900">
                        TOTAL COST
                      </span>
                      <div className="flex items-center">
                        <Caret className="-rotate-90 text-grey-700" />
                      </div>
                    </div>
                    <span className="">0</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
