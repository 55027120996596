import { WalletIcon } from '@heroicons/react/24/outline';

import { formatTokenBalance } from '~/modules/common/utils';

export const BalanceDisplay = ({
  balance,
  onClick,
  error,
  showMax = true,
}: {
  balance: number;
  onClick: () => void;
  error: boolean;
  showMax?: boolean;
}) => {
  return (
    <div className="group flex cursor-pointer items-center gap-1 text-xs" onClick={onClick}>
      <WalletIcon className={`h-5 w-5 ${error ? 'text-red-700' : 'text-primary'}`} />
      <div className={`${error ? 'text-red-700' : 'text-grey-500'}`}>{formatTokenBalance(balance, true, 6)}</div>
      {showMax && (
        <div
          className={`ml-1 flex h-5 items-center justify-center rounded-full bg-primary font-semibold text-[10px] leading-[9px] text-white transition-all ease-in-out ${
            error ? 'w-10 opacity-100' : 'w-0 opacity-0'
          }   group-hover:w-10 group-hover:opacity-100`}
        >
          MAX
        </div>
      )}
    </div>
  );
};
