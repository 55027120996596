import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import TosAttestationModal from '~/modules/common/components/TosAttestationModal';
import { ProfileDropDown } from '~/modules/navigation/components/ProfileDropDown';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';

const ConnectButton = observer(() => {
  const {
    isConnected,
    displayAddress,
    sourceNetwork,
    connect,
    disconnect,
    isTosAttestationModalOpen,
    setIsTosAttestationModalOpen,
    onTosSignature,
    isTosAttestationComplete,
  } = useConnectButton();
  const [showDropDown, setshowDropDown] = useState(false);

  async function handleClick() {
    if (!isConnected) connect();
    else setshowDropDown(!showDropDown);
  }

  const handleClickOutside = () => {
    if (showDropDown) {
      setshowDropDown(false);
    }
  };

  const handleCloseTosModal = () => {
    setIsTosAttestationModalOpen(false);
    if (!isTosAttestationComplete) {
      disconnect();
    }
  };

  const handleTosSignature = async () => {
    await onTosSignature();
    setTimeout(() => {
      setIsTosAttestationModalOpen(false);
    }, 3000);
  };

  const ref = useDetectClickOutside({ onTriggered: handleClickOutside });

  return (
    <>
      <TosAttestationModal
        isOpen={isTosAttestationModalOpen}
        isAttestationComplete={isTosAttestationComplete}
        onSignature={handleTosSignature}
        onClose={handleCloseTosModal}
      />

      <div className="group/profile relative" ref={ref}>
        <div
          className={`flex h-[40px] min-w-[134px] cursor-pointer flex-row items-center gap-2 rounded-full bg-black py-1 text-sm text-white hover:bg-primary ${
            isConnected ? 'pl-2 pr-4' : 'px-4'
          }`}
          onClick={handleClick}
          data-testid="connect-wallet"
        >
          {isConnected && sourceNetwork && (
            <img className="h-6 w-6 rounded-full" src={`/img/${sourceNetwork.toLowerCase()}_bright.webp`} />
          )}
          <span className="flex w-full justify-center" data-testid="address">
            {isConnected ? displayAddress : 'Connect'}
          </span>
        </div>

        {/*Mobile*/}
        <div className="md:hidden">{showDropDown && <ProfileDropDown setshowDropDown={setshowDropDown} />}</div>

        {/*Desktop*/}
        <div className="hidden md:block">{isConnected && <ProfileDropDown setshowDropDown={setshowDropDown} />}</div>
      </div>
    </>
  );
});

export default ConnectButton;
