import { Pool } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER, prepareDepositWithLiquiditySwaps } from '@catalabs/catalyst-sdk';

import { TokenBalance } from '~/config';
import { ChainFeeData } from '~/modules/common';

import { getLiquiditySwapTxInputs } from './deposit.utils';
import { getAssetKey } from './pools.utils';

const getSampleTxInputs = ({
  chainId,
  pool,
  address,
  feeData,
  tokenPrices = new Map<string, number>(),
}: {
  chainId: string;
  pool: Pool;
  address: string;
  feeData: ChainFeeData;
  tokenPrices?: Map<string, number>;
}) => {
  const vaults = Object.values(pool.vaults);
  const depositAmounts = new Map<string, string>();
  const gasTokenBalances = new Map<string, TokenBalance>();
  const assetBalances = new Map<string, TokenBalance>();
  const isAssetReplacedWithGasToken = new Map<string, boolean>();
  for (const asset of pool.assets) {
    const currentChainId = asset.chainId.toString();
    const isCurrentChain = currentChainId === chainId;
    const assetKey = getAssetKey(asset);
    depositAmounts.set(assetKey, isCurrentChain ? '1' : '0');
    if (!tokenPrices.has(assetKey)) {
      tokenPrices.set(assetKey, 1);
    }
    gasTokenBalances.set(assetKey, {
      amount: 0n,
      decimals: 18,
      address: GAS_TOKEN_IDENTIFIER,
      name: '',
      symbol: '',
    });
    assetBalances.set(assetKey, {
      address: asset.address,
      name: '',
      amount: 0n,
      decimals: 18,
      symbol: '',
    });
    isAssetReplacedWithGasToken.set(assetKey, isCurrentChain);
  }
  const txInputs = getLiquiditySwapTxInputs({
    pool,
    address,
    feeData,
    vaults,
    depositAmounts,
    gasTokenBalances,
    tokenPrices,
    assetBalances,
    isAssetReplacedWithGasToken,
  });

  return txInputs;
};

export const getDepositGasCostWithLiquiditySwap = ({
  feeData,
  pool,
  address,
  chainId,
  tokenPrices,
  maxGasFee,
}: {
  feeData: ChainFeeData;
  pool: Pool;
  address: string;
  chainId: string;
  tokenPrices?: Map<string, number>;
  maxGasFee: bigint;
}): bigint => {
  const txInputs = getSampleTxInputs({ chainId, pool, address, feeData, tokenPrices });
  const {
    userDeposits,
    chains,
    vaults,
    vaultAddresses,
    assetsAddresses,
    userAddresses,
    refundGasTo,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    wrapGasValues,
  } = txInputs;

  const responses = prepareDepositWithLiquiditySwaps(
    userDeposits,
    chains,
    vaults,
    vaultAddresses,
    assetsAddresses,
    userAddresses,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    refundGasTo,
    undefined,
    wrapGasValues,
  );
  const response = responses.find((_, i) => chains[i].chainId.toString() === chainId);
  if (!response) {
    throw new Error('Invalid routerArgs, gasUsage, or transferDetails');
  }
  const { routerArgs, gasUsage, transferDetails } = response;
  const { executionInstructions } = routerArgs.transferWithPermitForDepositWithLiquiditySwaps(
    transferDetails,
    address,
    gasUsage,
  );
  const { estimatedRoutingPayment } = executionInstructions.gas;
  const totalGasCost = (estimatedRoutingPayment * 12n) / 10n;
  return totalGasCost + maxGasFee;
};
