export enum FeatureFlag {
  // Environment
  Testnet,

  // User Features
  PoolCreation,
  PoolInteraction,
  PoolWithdraw,
  WelcomePopUp,
  Missions,
  Rewards,
  RewardsSeasonActive,
  UserPortfolio,
  TransactionHistory,

  // Chain Support
  OmniSupport,
  ScrollSupport,
  InjectiveEvmSupport,
  OpBNBSupport,
  BSCSupport,
  ArbitrumSupport,
}
