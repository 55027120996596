import { SwapState } from '@catalabs/catalyst-api-client';
import { observer } from 'mobx-react-lite';

interface TransactionStatusProps {
  status: SwapState;
}

const statusBgClasses = {
  [SwapState.PENDING]: 'bg-yellow-400',
  [SwapState.CONFIRMED]: 'bg-blue-500',
  [SwapState.COMPLETED]: 'bg-green-600',
  [SwapState.TIMED_OUT]: 'bg-red-600',
  [SwapState.REVERTED]: 'bg-red-600',
};

export const TransactionStatus = observer(({ status }: TransactionStatusProps) => {
  let formattedStatus = '';
  switch (status) {
    case SwapState.PENDING:
      formattedStatus = 'In Progress';
      break;
    case SwapState.CONFIRMED:
      formattedStatus = 'Confirmed';
      break;
    case SwapState.COMPLETED:
      formattedStatus = 'Completed';
      break;
    case SwapState.TIMED_OUT:
    case SwapState.REVERTED:
    default:
      formattedStatus = 'Not Processed';
  }

  return (
    <span className="col-span-1 flex items-center gap-2">
      <span className={`h-2 w-2 rounded-full ${statusBgClasses[status]}`} /> {formattedStatus}
    </span>
  );
});
