import { runInAction } from 'mobx';
import { useContext } from 'react';

import { StoreContext } from '~/modules/common';
import { SortDirection } from '~/modules/common/enums';
import { TransactionSortCriteria } from '~/modules/transactions/enums';

export const useVaultInteractionListHeader = () => {
  const { wallet } = useContext(StoreContext);
  const transactionStore = wallet.transactions;

  const setSortCriteria = async (newCriteria: TransactionSortCriteria) => {
    if (transactionStore.vaultInteractionSortCriteria === newCriteria) {
      return;
    }
    runInAction(() => {
      transactionStore.vaultInteractionSortCriteria = newCriteria;
    });
    await transactionStore.sortVaultInteractions(newCriteria, transactionStore.vaultInteractionSortDirection);
  };

  const setSortDirection = async (newDirection: SortDirection) => {
    if (transactionStore.vaultInteractionSortDirection === newDirection) {
      return;
    }
    runInAction(() => {
      transactionStore.vaultInteractionSortDirection = newDirection;
    });
    await transactionStore.sortVaultInteractions(transactionStore.vaultInteractionSortCriteria, newDirection);
  };

  return {
    sortCriteria: transactionStore.vaultInteractionSortCriteria,
    setSortCriteria,
    sortDirection: transactionStore.vaultInteractionSortDirection,
    setSortDirection,
  };
};
