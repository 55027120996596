import { ArbitraryMessagingBridge } from '@catalabs/catalyst-channel-lists';

import { ReactComponent as WormHoleIcon } from '~/img/wormhole.svg';

export const MessagingBridgeIcon = ({ bridge }: { bridge: ArbitraryMessagingBridge }) => {
  switch (bridge) {
    case ArbitraryMessagingBridge.Wormhole:
      return <WormHoleIcon className="h-6 w-6" />;
    default:
      return null;
  }
};
