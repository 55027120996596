interface InfoBannerProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
}

export const InfoBanner = ({ title, content }: InfoBannerProps) => {
  return (
    <header className="flex w-full flex-col gap-1 rounded-3xl bg-emerald px-6 py-5 text-center">
      <h2 className="font-header text-grey-800">{title}</h2>
      <p className="text-sm text-black-900">{content}</p>
    </header>
  );
};
