export const TermsOfService = () => {
  return (
    <div className="flex flex-grow flex-col items-center">
      <main className="flex flex-col gap-2 px-10 py-10 md:px-20 lg:px-[15rem] xl:px-[25rem]">
        <p className="mb-5 flex w-full justify-center font-bold text-[30px]">CATA LABS TERMS OF SERVICE</p>
        <p className="mb-5">
          Welcome to the Cata Labs Interface! Please read these Terms of Service (these “TOS”) carefully, as they
          constitute a legally binding agreement between you (“you”, “your”, or “user”) and Cata Labs, Inc. (“Cata
          Labs”, “we”, “us” or “our”).
        </p>
        <p className="mb-5">
          These TOS, together with any Modifications, as well as all policies posted on
          <a href="http://www.catalyst.exchange" className="mx-2 text-gray-800">
            catalyst.exchange
          </a>
          and incorporated by reference herein, set out the terms on which Cata Labs offers you access to and use of (a)
          the website hosted user interface located at
          <a href="http://www.catalyst.exchange" className="mx-2 text-gray-800">
            catalyst.exchange
          </a>
          and all relevant sub-pages associated with the foregoing (the “Interface”) and (b) any other applications,
          add-ons, tools, services and offerings provided by us (together with the Interface, the “Products”). These TOS
          apply whether you are accessing the Products via a personal computer, a wireless or mobile device or any other
          technology or device now known or hereafter developed or discovered.
        </p>
        <p className="mb-5">
          THIS DOCUMENT CONTAINS IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS,
          LIMITATIONS, AND EXCLUSIONS THAT APPLY TO YOU. PLEASE READ IT CAREFULLY.
        </p>
        <p className="mb-5">
          BY ACCESSING OR USING THE PRODUCTS IN ANY MANNER, YOU AFFIRM, REPRESENT AND WARRANT (A) THAT YOU HAVE READ,
          UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS SET FORTH IN THESE TOS (ALONG WITH ANY
          MODIFICATIONS AND/OR ADDITIONAL TERMS, POLICIES AND AGREEMENTS REFERENCED HEREIN), INCLUDING THE ARBITRATION
          AGREEMENT AND CLASS ACTION WAIVER, (B) THAT YOU HAVE THE RIGHT, AUTHORITY AND CAPACITY TO ENTER INTO THESE TOS
          (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT) AND MEET ALL OF THE ELIGIBILITY AND OTHER
          REQUIREMENTS, AND (C) THAT YOUR ACCESS AND USE OF THE PRODUCTS IS PERMITTED BY, AND SHALL NOT VIOLATE, ANY
          APPLICABLE LAWS, INCLUDING THOSE IN YOUR JURISDICTION.
        </p>
        <p className="mb-5">
          THE PRODUCTS ARE NOT AVAILABLE TO RESTRCITED PERSONS, PERSONS OR ENTITIES WHO RESIDE IN, ARE LOCATED IN, ARE
          INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN THE UNITED STATES OF AMERICA, OR ANY OTHER RESTRICTED
          JURISDICTION. WE DO NOT MAKE EXCEPTIONS. YOU ACKNOLWEDGE AND AGREE THAT THE USE OF A VIRTUAL PRIVATE NETWORK
          TO CIRCUMVENT THE RESTRICTIONS SET FORTH HEREIN IS PROHIBITED.
        </p>
        <p className="mb-5">
          IF YOU DO NOT AGREE TO THE CURRENT VERSION OF THE TOS AND/OR ANY MODIFICATIONS, YOUR SOLE AND EXCLUSIVE REMEDY
          IS TO REFRAIN FROM ACCESSING OR OTHERWISE USING THE PRODUCTS.
        </p>
        <ol className="flex list-decimal flex-col gap-8">
          <li>
            <h2 className="font-subheader uppercase">Modifications.</h2>
            We reserve the right to revise these TOS, at any time and from time to time, for any reason in our sole
            discretion by any written announcement or notice or by posting an updated TOS without advance notice to you
            (the “Modifications”). These changes become effective immediately and, if you use the Products after they
            become effective, it will signify your agreement to be bound by the Modifications. It is your responsibility
            to monitor and periodically check for changes to these TOS by consulting the “Last Modified” date at the
            bottom of this page.
          </li>
          <li>
            <h2 className="font-subheader uppercase">What is the Catalyst Protocol? </h2>
            <p className="mb-5">
              The Catalyst Protocol is the decentralized, permissionless cross-chain automated market maker (an “AMM”)
              for the modular future (the “Protocol”). The Protocol’s self-executing smart contracts enable easy
              liquidity connections and asset swaps between various permissionless public blockchains, such as Ethereum
              and Cosmos, and rollups like Optimism and Eclipse. The Protocol requires only an interoperability
              messaging layer to operate, allowing easy-to-use, secure, and fast cross-chain swaps. Traditional AMMs
              price assets based on their internal state, which means the balances of assets within the AMM, requiring
              full knowledge of all utilized assets. The Protocol uses a concept called “unit of liquidity”, a value
              abstraction easily transferred between pools asynchronously, permitting universal comprehension between
              any Protocol smart contract on any chain. As a result, any chain that integrates the Protocol can
              automatically move value to or from any other Protocol-enabled chain, dissolving the barriers between
              chains. For more information, please see
              <a href="https://docs.catalyst.exchange" className="mx-2 text-gray-800">
                https://docs.catalyst.exchange
              </a>
              .
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">The Interface/Wallet Access.</h2>
            <ol>
              <li>
                <h3 className="font-subheader">(a) The Interface.</h3>
                <p className="mb-5">
                  The Interface provides a web or mobile-based means of access to the decentralized Protocol. The
                  Interface is distinct from the Protocol and is one, but not the exclusive, means of accessing the
                  Protocol, which comprises open-source or source-available self-executing smart contracts deployed on
                  various public blockchains. Cata Labs does not control or operate any version of the Protocol on any
                  blockchain network. By using the Interface, you understand that you are not buying or selling digital
                  assets from us and that we do not operate any liquidity connections or asset swaps on the Protocol.
                </p>
              </li>

              <li>
                <h3 className="font-subheader">(b) Wallet Access.</h3>
                <p className="mb-5">
                  In order to connect with the Interface, or use other Products, you must connect a non-custodial
                  wallet. It’s essential to understand that your non-custodial wallet is provided by a third-party
                  entity and is generally governed by separate terms and conditions set by the respective third-party
                  service provider. These terms and conditions may involve additional fees, disclaimers, or risk
                  warnings regarding the accuracy and reliance on the provided information. Reviewing and comprehending
                  the terms and conditions associated with your chosen non-custodial wallet provider to ensure
                  compliance and to be aware of any applicable charges or risks is your sole responsibility. We reserve
                  the right to refuse to engage with a non-custodial wallet in our sole and exclusive discretion. We
                  accept no responsibility or liability to you in connection with your use of a non-custodial wallet and
                  make no representations or warranties regarding how the Products will operate with any specific
                  non-custodial wallet.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Taxes.</h2>
            <p className="mb-5">
              We are not responsible for determining the withholding, sales, use, value added, transfer or other tax,
              together with any interest and penalties imposed with respect thereto, that apply to your use of our
              Products. Blockchain-based transactions are novel, and their tax treatment is uncertain. You agree that
              you are solely responsible for determining what, if any, tax applies to your transactions and to withhold,
              collect, report and remit the correct amounts of tax to the appropriate taxing authorities.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Proprietary Rights. </h2>
            <p className="mb-5">
              As between you and us, we own, solely and exclusively, all right, title and interest in and to the
              Products and all elements thereof, including any and all graphics, images, text, files, designs, systems,
              methods, information, computer code, software, scripts, services, marks and branding, the selection and
              arrangement thereof, including all intellectual property and proprietary rights (whether registered or
              unregistered, in any jurisdiction) related to any of the foregoing. All rights in and to the Products not
              expressly granted to you under these TOS are expressly reserved. Your use of the Products does not grant
              to you any right, title or interest in any element thereof, and we (or our applicable licensors, partners,
              or affiliates) own and retain all right, title and interest (including all intellectual property and
              proprietary rights) therein and thereto.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Limited License. </h2>
            <p className="mb-5">
              Subject to the restrictions and limitations set forth in these TOS, and contingent upon your ongoing
              compliance with these TOS, we grant you a personal, worldwide, revocable, non-exclusive, and
              non-assignable license to use the Products. The only purpose of this license is to allow you to use and
              enjoy the Products solely as permitted by these TOS.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Prohibited Use. </h2>
            <p className="mb-5">
              Any commercial distribution, publishing, use or exploitation of the Products or any element thereof
              (including any content, software, code, data or materials used in or available on the Products) is
              prohibited. As a condition of your access to and use of the Products, you agree that you will not use the
              Products or any element thereof for any purpose that is unlawful, commercial in nature or prohibited by
              these TOS. You agree to abide by all applicable local, state, national and international laws, regulations
              and rules and not to access or use the Products if you are a Restricted Person, in the United States of
              America or other Restricted Jurisdiction, or where such access or use is illegal or unauthorized.
              <br /> <br />
              By way of example, and not limitation, you agree that you shall not:
              <ol className="my-2 flex flex-col gap-2">
                <li>
                  (a) use the Products for, or to promote or facilitate, illegal activity (including, without
                  limitation, money laundering, financing terrorism, tax evasion, buying or selling illegal drugs,
                  contraband, counterfeit goods, or illegal weapons);
                </li>
                <li>(b) exploit the Products for any unauthorized commercial purpose; </li>
                <li>
                  (c) upload or transmit viruses, worms, trojan horses, time bombs, cancel bots, spiders, malware, or
                  any other type of malicious code that will or may be used in any way that could affect the
                  functionality or operation of the Products;
                </li>
                <li>
                  (d) attempt to or actually copy or make unauthorized use of all or any portion of the Products,
                  including by attempting to reverse compile, reformat or frame, disassemble, or reverse engineer any
                  part of the Products;
                </li>
                <li>(e) harvest or otherwise collect information from the Products for any unauthorized purpose;</li>
                <li>(f) use the Products under false or fraudulent pretenses or otherwise be deceitful; </li>
                <li>(g) interfere with other users’ access to or use of the Products; </li>
                <li>
                  (h) interfere with or circumvent the security features of the Products or any third party’s systems,
                  networks, or resources used in the Products;
                </li>
                <li>
                  (i) engage in any attack, hack, denial-of-service attack, interference, or exploit of any smart
                  contract in connection with the use of the Products (and operations performed by a user that are
                  technically permitted by a smart contract may nevertheless be a violation of these TOS, and the law);
                  or
                </li>
                <li> (j) engage in any anticompetitive behavior or other misconduct.</li>
              </ol>
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Restriction of Access. </h2>
            <p className="mb-5">
              We reserve the right to deny access and use privileges to anyone (and to suspend, restrict or terminate an
              existing non-custodial wallet) at our sole and absolute discretion, including without limitation if we
              suspect that the non-custodial wallet has or will be used for any fraudulent, illegal, or other
              unauthorized purposes. We reserve the right to refuse or revoke permission to use the Products and to
              remove any other privileges at any time and for any reason.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Third Party Websites. </h2>
            <p className="mb-5">
              The Products may contain links or connections to third party websites, and third party websites may link
              to the Products. For purposes of this Section, “third party websites” include all websites, mobile
              websites, mobile and web applications, and services owned, operated, or provided by any third party,
              including any such website, application or service operated or provided by a third party under license
              from us or any of our affiliates. The inclusion in the Products of any link to a third party website does
              not constitute an endorsement or sponsorship by us of such third party website, or the information,
              content, products, services, advertising, code or other materials presented on or through such third party
              website. Any reliance on the contents of a third party website is done at your own risk.
            </p>
          </li>
          <li>
            <p className="mb-5">
              <h2 className="font-subheader uppercase">User Content. </h2>
              The Products may provide you with the opportunity to submit, post, display, transmit or otherwise make
              available certain content, including messages, files, data, software, sound, videos, photos, graphics,
              comments, and other items. By submitting, transmitting, posting, displaying or otherwise making available
              any content, you grant us a worldwide, non-exclusive, sublicensable, royalty-free and fully paid-up
              license to exploit such content for our lawful business purposes, including to provide, promote, and
              improve the Products, without obligation. You represent and warrant that you have, or have obtained, all
              rights, licenses, consents, permissions, power and/or authority necessary to grant the rights granted
              herein. We shall have the right, but not the obligation, to monitor any content submitted to or posted
              through the Products, to determine compliance with these TOS and any operating rules established by us, as
              well as to satisfy any requirement under law or government request. We shall have the right, but not the
              obligation, to edit or remove any content submitted to or posted through the Products.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Representations and Warranties. </h2>
            <p className="mb-5">
              In addition to any representations and/or warranties set forth elsewhere in these TOS, you represent and
              warrant that at all relevant times: (a) neither your performance under these TOS, nor your use of the
              Products in accordance with the terms herein, has violated or will violate any applicable law and you will
              not access or use any of the Products to conduct, promote, or otherwise facilitate any illegal activity;
              (b) you are of legal age, in the jurisdiction in which you reside, to enter into a binding contract (and
              in any event are older than eighteen (18) years of age); and (c) you have full legal authority without any
              further action or other party’s consent to enter into and perform this agreement and to give these
              representations and warranties; if you are an entity, the individual transacting on your behalf is
              authorized to do so and the entity is duly incorporated or formed, validly existing and in good standing
              in the jurisdiction where it is incorporated or formed. You also represent and warrant that at all
              relevant times: (i) your use of the Products is genuine and not the product of any collusive or other
              anti-competitive agreement and otherwise consistent with any applicable antitrust laws; (ii) you have no
              knowledge or reason to suspect that the funds used are connected with the proceeds of criminal activity;
              (iii) you are not, nor are you owned by, controlled by, or acting on behalf of, any entity or individual
              that is the subject of economic sanctions, embargoes or other trade restrictions administered or enforced
              by any governmental authority in any jurisdiction or otherwise designated on any list of prohibited or
              restricted parties (including but not limited to the list maintained by the Office of Foreign Assets
              Control of the U.S. Department of the Treasury)(a “Restricted Individual”), or a citizen or resident, or
              otherwise located or organized in the United States of American, or any jurisdiction or territory that is
              the subject of comprehensive country-wide, territory-wide, or regional economic sanctions by the U.S.
              (collectively a “Restricted Jurisdiction”); and (iv) you will not use the Products in a way that violates
              any applicable law, rule, or regulation concerning the trading of securities or derivatives, including,
              but not limited to, the unregistered offering of securities and the offering of leveraged and margined
              commodity products to retail customers in the United States.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">General Release of Claims. </h2>
            <p className="mb-5">
              You do hereby irrevocably and unconditionally release, cancel, and forever discharge Cata Labs and its
              directors, officers, employees, subsidiaries, affiliates, agents, and representatives from any and all
              claims, complaints, causes of action, demands, damages, obligations, liabilities, losses, promises,
              agreements, controversies, penalties, expenses, and executions of any kind or nature whatsoever, whether
              known or unknown, actual or potential, whether arising in law or in equity, which you may have, may have
              had, or may in the future obtain, arising out of or relating to any acts, omissions, agreements, or events
              relating in any manner to the Products, except for the right to enforce these TOS. Without limitation on
              the foregoing, you have been made aware of, fully understand, and expressly, knowingly, and intentionally
              waive any and all rights, benefits, and other protections afforded by Cal. Civ. Code § 1542 which states “
              <strong>
                a general release does not extend to claims that the releasing party does not know or suspect to exist
                in his or her favor at the time of executing the release and that, if known by him or her, would have
                materially affected his or her settlement with the released party
              </strong>
              ” and of any other analogous federal or state statute or common law principle that limits the scope of a
              general release.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Indemnification. </h2>
            <p className="mb-5">
              You agree to defend, indemnify on demand and keep Cata Labs and our affiliates, parents, subsidiaries and
              each of our and their respective officers, employees, agents, partners, members, providers, suppliers and
              licensors (collectively, the “Released Parties”) indemnified, and hold the Released Parties harmless from
              any and all claims, liabilities, costs, losses (including without limitation consequential and indirect
              losses) and expenses, including reasonable attorneys’ fees, arising in any way from (a) your use of or
              reliance on the Products or any services, content, functions, information, materials, or products
              available through the Products, (b) your placement or transmission of any transaction, message, content,
              information, software or other materials through the Products, or (c) your fraudulent or deceptive acts or
              omissions, or breach or violation of the law (including infringement of any intellectual property or other
              right of any person or entity) or of these TOS.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Disclaimer of Warranties. </h2>
            <p className="mb-5">
              The Products are provided on an “As Is” and “As Available” basis without any guarantee, representations or
              warranties by us. You should not take, or refrain from taking, any action based on any information
              contained at
              <a href="https://docs.catalyst.exchange" className="mx-2 text-gray-800">
                https://docs.catalyst.exchange
              </a>
              , or any other information that we make available at any time, including blog posts, data, articles, links
              to third-party content, discord or telegram content, news feeds, tutorials, tweets, and videos. You
              further acknowledge and agree that we will not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such
              content, goods, or services available on or through any such site or resource. Each of the Products is a
              purely non-custodial application, meaning we do not ever have custody, possession, or control of your
              digital assets at any time. It further means you are solely responsible for the custody of the
              cryptographic private keys to the digital asset wallets you hold and you should never share your wallet
              credentials or seed phrase with anyone. We accept no responsibility for, or liability to you, in
              connection with your use of a wallet and make no representations or warranties regarding how any of our
              Products will operate with any specific wallet. We have no information about Protocol transactions beyond
              what is publicly available via the blockchain. Transactions using blockchains may require the payment of
              gas fees, which are essentially network transaction fees paid on every transaction that occurs on the
              selected blockchain network, and are non-refundable. We offer the Products as a convenience. Broadband or
              other internet capacity constraints, corporate firewalls and other technical problems beyond our
              reasonable control may create difficulties for some users including, for example, in relation to accessing
              the Products and in maintaining continuity of such access.
            </p>
            <p className="mb-5">
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE PRODUCTS IS AT YOUR SOLE RISK, AND
              THE PRODUCTS AND ALL SERVICES, CONTENT, FUNCTIONS, INFORMATION, AND MATERIALS AVAILABLE THROUGH THE
              PRODUCTS ARE PROVIDED ON AN “ AS IS ” AND “AS AVAILABLE ” BASIS WITHOUT WARRANTY OF ANY KIND, INCLUDING
              BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, INTEGRATION, MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF
              TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.
            </p>
            <p className="mb-5">
              WITHOUT LIMITING THE FOREGOING, THE RELEASED PARTIES (I) MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING
              THE INTERFACE OR ANY PRODUCTS, AND (II) HEREBY DISCLAIM, AND YOU HEREBY WAIVE, ANY AND ALL WARRANTIES AND
              REPRESENTATIONS WITH RESPECT THERETO. YOU ARE SOLELY RESPONSIBLE FOR, AND ASSUME ALL RISKS RELATED TO THE
              INTERFACE AND PRODUCTS.
            </p>
            <p className="mb-5">THE RELEASED PARTIES FURTHER DO NOT WARRANT OR GUARANTEE THAT:</p>
            <p className="mb-5">
              (A) THE PRODUCTS OR ANY SERVICES, CONTENT, FUNCTIONS, INFORMATION, OR MATERIALS AVAILABLE THROUGH THE
              PRODUCTS WILL MEET YOUR REQUIREMENTS OR THAT THE QUALITY OF ANY PRODUCTS AVAILABLE WILL MEET YOUR
              EXPECTATIONS;
            </p>
            <p className="mb-5">
              (B) ANY INFORMATION PROVIDED WILL BE TIMELY, ACCURATE, RELIABLE, PRECISE, THOROUGH, CORRECT OR COMPLETE;
            </p>
            <p className="mb-5">
              (C) THE PRODUCTS OR ANY SERVICES, CONTENT, FUNCTIONS, OR MATERIALS AVAILABLE THROUGH THE PRODUCTS WILL BE
              AVAILABLE AT ANY PARTICULAR TIME OR PLACE, UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE;
            </p>
            <p className="mb-5">(D) ANY DEFECTS OR ERRORS IN THE PRODUCTS WILL BE CORRECTED; OR</p>
            <p className="mb-5">(E) THE PRODUCTS WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
          </li>

          <li>
            <h2 className="font-subheader uppercase">
              LIMITATION OF LIABILITY. IN NO EVENT SHALL ANY RELEASED PARTY BE LIABLE,
            </h2>

            <p className="mb-5">
              WHETHER IN AN ACTION BASED ON A CONTRACT INDEMNIFICATION, OBLIGATION, TORT (INCLUDING WITHOUT LIMITATION
              NEGLIGENCE), COLLATERALLY OR ARISING FROM ANY STATUTORY DUTY, PRE-CONTRACT OR OTHER REPRESENTATIONS, OR
              OTHERWISE, HOWEVER ARISING, FOR ANY ECONOMIC LOSSES (INCLUDING, WITHOUT LIMITATION, LOSS OF REVENUES,
              PROFITS, CONTRACTS, BUSINESS, GOODWILL OR ANTICIPATED SAVINGS) OR ANY DIRECT, INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED
              TO (A) ANY BREACH OF THESE TOS, (B) THE USE OF, OR THE INABILITY TO USE, THE PRODUCTS OR THE CONTENT,
              MATERIALS, INFORMATION, OR FUNCTIONS AVAILABLE THROUGH THE PRODUCTS, (C) YOUR PROVISION OF INFORMATION VIA
              THE PRODUCTS; (D) ANY INACCURACY OR OMISSION CONCERNING ANY OF THE INFORMATION PROVIDED ON THE PRODUCTS;
              (E) ANY INFORMATION POSTED, TRANSMITTED, EXCHANGED OR RECEIVED BY OR ON BEHALF OF ANY USER OR OTHER PERSON
              ON OR THROUGH THE PRODUCTS, OR (F) LOST PROFIT, BUSINESS OR SALES, EVEN IF SUCH RELEASED PARTY HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p className="mb-5">
              SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR DIRECT, INCIDENTAL OR
              CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Acknowledgement and Acceptance of Risk.</h2>
            <ol className="[list-style: lower-alpha]">
              <li>
                <h3 className="font-subheader"> Inherent Risks of Smart Contracts and Blockchain Technology. </h3>
                <p className="mb-5">
                  Where a transaction involves assets and activities memorialized on the blockchain, certain related
                  activities utilize experimental smart contract and blockchain technology. You represent and warrant
                  that you are knowledgeable, experienced and sophisticated in using blockchain technology and that you
                  acknowledge, understand and are willing to accept the risks associated with related technological and
                  cryptographic systems such as blockchains, smart contracts, consensus algorithms, cryptocurrencies,
                  and decentralized or peer-to-peer networks and systems. You further acknowledge and agree that such
                  technological and cryptographic systems (inclusive of the examples listed in the preceding sentence)
                  may be subject to malfunctions, bugs, timing errors, hacking and theft, changes to the protocol rules
                  of the blockchain (i.e., “forks”), hardware, software and/or Internet connectivity failures,
                  unauthorized third party data access, and other technological risks, any of which can adversely affect
                  the relevant smart contracts and may expose you to a risk of loss, forfeiture of your digital
                  currency. You accept the inherent security risks of providing information and conducting business over
                  the Internet and acknowledge that Cata Labs assumes no liability or responsibility for any such
                  failures, risks, or uncertainties, nor for any communication failures, disruptions, errors,
                  distortions or delays you may experience when using the Products, however so caused. You acknowledge
                  that the Products are subject to flaws and acknowledge that you are solely responsible for evaluating
                  any code provided by the Products. The Products may experience or be the target of sophisticated
                  cyber-attacks, unexpected surges in activity or other operational or technical difficulties that may
                  cause interruptions to or delays on the Products.
                </p>
              </li>
              <li>
                <h3 className="font-subheader">
                  Acknowledgement of the Volatility of the Valuation of Blockchain Assets.
                </h3>
                <p className="mb-5">
                  You acknowledge and agree that the prices of blockchain assets are extremely volatile and
                  unpredictable, that such assets may have no or little inherent or intrinsic value and that
                  fluctuations in the price or markets of those or other digital assets could materially and adversely
                  affect value.
                </p>
              </li>
              <li>
                <h3 className="font-subheader">Risks and Limitations of the Products.</h3>
                <p className="mb-5">
                  You accept and acknowledge that (i) we do and will rely on third-parties in the operation of the
                  Products, and for support in the facilitation of transactions, the recording of any transfer in the
                  decentralized ledger within the blockchain, and other assistance, tasks and support relating to the
                  operation and administration of the Products; and (ii) the applicable regulatory landscape governing
                  blockchain technologies, cryptocurrencies, and other digital assets is uncertain, and new regulations
                  or policies may materially adversely affect the development of the Products, as well as interfere with
                  our ability to operate or improve upon the Products (or your ability to access or use the Products or
                  applicable smart contracts).
                </p>
              </li>
              <li>
                <h3 className="font-subheader"> No Implicit Endorsements. </h3>
                <p className="mb-5">
                  You acknowledge that reference on or in the Products to any products, services, processes or other
                  information by name, trademark, manufacturer, supplier or otherwise does not constitute or imply
                  endorsement, sponsorship or recommendation by us, and that we do not endorse or assume any
                  responsibility for any activities of or resources, products, services, content or promotions owned,
                  controlled, operated or sponsored by third parties (including third party sites).
                </p>
              </li>
              <li>
                <h3 className="font-subheader"> No Investment Advice. </h3>
                <p className="mb-5">
                  You acknowledge that any information provided on or in our Products is purely for informational
                  purposes only and: (a) is not to be construed as investment advice or a recommendation that a
                  particular digital asset is a safe or sound investment; (b) is not a solicitation of any digital asset
                  transaction; and (c) does not attempt to induce or influence you to make any transactions or take any
                  actions as a result of the information provided. You should not take or refrain from taking any action
                  based on any information contained on or in any of the Products. You alone are responsible for
                  determining whether any investment, investment strategy, or related transaction is appropriate for you
                  based on your personal investment objectives, financial circumstances, and risk tolerance.
                </p>
              </li>
              <li>
                <h3 className="font-subheader"> Acknowledgement of Responsibilities for Transaction Outcomes. </h3>
                <p className="mb-5">
                  You acknowledge and agree that the use of the Products, including the swapping or utilizing of digital
                  assets, may carry financial risk, and that the risk of loss may be substantial. All transaction
                  decisions are made solely by you and you have not received any investment advice from us in connection
                  with any transactions. By using the Products, you represent that you have been, are and will be solely
                  responsible for making your own independent appraisal and investigations into the risks of a given
                  transaction and the underlying digital assets. You acknowledge and assume the risk of initiating,
                  interacting with and participating in transactions via the Products, in whatever means effectuated
                  thereby, and take full responsibility and liability for the outcome of any transaction you initiate
                  (regardless of whether the smart contracts, the Products, other market participants or other users
                  behave as expected or intended) and all other aspects of your use of and access to the Products. Under
                  no circumstances will the operation of all or any portion of the Products be deemed to create a
                  relationship that includes the provision or tendering of investment advice.{' '}
                </p>
              </li>
              <li>
                <h3 className="font-subheader"> Local Standards. </h3>
                <p className="mb-5">
                  You acknowledge that the Products and the content, materials, information, or functions available
                  through the Products may not be appropriate for use in all locations. Persons who choose to access the
                  Products do so on their own initiative, and are responsible for compliance with local laws, if and to
                  the extent local laws are applicable.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p className="mb-5 font-subheader">Governing Law, Dispute Resolution and Class Action Waiver.</p>
            <p className="mb-5">
              (a) Governing Law. These TOS and the relationship between you and us shall be governed by and construed in
              accordance with the laws of the state of California applicable to contracts entered into and performed in
              California by residents thereof; provided that all provisions hereof related to arbitration shall be
              governed by and construed in accordance with the Federal Arbitration Act (U.S. Code Title 9) to the extent
              provided above.
            </p>
            <p className="mb-5">
              (b) Dispute Resolution. We will use our best efforts to resolve any potential disputes through informal,
              good faith negotiations. If a potential dispute arises, you must contact us by sending an email to
              <a href="mailto:legal@catalabs.com" className="mx-2 text-gray-800">
                legal@catalabs.com
              </a>
              so that we can attempt to resolve it without resorting to formal dispute resolution. If we aren’t able to
              reach an informal resolution within sixty (60) days of your email, then you and we both agree to resolve
              the potential dispute according to the process set forth herein. Any claim or controversy arising out of
              or relating to the use of our Interface, other Products, these TOS, or any other acts or omissions for
              which you may contend that we are liable, including, but not limited to, any claim or controversy as to
              arbitrability (“dispute”), shall be finally and exclusively settled by arbitration under the JAMS Optional
              Expedited Arbitration Procedures. You understand that you are required to resolve all disputes by binding
              arbitration. The arbitration shall be held on a confidential basis before a single arbitrator, who shall
              be selected pursuant to JAMS rules. The arbitration will be held in Los Angeles, California, unless you
              and we both agree to hold it elsewhere. Unless we agree otherwise, the arbitrator may not consolidate your
              claims with those of any other party. Any judgment on the award rendered by the arbitrator may be entered
              in any court of competent jurisdiction. If for any reason a claim by law or equity must proceed in court
              rather than in arbitration you agree to waive any right to a jury trial and any claim may be brought only
              in a Federal District Court or a California state court located in Los Angeles, California.
            </p>
            <p className="mb-5">
              (c) Class Action and Jury Trial Waiver. You must bring any and all disputes against us in your individual
              capacity and not as a plaintiff in or member of any purported class action, collective action, private
              attorney general action, or other representative proceeding. This provision applies to class arbitration.
              You and we both agree to waive the right to demand a trial by jury. You have the right to opt-out and not
              be bound by the arbitration and waiver of class provisions set forth in these TOS by sending written
              notice of your decision to opt-out to
              <a href="mailto:legal@catalabs.com" className="mx-2 text-gray-800">
                legal@catalabs.com
              </a>
              . The notice must be sent to us within thirty (30) days of agreeing to these TOS (or if this Section
              pertaining to arbitration is materially amended hereafter, within 30 days of such amendment being
              effective), otherwise you shall be bound to arbitrate disputes in accordance with these TOS, and the
              notice must specify your name and mailing address.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Termination. </h2>
            <p className="mb-5">
              Notwithstanding anything to the contrary set forth in these TOS, we may terminate access to all or any
              part of the Products at any time, and/or block or prevent future access to and use of the Products, in
              each case with or without cause, with or without notice and without attendant liability, effective
              immediately. Upon any termination, all rights and obligations of the parties hereunder shall cease and you
              shall immediately cease using the Products, except that (a) all obligations that accrued prior to the
              effective date of termination and all remedies for breach of these TOS shall survive and (b) the
              provisions of these TOS that by their nature are intended to survive termination shall so survive.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Injunctive Relief. </h2>
            <p className="mb-5">
              You agree that a breach of these TOS will cause irreparable injury to Cata Labs for which monetary damages
              would not be an adequate remedy and we shall be entitled to equitable relief in addition to any remedies
              it may have hereunder or at law without a bond, other security or proof of damages. You are not entitled
              to seek equitable relief.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase">Severability. </h2>
            <p className="mb-5">
              Wherever possible, each provision of these TOS shall be interpreted in such manner as to be effective and
              valid under applicable law, but if any provision of these TOS shall be prohibited by or invalid under
              applicable law, such provision shall be deemed modified to the extent necessary to make it enforceable
              under applicable law. If any such provision is not enforceable as set forth in the preceding sentence, the
              unenforceability of such provision shall not affect the other provisions of these TOS, but these TOS shall
              be construed as if such unenforceable provision had never been contained herein.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase"> Assignment </h2>
            <p className="mb-5">
              We have the unrestricted right to assign these TOS, and/or any of the rights granted hereunder, in whole
              or in part, to any entity as we may determine from time to time in our sole discretion. You may not assign
              these TOS.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase"> Miscellaneous. </h2>
            <p className="mb-5">
              We both agree: (a) these TOS incorporate the applicable Privacy Policy and any other terms applying to
              your use of the Products by reference; (b) captions are inserted for reference and convenience only and in
              no way define, limit, or describe the scope of these TOS or intent of any provision; (c) a waiver by us of
              any term or condition of these TOS in any instance shall not be deemed or construed to be a waiver of such
              term or condition for the future, or any subsequent breach thereof; (d) these TOS shall not give any right
              or remedy to any third party whatsoever unless said right or remedy is specifically granted by us in
              writing to such third party; (e) these TOS contain our entire understanding relating to the subject matter
              hereof; (f) these TOS cannot be changed except by written agreement signed by us; (g) we may from time to
              time in the future offer additional products, and such additional products shall be considered Products as
              used herein, regardless of whether such product is specifically defined in these TOS and (h) we reserve
              the following rights, which do not constitute obligations of ours: (i) with or without notice to you, to
              modify, substitute, eliminate or add to any of the Products; (ii) to review, modify, filter, disable,
              delete and remove any and all content and information from any of the Products.
            </p>
          </li>
          <li>
            <p className="mb-2">Further Inquiries. You may direct questions about these TOS, or other issues, to:</p>
            <p className="mb-2">Cata Labs, Inc.</p>
            <p className="mb-2">
              <a href="mailto:legal@catalabs.com" className=" text-gray-800">
                legal@catalabs.com
              </a>
            </p>
          </li>
        </ol>
        <p className="mb-5">
          <strong>Last Modified: April 1, 2024</strong>
        </p>
      </main>
    </div>
  );
};

export default TermsOfService;
