import { VaultInteraction, VaultInteractionType } from '@catalabs/catalyst-api-client';
import { observer } from 'mobx-react-lite';

import { cn, formatDate, formatValue, TableAssetsDisplay, TokenAmountDisplay } from '~/modules/common';

interface VaultInteractionListItemProps {
  vaultInteraction: VaultInteraction;
  poolAssets: {
    chain: string;
    address: string;
  }[];
  isLast: boolean;
  onClick: () => void;
}

export const VaultInteractionListItem = observer(
  ({ vaultInteraction, poolAssets, isLast, onClick }: VaultInteractionListItemProps) => {
    const interactedTokens = vaultInteraction.transactions.flatMap((transaction) =>
      transaction.assets.map((asset) => ({
        chainId: asset.chain,
        asset: asset.token,
        amount: asset.amount,
      })),
    );

    const totalLiquidity = vaultInteraction.transactions.reduce((sum, transaction) => {
      return sum + transaction.value;
    }, 0);

    return (
      <div
        className={cn(
          'border-gray grid min-h-20 grid-cols-8 border-t bg-white px-5 font-sans text-sm text-black hover:cursor-pointer hover:bg-primary hover:text-white',
          {
            'rounded-b-3xl': isLast,
          },
        )}
        onClick={onClick}
      >
        <span className="col-span-1 flex items-center">
          {/* TODO: Add timestamp to each vault interaction for grouping on API */}
          {formatDate(vaultInteraction.transactions[0].timestamp)}
        </span>
        <span className="col-span-1 flex items-center">
          {vaultInteraction.interactionType === VaultInteractionType.Deposit ? 'Deposit' : 'Withdraw'}
        </span>
        <span className="col-span-2 mr-4 flex items-center">
          <TableAssetsDisplay assets={poolAssets} maxTokenDisplay={8} showSymbol={false} className="my-4 gap-2" />
        </span>
        <span className="col-span-2 my-4 flex flex-col justify-center gap-1">
          {interactedTokens.map((asset) => (
            <TokenAmountDisplay
              key={`interacted-token-${asset.chainId}-${asset.asset}`}
              chainId={asset.chainId}
              asset={asset.asset}
              amount={asset.amount}
              size="sm"
            />
          ))}
        </span>
        <span className="col-span-2 flex items-center">$ {formatValue(totalLiquidity, 2)}</span>
      </div>
    );
  },
);
