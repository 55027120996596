import { twMerge } from 'tailwind-merge';

type ButtonSize = 'extra-small' | 'small' | 'medium' | 'large';

type ButtonVariant = 'primary' | 'outline';
export interface ButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  label?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
  children?: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

export const Button = ({
  variant = 'primary',
  size = 'medium',
  type = 'button',
  fullWidth = false,
  label,
  disabled,
  onClick,
  children,
  className,
  ...props
}: ButtonProps) => {
  const widthMap: Record<ButtonSize, string> = {
    'extra-small': 'px-3 h-[22px] text-xs',
    small: 'w-[134px]',
    medium: 'w-[240px]',
    large: 'w-[340px]',
  };

  const width = fullWidth ? 'w-full' : widthMap[size];
  const sizeClassesMap: Record<ButtonSize, string> = {
    'extra-small': 'px-3 h-[22px] text-xs',
    small: `${width} h-[40px] text-sm`,
    medium: `${width} h-[52px] text-sm`,
    large: `${width} h-[64px] text-sm`,
  };

  const variantClassesMap: Record<ButtonVariant, string> = {
    primary: 'bg-primary text-white',
    outline: 'border border-gray-200 text-gray-500',
  };

  const disabledClasses = !disabled ? variantClassesMap[variant] : `${variantClassesMap[variant]} bg-opacity-30`;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={twMerge(
        `mt-4 flex items-center justify-center rounded-full  ${disabledClasses} ${sizeClassesMap[size]}`,
        className,
      )}
      {...props}
    >
      {label || children}
    </button>
  );
};

export default Button;
