import { Referral } from '@catalabs/catalyst-api-client';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import * as HoverCard from '@radix-ui/react-hover-card';
import { useState } from 'react';

import { Button, Input } from '~/modules/common';

import { UpdateSharePercentage } from './UpdateReferralSharePercentage/UpdateReferralSharePercentage';

export function UpdateOwnReferralLink({
  inputRef,
  ownReferral,
}: {
  ownReferral: Referral;
  inputRef: React.RefObject<HTMLInputElement>;
}) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const code = ownReferral.code.toLocaleLowerCase();
    const url = new URL(window.location.href);
    navigator.clipboard.writeText(`${url.protocol}//${url.hostname}/join/${code}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="flex w-full flex-col gap-10 md:flex-row">
      <div className="flex gap-10">
        <div className="flex flex-col gap-4">
          <span className="text-xs text-grey-500">YOUR REFERRALS</span>
          <span className="font-semibold">{ownReferral.referralCount}</span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-xs text-grey-500">VOLUME</span>
          <span className="font-semibold">${ownReferral.referralVolume}</span>
        </div>
      </div>
      <div className="flex flex-grow flex-col gap-2">
        <div className="flex items-center gap-1">
          <span className="text-xs text-grey-500">LINK AND REWARD</span>
          <LinkAndRewardInformation />
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <UpdateSharePercentage ownReferral={ownReferral} />
          <div className="hover:[not(:focus-within):border-gray-400] group relative flex w-full items-center  rounded-lg border border-gray-300 focus-within:border-indigo-500">
            <div className="w-full">
              <Input
                ref={inputRef}
                type="search"
                className={'peer order-2 h-[42px] w-full rounded-lg px-4 py-3 font-light'}
                value={`${ownReferral.code}`}
                required
              />
            </div>
            <div className="absolute right-2">
              <Button size="extra-small" className="mt-0 w-full" type="submit" onClick={handleCopy}>
                {copied ? 'Link copied' : 'Copy'}
              </Button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

function LinkAndRewardInformation() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HoverCard.Root openDelay={100} open={isOpen} onOpenChange={(val) => setIsOpen(val)}>
      <HoverCard.Trigger>
        <InformationCircleIcon
          className={`h-[15px] w-[15px] transition-colors duration-200 ${isOpen ? 'text-primary' : 'text-gray-400'}`}
        />
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          side="top"
          className="mb-3 max-w-[330px] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
        >
          <div className="flex flex-col gap-2 rounded-3xl bg-white p-5 text-sm shadow-xl ">
            <span className="text-gray-500">
              You have the option to share a percentage of your bonus with your referrals (kickback). While you can
              adjust this percentage over time, please note that the kickback can only be increased and not decreased.
            </span>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
