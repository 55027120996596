import { observer } from 'mobx-react-lite';
import { useContext, useRef } from 'react';

import { StoreContext } from '~/modules/common';

import { useOwnReferral } from '../../hooks/useOwnReferral';
import { CreateOwnReferralLink } from './CreateOwnReferralLink';
import { Layout } from './Layout';
import { UpdateOwnReferralLink } from './UpdateOwnReferralLink';

export const OwnReferralLink = observer(() => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { referral: referralStore, wallet: walletStore } = useContext(StoreContext);
  const { ownReferral } = useOwnReferral(referralStore, walletStore);

  const shouldShowConnectWalletPrompt = !walletStore.isConnected;

  if (!walletStore.isConnected || !ownReferral) {
    return (
      <Layout shouldShowConnectWalletPrompt={shouldShowConnectWalletPrompt}>
        <CreateOwnReferralLink inputRef={inputRef} />
      </Layout>
    );
  }

  return (
    <Layout shouldShowConnectWalletPrompt={shouldShowConnectWalletPrompt}>
      <UpdateOwnReferralLink inputRef={inputRef} ownReferral={ownReferral} />
    </Layout>
  );
});
