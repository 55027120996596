import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface TokenDisplaySkeletonProps {
  showChain?: boolean;
  showChainIcon?: boolean;
  showSymbol?: boolean;
  showTooltip?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const TokenDisplaySkeleton = ({
  showChain = false,
  showSymbol = true,
  showChainIcon = true,
  size = 'md',
}: TokenDisplaySkeletonProps) => {
  const logoSizeMap: Record<string, string> = {
    xs: '1.25rem',
    sm: '1.5rem',
    md: '2rem',
    lg: '2.5rem',
  };

  const chainIconSizeMap: Record<string, string> = {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '19px',
    lg: '2rem',
  };

  return (
    <SkeletonTheme borderRadius={100} height={18}>
      <div className="flex items-end justify-center">
        <Skeleton circle width={logoSizeMap[size]} height={logoSizeMap[size]} />

        {showChainIcon && (
          <Skeleton
            circle
            width={chainIconSizeMap[size]}
            height={chainIconSizeMap[size]}
            style={{ zIndex: '2', marginLeft: '-0.5rem', border: '1px solid white' }}
          />
        )}

        <div className="self-center pl-2">
          {showSymbol && <Skeleton width={50} />}
          {showChain && <Skeleton width={70} />}
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default TokenDisplaySkeleton;
