import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { formatCurrency, PriceImpact, StoreContext, SwapReviewLine, SwapSlippage } from '~/modules/common';
import PoolDepositModal from '~/modules/pools/components/PoolDepositModal';
import PoolTokenDepositPane from '~/modules/pools/components/PoolTokenDepositPane';
import { PoolActionType, PoolInteractionStep } from '~/modules/pools/enums';
import { usePoolDeposit } from '~/modules/pools/hooks/usePoolDeposit';
import { getAssetKey } from '~/modules/pools/utils';
import { SwapCosts } from '~/modules/swap';

import OptimalDeposit from './OptimalDeposit/OptimalDeposit';
import { PoolComposition } from './PoolComposition';

export const PoolDeposit = observer(() => {
  const { wallet, pool: poolStore, catalyst } = useContext(StoreContext);
  const poolDepositHelpers = usePoolDeposit(catalyst, wallet, poolStore);
  const { poolAccountBalance, depositStore, pool } = poolStore;
  const {
    depositInfo,
    showPendingDeposit,
    poolDepositStep,
    poolDepositRequest,
    poolDepositError,
    partialDeposit,
    runningDeposit,
    withLiquiditySwap,
    liquiditySwapFinalAmounts,
    liquiditySwapFinalValues,
  } = depositStore;
  const { address, isConnected } = wallet;

  if (!pool || !poolDepositHelpers) {
    return null;
  }

  const liquidityBalance = poolAccountBalance?.get(pool.id)?.value;

  const {
    depositInputRefs,
    poolAssets,
    depositAmounts,
    depositValues,
    depositWeights,
    depositPercentages,
    poolTokenInfos,
    depositValue,
    poolShare,
    optimalDepositEnabled,
    liquiditySwapsEnabled,
    liquiditySwapRoute,
    insufficientBalanceError,
    liquiditySwapFinalDepositAmounts,
    gasTokenBalances,
    assetBalances,
    handlers: {
      handleDepositInputChange,
      handleDeposit,
      handleMaxClick,
      handleDepositPaneClick,
      handleDepositPanelBlur,
      handleOptimalDepositChange,
      handleLiquiditySwapsChange,
      handleSwitchAssetToGasToken,
    },
  } = poolDepositHelpers;
  const canDeposit = address && isConnected && !insufficientBalanceError && !showPendingDeposit;
  const depositButtonText = () => {
    if (!address || !isConnected) {
      return 'Connect Wallet';
    }
    if (insufficientBalanceError) {
      return 'Insufficient Balance';
    }
    if (showPendingDeposit) {
      return 'Pending Deposit...';
    }
    return 'Deposit';
  };

  const showDepositModal =
    poolDepositStep === PoolInteractionStep.Approval || poolDepositStep === PoolInteractionStep.Interact;

  const handleCloseDepositModal = () => {
    if (partialDeposit || runningDeposit) {
      runInAction(() => {
        poolStore.depositStore.poolDepositStep = PoolInteractionStep.Interact;
      });
    } else {
      poolStore.depositStore.reset();
    }
  };

  const navigate = useNavigate();

  const handleCompleteDeposit = () => {
    poolDepositHelpers.handlers.handleCompleteDeposit();
    const poolId = poolStore.pool?.id;
    if (poolId) {
      poolStore.updatePoolAccountBalance(poolId);
      navigate(`/pools/${poolId}`);
    }
  };

  const canRenderModal = poolDepositStep && depositInfo && poolDepositRequest;

  return (
    <div className="mx-3 mt-10 flex flex-col justify-center md:flex-row">
      {canRenderModal ? (
        <PoolDepositModal
          handleTryAgain={poolDepositHelpers.handlers.handleTryAgain}
          handleCompleteDeposit={handleCompleteDeposit}
          open={showDepositModal}
          handleCloseModal={handleCloseDepositModal}
          depositInfo={depositInfo}
          poolDepositRequest={poolDepositRequest}
          poolDepositError={poolDepositError}
          partialDeposit={partialDeposit}
          withLiquiditySwap={withLiquiditySwap}
          liquiditySwapFinalAmounts={liquiditySwapFinalAmounts}
          liquiditySwapFinalValues={liquiditySwapFinalValues}
          poolAssets={poolAssets}
          poolDepositStep={poolDepositStep}
          canCloseModal={!runningDeposit}
        />
      ) : null}

      <div className="flex flex-col gap-6">
        <div className="mx-2 flex w-fit flex-col">
          <div
            className="flex w-fit cursor-pointer items-center gap-2"
            onClick={() => runInAction(() => (poolStore.poolAction = PoolActionType.None))}
          >
            <Caret className="text-grey-600" />
            <div className="text-xs">BACK</div>
          </div>
          <span className="mt-[26px] font-header text-[24px] text-grey-800">Deposit Liquidity</span>
          {liquidityBalance ? (
            <span className="mt-[8px] font-semibold text-sm text-grey-600">
              Liquidity Balance: {formatCurrency(liquidityBalance)}
            </span>
          ) : null}
        </div>

        <div className="flex w-full flex-col items-start gap-10 md:flex-row md:gap-28">
          <div className="flex flex-col rounded-[25px] bg-white shadow md:w-[704px] ">
            <div className="flex flex-col gap-4 p-7 md:p-6">
              <OptimalDeposit
                optimalDepositEnabled={optimalDepositEnabled}
                onToggle={handleOptimalDepositChange}
                poolAssets={poolAssets}
                onToggleLiquiditySwaps={handleLiquiditySwapsChange}
                liquiditySwapsEnabled={liquiditySwapsEnabled}
              />
              {poolAssets.map((asset) => {
                const depositDisplay = depositAmounts.get(getAssetKey(asset));
                const paneKey = getAssetKey(asset);
                const walletBalance = assetBalances.get(paneKey);
                const gasTokenWalletBalance = gasTokenBalances.get(paneKey);
                return (
                  <PoolTokenDepositPane
                    key={paneKey}
                    walletBalance={walletBalance}
                    gasTokenBalance={gasTokenWalletBalance}
                    asset={asset}
                    depositValue={depositValues.get(paneKey) ?? 0}
                    depositWeight={depositWeights.get(paneKey) ?? 0}
                    depositDisplay={depositDisplay}
                    updateInputText={handleDepositInputChange}
                    setMaxInput={handleMaxClick}
                    handleDepositPaneClick={handleDepositPaneClick}
                    handleDepositPanelBlur={handleDepositPanelBlur}
                    ref={depositInputRefs.get(paneKey)}
                    liquiditySwapQuote={liquiditySwapRoute.get(asset.chainId)}
                    poolAssets={poolAssets}
                    allLiquiditySwapQuotes={Array.from(liquiditySwapRoute.values())}
                    handleUseGasToken={handleSwitchAssetToGasToken}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex w-full flex-col-reverse items-center gap-6 text-sm md:w-fit md:flex-col">
            <div className="flex w-full flex-col items-center gap-3 md:gap-4">
              <PoolComposition
                title="Your deposit"
                tokens={poolTokenInfos}
                percentages={depositPercentages}
                amounts={poolAssets.map((asset) => {
                  const assetKey = getAssetKey(asset);
                  const amount = liquiditySwapsEnabled
                    ? liquiditySwapFinalDepositAmounts.get(assetKey)
                    : depositAmounts.get(assetKey);
                  return isNaN(Number(amount)) ? 0 : Number(amount);
                })}
                totalValue={depositValue}
                isTitleBold={false}
                showPercentage={false}
                showRemainder={false}
              />
              <SwapReviewLine title="Pool Share" value={poolShare === 0 ? '-' : `${poolShare.toFixed(2)}%`} />
              <SwapSlippage allowEdit={true} />
              <PriceImpact value={depositInfo?.priceImpact || 0} />
              <SwapCosts feeDetails={depositInfo ? depositInfo.totalCost : []} />
            </div>

            <div className="flex w-full justify-center">
              <button
                onClick={handleDeposit}
                disabled={!canDeposit}
                className={`flex h-[78px] w-full cursor-pointer items-center justify-center rounded-full bg-primary px-12 py-8 text-white md:w-[416px] ${
                  !canDeposit ? 'bg-opacity-30' : ''
                }`}
              >
                {depositButtonText()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PoolDeposit;
