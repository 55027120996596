import { Swap } from '@catalabs/catalyst-api-client';
import { observer } from 'mobx-react-lite';

import { isAddressZero } from '~/config';
import { TokenAmountDisplay } from '~/modules/common/components';
import { cn, formatDate, formatValue } from '~/modules/common/utils';
import { TransactionStatus } from '~/modules/transactions/components';

interface SwapListItemProps {
  swap: Swap;
  isLast: boolean;
  onClick: () => void;
}

export const SwapListItem = observer(({ swap, isLast, onClick }: SwapListItemProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'border-gray grid h-20 grid-cols-8 border-t bg-white px-5 font-sans text-sm text-black hover:cursor-pointer hover:bg-primary hover:text-white',
        {
          'rounded-b-3xl': isLast,
        },
      )}
    >
      <span className="col-span-1 flex items-center">{formatDate(swap.startedAt)}</span>
      <span className="col-span-2 flex items-center">
        {/* TODO: Validate if this check is required */}
        {isAddressZero(swap.toAsset) ? (
          <>Empty</>
        ) : (
          <TokenAmountDisplay chainId={swap.fromChainId} asset={swap.fromAsset} amount={swap.input} />
        )}
      </span>
      <span className="col-span-1 flex items-center">$ {formatValue(swap.inputValue, 2)}</span>
      <span className="col-span-2 flex items-center">
        {/* TODO: Validate if this check is required */}
        {swap.toChainId === '0' || isAddressZero(swap.toAsset) ? (
          <>Empty</>
        ) : (
          <TokenAmountDisplay chainId={swap.toChainId} asset={swap.toAsset} amount={swap.output} />
        )}
      </span>
      <span className="col-span-1 flex items-center">$ {formatValue(swap.outputValue, 2)}</span>
      <TransactionStatus status={swap.state} />
    </div>
  );
});
