import { SortDirection } from '../enums';

/**
 * Comparison function for sorting dates based on their chronological order.
 *
 * @param a - First date to compare
 * @param b - Second date to compare
 * @param order - Sort direction (Ascending or Descending)
 * @returns A negative number if 'a' should be sorted before 'b',
 *          a positive number if 'a' should be sorted after 'b',
 *          or zero if they are equivalent.
 *
 * For Ascending order, earlier dates come first (natural chronological order).
 * For Descending order, later dates come first (reverse chronological order).
 */
export const compareFnForDate = (a: Date, b: Date, order: SortDirection) => {
  if (order === SortDirection.Ascending) {
    return a.getTime() - b.getTime();
  } else {
    return b.getTime() - a.getTime();
  }
};
