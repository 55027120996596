import { Button } from './Button';
import { Modal } from './Modal';

interface WalletComplianceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnectAnotherWallet: () => void;
}

export const WalletComplianceModal = ({ isOpen, onClose, onConnectAnotherWallet }: WalletComplianceModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose} hasError>
      <div className="flex w-[95vw] flex-col gap-2 rounded-[25px] bg-white p-10 md:w-[70vw]">
        <h2 className="my-1 font-subheader uppercase text-red-600 ">Regulatory Compliance Notice</h2>
        <div>
          <p className="text-xs font-medium text-grey-500 md:text-sm">
            To ensure compliance with regulatory standards and maintain a safe environment for all users, we actively
            monitor wallet addresses attempting to connect to our application. Wallet addresses that do not meet our
            regulatory standards will be restricted from accessing our platform.
            <br /> <br />
            If you believe your wallet address has been flagged erroneously or have any questions regarding this policy,
            please{' '}
            <a target="_blank" href="https://discord.com/invite/4BRVhgFZb4" className="text-primary underline">
              contact us
            </a>{' '}
            for assistance.
            <br /> <br />
            Thank you for your understanding and cooperation in helping us uphold regulatory requirements and ensure the
            security of our platform.
          </p>
        </div>
        <Button onClick={onConnectAnotherWallet} fullWidth>
          Connect another wallet
        </Button>
      </div>
    </Modal>
  );
};
