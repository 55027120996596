import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { Button, cn, StoreContext, Table } from '~/modules/common';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';
import {
  SwapList,
  SwapListHeader,
  VaultInteractionList,
  VaultInteractionListHeader,
} from '~/modules/transactions/components';
import { TransactionType } from '~/modules/transactions/enums';

interface TransactionListProps {
  selectedTab: TransactionType;
}

export const TransactionList = observer(({ selectedTab }: TransactionListProps) => {
  const { isConnected, connect } = useConnectButton();

  const { wallet } = useContext(StoreContext);
  const { swapCount, vaultInteractionCount } = wallet.transactions;
  const showSwapHeader = selectedTab === TransactionType.SWAPS && swapCount > 0;
  const showVaultInteractionHeader = selectedTab === TransactionType.VAULT_INTERACTIONS && vaultInteractionCount > 0;

  return (
    <div className="w-full overflow-x-scroll rounded-3xl">
      <Table
        headersPanelStyle={cn('rounded-t-3xl grid', {
          'grid-cols-8': showSwapHeader || showVaultInteractionHeader,
          hidden: !showSwapHeader && !showVaultInteractionHeader,
        })}
        header={
          showSwapHeader ? <SwapListHeader /> : showVaultInteractionHeader ? <VaultInteractionListHeader /> : <></>
        }
      >
        <>
          {!isConnected ? (
            <div className="flex flex-col items-center justify-center py-9">
              <Button className="mt-0 w-fit" onClick={connect} size="extra-small">
                Connect Wallet
              </Button>
            </div>
          ) : selectedTab === TransactionType.SWAPS ? (
            <SwapList />
          ) : (
            <VaultInteractionList />
          )}
        </>
      </Table>
    </div>
  );
});
