export enum SwapError {
  SECURITY_LIMIT,
  INSUFFICIENT_TOKENS,
  INSUFFICIENT_GAS,
  INSUFFICIENT_TOKENS_AND_GAS,
  FAILURE,
  NO_ROUTE,
  REJECTED,
  REVERTED,
}
