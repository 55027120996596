import { FeeDetail } from '@catalabs/catalyst-api-client';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { CatalystNetwork, formatBalance } from '~/config';
import { formatCurrency, formatPercentage, Modal, SwapReviewLine, TokenDisplay } from '~/modules/common';
import PoolChainSwap from '~/modules/pools/components/PoolChainSwap';
import PoolCreateApproval from '~/modules/pools/components/PoolCreateApproval';
import PoolCreateDepositDisplay from '~/modules/pools/components/PoolCreateDepositDisplay';
import PoolCreateSetChannel from '~/modules/pools/components/PoolCreateSetChannel';
import PoolInteractionProgressBar from '~/modules/pools/components/PoolInteractionProgressBar';
import { DEFAULT_CREATE_POOL_SHARE } from '~/modules/pools/constants';
import { PoolActionState, PoolCreateStep } from '~/modules/pools/enums';
import { PoolCreateRequest } from '~/modules/pools/interfaces';
import { getDepositText } from '~/modules/pools/utils';
import { SwapCosts } from '~/modules/swap';

interface PoolCreateModalProps {
  onCancel: () => void;
  createPoolStep: PoolCreateStep;
  poolCreateRequests: PoolCreateRequest[];
  totalLiquidity: number;
  totalCost: FeeDetail[];
  completeDeposit: () => void;
  resumeDeposit: () => void;
  hasSavedPoolCreation?: boolean;
  onWithdraw?: () => void;
}

export const PoolCreateModal = ({
  onCancel,
  createPoolStep,
  poolCreateRequests,
  totalLiquidity,
  totalCost,
  completeDeposit,
  resumeDeposit,
  hasSavedPoolCreation,
  onWithdraw,
}: PoolCreateModalProps) => {
  if (createPoolStep !== PoolCreateStep.Approval && createPoolStep !== PoolCreateStep.Paused) {
    return null;
  }
  const hasCompletedDeposits = poolCreateRequests.every(
    (chainAction) => chainAction.actionState === PoolActionState.Completed,
  );

  const hasCompletedSetups = poolCreateRequests.every((poolCreateRequests) =>
    poolCreateRequests?.setChannelRequests?.every((req) => req.requestStatus === PoolActionState.Completed),
  );

  return (
    <Modal open={true} onClose={onCancel}>
      <div className="mx-3 flex w-full flex-col">
        <div className="mb-4 flex flex-col items-center gap-4">
          {hasSavedPoolCreation && createPoolStep === PoolCreateStep.Paused && (
            <div className="flex flex-row justify-between gap-2 rounded-[20px] bg-red-500  p-4 shadow-2xl md:w-[552px] ">
              <div className="flex flex-col gap-2 ">
                <p className="font-subheader text-sm text-white">Your progress is saved</p>
                <p className="text-sm text-white">
                  Pool creation and Deposit in progress. You started a pool and already deposited parts of the
                  liquidity. Resume your deposit here or withdraw funds.
                </p>
                <div>
                  <button
                    onClick={onWithdraw}
                    className="rounded-full border-[1px] border-white bg-red-500 px-[12px] py-[4px] text-xs text-white"
                  >
                    Withdraw Funds
                  </button>
                </div>
              </div>

              <div>
                <button>
                  <XMarkIcon className="h-5 w-5 text-white" />
                </button>
              </div>
            </div>
          )}
          <div className="flex w-full flex-col items-center rounded-[25px] bg-white p-5 md:w-[552px]">
            <span className="mb-4 w-full font-semibold text-xs uppercase leading-4 tracking-wide text-gray-900">
              {hasCompletedDeposits ? 'Pool Created' : 'Pool Creation in progress'}
            </span>
            <div className="flex w-full flex-col items-center space-y-2">
              {poolCreateRequests.map((poolCreateRequest) => {
                const targetNetwork = CatalystNetwork.getCatalystNetwork(poolCreateRequest.chainId);
                const requestsArray = Array.from(poolCreateRequest.approvalRequests.values());
                const allTokens = requestsArray.map((req) => {
                  const token = req.asset;
                  return token.symbol;
                });
                const depositHash = poolCreateRequest.deployVaultRequest?.hash;
                const explorerUrl = depositHash ? targetNetwork.getTransactionUrl(depositHash) : undefined;
                if (poolCreateRequest.actionState === PoolActionState.Inactive) {
                  return null;
                }
                return (
                  <PoolInteractionProgressBar
                    key={`deposit-progress-bar-${poolCreateRequest.chainId}`}
                    interactionText={`Deposit ${getDepositText(allTokens)} on ${targetNetwork.config.name}`}
                    state={poolCreateRequest.deployVaultRequest?.requestStatus || PoolActionState.Inactive}
                    explorerUrl={explorerUrl}
                  />
                );
              })}
            </div>
            <div className="mt-2 flex w-full flex-col items-center space-y-2">
              {poolCreateRequests.map((poolCreateRequest) => {
                const targetNetwork = CatalystNetwork.getCatalystNetwork(poolCreateRequest.chainId);
                const requestsArray = Array.from(poolCreateRequest.approvalRequests.values());
                const allTokens = requestsArray.map((req) => {
                  const token = req.asset;
                  return token.symbol;
                });
                // get the hash of the last request, it is the hash of the finishVault request
                const depositHash =
                  poolCreateRequest.setChannelRequests[poolCreateRequest.setChannelRequests.length - 1]?.hash;
                const explorerUrl = depositHash ? targetNetwork.getTransactionUrl(depositHash) : undefined;
                const state = poolCreateRequest?.setChannelRequests?.every(
                  (req) => req.requestStatus === PoolActionState.Completed,
                )
                  ? PoolActionState.Completed
                  : PoolActionState.Pending;
                const isNotSettingUp = poolCreateRequest?.setChannelRequests?.every(
                  (req) => req.requestStatus === PoolActionState.Inactive,
                );
                if (isNotSettingUp) {
                  return null;
                }
                return (
                  <PoolInteractionProgressBar
                    key={`setup-progress-bar-${poolCreateRequest.chainId}`}
                    interactionText={`Setup ${getDepositText(allTokens)} on ${targetNetwork.config.name}`}
                    state={state}
                    explorerUrl={explorerUrl}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          {hasCompletedDeposits && hasCompletedSetups && (
            <>
              <div className="mt-5 flex flex-col space-y-4 md:w-[552px]">
                <div className="flex flex-col space-y-6 rounded-[25px] bg-white p-6">
                  {poolCreateRequests.map((chainAction) => {
                    return chainAction.approvalRequests.map((req) => {
                      const displayAmount = formatBalance(req.amount, req.asset.decimals);
                      return (
                        <div
                          key={`deposit-completed-display-${req.chainId}-${req.asset.address}`}
                          className="flex space-x-4"
                        >
                          <div className="flex w-full justify-between rounded-[15px] border border-gray-200 p-5">
                            <TokenDisplay showSymbol chainId={req.chainId} token={req.asset.address} />
                            <div className="ml-4 mt-1 flex flex-col">
                              <span className="font-semibol text-[32px] leading-[28px]">{displayAmount}</span>
                              <span className="text-[14px] font-normal text-gray-600">
                                {formatCurrency(req.amountValue)}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
                <SwapReviewLine title="Total Liquidity" value={formatCurrency(totalLiquidity)} />
                <SwapReviewLine title="Pool Share" value={`${formatPercentage(DEFAULT_CREATE_POOL_SHARE)}`} />
                <SwapCosts feeDetails={totalCost} />
              </div>
              <div
                onClick={completeDeposit}
                className="itemx-center mt-16 flex cursor-pointer justify-center rounded-[145px] bg-primary px-12 py-8 text-white md:w-[552px]"
              >
                Go to Pool
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          {poolCreateRequests.map((poolCreateRequest, index) => {
            const chainDetails = CatalystNetwork.getCatalystNetwork(poolCreateRequest.chainId.toString());
            const chainSwapText = `Switch to ${chainDetails.config.name}`;

            return (
              <div className="flex flex-col items-center gap-4" key={chainDetails.config.chainId}>
                {index > 0 && poolCreateRequest.chainSwap !== PoolActionState.Completed && (
                  <PoolChainSwap
                    actionState={poolCreateRequest.chainSwap || PoolActionState.Inactive}
                    chainSwapText={chainSwapText}
                  />
                )}

                <div className="flex flex-col gap-4">
                  <PoolCreateApproval requests={poolCreateRequest.approvalRequests} />
                </div>

                <div className="flex flex-col gap-4">
                  {poolCreateRequest?.deployVaultRequest && (
                    <PoolCreateDepositDisplay
                      isLastRequest={poolCreateRequests.length === index + 1}
                      request={poolCreateRequest.deployVaultRequest}
                      isPaused={createPoolStep === PoolCreateStep.Paused}
                      onResume={resumeDeposit}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-4 flex flex-col items-center gap-4">
          {poolCreateRequests.map((poolCreateRequest, index) => {
            return (
              <div className="flex flex-col gap-4" key={`set-channel-request-${index}`}>
                {poolCreateRequest?.setChannelRequests && (
                  <PoolCreateSetChannel requests={poolCreateRequest.setChannelRequests} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
