import { ChainConfig } from '@catalabs/catalyst-chain-lists';

import { NetworkConfig, NetworkType } from '~/config';

// TODO: move network type enumeration into chain lists
export function buildNetworkConfig({ id, name, nativeCurrency, blockExplorers, rpcUrls }: ChainConfig): NetworkConfig {
  return {
    chainId: id.toString(),
    name,
    currencySymbol: nativeCurrency.symbol,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    explorerUrl: blockExplorers ? (Object.values(blockExplorers)[0] as any).url : '',
    rpcUrl: rpcUrls.default.http[0],
    networkType: NetworkType.EVM,
  };
}
