import { getChainConfig } from '@catalabs/catalyst-chain-lists';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { isAddress } from '@ethersproject/address';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';

import { CATALYST_NETWORKS } from '~/config/network/network.config';
import { formatBalance } from '~/config/utils/tokens.utils';
import { formatCurrency, formatTokenBalance, Modal, TokenDisplay, Tooltip } from '~/modules/common';
import { Input } from '~/modules/common/components/Input';
import { StoreContext } from '~/modules/common/store/context';
import SelectNetwork from '~/modules/swap/components/SelectNetwork';
import { TOKEN_SELECTOR_PANE_NO_OF_TOKENS_TO_DISPLAY } from '~/modules/swap/constants';
import { ChainSelectMode } from '~/modules/swap/enums';

export interface ChainSelectorProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (chainId: string, asset: TokenInfo) => void;
  selectedChainId?: string;
  hideNativeAssets?: boolean;
  chainSelectMode?: ChainSelectMode;
}

export const ChainSelector = observer(
  ({
    onSelect,
    onClose,
    open,
    selectedChainId,
    hideNativeAssets,
    chainSelectMode = ChainSelectMode.None,
  }: ChainSelectorProps) => {
    const store = useContext(StoreContext);
    const { swap, catalyst, wallet } = store;
    const { address } = wallet;
    const { sourceNetwork, targetNetwork, sourceAsset } = swap;

    // Map of chainIds to the timestamp of the last balance API call for corresponding chainId
    const apiCallTimestampRef = React.useRef<Map<string, number>>(new Map());

    // @TODO: Asset fetching should be moved to the parent component and passed as prop to ChainSelector
    // Map of chainIds to their respective lists of TokenInfo objects
    const [assets, setAssets] = useState<Map<string, TokenInfo[]>>(new Map());
    const [filteredAssets, setFilteredAssets] = useState<TokenInfo[]>([]);

    const [searchInput, setSearchInput] = useState<string>('');
    const options = CATALYST_NETWORKS.map((o) => o.config.chainId);

    function resolveChainId(): string {
      let resolvedChainId: string;
      if (!selectedChainId) {
        if (chainSelectMode === ChainSelectMode.Source && sourceNetwork) {
          resolvedChainId = sourceNetwork;
        } else if (chainSelectMode === ChainSelectMode.Target && targetNetwork) {
          resolvedChainId = targetNetwork;
        } else {
          resolvedChainId = options[0];
        }
      } else {
        resolvedChainId = selectedChainId;
      }
      return resolvedChainId;
    }

    const initialChainId = resolveChainId();

    const [selectedNetwork, setSelectedNetwork] = useState<string>(initialChainId);
    const selectedNetworkName = getChainConfig(selectedNetwork).name;

    const [previouslySelectedAsset, setPreviouslySelectedAsset] = useState<TokenInfo>();

    const [previouslySelectedChainId, setPreviouslySelectedChainId] = useState<string>();

    useEffect(() => {
      const newChainId = resolveChainId();
      setSelectedNetwork(newChainId);
    }, [selectedChainId]);

    useEffect(() => {
      // Updates the timestamp for the last balance API call for the selected network to the current time
      apiCallTimestampRef.current.set(selectedNetwork, Date.now());
      wallet.updateChainBalances([selectedNetwork]);
    }, [address]);

    useEffect(() => {
      if (!selectedNetwork) {
        return;
      }

      // Filter out native tokens from the asset list if `hideNativeAssets` is true, otherwise return all assets for the selected network
      const selectedNetworkAssets = hideNativeAssets
        ? assets.get(selectedNetwork)?.filter((asset) => {
            const isNativeToken = asset.address === GAS_TOKEN_IDENTIFIER;
            return !isNativeToken;
          })
        : assets.get(selectedNetwork);

      if ((!searchInput || searchInput.length < 1) && selectedNetworkAssets) {
        setFilteredAssets(selectedNetworkAssets);
        return;
      }
      if (isAddress(searchInput)) {
        catalyst.getToken(selectedNetwork, searchInput).then((tokenInfo) => {
          if (tokenInfo) {
            setFilteredAssets([tokenInfo]);
          }
        });
      } else if (selectedNetworkAssets) {
        const filteredAssets = selectedNetworkAssets.filter((a) =>
          a.symbol.toLowerCase().includes(searchInput.toLowerCase()),
        );
        setFilteredAssets(filteredAssets);
      }
    }, [searchInput, assets, selectedNetwork]);

    useEffect(() => {
      if (chainSelectMode !== ChainSelectMode.Target || !sourceNetwork || !sourceAsset) {
        return;
      }

      const fetchValidTargetAssets = async () => {
        try {
          // Initialize valid target assets map
          const validTargetAssets = new Map<string, TokenInfo[]>();
          options.forEach((chainId) => {
            validTargetAssets.set(chainId, []);
          });

          // Get valid target tokens from the source network, asset address
          const validTargetTokens = await store.client.tokens.getTokenOutputs(sourceNetwork, sourceAsset.address);
          const validTargetTokenInfo = validTargetTokens.map((token): TokenInfo => {
            const isGasToken = token.address === GAS_TOKEN_IDENTIFIER;
            return {
              ...token,
              chainId: Number(token.chainId),
              // Cleaning up the token name and symbol
              name: isGasToken ? 'Ether' : 'Wrapped Ether',
              symbol: isGasToken ? 'ETH' : 'WETH',
            };
          });

          // Update valid target assets map with the fetched target tokens
          validTargetTokenInfo.forEach((token) => {
            const chainId = token.chainId.toString();
            const targetTokens = validTargetAssets.get(chainId) ?? [];
            if (token.address === GAS_TOKEN_IDENTIFIER) {
              targetTokens.unshift(token); // Add gas token at the beginning
            } else {
              targetTokens.push(token); // Add other tokens after gas token
            }
            validTargetAssets.set(chainId, targetTokens);
          });

          setAssets(validTargetAssets);
        } catch (error) {
          console.error('Error fetching valid target assets', error);
          return;
        }
      };
      fetchValidTargetAssets();
    }, [sourceNetwork, sourceAsset]);

    useEffect(() => {
      const fetchAssets = async () => {
        // Skip fetching assets if they are already loaded for the selected network
        if (assets.has(selectedNetwork)) {
          return;
        }

        const tokenList = catalyst.getTokenList(selectedNetwork);
        const tokenListFromDB = await store.client.tokens.getChainTokens(selectedNetwork);
        const tokenInfoListFromDB = tokenListFromDB.map(
          (t) => ({ ...t, chainId: Number(selectedNetwork) } as TokenInfo),
        );
        const newTokensFromDB = tokenInfoListFromDB.filter((t) => !tokenList.some((tl) => tl.address === t.address));
        const allAssets = [...tokenList, ...newTokensFromDB];
        return setAssets((oldAssets) => {
          const newAssets = new Map(oldAssets);
          newAssets.set(selectedNetwork, allAssets ?? []);
          return newAssets;
        });
      };
      fetchAssets();

      // Prevent updating the chain balances if the last balance API call for the selected network was made less than 5 seconds ago
      if ((apiCallTimestampRef.current.get(selectedNetwork) ?? 0) + 5000 > Date.now()) {
        return;
      }

      // Updates the timestamp for the last balance API call for the selected network to the current time
      apiCallTimestampRef.current.set(selectedNetwork, Date.now());
      wallet.updateChainBalances([selectedNetwork]);
    }, [selectedNetwork]);

    function closeSelector() {
      runInAction(() => (swap.chainSelectMode = ChainSelectMode.None));
      onClose();
    }

    function finalizeSelection(selectedAsset: TokenInfo) {
      if (onSelect) {
        setPreviouslySelectedAsset(selectedAsset);
        setPreviouslySelectedChainId(selectedNetwork);
        onSelect(selectedNetwork, selectedAsset);
      }
      closeSelector();
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
      setSearchInput(e.target.value);
    }

    return (
      <Modal open={open} onClose={closeSelector}>
        <div className="mx-3 flex w-full flex-col gap-5 md:mx-0 md:w-[552px]">
          <div className="flex flex-col items-center justify-between rounded-3xl bg-white p-5">
            <div className="flex w-full flex-row items-center justify-between">
              <div className="font-semibold text-sm uppercase text-black">Network</div>
              <div className="flex items-center space-x-1 rounded-3xl">
                {options.slice(0, TOKEN_SELECTOR_PANE_NO_OF_TOKENS_TO_DISPLAY).map((o, index) => (
                  <Tooltip
                    key={`${o}-input-select`}
                    tooltip={getChainConfig(o).name}
                    tooltipStyle="min-w-[120px] p-[8px] rounded-lg"
                  >
                    <div
                      className={' flex items-center justify-center rounded-full bg-white'}
                      onClick={() => setSelectedNetwork(o)}
                      data-testid={`network-pane-item-${index}`}
                    >
                      <img className="h-8 w-8 rounded-full" src={`/img/${o.toLowerCase()}_bright.webp`} />
                    </div>
                  </Tooltip>
                ))}
                {options.length > TOKEN_SELECTOR_PANE_NO_OF_TOKENS_TO_DISPLAY && (
                  <div className={'flex h-8 w-8 items-center justify-center rounded-full bg-[#D8DDF4]'}>
                    <span className="font-bold text-xs text-primary-600">
                      +{options.length - TOKEN_SELECTOR_PANE_NO_OF_TOKENS_TO_DISPLAY}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center">
              <SelectNetwork
                networks={options}
                selectedNetwork={selectedNetwork}
                onSelect={(network) => {
                  setSelectedNetwork(network);
                }}
              />
            </div>
          </div>
          {selectedNetwork && (
            <div className="">
              <div className="flex flex-col items-start justify-between gap-2 rounded-t-3xl border-b-2 border-gray-100 bg-white p-5">
                <div className="font-semibold text-sm uppercase text-black">Select Token</div>
                <div className="hover:[not(:focus-within):border-gray-400] group flex w-full items-center rounded-lg border border-gray-300 focus-within:border-indigo-500">
                  <Input
                    type="search"
                    className={'peer order-2 h-[42px] min-w-0 rounded-lg pl-1 pr-3 text-sm'}
                    placeholder="Search token or enter address"
                    value={searchInput}
                    onChange={handleInputChange}
                  />
                  <MagnifyingGlassIcon
                    className="order-1 ml-3 mt-0.5 flex h-5 w-5 text-gray-400 peer-focus:text-indigo-500"
                    strokeWidth={3}
                  />
                  {searchInput.length > 0 && (
                    <XMarkIcon
                      className="order-3 mr-3 mt-0.5 flex h-5 w-5 cursor-pointer text-gray-400 peer-focus:text-indigo-500"
                      onClick={() => setSearchInput('')}
                      strokeWidth={3}
                    />
                  )}
                </div>
              </div>
              <div className="max-h-96 space-y-3 overflow-y-scroll rounded-b-3xl bg-white p-5 [&::-webkit-scrollbar]:hidden">
                {assets.get(selectedNetwork)?.length === 0 ? (
                  <div className="flex h-[7.25rem] w-full items-center justify-center">
                    <span className="text-sm">No tokens available on {selectedNetworkName} Network.</span>
                  </div>
                ) : filteredAssets.length === 0 ? (
                  <div className="flex w-full flex-col items-center gap-4">
                    <span className="flex w-full justify-center text-sm">No tokens matched your search.</span>
                    <button
                      onClick={() => {
                        setSearchInput('');
                      }}
                      className="rounded-[18px] bg-primary px-2 py-1 text-xs text-white"
                    >
                      Clear Search
                    </button>
                  </div>
                ) : (
                  filteredAssets.map((a, index) => {
                    const assetBalance = wallet.getBalance(a.chainId.toString(), a.address);
                    const visualBalance = formatBalance(assetBalance.amount, a.decimals);
                    const assetValue = catalyst.getPrice(a) * visualBalance;
                    const isPreviouslySelected =
                      previouslySelectedChainId === a.chainId.toString() &&
                      previouslySelectedAsset?.address === a.address;
                    return (
                      <div
                        key={`${a.chainId}-${a.address}-input-select`}
                        className={`flex cursor-pointer items-center rounded-lg border-gray-300 bg-white px-4 py-3 hover:border-gray-400 md:h-[52px] md:py-4 ${
                          isPreviouslySelected ? 'border-2 border-primary-600' : 'border'
                        }`}
                        onClick={() => finalizeSelection(a)}
                        data-testid={`token-item-${index}`}
                      >
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <TokenDisplay
                              chainId={a.chainId.toString()}
                              token={a.address}
                              showChain={false}
                              showChainIcon={false}
                              showSymbol={false}
                              showTooltip={false}
                            />
                            <span className="ml-2">{a.symbol}</span>
                          </div>
                          <div className="flex flex-row text-xs">
                            <span className="mr-2 text-xs opacity-60">Balance</span>
                            <span className="mr-[2px] font-semibold">{formatTokenBalance(visualBalance)}</span>
                            <span className="font-semibold">
                              {a.symbol} / {formatCurrency(assetValue)}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  },
);

export default ChainSelector;
