export interface TokenRatioProps {
  assetSymbol: string;
}

export function TokenRatioDetails({ assetSymbol }: TokenRatioProps) {
  return (
    <div className="absolute bottom-10 left-1/2 z-10 flex h-[144px] w-[343px] -translate-x-1/2 flex-col justify-around rounded-[25px] bg-white p-6 text-sm text-grey-900 shadow">
      <span className="font-subheader text-primary">Current ratio / Optimal ratio</span>
      <span>
        Comparison between the current ratio of {assetSymbol.toUpperCase()} in this deposit and the optimal ratio
        defined by the pool settings. Optimal token distribution means low price impact.
      </span>
    </div>
  );
}

export default TokenRatioDetails;
