import { observer } from 'mobx-react-lite';

import { TableHeader } from '~/modules/common/components';
import { TransactionSortCriteria } from '~/modules/transactions/enums';
import { useVaultInteractionListHeader } from '~/modules/transactions/hooks';

export const VaultInteractionListHeader = observer(() => {
  const { sortCriteria, setSortCriteria, sortDirection, setSortDirection } = useVaultInteractionListHeader();

  return (
    <>
      <TableHeader
        key="date"
        title="Date"
        className="col-span-1"
        isActive={sortCriteria === TransactionSortCriteria.Date}
        sort={sortCriteria}
        setSort={setSortCriteria}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <div key="type" className="col-span-1 flex items-center">
        TYPE
      </div>
      <div key="pool" className="col-span-2 flex items-center">
        POOL
      </div>
      <div key="amount" className="col-span-2 flex items-center">
        AMOUNT
      </div>
      <div key="total-liquidity" className="col-span-2 flex items-center">
        TOTAL LIQUIDITY
      </div>
    </>
  );
});
