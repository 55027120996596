import { getChainGasToken } from '@catalabs/catalyst-chain-lists';
import { useContext, useState } from 'react';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useNavigate } from 'react-router-dom';

import { SHOW_TRANSACTION_HISTORY, SHOW_USER_PORTFOLIO } from '~/config/environment';
import { CatalystNetwork } from '~/config/network';
import { routes } from '~/config/routes';
import { copyToClipboard, openNewTab } from '~/config/utils';
import { ReactComponent as Copy } from '~/img/copy.svg';
import { ReactComponent as Disconnect } from '~/img/disconnect.svg';
import { ReactComponent as Explorer } from '~/img/explorer.svg';
import { Button, StoreContext } from '~/modules/common';
import { ProfileDropdownTooltip } from '~/modules/common/components/Tooltip';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';

const iconStyle =
  'cursor-pointer bg-grey-100 hover:bg-primary hover:text-white rounded-full p-[10px] h-[42px] w-[42px]';

export const ProfileDropDown = ({ setshowDropDown }: { setshowDropDown(show: boolean): void }) => {
  const navigate = useNavigate();
  const { wallet, swap } = useContext(StoreContext);

  const network = swap.sourceNetwork ? CatalystNetwork.getCatalystNetwork(swap.sourceNetwork) : undefined;
  const { address, displayAddress } = wallet;
  const asset = network?.getAsset(getChainGasToken(network.config.chainId));
  const { disconnect } = useConnectButton();
  const [copyTooltip, setCopyTooltip] = useState('Copy Address');

  return (
    <div className="z-50 mt-3 scale-0 transition-all group-hover/profile:scale-100">
      <div className="absolute right-0 w-[calc(100vw-20px)] rounded-[25px] bg-white shadow-2xl md:w-[260px]">
        <div className="flex flex-col items-center p-[24px]">
          <Jazzicon diameter={48} seed={jsNumberForAddress(address || '')} />
          <div className="mb-[8px] font-semibold">{displayAddress}</div>

          {asset && (
            <div className="mb-[16px] flex items-center gap-[4px] text-center">
              <img
                alt={`${asset.symbol}`}
                className="h-5 w-5 rounded-full"
                src={`/img/${network?.config.chainId.toLowerCase()}_bright.webp`}
              />
              <div className="text-[12px] text-grey">{network?.config.name} </div>
            </div>
          )}

          <div className="flex w-[156px] items-center justify-center gap-[20px] text-primary">
            <ProfileDropdownTooltip content={copyTooltip}>
              <Copy
                className={iconStyle}
                onClick={async () => {
                  setCopyTooltip('Copied!');
                  setTimeout(() => {
                    setCopyTooltip('Copy Address');
                  }, 1000);
                  copyToClipboard(address ?? '');
                }}
              />
            </ProfileDropdownTooltip>
            {network && address && (
              <ProfileDropdownTooltip content="View On Explorer">
                <Explorer
                  className={iconStyle}
                  onClick={() => {
                    openNewTab(network.getAccountUrl(address));
                  }}
                />
              </ProfileDropdownTooltip>
            )}
            <ProfileDropdownTooltip content="Disconnect">
              <Disconnect
                className={iconStyle}
                onClick={async () => {
                  await disconnect();
                  setshowDropDown(false);
                }}
              />
            </ProfileDropdownTooltip>
          </div>
        </div>

        {SHOW_USER_PORTFOLIO || SHOW_TRANSACTION_HISTORY ? (
          <>
            <hr className="w-full text-grey-200" />
            <div className="flex flex-col gap-y-3 p-[24px]">
              {SHOW_USER_PORTFOLIO && (
                <Button
                  size="small"
                  fullWidth
                  className="mt-0 px-[10px]"
                  onClick={() => navigate(routes.userPortfolio)}
                >
                  Portfolio
                </Button>
              )}
              {SHOW_TRANSACTION_HISTORY && (
                <Button
                  size="small"
                  fullWidth
                  className="mt-0 px-[10px]"
                  onClick={() => navigate(routes.transactionHistory)}
                >
                  Transactions
                </Button>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
