import localForage from 'localforage';

export const tokens = localForage.createInstance({
  name: 'Catalyst',
  storeName: 'tokens',
});

export const poolCreateRequests = localForage.createInstance({
  name: 'Catalyst',
  storeName: 'poolCreateRequests',
});

export const poolCreateConfig = localForage.createInstance({
  name: 'Catalyst',
  storeName: 'poolCreateConfig',
});

export const poolWithdrawRequests = localForage.createInstance({
  name: 'Catalyst',
  storeName: 'poolWithdrawRequests',
});

export const poolDepositRequests = localForage.createInstance({
  name: 'Catalyst',
  storeName: 'pendingDepositRequests',
});

const tosAttestation = localForage.createInstance({
  name: 'Catalyst',
  storeName: 'tosAttestation',
});

export const db = {
  tokens,
  poolCreateRequests,
  poolCreateConfig,
  poolWithdrawRequests,
  poolDepositRequests,
  tosAttestation,
};
