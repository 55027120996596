import { CatalystApiClient } from '@catalabs/catalyst-api-client';

import { CLIENT_API_URL } from '~/config/environment/environment.utils';
import { CatalystStore } from '~/modules/common/store/catalyst-store';
import { Store } from '~/modules/common/store/interfaces';
import { NotificationStore } from '~/modules/common/store/notification-store';
import { WalletStore } from '~/modules/common/store/wallet-store';
import { LobbyStore } from '~/modules/lobby';
import { PoolStore } from '~/modules/pools/store/pool-store';
import { SwapStore } from '~/modules/swap';

import { ReferralStore } from './referral-store';

/**
 * Root store acts as app connection hub.
 * All stores are allowed cross store access.
 */
export class RootStore implements Store {
  readonly client: CatalystApiClient;

  readonly swap: SwapStore;
  readonly catalyst: CatalystStore;
  readonly wallet: WalletStore;
  readonly pool: PoolStore;
  readonly notification: NotificationStore;
  readonly referral: ReferralStore;
  readonly lobby: LobbyStore;

  constructor() {
    this.client = new CatalystApiClient({
      baseURL: CLIENT_API_URL,
      timeout: 5_000,
    });
    this.swap = new SwapStore(this);
    this.catalyst = new CatalystStore(this);
    this.wallet = new WalletStore(this);
    this.pool = new PoolStore(this);
    this.notification = new NotificationStore(this);
    this.referral = new ReferralStore(this);
    this.lobby = new LobbyStore(this);
    this.refresh();
  }

  async refresh() {
    await Promise.all([this.catalyst.updatePrices()]);
  }

  async reset() {
    await this.swap.reset();
    await this.pool.reset();
    await this.referral.reset();
  }
}

export const store = new RootStore();

export default store;
