import { useContext } from 'react';

import { StoreContext } from '~/modules/common';
import { usePools } from '~/modules/pools';
import { LpType } from '~/modules/portfolio/enums';

interface UsePortfolioListProps {
  selectedTab: LpType;
}

export const usePortfolioList = ({ selectedTab }: UsePortfolioListProps) => {
  const { wallet: walletStore, pool: poolStore, catalyst: catalystStore } = useContext(StoreContext);
  const portfolioStore = walletStore.portfolio;
  const { stakedLpCount, nonStakedLpCount, stakedLps, nonStakedLps, isNonStakedLpsLoading, isStakedLpsLoading } =
    portfolioStore;

  const { allCatalystPools: pools } = usePools(poolStore, catalystStore);
  const poolAssetsById = new Map(
    pools.map((pool) => [pool.id, pool.assets.map((asset) => ({ chain: asset.chainId, address: asset.address }))]),
  );

  const hasNonStakedLps = selectedTab === LpType.NON_STAKED && nonStakedLpCount > 0;
  const hasStakedLps = selectedTab === LpType.STAKED && stakedLpCount > 0;

  const hasLpsToDisplay = hasStakedLps || hasNonStakedLps;
  const displayedLps = hasNonStakedLps ? nonStakedLps : hasStakedLps ? stakedLps : [];
  const displayedLpsCount = hasNonStakedLps ? nonStakedLpCount : hasStakedLps ? stakedLpCount : 0;
  const isLoadingLps =
    (selectedTab === LpType.NON_STAKED && isNonStakedLpsLoading) ||
    (selectedTab === LpType.STAKED && isStakedLpsLoading);

  const fetchLpsOnScroll = async () => {
    if (displayedLps.length === 0 || displayedLps.length === displayedLpsCount) {
      // Do not fetch before initial load or when all swaps are fetched
      return;
    }

    if (selectedTab === LpType.NON_STAKED) {
      await portfolioStore.fetchNonStakedLps();
    } else {
      await portfolioStore.fetchStakedLps();
    }
  };

  return {
    hasLpsToDisplay,
    displayedLps,
    isLoadingLps,
    poolAssetsById,
    fetchLpsOnScroll,
  };
};
