import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { ReactComponent as Header } from '~/img/pools_bg.svg';
import { ReactComponent as HeaderMobile } from '~/img/pools_bg_mobile.svg';
import { formatCurrency, PageHeader, SortDirection, Table, TableHeader } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { NetworkFilter, PoolsListItem, TokenFilter } from '~/modules/pools/components';
import { CreatePoolButton } from '~/modules/pools/components/CreatePoolButton';
import { PoolsListItemMobile } from '~/modules/pools/components/PoolsListItemMobile';
import { TokensNetworksFilterDisplay } from '~/modules/pools/components/TokensNetworksFilterDisplay';
import { PoolsSort } from '~/modules/pools/enums';
import { usePools } from '~/modules/pools/hooks';

export const PoolsList = observer(() => {
  const {
    catalyst,
    pool: poolStore,
    wallet: { account },
  } = useContext(StoreContext);
  const {
    catalystPools,
    allPoolsDisplayed,
    filteredNetworks,
    filteredTokens,
    poolsSort,
    poolsSortDirection,
    pagesLoaded,
    handlers: {
      setPoolsSort,
      setPoolsSortDirection,
      setPagesLoaded,
      clearFilters,
      clearNetwork,
      clearToken,
      navigateToCreate,
    },
  } = usePools(poolStore, catalyst);

  return (
    <div className="mx-3 flex flex-col items-center">
      <PageHeader
        title="Pools"
        HeaderImage={Header}
        MobileHeaderImage={HeaderMobile}
        subTitle="Provide liquidity, earn fees, and keep the protocol running."
        total={{
          text: 'Your Total Deposited',
          value: account ? formatCurrency(account.depositedValue) : '$0.00',
        }}
      />

      {/* Desktop */}
      <div className="mt-10 hidden md:block md:w-[1224px]">
        <Table
          title={
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <NetworkFilter />
                <TokenFilter hideGasTokens />
              </div>
              <TokensNetworksFilterDisplay
                filteredTokens={filteredTokens}
                filteredNetworks={filteredNetworks}
                clearToken={clearToken}
                clearNetwork={clearNetwork}
              />
            </div>
          }
          headersPanelStyle="grid grid-cols-16"
          header={
            <PoolHeaders
              setSort={setPoolsSort}
              sort={poolsSort}
              setSortDirection={setPoolsSortDirection}
              sortDirection={poolsSortDirection}
            />
          }
          action={<CreatePoolButton onClick={navigateToCreate} />}
        >
          <div data-testid="pool-list" data-pools-lenght={catalystPools.length}>
            {catalystPools.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-9">
                <span className="mb-2">No results found</span>
                <div
                  className="cursor-pointer rounded-[18px] bg-primary px-2 text-[12px] text-white"
                  onClick={clearFilters}
                >
                  Clear filters
                </div>
              </div>
            ) : (
              catalystPools.map((p, i) => (
                <PoolsListItem
                  key={`pool-list-summary-${p.id}`}
                  pool={p}
                  final={i === catalystPools.length - 1 && allPoolsDisplayed}
                  index={i}
                />
              ))
            )}

            {!allPoolsDisplayed && (
              <div className="flex h-[52px] items-center justify-center rounded-b-[25px] border-t text-primary">
                <div className="flex cursor-pointer items-center gap-2" onClick={() => setPagesLoaded(pagesLoaded + 1)}>
                  <span>Load more</span>
                  <Caret className="-rotate-90" />
                </div>
              </div>
            )}
          </div>
        </Table>
      </div>

      {/* Mobile */}
      <div className="mt-10 w-full md:hidden">
        <CreatePoolButton onClick={navigateToCreate} />
        <div className="mt-4 flex flex-col gap-4 rounded-3xl bg-white p-5">
          <NetworkFilter />
          <TokenFilter hideGasTokens />
          <TokensNetworksFilterDisplay
            filteredTokens={filteredTokens}
            filteredNetworks={filteredNetworks}
            clearToken={clearToken}
            clearNetwork={clearNetwork}
          />
        </div>
      </div>

      <div className="my-5 w-full md:hidden">
        {catalystPools.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-9">
            <span className="mb-2">No results found</span>
            <div
              className="cursor-pointer rounded-[18px] bg-primary px-2 text-[12px] text-white"
              onClick={clearFilters}
            >
              Clear filters
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            {catalystPools.map((p) => (
              <PoolsListItemMobile key={`pool-list-summary-${p.id}`} pool={p} />
            ))}
          </div>
        )}

        {!allPoolsDisplayed && (
          <div className="flex h-[52px] items-center justify-center rounded-b-[25px] border-t text-primary">
            <div className="flex cursor-pointer items-center gap-2" onClick={() => setPagesLoaded(pagesLoaded + 1)}>
              <span>Load more</span>
              <Caret className="-rotate-90" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const PoolHeaders = ({
  sort,
  setSort,
  sortDirection,
  setSortDirection,
}: {
  sort: PoolsSort;
  setSort: (sort: PoolsSort) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
}) => {
  return (
    <>
      <span key="assets" className="col-span-5 flex items-center">
        ASSETS
      </span>
      <TableHeader
        key="Fee"
        title="fee"
        className="col-span-3"
        isActive={sort === PoolsSort.Fee}
        sort={PoolsSort.Fee}
        setSort={setSort}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <TableHeader
        key="TVL"
        title="tvl"
        className="col-span-3"
        isActive={sort === PoolsSort.TVL}
        sort={PoolsSort.TVL}
        setSort={setSort}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <TableHeader
        title="24H VOL"
        key="vol"
        className="col-span-3"
        isActive={sort === PoolsSort.Volume}
        sort={PoolsSort.Volume}
        setSort={setSort}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <TableHeader
        key="apr"
        title="APR"
        isActive={sort === PoolsSort.APR}
        sort={PoolsSort.APR}
        setSort={setSort}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
    </>
  );
};

export default PoolsList;
