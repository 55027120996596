import { TokenInfo } from '@uniswap/token-lists';

import { displayChainName, formatCurrency, formatValue } from '~/modules/common/utils/display.utils';
import { bgcolors } from '~/modules/pools/config/pool-composition-colors';

interface PoolCompositionProps {
  tokens: TokenInfo[];
  percentages: number[];
  amounts?: number[];
  title?: string;
  totalValue?: number;
  isTitleBold?: boolean;
  showPercentage?: boolean;
  showRemainder?: boolean;
}

export const PoolComposition = ({
  tokens,
  percentages,
  title = 'POOL COMPOSITION',
  amounts,
  totalValue,
  isTitleBold = true,
  showPercentage = true,
  showRemainder = true,
}: PoolCompositionProps) => {
  const total = percentages.reduce((acc, curr) => acc + curr, 0);
  const remainder = 100 - total;
  /**
   * If we don't show remainder, we need to adjust the token percentages
   * to ensure the sum of the percentages is 100.
   * We divide the remainder by the number of tokens
   * and add the quotient to each token percentage.
   */
  if (!showRemainder && remainder > 0) {
    const quotient = remainder / tokens.length;
    percentages = percentages.map((percentage) => percentage + quotient);
  }

  return (
    <div className="flex w-full flex-col gap-4 rounded-[25px] bg-white p-5 md:w-[416px]">
      <div className="flex justify-between">
        <span
          className={`flex items-center bg-opacity-15 text-sm text-grey-900  ${
            isTitleBold ? 'font-semibold font-subheader tracking-wide' : 'font-normal'
          }`}
        >
          {title}
        </span>
        {totalValue && (
          <span
            className={`flex items-center bg-opacity-15 text-sm text-grey-900  ${
              isTitleBold ? 'font-semibold font-subheader tracking-wide' : 'font-normal'
            }`}
          >
            {formatCurrency(totalValue)}
          </span>
        )}
      </div>
      <div className="w-full text-center">
        {tokens.length > 0 && (
          <div className="flex w-full flex-col gap-2">
            <div className="chart flex w-full overflow-hidden rounded-3xl">
              {tokens.map((_, index) => {
                const percentage = percentages[index];
                return (
                  <div
                    key={`pool-composition-token-${_.address}-${index}`}
                    className={`composition-bar flex h-2 flex-row items-center justify-center transition-all
                      ${bgcolors[index] || bgcolors[0]}
                    `}
                    style={{ width: `${percentage}%` }}
                  ></div>
                );
              })}
              {remainder > 0 && showRemainder && (
                <div
                  className="composition-bar flex h-2 flex-row items-center justify-center bg-gray-500 transition-all"
                  style={{ width: `${remainder}%` }}
                ></div>
              )}
            </div>
            <div>
              {tokens.map((token, index) => {
                return (
                  <div
                    key={`pool-composition-percentage-${token.address}-${index}`}
                    className="flex flex-row items-center justify-between"
                  >
                    <div className="flex flex-row items-center justify-start gap-2">
                      <div className={`h-2 w-2 rounded-full ${bgcolors[index] || bgcolors[0]}`} />
                      <span className="text-sm text-grey-900">
                        {showPercentage && `${percentages[index] || 0}%`} {token.symbol} on{' '}
                        {displayChainName(token.chainId)}
                      </span>
                    </div>
                    {amounts && (
                      <span className="text-sm text-grey-500">
                        {formatValue(amounts[index], 6)} {token.symbol}
                      </span>
                    )}
                  </div>
                );
              })}
              {remainder > 0 && showRemainder && (
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="h-2 w-2 rounded-full bg-gray-500" />
                  <span className="text-sm text-grey-900">{`${remainder}%`} Unallocated</span>
                </div>
              )}
            </div>
          </div>
        )}
        {tokens.length === 0 && percentages.length === 0 && (
          <span className="text-sm text-grey-500"> Select chain and tokens to view info</span>
        )}
      </div>
    </div>
  );
};
