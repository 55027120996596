import { createPortal } from 'react-dom';

import CompletedTxnSection from './CompletedTxnSection';
import PendingTxnSection from './PendingTxnSection';

export const Lobby = () => {
  return (
    <>
      <PendingTxnSection />
      {createPortal(<CompletedTxnSection />, document.body)}
    </>
  );
};

export default Lobby;
