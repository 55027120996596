import { observer } from 'mobx-react-lite';

import { formatCurrency, Heading } from '~/modules/common';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';
import { PortfolioList } from '~/modules/portfolio/components';
import { usePortfolioPage } from '~/modules/portfolio/hooks';

export const PortfolioPage = observer(() => {
  const { isConnected } = useConnectButton();
  const {
    totalDepositValue,
    selectedTab,
    // TODO: To be enabled when staked pools are rolled out
    // stakedLpCount,
    // nonStakedLpCount,
    // selectStakedLpsTab,
    // selectNonStakedLpsTab
  } = usePortfolioPage();

  return (
    <div className="mx-auto flex w-11/12 flex-col xl:w-[1224px]">
      <Heading text="Portfolio" className="mb-6 mt-[12vh]" />
      {isConnected && (
        <div className="mb-12 flex w-fit items-center justify-center gap-x-6 rounded-xl bg-black px-6 py-4 font-semibold text-white">
          <span>TOTAL DEPOSITED</span>
          <span>{totalDepositValue !== -1 ? formatCurrency(totalDepositValue, 2) : ''}</span>
        </div>
      )}
      {/* TODO: To be enabled when staked pools are rolled out */}
      {/* <div className="mt-8 flex gap-4">
        <Button
          size="small"
          className={cn('mt-0 w-fit gap-3 rounded-xl bg-white px-5 text-black max-[375px]:h-16', {
            'bg-black text-white': selectedTab === LpType.NON_STAKED,
          })}
          onClick={selectNonStakedLpsTab}
        >
          Non-Staked Pools <span className="font-bold">{nonStakedLpCount !== -1 && nonStakedLpCount}</span>
        </Button>

        <Button
          size="small"
          className={cn('mt-0 w-fit gap-3 rounded-xl bg-white px-5 text-black max-[375px]:h-16', {
            'bg-black text-white': selectedTab === LpType.STAKED,
          })}
          onClick={selectStakedLpsTab}
        >
          Staked Pools <span className="font-bold">{stakedLpCount !== -1 && stakedLpCount}</span>
        </Button>
      </div> */}
      <PortfolioList selectedTab={selectedTab} />
    </div>
  );
});
