/* eslint-disable unused-imports/no-unused-vars */
import { PoolAsset } from '@catalabs/catalyst-api-client';
import { Cog8ToothIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
// import { Cog8ToothIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

import { formatPercentage, Modal, Toggle, TokenDisplay, Tooltip } from '~/modules/common';

import { LIQUIDITY_SWAP_DOCS_URL } from '../../constants';

export interface OptimalDepositProps {
  onToggle: (enabled: boolean) => void;
  poolAssets: PoolAsset[];
  onToggleLiquiditySwaps: (enabled: boolean) => void;
  optimalDepositEnabled: boolean;
  liquiditySwapsEnabled: boolean;
}

export interface AdvancedSettingsProps {
  open: boolean;
  onClose: () => void;
  onToggleLiquiditySwaps: (enabled: boolean) => void;
  optimalDepositEnabled: boolean;
  liquiditySwapsEnabled: boolean;
}

export interface PoolAssetsToolTipProps {
  poolAssets: PoolAsset[];
}

const PoolAssetsToolTip = ({ poolAssets }: PoolAssetsToolTipProps) => {
  return (
    <div className="absolute bottom-8 left-1/2 flex min-w-[290px] -translate-x-1/2 flex-col gap-2 whitespace-break-spaces rounded-3xl bg-white p-6 shadow-2xl">
      <div className="flex flex-row gap-4">
        {poolAssets.map((asset, index) => (
          <div key={index} className="flex flex-row items-center">
            <TokenDisplay showChain={false} showSymbol={false} token={asset.address} chainId={asset.chainId} />
            <span className="text-sm font-normal text-gray-600">{formatPercentage(asset.weight, 0)}</span>
          </div>
        ))}
      </div>
      <div className="text-xs font-normal text-gray-800">Token amounts will respect the weights set on the pool.</div>
    </div>
  );
};

const AdvancedSettingModal = ({
  open,
  onClose,
  onToggleLiquiditySwaps,
  optimalDepositEnabled,
  liquiditySwapsEnabled,
}: AdvancedSettingsProps) => {
  const handleLiquiditySwapToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (!optimalDepositEnabled) {
      onToggleLiquiditySwaps(checked);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col">
        <div className="mb-3 font-bold text-xl tracking-wide">Advanced Settings</div>
        <div className="flex flex-col gap-3 rounded-xl bg-white p-6">
          <div className="flex items-center justify-between">
            <Toggle
              id="liquidity-swaps"
              label="Liquidity Swaps"
              onChange={handleLiquiditySwapToggle}
              disabled={optimalDepositEnabled}
              checked={liquiditySwapsEnabled}
            />
            {!optimalDepositEnabled && (
              <div className="rounded-full bg-primary-600/[0.2] px-3 py-1 text-xs text-primary-600">Recommended</div>
            )}
          </div>
          <div className="text-sm font-medium text-gray-500">
            Liquidity Swap is a security measure that swap tokens to make a more even deposit. Keep this feature ON to
            limit your risks.{' '}
            <a className="text-primary underline" target="_blank" href={LIQUIDITY_SWAP_DOCS_URL}>
              Learn more here
            </a>
          </div>
          {optimalDepositEnabled && (
            <div className="mt-2 w-full rounded-md bg-gray-200 p-2 text-center text-sm text-gray-400">
              Not available for optimal deposits
            </div>
          )}
          {!optimalDepositEnabled && !liquiditySwapsEnabled && (
            <div className="rounded-md bg-red-100 px-5 py-4 text-sm font-medium text-red-600">
              You can turn off the Liquidity Swap feature, however your position will experience changes in value based
              on liquidity trends. Furthermore, any market making costs will be attributed both on the total asset sum
              and total asset value.
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

function OptimalDeposit({
  onToggle,
  poolAssets,
  onToggleLiquiditySwaps,
  optimalDepositEnabled,
  liquiditySwapsEnabled,
}: OptimalDepositProps) {
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <Toggle
            id="optimal-deposit"
            label="Optimal Deposit"
            checked={optimalDepositEnabled}
            onChange={(e) => onToggle(e.target.checked)}
          />
          <Tooltip tooltip={<PoolAssetsToolTip poolAssets={poolAssets} />}>
            <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-400" />
          </Tooltip>
        </div>
        <div className="flex items-center gap-1">
          {!optimalDepositEnabled && !liquiditySwapsEnabled && (
            <Tooltip
              tooltip="Liquidity Swap is OFF. 
            We highly recommend it to be turned ON."
              tooltipStyle="rounded-2xl"
            >
              <ExclamationTriangleIcon className="h-5 w-5 cursor-pointer text-red-600" />
            </Tooltip>
          )}
          <Cog8ToothIcon
            className="h-5 w-5 cursor-pointer text-gray-400"
            onClick={() => setShowAdvancedSettings(true)}
          />
        </div>
      </div>
      {/* Advanced Settings Modal */}
      <AdvancedSettingModal
        open={showAdvancedSettings}
        onClose={() => setShowAdvancedSettings(false)}
        onToggleLiquiditySwaps={onToggleLiquiditySwaps}
        optimalDepositEnabled={optimalDepositEnabled}
        liquiditySwapsEnabled={liquiditySwapsEnabled}
      />
    </>
  );
}

export default OptimalDeposit;
