import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { Modal } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { CreatePoolType } from '~/modules/pools';

export interface PoolSettingsProps {
  open: boolean;
  onClose: () => void;
}

const PoolSettings = observer(({ onClose, open }: PoolSettingsProps) => {
  const { pool: poolStore } = useContext(StoreContext);

  function setPoolType(type: CreatePoolType) {
    runInAction(() => (poolStore.poolCreateType = type));
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-128 flex flex-col">
        <div className="mb-4 font-semibold text-xl">Select pool type</div>
        <div className="w-[34.5rem] space-y-4 overflow-auto rounded-2xl bg-white p-5">
          <button
            className="border-gray flex h-[80px] cursor-pointer rounded-lg border bg-white px-6 py-4 hover:border-2 hover:border-primary focus:border-2 focus:border-primary md:w-[512px]"
            onClick={() => setPoolType(CreatePoolType.CLASSIC)}
          >
            <div className="flex flex-col items-start">
              <span>Classic</span>
              <span className="text-grey-500">Good for assets that have the same price</span>
            </div>
          </button>

          <button
            className="border-gray flex h-[80px] cursor-pointer rounded-lg border bg-white px-6 py-4 hover:border-2 hover:border-primary focus:border-2 focus:border-primary md:w-[512px]"
            onClick={() => setPoolType(CreatePoolType.STABLESWAP)}
          >
            <div className="flex flex-col items-start">
              <span>Stable Swap</span>
              <span className="text-grey-500">Good for assets that should be equal in value</span>
            </div>
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default PoolSettings;
