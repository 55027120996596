import { FeeDetail } from '@catalabs/catalyst-api-client';
import { useState } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { formatCurrency, getAnimationState } from '~/modules/common/utils';

export interface SwapCostsProps {
  feeDetails: FeeDetail[];
  sufficientGas?: boolean;
}

export function SwapCosts({ feeDetails, sufficientGas = true }: SwapCostsProps): JSX.Element {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const totalCost = feeDetails.reduce((total, d) => (total += d.value), 0);

  function handleToggleDetails() {
    if (totalCost === 0) {
      return;
    }
    setShowDetails(!showDetails);
  }

  return (
    <div
      onClick={handleToggleDetails}
      className={`flex w-full flex-col items-center justify-between rounded-[20px] bg-white ${
        showDetails ? 'cursor-pointer' : ''
      }`}
    >
      <div className="flex h-[36px] w-full items-center justify-between text-[14px]">
        <div className="flex cursor-pointer items-center pl-4">
          <span className="text-gray-600">Total Cost</span>
          {totalCost > 0 && (
            <div className={`${getAnimationState(showDetails)} p-[8px]`}>
              <Caret className="rotate-90 text-gray-600" />
            </div>
          )}
        </div>

        <div className="flex">
          {!sufficientGas && (
            <div className="mr-4 rounded-[10px] bg-red-600 px-[10px] py-[2px] text-xs font-medium leading-4 text-white">
              Insufficient Balance
            </div>
          )}
          <span className="mr-4 font-semibold font-subheader leading-5">{formatCurrency(totalCost)}</span>
        </div>
      </div>

      {showDetails && (
        <div className="mb-2 flex w-full flex-col space-y-2 px-2">
          {feeDetails.map(({ name, amount, currency, value }, i) => (
            <div
              key={`fee-item-${name}-${i}`}
              className="flex items-center justify-between rounded-[15px] bg-gray-100 p-2"
            >
              <span className="flex items-center text-sm font-medium leading-4 text-grey-500">{name}</span>
              <div className="flex flex-col items-end gap-0 text-sm font-medium uppercase leading-5 md:flex-row md:items-center md:gap-4">
                {currency !== 'USD' ? (
                  <span className="flex items-center justify-end text-grey">
                    {amount.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                      maximumSignificantDigits: 3,
                    })}{' '}
                    {currency}
                  </span>
                ) : null}
                <span className="flex items-center justify-end text-grey">{formatCurrency(value, 6)}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SwapCosts;
