import { useContext, useEffect, useState } from 'react';

import { StoreContext } from '~/modules/common';
import { LpType } from '~/modules/portfolio/enums';

export const usePortfolioPage = () => {
  const { wallet } = useContext(StoreContext);
  const { totalDepositValue, stakedLpCount, nonStakedLpCount } = wallet.portfolio;

  const [selectedTab, setSelectedTab] = useState(LpType.NON_STAKED);

  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([wallet.portfolio.fetchStakedLps(), wallet.portfolio.fetchNonStakedLps()]);
    };
    initializeData();
  }, [wallet.address]);

  return {
    totalDepositValue,
    stakedLpCount,
    nonStakedLpCount,
    selectedTab,
    selectStakedLpsTab: () => setSelectedTab(LpType.STAKED),
    selectNonStakedLpsTab: () => setSelectedTab(LpType.NON_STAKED),
  };
};
