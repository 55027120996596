import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { ReactComponent as EmptyStateImage } from '~/img/loyalty_score_empty_state.svg';

import { StoreContext } from '../../common';

export const LoyaltyScore = observer(() => {
  const { referral: referralStore, wallet: walletStore } = useContext(StoreContext);

  const [loyalyScore, setLoyaltyScore] = useState('');
  const [displayDate, setDisplayDate] = useState<Date | undefined>();
  const [hasLoyaltyData, setHasLoyaltyData] = useState(false);

  useEffect(() => {
    if (referralStore.eggData && referralStore.eggData.loyaltyData.length > 0) {
      const recentData = referralStore.eggData.loyaltyData[referralStore.eggData.loyaltyData.length - 1];
      setDisplayDate(new Date(recentData.timestamp));
      setLoyaltyScore(recentData.score.toString());
      setHasLoyaltyData(referralStore.eggData.loyaltyData.length > 1);
    }
  }, [referralStore.eggData]);

  function dateHook(_: string, payload: Array<Payload<ValueType, NameType>>): React.ReactNode {
    if (payload && payload.length > 0) {
      const { score, timestamp } = payload[0].payload;
      setLoyaltyScore(score);
      setDisplayDate(new Date(timestamp));
    }
    return null;
  }

  return (
    <div className="items-cente flex h-full w-full flex-col rounded-3xl bg-primary p-6">
      <div className="flex justify-between">
        <div>
          <div className="text-xs uppercase text-white">Loyalty Score</div>
          <div className="text-lg text-primary-300">
            <span className="font-bold text-lg text-white">{loyalyScore}</span>
          </div>
        </div>
        <div className="text-[12px] text-white">{displayDate && displayDate.toDateString()}</div>
      </div>
      {(!referralStore.eggData || !hasLoyaltyData) && (
        <EmptyStateImage className={`h-full w-full ${walletStore.isConnected ? 'animate-pulse' : ''}`} />
      )}
      {referralStore.eggData && hasLoyaltyData && (
        <ResponsiveContainer width="100%" height={200}>
          <AreaChart data={referralStore.eggData.loyaltyData}>
            <defs>
              <linearGradient id={'loyalty'} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={'white'} stopOpacity={0.4}></stop>
                <stop offset="75%" stopColor={'white'} stopOpacity={0.05}></stop>
              </linearGradient>
            </defs>
            <Tooltip
              cursor={{ stroke: 'rgb(255,255,255,0.8)', strokeDasharray: '1 1' }}
              labelFormatter={dateHook}
              contentStyle={{ visibility: 'hidden' }}
            />
            <Area dataKey={'score'} stroke="rgb(255,255,255,0.8)" fill={`url(#loyalty)`} dot={true} />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
});
