import { LiquidityPosition } from '@catalabs/catalyst-api-client';
import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/16/solid';
import { runInAction } from 'mobx';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '~/config';
import { cn, formatCurrency, StoreContext, TableAssetsDisplay, TokenAmountDisplay } from '~/modules/common';
import { InfoBox, InfoBoxOptionProps } from '~/modules/navigation';
import { PoolActionType, PoolAprBreakdown } from '~/modules/pools';

interface PortfolioListItemProps {
  position: LiquidityPosition;
  poolAssets: {
    chain: string;
    address: string;
  }[];
  isLast?: boolean;
}

export const PortfolioListItem = ({ position, poolAssets, isLast = false }: PortfolioListItemProps) => {
  const navigate = useNavigate();
  const { pool: poolStore } = useContext(StoreContext);

  const depositedAssets = position.assets.filter((asset) => asset.amount > 0);

  const navigateToPool = async () => {
    if (poolStore.pool?.id !== position.poolId) {
      await poolStore.updatePools();
    }
    navigate(`${routes.pools}/${position.poolId}`);
  };

  const infoBoxOptions: InfoBoxOptionProps[] = [
    {
      title: (
        <span className="flex gap-2 px-3 py-2">
          <ArrowDownTrayIcon className="h-4 w-4" /> Deposit
        </span>
      ),
      onClick: async (e) => {
        await navigateToPool();
        runInAction(() => {
          poolStore.poolAction = PoolActionType.Deposit;
        });
        e.stopPropagation();
      },
    },
    {
      title: (
        <span className="flex gap-2 px-3 py-2">
          <ArrowUpTrayIcon className="h-4 w-4" /> Withdraw
        </span>
      ),
      onClick: async (e) => {
        await navigateToPool();
        runInAction(() => {
          poolStore.poolAction = PoolActionType.Widthdraw;
        });
        e.stopPropagation();
      },
    },
  ];

  const handlePoolItemClick = () => {
    runInAction(() => (poolStore.poolAction = PoolActionType.None));
    navigateToPool();
  };

  return (
    <div
      className={cn(
        'border-gray group grid min-h-20 grid-cols-12 border-t bg-white px-5 font-sans text-sm text-black hover:cursor-pointer hover:bg-primary hover:text-white',
        {
          'rounded-b-3xl': isLast,
        },
      )}
      onClick={handlePoolItemClick}
    >
      <span className="col-span-3 mr-4 flex items-center">
        <TableAssetsDisplay assets={poolAssets} maxTokenDisplay={8} showSymbol={false} className="my-4 gap-2" />
      </span>
      <span className="col-span-3 my-4 flex flex-col justify-center gap-1">
        {depositedAssets.map((asset) => (
          <TokenAmountDisplay
            key={`interacted-token-${asset.chain}-${asset.token}`}
            chainId={asset.chain}
            asset={asset.token}
            amount={asset.amount}
            size="sm"
          />
        ))}
      </span>
      <span className="col-span-3 flex items-center">{formatCurrency(position.value, 2)}</span>
      <span className="col-span-2 flex items-center">
        <PoolAprBreakdown pool={position} />
      </span>
      <span className="col-span-1 flex items-center">
        <InfoBox
          options={infoBoxOptions}
          triggerClassName="rotate-90 group-hover:invert"
          contentClassName="w-[10rem]"
          contentPosition={{
            side: 'top',
            sideOffset: 110,
            align: 'start',
          }}
        />
      </span>
    </div>
  );
};
