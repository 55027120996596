export const PoolCreatePageButton = ({
  enabled,
  onClick,
}: {
  enabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      className={`flex h-[78px] cursor-pointer items-center justify-center rounded-full bg-primary px-12 py-8 text-white md:w-[416px] 
            ${!enabled ? 'opacity-40' : ''}
            `}
      disabled={!enabled}
      onClick={onClick}
    >
      Create Pool
    </button>
  );
};
