import { Pool, PoolAccountBalance, Vault } from '@catalabs/catalyst-api-client';
import { getChainGasToken } from '@catalabs/catalyst-chain-lists';
import { EVM_ROUTER_ADDRESS, findOptimalWithdrawLiquiditySwaps } from '@catalabs/catalyst-sdk';
import { parseUnits } from 'ethers';

import { ChainFeeData, WithdrawRequest, WithdrawWithLiquiditySwapInputs } from '~/modules/common';

import { PoolActionState, PoolApprovalState } from '../enums';
import { ChainWithdraw, WithdrawLiquiditySwapInfoItem } from '../interfaces';
import { getAssetKey } from './pools.utils';
import { formatVaultInfoWithdraw } from './vault.utils';

export const sortAndfilterOutZeroWithdrawRequests = ({
  request,
  isLiquiditySwap,
  sourceNetwork,
}: {
  request: WithdrawRequest;
  isLiquiditySwap?: boolean;
  sourceNetwork?: string;
}): ChainWithdraw[] => {
  if (request.length === 0) {
    return [];
  }
  // if the source network is not set, set it to the first request's chainId
  const initialNetwork = sourceNetwork ?? request[0].chainId;

  // this operation creates request chunks by chainId
  // create a temp store for requests
  const requestMap = new Map<string, WithdrawRequest>();
  // for each request
  request.forEach((r) => {
    // get a chunk of the request from the temp store based on the chainId
    const requestChunk = requestMap.get(r.chainId);
    if (!requestChunk) {
      // if there's no chunk, create one
      requestMap.set(r.chainId, [r]);
    } else {
      requestChunk.push(r);
    }
  });

  // sort the request chunks by chainId
  const sortedRequests: ChainWithdraw[] = Array.from(requestMap.entries())
    .sort((a, b) => {
      const [chainIdA, _a] = a;
      const [chainIdB, _b] = b;
      if (chainIdA === initialNetwork) {
        return -1;
      }
      if (chainIdB === initialNetwork) {
        return 1;
      }
      return chainIdA.localeCompare(chainIdB);
    })
    // filter out any chunks that have no withdraws
    .filter((e) => (isLiquiditySwap ? true : e[1].some((r) => r.amount > 0n)))
    .map((e) => {
      const [chainId, request] = e;
      const [{ vault }] = request;
      return {
        vault,
        chainId,
        chainSwap: PoolActionState.Inactive,
        approval: PoolApprovalState.Inactive,
        withdraw: PoolActionState.Inactive,
        request,
      };
    });

  return sortedRequests;
};

export const findWithdrawLiquiditySwapRoute = ({
  vaults,
  poolAccountBalance,
  withdrawAmounts,
  withdrawPortion,
}: {
  vaults: Vault[];
  poolAccountBalance: PoolAccountBalance;
  withdrawAmounts: Map<string, string>;
  withdrawPortion: number;
}) => {
  const { vaultsInfo, vaultTokens, userWithdrawals } = formatVaultInfoWithdraw({
    vaults,
    poolAccountBalance,
    withdrawAmounts,
    withdrawPortion,
  });
  const quote = findOptimalWithdrawLiquiditySwaps(vaultsInfo, vaultTokens, userWithdrawals);
  const formattedQuote = formatLiquiditySwapWithdrawDetails({
    quote,
    vaults,
  });
  return formattedQuote;
};

export const getLiquiditySwapTxInputsWithdraw = ({
  address,
  vaults,
  withdrawAmounts,
  pool,
  tokenPrices,
  poolAccountBalance,
  withdrawPortion,
  chainFeeData,
}: {
  address: string;
  chainFeeData: ChainFeeData;
  vaults: Vault[];
  withdrawAmounts: Map<string, string>;
  pool: Pool;
  tokenPrices: Map<string, number>;
  poolAccountBalance: PoolAccountBalance;
  withdrawPortion: number;
}): WithdrawWithLiquiditySwapInputs => {
  const { vaultsInfo, vaultTokens, userWithdrawals } = formatVaultInfoWithdraw({
    vaults,
    poolAccountBalance,
    withdrawAmounts,
    withdrawPortion,
  });
  const vaultAddresses = vaults.map((vault) => vault.address);

  const assetsAddresses = vaults.map((vault) => vault.assets.map((asset) => asset.token));

  const userAddresses = vaults.map(() => address);

  const chains = vaults.map((vault, index) => {
    const chainId = vault.chain;
    const otherChainId = vaults[(index + 1) % vaults.length].chain;
    const channelId = pool.channels[otherChainId][chainId];
    return { chainId, channelId };
  });

  const messageVerifyGasCosts: bigint[][] = [];
  const priceOfDeliveryGas: bigint[][] = [];
  const priceOfAckGas: bigint[] = [];
  const routerAddresses: string[] = [];

  for (const vault of vaults) {
    if (!chainFeeData[vault.chain]) {
      throw new Error(`Gas data for chain ${vault.chain} not found`);
    }

    const { gasPrice, cciFeeData, maxFeePerGas, maxPriorityFeePerGas } = chainFeeData[vault.chain];

    // get the gas price, AMB cost to deliver the token to other vaults
    const messageVerifyGasCostsForVault: bigint[] = [];
    const priceOfDeliveryGasForVault: bigint[] = [];
    for (const otherVault of vaults) {
      const messageVerifyGasCostToOtherVault = BigInt(cciFeeData[otherVault.chain].messageVerifyGasCost.amount);
      messageVerifyGasCostsForVault.push(messageVerifyGasCostToOtherVault);

      const sourceVaultGasTokenKey = getAssetKey({ address: getChainGasToken(vault.chain), chainId: vault.chain });
      const sourceVaultGasTokenPrice = tokenPrices.get(sourceVaultGasTokenKey) || 0;

      const destinationVaultGasTokenKey = getAssetKey({
        address: getChainGasToken(otherVault.chain),
        chainId: otherVault.chain,
      });
      const destinationVaultGasTokenPrice = tokenPrices.get(destinationVaultGasTokenKey) || 0;

      const priceAccuracy = 100_000;
      const toGasTokenPrice = BigInt(Math.floor(destinationVaultGasTokenPrice * priceAccuracy));
      const fromGasTokenPrice = BigInt(Math.floor(sourceVaultGasTokenPrice * priceAccuracy));
      const toChainGasPrice = BigInt(gasPrice ?? '0');

      const priceOfDeliveryGasToOtherVault = (toChainGasPrice * toGasTokenPrice) / fromGasTokenPrice;
      priceOfDeliveryGasForVault.push(priceOfDeliveryGasToOtherVault);
    }
    messageVerifyGasCosts.push(messageVerifyGasCostsForVault);
    priceOfDeliveryGas.push(priceOfDeliveryGasForVault);

    // get the gas price to acknowledge the delivery
    const supportsEip1559 = BigInt(maxPriorityFeePerGas) !== 0n;
    const baseGasCost = supportsEip1559 ? maxFeePerGas : gasPrice;
    const priorityFee = supportsEip1559 ? maxPriorityFeePerGas : BigInt(parseUnits('1', 'gwei').toString());

    const totalGas = BigInt(baseGasCost) + BigInt(priorityFee);
    const priceOfAckGasForVault = (totalGas * 12n) / 10n + BigInt(parseUnits('1', 'wei').toString());
    priceOfAckGas.push(priceOfAckGasForVault);

    routerAddresses.push(EVM_ROUTER_ADDRESS);
  }

  const refundGasTo = address;

  const unWrapGas = vaults.map(() => true);
  return {
    vaults: vaultsInfo,
    vaultAddresses,
    assetsAddresses,
    userWithdrawals,
    userAddresses,
    chains,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    refundGasTo,
    userVaultTokens: vaultTokens,
    unWrapGas,
    routerAddresses,
  };
};

export const formatLiquiditySwapWithdrawDetails = ({
  quote,
  vaults,
}: {
  quote: ReturnType<typeof findOptimalWithdrawLiquiditySwaps>;
  vaults: Vault[];
}) => {
  const swapDetails: Map<string, WithdrawLiquiditySwapInfoItem> = new Map();

  for (const [_, tokenSwap] of quote.vaultTokenSwaps.entries()) {
    if (!tokenSwap) {
      continue;
    }

    if (tokenSwap.from === tokenSwap.to) {
      continue;
    }

    const fromVault = vaults[tokenSwap.from];
    const toVault = vaults[tokenSwap.to];
    const amount = tokenSwap.vaultTokens;
    const swap: WithdrawLiquiditySwapInfoItem = {
      fromVault,
      toVault,
      amount,
    };
    swapDetails.set(fromVault.chain, swap);
  }

  return swapDetails;
};
