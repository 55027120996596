import { BoltIcon } from '@heroicons/react/20/solid';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import type { OnValueChange } from 'react-number-format';

import { toUnits } from '~/config';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { AmountInput, Button, formatPercentage, formatValue, Modal, StoreContext, Toggle } from '~/modules/common';
import { InlineLink } from '~/modules/pools';

import { FAST_SWAP_DETAILS_URL } from '../constants';
import { SwapSpeed } from '../enums';

export const SwapSpeedInput = observer(() => {
  const { swap } = useContext(StoreContext);
  const { swapSpeed, fastSwapFee } = swap;
  const { swapInput, sourceAsset } = swap;

  const [open, setOpen] = useState(false);
  const [customFeeInputFocused, setCustomFeeInputFocused] = useState(false);
  const [feePercentage, setFeePercentage] = useState(fastSwapFee || 0.1);
  // fee amount is a percentage of the source swap amount
  const feeAmount = swap.lastQuote ? (swap.lastQuote.expectedOut * feePercentage) / 100 : 0;
  const [customFastSwap, setCustomFastSwap] = useState(fastSwapFee !== undefined);
  const handleClose = () => {
    if (customFastSwap && feePercentage === 0) {
      setFeePercentage(0.1);
      runInAction(() => {
        swap.fastSwapFee = 0.1;
      });
    }
    if (swapInput && sourceAsset) {
      swap.quote(toUnits(swapInput, sourceAsset.decimals));
    }
    setOpen(false);
  };
  const handleOpen = () => setOpen(true);

  const handleCustomFastSwapToggle = () => {
    setCustomFastSwap(!customFastSwap);
  };

  useEffect(() => {
    if (!customFastSwap) {
      setFeePercentage(fastSwapFee || 0.1);
    }
  }, [customFastSwap]);

  const handleCustomFeeInputChange: OnValueChange = (values) => {
    const fee = values.value ? Number(values.value) : 0;
    setFeePercentage(fee);
  };

  const handleSwapSpeedChange = (speed: SwapSpeed) => {
    runInAction(() => {
      swap.swapSpeed = speed;
    });
    if (speed === SwapSpeed.REGULAR) {
      setCustomFastSwap(false);
    }
    handleClose();
  };

  const handleSaveButtonClick = () => {
    runInAction(() => {
      swap.swapSpeed = SwapSpeed.FAST;
      swap.fastSwapFee = feePercentage;
    });
    handleClose();
  };

  const feeText =
    swapSpeed === SwapSpeed.FAST
      ? `${formatPercentage(feePercentage)} • ${formatValue(feeAmount, 6)} ${swap.sourceAsset?.symbol || ''}`
      : 'No Fee';
  return (
    <>
      <div className="flex w-full items-center justify-between rounded-3xl bg-white px-4 py-2 md:w-1/2">
        <div className=" flex items-center gap-2 text-[14px] leading-5 text-gray-600">
          {swapSpeed === SwapSpeed.FAST && (
            <div className="flex items-center gap-2 text-primary">
              <BoltIcon className="h-3 w-3" /> <span>Fast Swap</span>
            </div>
          )}
          {swapSpeed === SwapSpeed.REGULAR && (
            <>
              <BoltIcon className="h-3 w-3" />
              <span>Regular Swap</span>
            </>
          )}
        </div>
        <div
          onClick={handleOpen}
          className="font-xs flex cursor-pointer items-center justify-center rounded-[18px] bg-primary px-2 py-1 text-white"
        >
          <span className="mr-[8px] text-xs">{feeText}</span>
          <Caret className="-rotate-90" />
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div className="mx-3 flex w-full flex-col md:mx-auto md:w-fit">
          <div className="mb-4 font-semibold text-xl">Swap Speed</div>
          <div className="flex flex-col gap-3 rounded-[25px] bg-white p-5 md:w-[552px]">
            <div
              className={`flex cursor-pointer flex-col gap-2 rounded-xl border-2 p-4 ${
                swapSpeed === SwapSpeed.FAST ? 'border-primary' : 'border-gray-200 hover:bg-gray-100'
              }`}
              onClick={() => handleSwapSpeedChange?.(SwapSpeed.FAST)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 text-sm text-primary">
                  <BoltIcon className="h-3 w-3" /> <span className="font-semibold ">Fast Swap</span>
                </div>
                <div className="flex items-center">
                  <Toggle
                    label="Custom"
                    id="swap-speed-fast-swap-toggle"
                    onChange={handleCustomFastSwapToggle}
                    checked={customFastSwap}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
              {!customFastSwap && (
                <div>
                  <span className="text-sm text-gray-500">
                    Additional Fee: {formatPercentage(feePercentage)} •{' '}
                    {`${formatValue(feeAmount, 5)} ${swap.sourceAsset?.symbol || ''}`}
                  </span>
                </div>
              )}
              {customFastSwap && (
                <div className="flex flex-col gap-2">
                  <span className="text-sm text-gray-500">Set a custom fee:</span>
                  <div
                    className={`flex items-center justify-between rounded-lg border-[1.5px] p-2 text-sm ${
                      customFeeInputFocused ? 'border-primary' : 'border-gray-200'
                    }`}
                  >
                    <AmountInput
                      className="h-full w-full p-1"
                      placeholder="0.1%"
                      value={feePercentage === 0 ? '' : feePercentage}
                      onValueChange={handleCustomFeeInputChange}
                      onFocus={() => setCustomFeeInputFocused(true)}
                      onBlur={() => setCustomFeeInputFocused(false)}
                      suffix="%"
                      isAllowed={(values) => {
                        const { floatValue, value } = values;
                        return value === '' || (Number(floatValue) >= 0 && Number(floatValue) <= 100);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className="w-2/5 text-nowrap text-right text-sm text-gray-500">{`${formatValue(
                      feeAmount,
                      6,
                    )} ${swap.sourceAsset?.symbol || ''}`}</div>
                  </div>
                  <Button
                    fullWidth
                    size="small"
                    label="Save"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSaveButtonClick();
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`flex  cursor-pointer flex-col gap-1 rounded-xl border-2 p-4
            ${swapSpeed === SwapSpeed.REGULAR ? 'border-primary' : 'border-gray-200 hover:bg-gray-100'}`}
              onClick={() => handleSwapSpeedChange?.(SwapSpeed.REGULAR)}
            >
              <div className="font-semibold text-sm ">Regular Swap ~ 20min</div>
              <div className="text-sm text-gray-500">No Additional Fee</div>
            </div>
            <div className="text-sm text-gray-500">
              Fast Swap allows you to increase transaction speed across chains by relying on third-party solvers. Read
              more about it <InlineLink href={FAST_SWAP_DETAILS_URL} text="here" /> .
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});
