import { db } from '~/modules/common/db';
import { PoolCreateConfig, PoolCreateRequest } from '~/modules/pools';

/**
 * This function is used to deserialize the pool create requests from the database.
 * We use the assetAmounts and assetValues as a Map<string, BigNumber> in the app.
 * However IndexedDB stores Maps as an array of arrays.
 * So, to use them in the app, we need to convert them back to a Map.
 *
 * @param requests : any[]
 * @returns An array of the pool requests: PoolCreateRequest[]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deserializePoolCreateRequests(requests: any[]): PoolCreateRequest[] {
  const hydratedRequests = requests.map((r) => {
    return {
      ...r,
      deployVaultRequest: {
        ...r.deployVaultRequest,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        assetAmounts: new Map<string, bigint>(r.deployVaultRequest.assetAmounts as any),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        assetValues: new Map<string, bigint>(r.deployVaultRequest.assetValues as any),
      },
    };
  });
  return hydratedRequests;
}

/**
 * This function gets the saved pool create config from the database.
 * This data is used on the pool create page to configure the pool before creation.
 * @returns PoolCreateConfig object, that contain the pending config of the pool.
 */
export async function getSavedPoolCreateState(address?: string) {
  if (!address) {
    return null;
  }
  const poolCreateConfig = await db.poolCreateConfig.getItem<PoolCreateConfig>(address);
  return poolCreateConfig;
}

/**
 * This function gets the pending pool create requests from the database.
 * @returns PoolCreateRequest[] An array of the pool requests.
 */
export async function getPendingPoolCreatePoolRequests(address?: string) {
  if (!address) {
    return [];
  }

  const requests = (await db.poolCreateRequests.getItem<PoolCreateRequest[]>(address)) || [];

  return deserializePoolCreateRequests(requests);
}

/**
 * This checks the db if there are any pending pool create requests.
 * @returns boolean
 */
export async function hasPendingPoolCreatePoolRequests(address?: string) {
  const poolCreateRequests = await getPendingPoolCreatePoolRequests(address);
  return Boolean(poolCreateRequests.length > 0);
}
