import { SHOW_REWARDS } from '~/config';
import { routes } from '~/config/routes';
import { NavItem, NavLink } from '~/modules/navigation/interfaces/nav-item.interface';

export const NAV_ITEMS: NavItem[] = [
  { title: 'Swap', href: routes.swap, matches: ['/', ''] },
  { title: 'Pools', href: routes.pools, matches: ['/create', '/pools/*'] },
  ...(SHOW_REWARDS ? [{ title: 'Rewards', href: routes.rewards, matches: ['/rewards'] }] : []),
];

export const NAV_LINKS: NavLink[] = [
  { title: 'GitHub', href: 'https://github.com/catalystdao' },
  { title: 'Terms of Service', href: routes.termsOfService },
  { title: 'Privacy Policy', href: routes.privacyPolicy },
];
