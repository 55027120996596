import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ReactComponent as ActiveItem } from '~/img/circle.svg';
import { ReactComponent as PendingItem } from '~/img/emptycircle.svg';
import { Modal } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import { SwapStep } from '~/modules/swap';

export const SwapApprovalToPermit2 = observer(() => {
  const store = useContext(StoreContext);
  const { swap } = store;

  const open = swap.swapStep === SwapStep.ApprovalToPermit2;

  function onClose() {
    swap.resetSwap();
  }

  return (
    <Modal onClose={onClose} open={open}>
      <div className="flex flex-col items-center">
        <div className="rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="flex">
            <ActiveItem />
            <div className="flex w-3/4 flex-col pl-4">
              <span className="font-semibold text-[12px] uppercase text-primary">
                Approve access to your {swap.sourceAsset?.symbol} tokens
              </span>
              <span className="text-[14px] text-grey-500">
                Permit2 smart contract needs permission to access your tokens. You only need to approve once per token.
              </span>
            </div>
          </div>
          <div className="approval mt-6 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            Waiting for approval on your wallet
          </div>
        </div>
        <div className="mt-4 rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="flex">
            <PendingItem />
            <div className="flex flex-col pl-4">
              <span className="font-semibold text-[12px] uppercase text-grey-500">
                Sign the permit to authorize {swap.sourceAsset?.symbol} token transfer
              </span>
              <span className="text-[14px] text-grey-600">
                Authorize the token transfer by signing the permit. You only need to sign once every 30 days.
              </span>
            </div>
          </div>
        </div>
        <div className="mt-4 rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="flex">
            <PendingItem />
            <div className="flex flex-col pl-4">
              <span className="font-semibold text-[12px] uppercase text-grey-500">Sign the transaction</span>
              <span className="text-[14px] text-grey-600">This is the last approval before you start the swap.</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default SwapApprovalToPermit2;
