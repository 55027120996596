import { useState } from 'react';

import { SHOW_MISSIONS } from '~/config';
import { ReactComponent as Arrow } from '~/img/arrow.svg';
import { ReactComponent as Close } from '~/img/close.svg';
import { InlineLink } from '~/modules/pools';

export function WelcomePopUp() {
  const [display, setDisplay] = useState(true);

  if (!display) {
    return null;
  }

  return (
    <div className="absolute z-20 h-full w-full bg-primary bg-opacity-10 backdrop-blur-sm md:static md:z-0 md:bg-transparent md:backdrop-blur-none">
      <div className="absolute bottom-[38px] z-10 mx-3 flex flex-col rounded-[25px] bg-white shadow md:right-[36px] md:mx-0 md:w-[314px]">
        <div className="flex justify-end px-5 pt-5">
          <div className="cursor-pointer" onClick={() => setDisplay(false)} data-testid="close-welcome-pop-up-button">
            <Close className="text-grey-400" />
          </div>
        </div>
        <div className="flex flex-grow flex-col px-8">
          <div className="font-header text-xl leading-tight text-primary">
            Welcome <br />
            to the Tiger Testnet!
          </div>
          <div className="mb-6 mt-2 flex flex-col space-y-2 text-sm font-normal text-gray-600">
            <div>
              By proceeding you agree to the <InlineLink text="Terms of Service" href="/terms-of-service" /> and
              acknowledge that you had read and understand the app{' '}
              <InlineLink text="Privacy Policy" href="/privacy-policy" />.
            </div>
            {SHOW_MISSIONS && <span>Not sure where to start?</span>}
          </div>
          {SHOW_MISSIONS && (
            <div className="mt-8 flex flex-grow items-end justify-center">
              <div
                onClick={() => window.open('https://galxe.com/catalyst/', '_blank')}
                className="flex h-[53px] w-full cursor-pointer items-center justify-between rounded-[25px] bg-primary px-5 py-4 text-white md:w-[250px]"
              >
                <span className="text-sm font-medium">Check Out Missions</span>
                <Arrow className="text-grey-100" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WelcomePopUp;
