import { Pool, PoolAsset } from '@catalabs/catalyst-api-client';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { CatalystNetwork } from '~/config/network';
import { StoreContext } from '~/modules/common';
import { useTokenDisplay } from '~/modules/common/hooks/useTokenDisplay';
import { formatCurrency, formatPercentage, formatTokenBalance } from '~/modules/common/utils/display.utils';
import { POOL_BREAKDOWN_COLORS } from '~/modules/pools/utils';

export interface PoolAssetItemProps {
  pool: Pool;
  asset: PoolAsset;
  index: number;
}

export const PoolAssetItem = observer(({ pool, asset, index }: PoolAssetItemProps) => {
  const { catalyst } = useContext(StoreContext);
  const { tvl } = pool;
  const { chainId, address, value, amount } = asset;
  const { symbol } = useTokenDisplay({ catalystStore: catalyst, chainId, token: address });
  return (
    <div key={`pool-breakdown-${chainId}-${address}`} className="flex items-center gap-4">
      <div className="flex items-center gap-1 md:w-full" style={{ minWidth: '64px' }}>
        <div className={`h-2 w-2 rounded-full`} style={{ backgroundColor: POOL_BREAKDOWN_COLORS[index] }} />
        <span>{formatPercentage((value / tvl) * 100)}</span>
      </div>
      <span className="w-full">
        {formatTokenBalance(amount)} {symbol}
      </span>
      <span className="hidden md:block md:w-full">{formatCurrency(value)}</span>
      <span className="whitespace-nowrap md:w-full">{CatalystNetwork.getCatalystNetwork(chainId).config.name}</span>
    </div>
  );
});

export default PoolAssetItem;
