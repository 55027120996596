import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';

import { cn, getNetworkName } from '~/modules/common';

export interface SelectNetworkProps {
  networks: string[];
  selectedNetwork: string;
  onSelect: (chaindId: string) => void;
}

export function SelectNetwork(props: SelectNetworkProps) {
  const { networks, selectedNetwork, onSelect } = props;
  const [selected, setSelected] = useState(selectedNetwork);

  const handleChange = (network: string) => {
    setSelected(network);
    onSelect(network);
  };

  useEffect(() => {
    setSelected(selectedNetwork);
  }, [selectedNetwork]);

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="relative z-20 mt-2 w-full">
            <Listbox.Button
              className={`relative w-full cursor-default rounded-lg bg-white p-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 ${
                open ? 'ring-2 ring-indigo-500' : ''
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <img
                    src={`/img/${selected.toLowerCase()}_bright.webp`}
                    alt=""
                    className="h-8 w-8 flex-shrink-0 rounded-full"
                  />
                  {getNetworkName(selected)}
                </div>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {networks.map((chainIds) => (
                  <Listbox.Option
                    key={chainIds}
                    className={({ active }) =>
                      cn(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={chainIds}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={`/img/${chainIds.toLowerCase()}_bright.webp`}
                            alt=""
                            className="h-8 w-8 flex-shrink-0 rounded-full"
                          />
                          <span className={cn(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                            {getNetworkName(chainIds)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={cn(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default SelectNetwork;
