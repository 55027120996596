import { runInAction } from 'mobx';
import { useContext } from 'react';

import { StoreContext } from '~/modules/common';
import { SortDirection } from '~/modules/common/enums';
import { TransactionSortCriteria } from '~/modules/transactions/enums';

export const useSwapListHeader = () => {
  const { wallet } = useContext(StoreContext);
  const transactionStore = wallet.transactions;

  const setSortCriteria = async (newCriteria: TransactionSortCriteria) => {
    if (transactionStore.swapSortCriteria === newCriteria) {
      return;
    }
    runInAction(() => {
      transactionStore.swapSortCriteria = newCriteria;
    });
    await transactionStore.sortSwaps(newCriteria, transactionStore.swapSortDirection);
  };

  const setSortDirection = async (newDirection: SortDirection) => {
    if (transactionStore.swapSortDirection === newDirection) {
      return;
    }
    runInAction(() => {
      transactionStore.swapSortDirection = newDirection;
    });
    await transactionStore.sortSwaps(transactionStore.swapSortCriteria, newDirection);
  };

  return {
    sortCriteria: transactionStore.swapSortCriteria,
    setSortCriteria,
    sortDirection: transactionStore.swapSortDirection,
    setSortDirection,
  };
};
