import { ethers } from 'ethers';

import { GasFeeData } from '~/modules/common/interfaces';

export const getMaxGasUnits = (): bigint => {
  /* 
    Note:
        - Currently, the max gas units required is hardcoded to 600k.
        - This value can be updated in the future to be dynamic using the estimateGasForExecuteInstructions method.
  */
  const maxGasUnits = 600_000n;

  return maxGasUnits;
};

/**
 * Calculates the maximum fee per gas for a chain based on provided gas fee data.
 * @param gasFeeData - An object containing gas fee data.
 * @returns The calculated maximum fee per gas as a BigInt value.
 */
export const getMaxFeePerGas = (gasFeeData: GasFeeData): bigint => {
  const supportsEip1559 = gasFeeData.maxPriorityFeePerGas !== 0n;

  const baseGasCost = supportsEip1559 ? gasFeeData.maxFeePerGas : gasFeeData.gasPrice;
  const maxPriorityFeePerGas = supportsEip1559
    ? gasFeeData.maxPriorityFeePerGas
    : BigInt(ethers.parseUnits('1', 'gwei').toString());

  const totalFeePerGas = baseGasCost + maxPriorityFeePerGas;
  const maxFeePerGas = (totalFeePerGas * 12n) / 10n + BigInt(ethers.parseUnits('1', 'wei').toString());

  return maxFeePerGas;
};

export const getMaxGasFee = (gasFeeData: GasFeeData): bigint => {
  const maxGasUnits = getMaxGasUnits();
  const maxFeePerGas = getMaxFeePerGas(gasFeeData);

  return maxFeePerGas * maxGasUnits;
};
