import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { TableAssetsDisplaySkeleton, TokenAmountDisplaySkeleton } from '~/modules/common';

export const VaultInteractionListItemSkeleton = () => {
  return (
    <SkeletonTheme borderRadius={100} height={18}>
      <div className="border-gray grid min-h-20 grid-cols-8 rounded-b-3xl border-t px-5">
        <span className="col-span-1 flex items-center">
          <Skeleton width={80} />
        </span>
        <span className="col-span-1 flex items-center">
          <Skeleton width={80} />
        </span>
        <span className="col-span-2 mr-4 flex items-center">
          <TableAssetsDisplaySkeleton showSymbol={false} className="my-4 gap-2" />
        </span>
        <span className="col-span-2 my-4 flex flex-col justify-center gap-1">
          <TokenAmountDisplaySkeleton size="sm" />
          <TokenAmountDisplaySkeleton size="sm" />
          <TokenAmountDisplaySkeleton size="sm" />
        </span>
        <span className="col-span-2 flex items-center">
          <Skeleton width={100} />
        </span>
      </div>
    </SkeletonTheme>
  );
};
