import { LiquidityPosition } from '@catalabs/catalyst-api-client';
import { makeAutoObservable } from 'mobx';

import { RootStore, Store } from '~/modules/common';

export class PortfolioStore implements Store {
  // Common data

  /**
   * Total value of all deposits in USD.
   * @property {number} totalDepositValue
   * @description -1: Not fetched, >=0: Actual total value
   */
  totalDepositValue: number = -1;

  // Staked liquidity position data

  stakedLps: LiquidityPosition[] = [];
  /**
   * Number of staked pool LPs of the user.
   * @property {number} stakedLpCount
   * @description -1: Not fetched, >=0: Actual count
   */
  stakedLpCount: number = -1;
  /**
   * Page number of fetched staked pool LP data.
   * @property {number} stakedLpPage
   * @description -1: None fetched, >=0: Fetched up to this page
   */
  stakedLpPage: number = -1;
  isStakedLpsLoading: boolean = false;

  // Non-staked liquidity position data

  nonStakedLps: LiquidityPosition[] = [];
  /**
   * Number of non-staked pool LPs of the user.
   * @property {number} nonStakedLpCount
   * @description -1: Not fetched, >=0: Actual count
   */
  nonStakedLpCount: number = -1;
  /**
   * Page number of fetched non-staked pool LP data.
   * @property {number} nonStakedLpPage
   * @description -1: None fetched, >=0: Fetched up to this page
   */
  nonStakedLpPage: number = -1;
  isNonStakedLpsLoading: boolean = false;

  constructor(private store: RootStore) {
    makeAutoObservable(this);
  }

  // Data fetching methods

  async fetchStakedLps(): Promise<void> {
    const address = this.store.wallet.address;
    if (!address || this.isStakedLpsLoading) {
      return;
    }

    this.isStakedLpsLoading = true;

    try {
      // TODO: Update the API call to fetch staked pools
      // const stakedLps = await this.store.client.accounts.<>(address, {
      //   page: this.stakedLpPage + 1,
      //   size: 50,
      // });
      // TODO: Update the totalDepositValue from the fetched data
      // this.stakedLpCount = stakedLps.count;
      // if (stakedLps.data.length !== 0) {
      //   this.stakedLps = [...this.stakedLps, ...stakedLps.data];
      //   this.stakedLpPage++;
      // }
    } catch {
      console.error(`Unable to load staked pools for ${address}.`);
    }

    this.isStakedLpsLoading = false;
  }

  async fetchNonStakedLps(): Promise<void> {
    const address = this.store.wallet.address;
    if (!address || this.isNonStakedLpsLoading) {
      return;
    }

    this.isNonStakedLpsLoading = true;

    try {
      const nonStakedLps = await this.store.client.accounts.getAccountLiquidityPositions(address, {
        page: this.nonStakedLpPage + 1,
        size: 50,
      });
      this.totalDepositValue = nonStakedLps.totalDeposits;
      this.nonStakedLpCount = nonStakedLps.count;
      if (nonStakedLps.data.length !== 0) {
        this.nonStakedLps = [...this.nonStakedLps, ...nonStakedLps.data];
        this.nonStakedLpPage++;
      }
    } catch {
      console.error(`Unable to load non-staked pools for ${address}.`);
    }

    this.isNonStakedLpsLoading = false;
  }

  async reset() {
    this.totalDepositValue = -1;

    this.stakedLps = [];
    this.stakedLpCount = -1;
    this.stakedLpPage = -1;
    this.isStakedLpsLoading = false;

    this.nonStakedLps = [];
    this.nonStakedLpCount = -1;
    this.nonStakedLpPage = -1;
    this.isNonStakedLpsLoading = false;

    this.store.wallet.reset();
  }
}
