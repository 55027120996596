import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Complete } from '~/img/circle_check.svg';
import { formatCurrency, TokenDisplay } from '~/modules/common';
import { PoolActionState } from '~/modules/pools/enums';
import { PoolCreateWithdrawRequest } from '~/modules/pools/interfaces';

export interface PoolDepositDisplayProps {
  request: PoolCreateWithdrawRequest;
}

export function PoolCreateWithdrawDisplay({ request }: PoolDepositDisplayProps) {
  const { assets, chainId, requestStatus, assetAmounts, assetValues } = request;
  if (requestStatus === PoolActionState.Completed) {
    return null;
  }
  return (
    <div className="flex items-center rounded-[25px] bg-white p-5 shadow md:w-[552px]">
      <div className="f flex w-full flex-col">
        <div className="flex">
          {requestStatus === PoolActionState.Pending ? <Active /> : <Complete className="h-8 w-8" />}
          <div className="ml-4 flex flex-col">
            <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">
              Sign Withdrawal.
            </span>
            <span className="text-sm leading-5 text-grey-500">This action will trigger the deposit of tokens.</span>
          </div>
        </div>

        <div className="mt-2 space-y-4">
          {assets?.map((asset) => {
            const amount = assetAmounts?.get(asset.symbol) || 0;
            const value = formatCurrency(assetValues?.get(asset.symbol) || 0);
            return (
              <div
                key={`deposit-item-${asset.chainId}-${asset.address}`}
                className={`flex items-center rounded-[15px] border-2 px-4 py-5 ${
                  asset.chainId.toString() === chainId ? 'border-primary' : 'border-grey-300'
                }`}
              >
                <TokenDisplay chainId={asset.chainId.toString()} token={asset.address} showSymbol={false} />
                <div className="ml-4 mt-1 flex flex-col">
                  <span className="font-semibol text-[32px] leading-[28px]">{amount}</span>
                  <span className="text-[14px] font-normal text-grey-500">{value}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
          {requestStatus === PoolActionState.Confirmed ? 'Tokens Withdrawn' : 'Waiting for your approval'}
        </div>
      </div>
    </div>
  );
}

export default PoolCreateWithdrawDisplay;
