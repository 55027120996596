import { formatValue } from '~/modules/common';
import TokenDisplay from '~/modules/common/components/TokenDisplay';
import { SwapCardType } from '~/modules/swap';

export interface SwapReviewCardProps {
  cardType: SwapCardType;
  chainId: string;
  token: string;
  amount: number;
  value: number;
  completed?: boolean;
}

export function SwapReviewCard({
  cardType,
  chainId,
  token,
  amount,
  value,
  completed = false,
}: SwapReviewCardProps): JSX.Element {
  let headerText;
  let valueTextId;
  let amountTextId;

  if (completed) {
    headerText = cardType === SwapCardType.Input ? 'SENT' : 'RECEIVED';
    amountTextId =
      cardType === SwapCardType.Input ? 'swap-review-card-sent-amount' : 'swap-review-card-received-amount';
    valueTextId = cardType === SwapCardType.Input ? 'swap-review-card-sent' : 'swap-review-card-received';
  } else {
    headerText = cardType === SwapCardType.Input ? 'SEND' : 'RECEIVE';
    amountTextId =
      cardType === SwapCardType.Input ? 'swap-review-card-sent-amount' : 'swap-review-card-received-amount';
    valueTextId = cardType === SwapCardType.Input ? 'swap-review-card-send' : 'swap-review-card-receive';
  }
  return (
    <div className={`flex w-1/2 flex-col rounded-3xl bg-white p-5`}>
      <span className="font-semibold text-[14px] uppercase leading-[12px] text-gray-800">{headerText}</span>
      <div className="mb-5 mt-3 flex">
        <TokenDisplay chainId={chainId} token={token} />
      </div>
      <div
        className="overflow-hidden text-ellipsis font-semibold text-[32px] leading-[28px]"
        data-testid={amountTextId}
      >
        {formatValue(amount, 6)}
      </div>
      <div className="mt-[8.5px] text-[14px] font-normal leading-[12px] text-grey-500" data-testid={valueTextId}>
        ${value.toLocaleString(undefined, { minimumFractionDigits: 2 })}
      </div>
    </div>
  );
}

export default SwapReviewCard;
