import { getReferralCreationMessage } from '@catalabs/catalyst-api-client';
import { useState } from 'react';

import { WalletStore } from '~/modules/common';
import { ReferralStore } from '~/modules/common/store/referral-store';

export function useCreateReferralCode(referralStore: ReferralStore, walletStore: WalletStore) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  async function createReferralCode(code: string) {
    try {
      if (!walletStore.address) {
        setError('Error creating referral code. Please try again.');
        return;
      }
      setIsLoading(true);
      const requestedCode = code.toLocaleUpperCase();
      const createReferralMessage = getReferralCreationMessage(walletStore.address, requestedCode);
      const signature = await walletStore.signMessage(createReferralMessage);
      await referralStore.createReferralLink({ walletAddress: walletStore.address, code: requestedCode, signature });
    } catch (e: unknown) {
      const isUserRejectedRequestError = (e as { code?: number })?.code === 4001;
      if (isUserRejectedRequestError) {
        return;
      }
      setError('Invalid code');
    } finally {
      setIsLoading(false);
    }
  }

  return {
    createReferralCode,
    isLoading,
    error,
    reset: () => setError(null),
  };
}
