import { observer } from 'mobx-react-lite';

import { EmptyTable, Modal } from '~/modules/common';
import { useInfiniteScroll } from '~/modules/common/hooks';
import { VaultInteractionReceipt } from '~/modules/pools';
import { VaultInteractionListItem, VaultInteractionListItemSkeleton } from '~/modules/transactions/components';
import { useVaultInteractionList } from '~/modules/transactions/hooks';

export const VaultInteractionList = observer(() => {
  const {
    vaultInteractions,
    vaultInteractionCount,
    isVaultInteractionsLoading,
    selectedVaultInteraction,
    isVaultInteractionSelected,
    selectVaultInteraction,
    unselectVaultInteraction,
    poolAssetsById,
    fetchVaultInteractionsOnScroll,
  } = useVaultInteractionList();

  const observerRef = useInfiniteScroll({ fetchData: fetchVaultInteractionsOnScroll });

  return (
    <>
      {vaultInteractionCount === 0 ? (
        <EmptyTable>You don't have any deposit/withdrawal transaction</EmptyTable>
      ) : (
        <>
          {vaultInteractions.map((vaultInteraction, index) => (
            <VaultInteractionListItem
              key={`vault-interaction-list-item-${vaultInteraction.id}`}
              vaultInteraction={vaultInteraction}
              poolAssets={poolAssetsById.get(vaultInteraction.poolId) ?? []}
              isLast={vaultInteractions.length - 1 === index}
              onClick={() => selectVaultInteraction(vaultInteraction)}
            />
          ))}
          {isVaultInteractionsLoading && <VaultInteractionListItemSkeleton />}
        </>
      )}

      {/* For infinite scroll */}
      <div ref={observerRef} />

      {/* Vault interaction receipt modal */}
      <Modal open={isVaultInteractionSelected} onClose={unselectVaultInteraction}>
        {selectedVaultInteraction !== null && <VaultInteractionReceipt receiptData={selectedVaultInteraction} />}
      </Modal>
    </>
  );
});
