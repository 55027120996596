import { twMerge } from 'tailwind-merge';

interface HeadingProps {
  text: string;
  className?: string;
}

export const Heading = ({ text, className }: HeadingProps) => {
  return <h1 className={twMerge(`font-header text-[32px] leading-[28px]`, className)}>{text}</h1>;
};
