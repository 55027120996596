import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { StoreContext } from '~/modules/common';
import { usePendingActions } from '~/modules/navigation';
import { PendingPoolCreationBar, PendingPoolDepositBar, PendingPoolWithdrawBar } from '~/modules/pools';
import PendingPoolCreationDepositBar from '~/modules/pools/components/PendingPoolCreationDepositBar';

export const PendingActionBanner = observer((): JSX.Element => {
  const store = useContext(StoreContext);
  const { pool } = store;
  const { depositStore, withdrawStore } = pool;
  const { handlers } = usePendingActions({ store });

  if (withdrawStore.showPendingWithdraw) {
    return (
      <PendingPoolWithdrawBar
        handleResume={handlers.handleResumeWithdraw}
        handleCancel={handlers.handleCancelWithdraw}
      />
    );
  }

  if (depositStore.showPendingDeposit) {
    return (
      <PendingPoolDepositBar
        handleResume={handlers.handleResumeDeposit}
        handleWithdraw={handlers.handleWithdrawDeposit}
        handleCancel={handlers.handleCancelDeposit}
      />
    );
  }

  if (pool.showPendingPoolConfigBanner) {
    return (
      <PendingPoolCreationBar
        handleCancel={handlers.handleCancelCreate}
        handleResume={handlers.handleResumeCreate}
        handleHide={handlers.handleHideCreate}
      />
    );
  }
  if (pool.hasPendingPoolCreateRequests) {
    return (
      <PendingPoolCreationDepositBar
        handleResume={handlers.handleResumeCreateDeposit}
        handleWithdraw={handlers.handleCreateWithdraw}
      />
    );
  }
  return <></>;
});
