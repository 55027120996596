import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ReactComponent as HeaderImage } from '~/img/eggs.svg';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';

import { Button, StoreContext } from '../../common';
import EggDataItem from './EggDataItem';

export const PageHeader = observer(() => {
  const { connect, isConnected } = useConnectButton();
  const { referral: referralStore } = useContext(StoreContext);

  return (
    <div className="relative flex w-full flex-col justify-end rounded-3xl bg-[#4553D3] text-white lg:h-[243px] lg:flex-row  lg:items-end lg:justify-between">
      <div className="flex w-full items-center justify-center lg:absolute lg:right-0 lg:w-fit">
        <HeaderImage className="rounded-3xl " />
      </div>
      <div className="z-5 flex h-full flex-col justify-between p-5 lg:p-10">
        <div className="flex flex-col gap-1">
          <span className="mb-2 font-header text-[24px] leading-[28px] md:mt-0 lg:text-[32px]">Catalyst Eggs</span>
          <div className="flex flex-col gap-2">
            <span className="font-light leading-5">
              Earn Eggs by using Catalyst. Eggs are distributed every Friday.
            </span>
            <Button
              onClick={() =>
                window.open('https://docs.catalyst.exchange/resources/eggs-rewards/', '_blank', 'noopener,noreferrer')
              }
              className="mt-0 w-fit bg-[#A8E18D] text-black"
              size="extra-small"
            >
              Learn more
            </Button>
          </div>
        </div>
        {!isConnected && (
          <div className="flex w-fit items-center gap-3 rounded-xl bg-white py-2 pl-4 pr-2">
            <span className="text-sm font-medium text-gray-800">Connect your wallet</span>
            <Button className="mt-0" size="extra-small" onClick={connect}>
              Connect
            </Button>
          </div>
        )}
        {isConnected && (
          <div className="grid grid-cols-2 md:grid-cols-4">
            <EggDataItem title={'Total Eggs'} value={referralStore.eggData?.totalEggs.toLocaleString()} />
            <EggDataItem title={'Weekly Eggs'} value={referralStore.eggData?.weeklyEggs.toLocaleString()} />
            <EggDataItem title={'Rank'} value={referralStore.eggData?.rank.toLocaleString()} />
            <EggDataItem title={'League'} value={referralStore.eggData?.accountRanking} />
          </div>
        )}
      </div>
    </div>
  );
});
