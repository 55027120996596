import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import { ReactComponent as Caret } from '~/img/caret.svg';
import { CircleLoader } from '~/modules/common/components';
import { StoreContext } from '~/modules/common/store/context';
import {
  QuoteError,
  SwapApprovalToPermit2,
  SwapCard,
  SwapConfirmation,
  SwapDetails,
  SwapFailure,
  SwapOverview,
  SwapPermitSignature,
} from '~/modules/swap/components';
import { SwapCardType } from '~/modules/swap/enums';

export const SwapPage = observer(() => {
  const { swap } = useContext(StoreContext);
  const { sourceAsset, targetAsset, fetchingQuote } = swap;

  useEffect(() => {
    swap.resetSwap();
    return () => {
      swap.resetSwap();
    };
  }, []);

  async function handleInputSwap() {
    if (!sourceAsset && !targetAsset) {
      return;
    }
    await swap.swapNetworks();
  }

  return (
    <div className="mx-3 flex flex-grow flex-col items-center md:mx-0">
      <SwapFailure />
      <SwapApprovalToPermit2 />
      <SwapPermitSignature />
      <SwapConfirmation />
      <SwapOverview />
      <div className="mt-6 flex flex-col items-center md:mt-[48px] md:flex-row md:gap-2">
        <SwapCard cardType={SwapCardType.Input} />
        <div
          className="z-2 group -my-3 flex h-10 w-10 rotate-90 cursor-pointer items-center justify-center rounded-full bg-white shadow md:z-0 md:my-0 md:rotate-0"
          onClick={handleInputSwap}
        >
          <Caret className="rotate-180 text-grey-500 transition-all duration-200 ease-in-out group-hover:rotate-0" />
        </div>
        <SwapCard cardType={SwapCardType.Output} />
      </div>
      <QuoteError />
      <SwapDetails />
      <CircleLoader loading={fetchingQuote} className="mt-[65px]" />
    </div>
  );
});

export default SwapPage;
