import React, { useEffect } from 'react';

import { ReactComponent as Close } from '~/img/close.svg';

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  open?: boolean;
  hasError?: boolean;
  canClose?: boolean;
}

const ModalContent = ({ children, onClose, open, hasError, canClose = true }: ModalProps) => {
  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && open) {
      onClose();
    }
  };

  useEffect(() => {
    const keydownEventName = 'keydown';
    document.addEventListener(keydownEventName, handleEscape);
    return () => {
      document.removeEventListener(keydownEventName, handleEscape);
    };
  }, [open]);

  return (
    <div>
      <div
        className={`absolute left-0 top-0 z-20 flex h-full max-h-screen w-full flex-col overflow-auto  pb-10 text-gray-800 backdrop-blur-3xl ${
          hasError ? 'bg-red-600 bg-opacity-15' : ' bg-primary bg-opacity-10'
        }`}
      >
        {canClose ? (
          <div className="flex justify-end p-6">
            <button
              onClick={onClose}
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white p-1 focus:outline-none md:h-16 md:w-16"
            >
              <Close />
            </button>
          </div>
        ) : (
          <div className="h-20 md:h-28" />
        )}
        <div className="flex justify-center">
          <div className="z-40 flex justify-center md:w-[552px]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const Modal = (props: ModalProps) => {
  if (!props.open) return null;

  return <ModalContent {...props} />;
};
