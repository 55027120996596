import { PoolAsset } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { roundDownToDecimals } from '~/config';
import { AmountInput, formatCurrency, TokenDisplay } from '~/modules/common';
import { BalanceDisplay } from '~/modules/common/components/BalanceDisplay';

import { DepositLiquiditySwapInfoItem } from '../interfaces';
import { getAssetKey } from '../utils';
import { AssetInputOptionsDropdown } from './AssetInputOptionsDropdown';

interface WithdrawSinglePaneProps {
  assets: PoolAsset[];
  assetBalances: Map<string, number>;
  setMaxInput: (asset: PoolAsset) => void;
  handleBlur: (asset: PoolAsset) => void;
  liquiditySwapQuote?: DepositLiquiditySwapInfoItem;
  allLiquiditySwapQuotes?: DepositLiquiditySwapInfoItem[];
  handleClick: (asset: PoolAsset) => void;
  withdrawAmounts: Map<string, string>;
  withdrawValues: Map<string, number>;
  handleChangeAsset: (asset: PoolAsset) => void;
  handleInputChange: (asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function WithdrawSinglePane({
  assets,
  assetBalances,
  setMaxInput,
  handleBlur,
  liquiditySwapQuote,
  handleClick,
  withdrawAmounts,
  handleChangeAsset,
  withdrawValues,
  handleInputChange,
}: WithdrawSinglePaneProps) {
  const [_openLiquiditySwapQuoteModal, setOpenLiquiditySwapQuoteModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(assets[0]);
  const [hasFocus, setHasFocus] = useState(false);
  const hasError = false;
  const borderClass = hasError ? 'border-red-600' : hasFocus ? 'border-primary-600' : 'border-gray';
  const selectedAssetKey = getAssetKey({
    chainId: selectedAsset.chainId,
    address: selectedAsset.address,
  });
  const selectedChainGasAssetKey = getAssetKey({
    chainId: selectedAsset.chainId,
    address: GAS_TOKEN_IDENTIFIER,
  });
  const assetOptions = assets.map((asset) => {
    return getAssetKey({
      chainId: asset.chainId,
      address: GAS_TOKEN_IDENTIFIER,
    });
  });
  const userBalance = assetBalances.get(selectedAssetKey) || 0;

  const liquiditySwapsForAsset = liquiditySwapQuote?.quoteDetails.filter((quoteDetail) => {
    return quoteDetail.swap.from.some(({ token }) => token.address === selectedAsset.address);
  });

  const amount = roundDownToDecimals(Number(withdrawAmounts.get(selectedAssetKey) || '0.00'), 6).toString();
  const value = withdrawValues.get(selectedAssetKey) || 0;
  return (
    <div className={`flex flex-col gap-1 ${borderClass} overflow-hidden rounded-[15px] border-[1.5px]`}>
      <div className={`flex h-[84px] flex-row justify-between  gap-2 p-4 md:w-full`}>
        <div className="flex flex-col items-start gap-2">
          <AssetInputOptionsDropdown
            assetKeys={assetOptions}
            onChange={(assetKey) => {
              const asset = assets.find(
                (asset) =>
                  getAssetKey({
                    chainId: asset.chainId,
                    address: GAS_TOKEN_IDENTIFIER,
                  }) === assetKey,
              );
              if (asset) {
                setSelectedAsset(asset);
                handleChangeAsset(asset);
              }
            }}
            selected={selectedChainGasAssetKey}
          />
          <div>
            <BalanceDisplay
              balance={userBalance}
              error={hasError}
              onClick={() => {
                setMaxInput(selectedAsset);
              }}
            />
          </div>
        </div>
        <div className="flex h-full flex-col items-end justify-center">
          <AmountInput
            onBlur={() => handleBlur(selectedAsset)}
            onClick={() => handleClick(selectedAsset)}
            className={`font-subheader ${
              hasError ? 'text-red-600' : 'text-gray-800'
            }  h-full bg-transparent text-right text-[32px]`}
            onChange={(e) => handleInputChange(selectedAsset, e)}
            value={amount}
            onFocus={() => setHasFocus(true)}
          />
          <div className="mt-1 flex justify-between">
            <span className="text-grey-600">{formatCurrency(value)}</span>
          </div>
        </div>
      </div>

      {liquiditySwapsForAsset && liquiditySwapsForAsset.length > 0 && (
        <div
          className="flex cursor-pointer items-center justify-between bg-gray-100 p-2 hover:bg-gray-200"
          onClick={() => setOpenLiquiditySwapQuoteModal(true)}
        >
          <span className="text-xs font-normal text-gray-500">Liquidity Swaps</span>
          <div className="flex items-center">
            <TokenDisplay
              chainId={selectedAsset.chainId.toString()}
              token={selectedAsset.address}
              showSymbol={false}
              showChain={false}
              showTooltip={false}
              showChainIcon
              size="sm"
            />
            <span className="mr-2 flex ">
              <ArrowLongRightIcon className="h-4 w-4" />
            </span>
            {liquiditySwapsForAsset.map((quoteDetail) => {
              return quoteDetail.swap.to.map(({ token }, index) => {
                return (
                  <div key={index}>
                    <TokenDisplay
                      chainId={token.chainId.toString()}
                      token={token.address}
                      showSymbol={false}
                      showChain={false}
                      showTooltip={false}
                      showChainIcon
                      size="sm"
                    />
                  </div>
                );
              });
            })}
          </div>
        </div>
      )}
    </div>
  );
}
