import { getChainConfig } from '@catalabs/catalyst-chain-lists';
import { ArbitraryMessagingBridge, getChannel } from '@catalabs/catalyst-channel-lists';
import { CCIVersion, DeployVaultOptions, VaultType } from '@catalabs/catalyst-sdk';
import { parseUnits } from '@ethersproject/units';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes, toUnits } from '~/config';
import { CatalystStore, WalletStore } from '~/modules/common';
import { db } from '~/modules/common/db';
import { CreatePoolType, PoolActionState, PoolCreateStep } from '~/modules/pools/enums';
import {
  ApprovalRequest,
  DeployVaultRequest,
  InteroperabilityProtocol,
  PoolCreateConfig,
  PoolCreateRequest,
  SetVaultChannelsRequest,
} from '~/modules/pools/interfaces';
import { SwapStore } from '~/modules/swap';

import { PoolStore } from '../store';

export interface usePoolCreateProps {
  poolStore: PoolStore;
  walletStore: WalletStore;
  catalystStore: CatalystStore;
}

export function usePoolCreate({ poolStore, catalystStore, walletStore }: usePoolCreateProps) {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectTokenModalOpen, setSelectTokenModalOpen] = useState(false);
  const [assetWeights, setAssetWeights] = useState<Map<TokenInfo, number>>(new Map());
  const [assetAmounts, setAssetAmounts] = useState<Map<TokenInfo, number>>(new Map());
  const [assets, setAssets] = useState<Map<TokenInfo, string>>(new Map());
  const [poolType, setPoolType] = useState<CreatePoolType>(CreatePoolType.CLASSIC);
  const [interopProtocol, setInteropProtocol] = useState<InteroperabilityProtocol>();
  const [chainCCIs, setChainCCIs] = useState<Map<string, string>>(new Map());
  const [chainVaultFactoryAddresses, setChainVaultFactoryAddresses] = useState<Map<string, string>>(new Map());
  const [depositValueMap, setDepositValueMap] = useState<Map<TokenInfo, number>>(new Map());
  const [hasSavedPoolCreation, setHasSavedPoolCreation] = useState(false);

  // computed properties
  const maxTokens = Array.from(assets.entries()).length < 4;
  const selectedTokensAndChain = Array.from(assets.entries());
  const selectedChains = [...new Set(Array.from(assets.values()))];
  const selectedTokens = Array.from(assets.keys());
  const selectedTokenWeights = Array.from(assetWeights.entries());
  const selectedTokenAmounts = Array.from(assetAmounts.entries());
  const allSelectedTokenAmountsAreValid = Array.from(assetAmounts.values()).every((amount) => amount > 0);
  const allSelecteTokenWeightsAreValid = Array.from(assetWeights.values()).every((weight) => weight > 0);
  const totalTokenWeight = Array.from(assetWeights.values()).reduce((total, weight) => total + weight, 0);
  const theTotalTokenWeightsAreLessThan100 = totalTokenWeight <= 100;
  const canClickCreate =
    selectedTokens.length > 0 &&
    selectedTokensAndChain.length === selectedTokens.length &&
    selectedTokenWeights.length === selectedTokens.length &&
    selectedTokenAmounts.length === selectedTokens.length &&
    isInitialized &&
    poolType !== undefined &&
    poolStore.poolCreateStep === PoolCreateStep.Configure &&
    allSelectedTokenAmountsAreValid &&
    allSelecteTokenWeightsAreValid &&
    theTotalTokenWeightsAreLessThan100;
  const totalDepositValue = Array.from(depositValueMap.values()).reduce((total, value) => total + value, 0);

  const cancelCreate = () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Configure;
    });
  };

  const resetPoolCreate = async () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Configure;
      poolStore.depositStore.poolDepositRequest = [];
    });
    setAssets(new Map());
    setAssetAmounts(new Map());
    setAssetWeights(new Map());
    setIsInitialized(false);
    setPoolType(CreatePoolType.CLASSIC);
    setInteropProtocol(undefined);
    setChainCCIs(new Map());
    setChainVaultFactoryAddresses(new Map());

    await poolStore.clearSavedPoolCreateRequests();
    await clearSavedPoolState();
  };

  const completePoolCreate = async () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Completed;
      poolStore.poolCreateRequests = [];
    });
    await resetPoolCreate();
    navigate(routes.pools);
  };

  const prepareApproveRequests = async () => {
    const assetsArray = Array.from(assets.keys());
    // prepare approve requests
    const approvalRequestsByChain = new Map<string, ApprovalRequest[]>();
    assetsArray.forEach((asset) => {
      const chainId = assets.get(asset)?.toString();
      if (chainId) {
        const request: ApprovalRequest = {
          chainId,
          asset: asset,
          address: asset.address,
          amountValue: depositValueMap.get(asset) || 0,
          approval: PoolActionState.Inactive,
          amount: toUnits(assetAmounts.get(asset)?.toString() || '0', asset.decimals),
          vaultFactoryAddress: chainVaultFactoryAddresses.get(chainId) || '',
        };
        const requests = approvalRequestsByChain.get(chainId);
        if (requests) {
          requests.push(request);
        } else {
          approvalRequestsByChain.set(chainId, [request]);
        }
      }
    });

    return approvalRequestsByChain;
  };

  const prepareVaultDeployRequests = async () => {
    const assetsArray = Array.from(assets.keys());

    // prepare pool create requests
    const deployVaultRequests = new Map<string, DeployVaultRequest>();
    const chainAssets = new Map<string, TokenInfo[]>();

    // create a map of assets by chain
    assetsArray.map((asset) => {
      const chainId = assets.get(asset)?.toString();
      if (chainId) {
        const assets = chainAssets.get(chainId);
        if (assets) {
          assets.push(asset);
        } else {
          chainAssets.set(chainId, [asset]);
        }
      }
    });

    for (const chainAsset of chainAssets) {
      const [chainId, assets] = chainAsset;
      const weights = computeAssetWeights(assets);
      const amounts: bigint[] = assets.map((asset) =>
        BigInt(parseUnits(assetAmounts.get(asset)?.toString() || '0', asset.decimals).toString()),
      );
      // TODO: this needs to be configurable on the UI for amplified pools
      const amplification = BigInt(
        (poolType === CreatePoolType.STABLESWAP ? parseUnits('0.5', 18) : parseUnits('1', 18)).toString(),
      );
      const vaultType = poolType === CreatePoolType.STABLESWAP ? VaultType.Amplified : VaultType.WeightedVolatile;
      const chainInterface = chainCCIs.get(chainId) || '';
      const deployVaultOptions: DeployVaultOptions = {
        vaultType,
        assets: assets.map((asset) => asset.address),
        initialBalances: amounts,
        weights,
        amplification,
        vaultFee: BigInt(parseUnits(SwapStore.DEFAULT_SLIPPAGE.toString(), 18).toString()),
        name: 'CAT-V1',
        chainInterface,
        symbol: 'CAT-V1',
      };
      const assetAmountMap = new Map<string, number>();
      const assetValues = new Map<string, number>();
      assets.forEach((asset) => {
        assetAmountMap.set(asset.symbol, assetAmounts.get(asset) || 0);
        assetValues.set(asset.symbol, depositValueMap.get(asset) || 0);
      });
      const deployVaultRequest: DeployVaultRequest = {
        deployOptions: deployVaultOptions,
        chainId,
        requestStatus: PoolActionState.Inactive,
        assetAmounts: assetAmountMap,
        assets,
        assetValues,
      };
      deployVaultRequests.set(chainId, deployVaultRequest);
    }

    return deployVaultRequests;
  };

  const prepareSetChannelRequests = async () => {
    const requests = new Map<string, SetVaultChannelsRequest[]>();

    for (const chainId of selectedChains) {
      const chainRequests = [];
      const otherChains = selectedChains.filter((c) => c !== chainId);
      for (const otherChainId of otherChains) {
        const setChannelRequest: SetVaultChannelsRequest = {
          requestStatus: PoolActionState.Inactive,
          chainId: chainId,
          originVaultAddress: undefined,
          destinationVaultAddress: undefined,
          channelId: getChannel(ArbitraryMessagingBridge.Default, chainId, otherChainId).toString(),
          isActive: true,
        };

        chainRequests.push(setChannelRequest);
      }

      requests.set(chainId, chainRequests);
    }
    return requests;
  };

  const clearSavedPoolState = async () => {
    await db.poolCreateConfig.clear();
    setHasSavedPoolCreation(false);
  };

  const savePoolCreateState = async (address?: string) => {
    try {
      if (!address) {
        return;
      }
      if (assets.size > 0) {
        const poolCreateState: PoolCreateConfig = {
          assets: Array.from(assets.entries()),
          assetAmounts: Array.from(assetAmounts.entries()),
          assetWeights: Array.from(assetWeights.entries()),
          poolType,
          interopProtocol,
        };
        await db.poolCreateConfig.setItem(address, poolCreateState);
        runInAction(() => {
          poolStore.hasSavedPoolCreateState = true;
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const startPoolCreation = async () => {
    const poolCreateRequests: PoolCreateRequest[] = [];

    // prepare approve requests
    const approvalRequestsByChain = await prepareApproveRequests();

    // prepare deposit requests
    const deployVaultRequestByChain = await prepareVaultDeployRequests();

    const setChannelRequestsByChain = await prepareSetChannelRequests();

    // create chain actions
    for (const chainId of selectedChains) {
      const approvalRequests = approvalRequestsByChain.get(chainId) || [];
      const deployVaultRequest = deployVaultRequestByChain.get(chainId);
      const setChannelRequests = setChannelRequestsByChain.get(chainId) || [];
      if (deployVaultRequest) {
        poolCreateRequests.push({
          chainId,
          actionState: PoolActionState.Inactive,
          approvalRequests,
          deployVaultRequest,
          chainSwap: PoolActionState.Inactive,
          setChannelRequests,
        });
      }
    }

    runInAction(() => {
      poolStore.poolCreateRequests = poolCreateRequests;
    });

    // clear saved pool state
    await clearSavedPoolState();
    // createPool();
    await poolStore.createPool();
  };

  const removeToken = (token: TokenInfo) => {
    assets.delete(token);
    assetAmounts.delete(token);
    assetWeights.delete(token);
    setAssets(new Map(assets));
    setAssetAmounts(new Map(assetAmounts));
    setAssetWeights(new Map(assetWeights));

    if (assets.size === 0) {
      setIsInitialized(false);
    }
  };

  const addToken = (chainId: string, token: TokenInfo) => {
    assets.set(token, chainId);
    setIsInitialized(true);
    setAssets(new Map(assets));
  };

  const setTokenWeight = (token: TokenInfo, weight: number) => {
    assetWeights.set(token, weight);
    setAssetWeights(new Map(assetWeights));
  };

  const setTokenAmount = (token: TokenInfo, value: number) => {
    assetAmounts.set(token, value);
    setAssetAmounts(new Map(assetAmounts));
  };

  const fetchChainCCIs = async () => {
    for (const chainId of selectedChains) {
      if (!chainCCIs.has(chainId)) {
        for (const cciVersion of Object.values(CCIVersion)) {
          // TODO: there is more than one cci per chain, this map needs to be revised
          const cci = await poolStore.fetchCCI(chainId, cciVersion);
          if (cci) {
            chainCCIs.set(chainId, cci);
          } else {
            const chain = getChainConfig(chainId);
            throw new Error(`CCI not found for chain ${chain.name} and version ${cciVersion}`);
          }
          setChainCCIs(new Map(chainCCIs));
        }
      }
    }
  };

  const fetchChainAddresses = async () => {
    for (const chainId of selectedChains) {
      if (!chainVaultFactoryAddresses.has(chainId)) {
        const address = await poolStore.fetchVaultFactoryAddress(chainId);
        chainVaultFactoryAddresses.set(chainId, address);
        setChainVaultFactoryAddresses(new Map(chainVaultFactoryAddresses));
      }
    }
  };

  const updateDepositValueMap = async () => {
    const assets = assetAmounts.keys();
    const depositValueMap = new Map<TokenInfo, number>();

    for (const asset of assets) {
      const tokenPrice = catalystStore.getPrice(asset);
      const amount = assetAmounts.get(asset) || 0;
      if (amount && tokenPrice) {
        const value = tokenPrice * amount;
        depositValueMap.set(asset, value);
      }
    }
    setDepositValueMap(new Map(depositValueMap));
  };

  const withdrawFunds = async () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Withdraw;
      poolStore.withdrawFromIncompletePool();
    });
  };

  const resumePoolCreation = async () => {
    poolStore.createPool();
  };

  const showResumePoolCreation = () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Paused;
    });
  };

  const computeAmplifiedAssetWeights = () => {
    const computedAssetWeights = new Map<TokenInfo, number>();

    const allAssets = Array.from(assets.keys());

    const tokenWithMostDecimals = allAssets.reduce((prev, current) => {
      return prev.decimals > current.decimals ? prev : current;
    });

    computedAssetWeights.set(tokenWithMostDecimals, 1);
    const otherTokens = allAssets.filter((token) => token !== tokenWithMostDecimals);
    for (const token of otherTokens) {
      const weight = tokenWithMostDecimals.decimals / token.decimals;
      computedAssetWeights.set(token, weight);
    }
    return computedAssetWeights;
  };

  const computeAssetWeights = (assets: TokenInfo[]) => {
    const computedAssetWeights = poolType === CreatePoolType.STABLESWAP ? computeAmplifiedAssetWeights() : assetWeights;

    return assets.map((asset) => computedAssetWeights.get(asset) || 0);
  };

  useEffect(() => {
    const loadSavedPoolCreateState = async () => {
      const address = walletStore.address;
      if (!address) {
        return;
      }
      const state = await db.poolCreateConfig.getItem<PoolCreateConfig>(address);
      if (state) {
        const { assets, assetAmounts, assetWeights, poolType, interopProtocol } = state;
        setHasSavedPoolCreation(true);
        setAssets(assets.length > 0 ? new Map(assets) : new Map());
        setAssetAmounts(assetAmounts.length > 0 ? new Map(assetAmounts) : new Map());
        setAssetWeights(assetWeights.length > 0 ? new Map(assetWeights) : new Map());
        setPoolType(poolType);
        setInteropProtocol(interopProtocol);
        setIsInitialized(true);
      }
    };
    loadSavedPoolCreateState();
  }, [walletStore.address]);

  useEffect(() => {
    updateDepositValueMap();
  }, [assetAmounts]);

  useEffect(() => {
    // get all chains
    fetchChainCCIs();
    fetchChainAddresses();
  }, [assets]);

  useEffect(() => {
    const userAddress = walletStore.address;
    if (!userAddress) {
      return;
    }
    savePoolCreateState(userAddress);
  }, [assets, assetAmounts, assetAmounts, interopProtocol, poolType, walletStore.address]);

  return {
    poolType: CreatePoolType.CLASSIC,
    isInitialized,
    selectTokenModalOpen,
    selectedTokensAndChain,
    selectedTokenWeights,
    canClickCreate,
    maxTokens,
    interopProtocol,
    selectedChains,
    selectedTokens,
    totalTokenWeight,
    totalDepositValue,
    hasSavedPoolCreation,
    assetWeights,
    assetAmounts,
    handlers: {
      setTokenAmount,
      setPoolType,
      cancelCreate,
      startPoolCreation,
      removeToken,
      addToken,
      openSelectTokenModal: () => setSelectTokenModalOpen(true),
      closeSelectTokenModal: () => setSelectTokenModalOpen(false),
      setTokenWeight,
      setInteropProtocol,
      completePoolCreate,
      resetPoolCreate,
      withdrawFunds,
      resumePoolCreation,
      showResumePoolCreation,
    },
  };
}
