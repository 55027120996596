import { useState } from 'react';
import { createPortal } from 'react-dom';

import { CatalystNetwork } from '~/config';
import { ReactComponent as Close } from '~/img/close.svg';
import { formatCurrency, TokenDisplay } from '~/modules/common';
import { getAssetKey, PoolInteractionStep } from '~/modules/pools';
import PoolDepositModal from '~/modules/pools/components/PoolDepositModal';

import { PendingTnxStatus, PendingTxn } from '../interfaces';

interface CompletedDepositCardProps {
  txnDetails: PendingTxn;
  handleCompletedDeposit: (hash: string) => void;
}

export const CompletedDepositCard: React.FC<CompletedDepositCardProps> = ({
  txnDetails,
  handleCompletedDeposit,
}: CompletedDepositCardProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { depositDetails, status } = txnDetails;
  if (!depositDetails) {
    return null;
  }
  const {
    poolAssets,
    requests,
    depositInfo,
    withLiquiditySwap,
    liquiditySwapFinalSwapAmounts,
    liquiditySwapFinalValues,
  } = depositDetails;

  if (!requests || !requests.length || !depositInfo) {
    return null;
  }

  const totalAmount = requests.reduce((acc, txn) => {
    return (
      acc +
      txn.request.reduce((acc, req) => {
        return acc + req.value;
      }, 0)
    );
  }, 0);

  const handleCloseModal = () => {
    setIsDetailsOpen(false);
    handleCompletedDeposit(txnDetails.hash);
  };

  const handleComplete = () => handleCompletedDeposit(txnDetails.hash);

  const handleViewDetails = () => {
    setIsDetailsOpen(true);
  };

  const originRequest = requests[0];
  const fromChain = CatalystNetwork.getCatalystNetwork(originRequest.chainId);
  const isError = status === PendingTnxStatus.Failed;

  return (
    <>
      <div className="flex min-w-[274px] flex-col rounded-[25px] bg-white shadow">
        <div className="flex w-full justify-end px-5 pb-2 pt-5">
          <div className="z- cursor-pointer" onClick={handleComplete}>
            <Close className="text-grey-400" />
          </div>
        </div>
        <div className="flex flex-col items-center px-6 pb-8">
          {!isError && (
            <>
              <div className="text-[20px] leading-[17px]">Deposit Completed</div>
              <div className="flex w-full justify-between pb-6 pt-8">
                <div className="pb-2">
                  {
                    <div className="gap flex text-xs">
                      {poolAssets.map((token) => (
                        <TokenDisplay
                          chainId={token.chainId}
                          key={getAssetKey(token)}
                          token={token.address}
                          size="sm"
                          showSymbol={false}
                        />
                      ))}
                    </div>
                  }
                </div>
                <div className="text-xs text-gray-800">{formatCurrency(totalAmount, 2)} </div>
              </div>
            </>
          )}

          {isError && (
            <>
              <div className="mb-6 flex flex-col items-center gap-4 p-4">
                <div className="text-[20px] leading-[17px] text-red-600">Deposit Failed</div>
                <div className="text-sm">Funds will be refunded on the source chain in ~1 hour.</div>
              </div>
            </>
          )}

          <div
            onClick={() => window.open(fromChain.getTransactionUrl(txnDetails.hash), '_blank')}
            className="mb-2 flex w-full cursor-pointer justify-center rounded-[18px] bg-primary py-[5px] text-[12px] leading-[10px] text-white"
          >
            View Explorer
          </div>
          <div
            onClick={handleViewDetails}
            className="flex w-full cursor-pointer justify-center rounded-[18px] bg-primary py-[5px] text-[12px] leading-[10px] text-white"
          >
            View Receipt
          </div>
        </div>
      </div>
      {createPortal(
        <PoolDepositModal
          poolAssets={poolAssets}
          poolDepositRequest={requests}
          open={isDetailsOpen}
          handleCloseModal={handleCloseModal}
          partialDeposit={false}
          withLiquiditySwap={Boolean(withLiquiditySwap)}
          depositInfo={depositInfo}
          liquiditySwapFinalAmounts={liquiditySwapFinalSwapAmounts}
          liquiditySwapFinalValues={liquiditySwapFinalValues}
          poolDepositStep={PoolInteractionStep.Completed}
          handleCompleteDeposit={handleCloseModal}
        />,
        document.body,
      )}
    </>
  );
};
