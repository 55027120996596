import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { toUnits } from '~/config/utils/tokens.utils';
import { StoreContext } from '~/modules/common/store/context';
import { SwapError } from '~/modules/swap';

export const QuoteError = observer(() => {
  const { swap } = useContext(StoreContext);
  const { swapInput, sourceAsset, swapError } = swap;

  if (!swapError || swapError === SwapError.NO_ROUTE) {
    return null;
  }

  return (
    <div className="mt-[72px] flex flex-col items-center">
      <div className="text-xs font-medium leading-4 text-gray-600">Sorry, we couldn't generate a quote.</div>
      <div
        className="mt-3 flex h-5 w-[70px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-xs leading-[10px] text-white"
        onClick={() => {
          if (swapInput && sourceAsset) {
            swap.quote(toUnits(swapInput, sourceAsset.decimals));
          }
        }}
      >
        Try again
      </div>
    </div>
  );
});

export default QuoteError;
