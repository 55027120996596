import { ReactComponent as CircleCheckIcon } from '~/img/circle_check.svg';

import { Button } from './Button';
import { Modal } from './Modal';

interface TosAttestationModalProps {
  isOpen: boolean;
  isAttestationComplete: boolean;
  onSignature: () => void;
  onClose: () => void;
}

const TosAttestationModal = ({ isOpen, isAttestationComplete, onClose, onSignature }: TosAttestationModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="flex w-[95vw] flex-col gap-[27px] rounded-[25px] bg-white p-10 md:w-[50vw]">
        <img src={'/img/logo_dark_web.svg'} alt="Catalyst Logo" className="w-[155px] md:w-[160px]" />
        {isAttestationComplete ? (
          <div className="flex flex-col items-center justify-center gap-3">
            <CircleCheckIcon className="h-11 w-11 md:h-12 md:w-12" />
            <p className="font-subheader text-lg md:text-xl">Wallet attestation succeeded.</p>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <p className="font-subheader text-sm md:text-base">
              By using the Catalyst UI (catalyst.exchange), you agree to the 
              <a className="underline" href="/terms-of-service" target="_blank">
                Terms of Service
              </a>
               and 
              <a className="underline" href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              .
            </p>
            <p className="font-sans text-xs font-medium text-grey-800 md:text-sm">
              It appears that you are accessing the Catalyst UI from outside of the United States or a restricted
              territory. You hereby agree, represent, and warrant that:
            </p>
            <ol className="flex list-decimal flex-col gap-3 px-4 font-sans text-xs font-medium text-grey-500 md:text-sm">
              <li>
                You are not a person or company who is a resident of, or is located, incorporated, or has a registered
                agent in the United States or restricted location.
              </li>
              <li>
                You are not using, and will not in the future use, a VPN to mask your physical location from a
                restricted location.
              </li>
              <li>
                You are lawfully permitted to access this site and to use the Catalyst UI under the laws of the
                jurisdiction in which you reside and are located.
              </li>
            </ol>
          </div>
        )}
        <Button onClick={isAttestationComplete ? onClose : onSignature} fullWidth>
          {isAttestationComplete ? 'Close' : 'Sign Agreement'}
        </Button>
      </div>
    </Modal>
  );
};

export default TosAttestationModal;
