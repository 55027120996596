import { VaultInteraction } from '@catalabs/catalyst-api-client';
import { useContext, useState } from 'react';

import { StoreContext } from '~/modules/common';
import { usePools } from '~/modules/pools';

export const useVaultInteractionList = () => {
  const { wallet, catalyst, pool } = useContext(StoreContext);
  const trxStore = wallet.transactions;

  const [selectedVaultInteraction, setSelectedVaultInteraction] = useState<VaultInteraction | null>(null);

  const { allCatalystPools: pools } = usePools(pool, catalyst);
  const poolAssetsById = new Map(
    pools.map((pool) => [pool.id, pool.assets.map((asset) => ({ chain: asset.chainId, address: asset.address }))]),
  );

  const fetchVaultInteractionsOnScroll = async () => {
    if (
      trxStore.vaultInteractions.length === 0 ||
      trxStore.vaultInteractions.length === trxStore.vaultInteractionCount
    ) {
      // Do not fetch before initial load or when all vault interactions are fetched
      return;
    }
    await trxStore.fetchVaultInteractions();
  };

  return {
    vaultInteractions: trxStore.vaultInteractions,
    vaultInteractionCount: trxStore.vaultInteractionCount,
    isVaultInteractionsLoading: trxStore.isVaultInteractionsLoading,
    selectedVaultInteraction,
    isVaultInteractionSelected: selectedVaultInteraction !== null,
    selectVaultInteraction: (vaultInteraction: VaultInteraction) => setSelectedVaultInteraction(vaultInteraction),
    unselectVaultInteraction: () => setSelectedVaultInteraction(null),
    poolAssetsById,
    fetchVaultInteractionsOnScroll,
  };
};
