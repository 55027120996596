import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';

import { formatBalance, getAssetLookupAddress } from '~/config/utils/tokens.utils';
import { TokenDisplay, Tooltip } from '~/modules/common';
import { AmountInput } from '~/modules/common/components/AmountInput';
import { BalanceDisplay } from '~/modules/common/components/BalanceDisplay';
import { Input } from '~/modules/common/components/Input';
import { StoreContext } from '~/modules/common/store/context';
import { formatCurrency } from '~/modules/common/utils';
import ChainSelector from '~/modules/swap/components/ChainSelector';
import { ChainSelectMode, SwapCardType } from '~/modules/swap/enums';
import { useQuote } from '~/modules/swap/hooks';

export interface SwapCardProps {
  cardType: SwapCardType;
}

export const SwapCard = observer(({ cardType }: SwapCardProps) => {
  const { swap, wallet, catalyst } = useContext(StoreContext);
  const {
    sourceNetwork,
    sourceAsset,
    targetNetwork,
    targetAsset,
    lastQuote,
    swapInput,
    maxSlippage,
    toAccount,
    hasSufficientTokens,
    hasSufficientGas,
    securityLimitActivated,
  } = swap;
  const { address } = wallet;

  const { triggerQuote, getMaxSwapAmount } = useQuote();
  const [isInitialized, setIsInitialized] = useState(false);
  const [open, setOpen] = useState(false);

  const networkAsset = cardType === SwapCardType.Input ? sourceAsset : targetAsset;
  const selectedChainId = cardType === SwapCardType.Input ? sourceNetwork : targetNetwork;
  const chainSelectMode = cardType === SwapCardType.Input ? ChainSelectMode.Source : ChainSelectMode.Target;

  const lookupConvertedAddress = sourceAsset ? getAssetLookupAddress(sourceAsset) : undefined;
  const lookupAddress = isInitialized ? lookupConvertedAddress : undefined;
  const lookupBalance = lookupAddress && sourceNetwork ? wallet.getBalance(sourceNetwork, lookupAddress) : undefined;
  const userBalance = lookupBalance ? formatBalance(lookupBalance.amount, lookupBalance.decimals) : 0;

  const isBalanceZero = isInitialized && userBalance === 0 && cardType === SwapCardType.Input;

  async function setMaxInput() {
    const maxSwapAmount = await getMaxSwapAmount(userBalance);

    if (swapInput === maxSwapAmount) {
      return;
    }

    await triggerQuote(maxSwapAmount);

    runInAction(() => {
      if (isInitialized) {
        swap.swapInput = maxSwapAmount;
      }
    });
  }

  useEffect(() => {
    if (swap.swapInput) {
      runInAction(() => (swap.swapInput = undefined));
    }
  }, []);

  useEffect(() => {
    if (!selectedChainId || !networkAsset) {
      if (chainSelectMode === ChainSelectMode.Source) {
        runInAction(() => (swap.swapInput = undefined));
      }
      setIsInitialized(false);
    }
    if (!isInitialized && selectedChainId && networkAsset) {
      setIsInitialized(true);
    }
  }, [selectedChainId, networkAsset]);

  useEffect(() => {
    runInAction(() => {
      if (swapInput) {
        triggerQuote(swapInput);
      }
    });
  }, [sourceNetwork, sourceAsset, maxSlippage, toAccount]);

  async function initialize(chainId: string, selectedAsset: TokenInfo) {
    const { address } = selectedAsset;

    if (chainSelectMode === ChainSelectMode.Source) {
      if (chainId === targetNetwork && address === targetAsset?.address) {
        await swap.swapNetworks();
      } else if (chainId !== sourceNetwork || address !== sourceAsset?.address) {
        await swap.setSourceParameters(chainId, selectedAsset);
      }
    }
    if (chainSelectMode === ChainSelectMode.Target) {
      if (chainId === sourceNetwork && address === sourceAsset?.address) {
        await swap.swapNetworks();
      } else if (chainId !== targetNetwork || address !== targetAsset?.address) {
        await swap.setTargetParameters(chainId, selectedAsset);
        if (swapInput && sourceAsset) {
          await triggerQuote(swapInput);
        }
      }
    }
  }

  function updateInput() {
    setOpen(true);
    runInAction(() => (swap.chainSelectMode = chainSelectMode));
  }

  function updateInputText(e: React.ChangeEvent<HTMLInputElement>) {
    runInAction(() => (swap.swapInput = e.target.value));
    triggerQuote(e.target.value);
  }

  const hasError = securityLimitActivated || !hasSufficientGas || !hasSufficientTokens;
  const showAlert = address && isInitialized && cardType === SwapCardType.Input && hasError;

  const tokenAmount =
    cardType === SwapCardType.Input ? (swapInput ? Number(swapInput) : 0) : lastQuote ? lastQuote.amountOut : 0;
  const swapValue = tokenAmount * catalyst.getPrice(networkAsset);

  let priceImpact = undefined;
  if (lastQuote && cardType === SwapCardType.Output) {
    priceImpact = (lastQuote.toValue / lastQuote.fromValue - 1) * 100;
  }
  const isValidPriceImpact = priceImpact ? !isNaN(priceImpact) : false;

  return (
    <div
      className={`flex h-[180px] flex-col rounded-3xl p-5 shadow-md transition-all hover:shadow-xl md:w-[348px] ${
        showAlert ? 'alert' : 'bg-white'
      }`}
    >
      <ChainSelector
        onSelect={initialize}
        open={open}
        onClose={() => setOpen(false)}
        selectedChainId={selectedChainId}
        chainSelectMode={chainSelectMode}
      />
      <div className="pb-2 font-bold text-sm leading-[14px] text-grey-800">
        {chainSelectMode === ChainSelectMode.Source ? 'SEND' : 'RECEIVE'}
      </div>

      <div className="flex flex-col gap-4">
        <div
          onClick={updateInput}
          className="group flex w-fit cursor-pointer items-center justify-between rounded-full border border-grey-100 bg-grey-100 p-2 hover:border-primary"
          data-testid={cardType === SwapCardType.Input ? 'swap-select-button-from' : 'swap-select-button-to'}
        >
          {isInitialized ? (
            selectedChainId && networkAsset && <TokenDisplay chainId={selectedChainId} token={networkAsset.address} />
          ) : (
            <div className="flex items-end">
              <div className="h-8 w-8 rounded-full bg-grey-200" />
              <div className="z-2 -ml-2 h-4 w-4 rounded-full border border-white bg-grey-200" />
              <div className="ml-2 self-center text-grey-800">Select Token</div>
            </div>
          )}
          <ChevronDownIcon className="ml-4 mr-2 h-5 w-5 text-grey-500 group-hover:text-primary" />
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between font-semibold text-2xl leading-6">
            {cardType === SwapCardType.Input && (
              <AmountInput
                className={`text-ellipsis bg-transparent placeholder-black ${
                  !showAlert ? 'text-grey-800' : 'text-red-700'
                }`}
                onBlur={() =>
                  runInAction(() => {
                    if (!swapInput) {
                      swap.swapInput = undefined;
                    }
                  })
                }
                onClick={() =>
                  runInAction(() => {
                    if (!swapInput) {
                      swap.swapInput = '';
                    }
                  })
                }
                disabled={!isInitialized}
                onChange={(e) => updateInputText(e)}
                value={swapInput ?? '0.00'}
                data-testid="swap-input-from"
              />
            )}
            {cardType === SwapCardType.Output && (
              <>
                {lastQuote?.amountOut && lastQuote?.amountOut.toString().length > 11 ? (
                  <Tooltip
                    tooltip={
                      <span className="absolute bottom-10 left-1/2 -translate-x-1/2 whitespace-break-spaces rounded-2xl bg-white text-xs font-normal leading-5 text-gray-500 shadow-2xl">
                        {lastQuote?.amountOut.toString() || '0.00'}
                      </span>
                    }
                    className="w-full"
                    tooltipStyle="rounded-3xl font-normal"
                  >
                    <Input
                      className="text-ellipsis bg-transparent text-gray-800"
                      disabled={true}
                      step={'any'}
                      numeric
                      min={0}
                      onWheel={(e) => e.currentTarget.blur()}
                      onChange={(e) => updateInputText(e)}
                      value={lastQuote?.amountOut ?? '0.00'}
                      data-testid="swap-input-to"
                    />
                  </Tooltip>
                ) : (
                  <Input
                    numeric
                    className="text-ellipsis bg-transparent text-gray-800"
                    disabled={true}
                    step={'any'}
                    min={0}
                    onWheel={(e) => e.currentTarget.blur()}
                    onChange={(e) => updateInputText(e)}
                    value={lastQuote?.amountOut ?? '0.00'}
                    data-testid="swap-input-to"
                  />
                )}
              </>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className={`text-sm leading-[14px] text-grey-500 ${isValidPriceImpact ? 'flex w-full' : ''}`}>
              <span className="mr-4">{formatCurrency(swapValue)}</span>
              {isValidPriceImpact && (
                <Tooltip
                  tooltip={
                    <span
                      className="absolute bottom-10 left-1/2 min-w-[240px] -translate-x-1/2 whitespace-break-spaces rounded-2xl
                bg-white p-5 text-sm leading-5 text-gray-500 shadow-2xl"
                    >
                      The estimated difference between the USD values of input and output amounts.
                    </span>
                  }
                  tooltipStyle="rounded-lg bottom-4 text-sm text-grey-500"
                >
                  <span className={`${priceImpact && priceImpact > 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {priceImpact && priceImpact > 0 && '+'}
                    {priceImpact?.toLocaleString(undefined, { maximumFractionDigits: 2 }) ?? 0.0}%
                  </span>
                </Tooltip>
              )}
            </div>
            {isBalanceZero && address && <span className="textred-700 font-semibold text-sm ">Balance 0.00</span>}
            {userBalance > 0 && cardType === SwapCardType.Input && (
              <BalanceDisplay balance={userBalance} error={!hasSufficientTokens} onClick={setMaxInput} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default SwapCard;
