import { ReactComponent as Error } from '~/img/triangle_warning.svg';

export function PoolError(): JSX.Element {
  return (
    <div className="error mt-[22px] flex w-full items-center justify-center rounded-[25px] py-4">
      <Error />
      <span className="pl-3 text-sm font-medium leading-5 text-red-600">
        We can't load any data from our API, please reload the page or try again.
      </span>
    </div>
  );
}
