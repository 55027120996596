export interface PercentageSelectorProps {
  handlePercentageChange: (num: number) => void;
  percentage: number;
  withdrawPercentage?: number;
  disabled?: boolean;
}

export function PercentageSelector({
  handlePercentageChange,
  percentage,
  withdrawPercentage,
  disabled,
}: PercentageSelectorProps) {
  return (
    <button
      onClick={() => handlePercentageChange(percentage)}
      className={`flex w-full cursor-pointer items-center justify-center rounded-xl border bg-white py-2 md:h-[58px] md:w-[107px] md:py-0 ${
        withdrawPercentage === percentage ? 'border-2 border-primary' : 'border-gray'
      } ${disabled ? 'opacity-30' : ''}`}
      disabled={disabled}
      data-testid={`withdraw-${percentage}-percent-button`}
    >
      <span className="ml-1 text-grey-600">{percentage}%</span>
    </button>
  );
}

export default PercentageSelector;
