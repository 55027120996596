import { CatalystNetwork as Environment } from '@catalabs/catalyst-sdk';

function nonNullableEnvVar(name: string): string {
  const value = process.env[name];
  if (value === undefined) {
    throw new Error(`Missing environment variable: ${name}`);
  }
  return value;
}

export const CLIENT_API_URL: string = process.env.REACT_APP_CLIENT_API_URL ?? 'https://api.catalyst.exchange';
export const WALLETCONNECT_PROJECT_ID: string = nonNullableEnvVar('REACT_APP_WALLETCONNECT_PROJECT_ID');
export const CAPSULE_API_KEY: string = process.env['REACT_APP_CAPSULE_API_KEY'] ?? '';

const getEnvironment = (): Environment => {
  const env = nonNullableEnvVar('REACT_APP_ENVIRONMENT');

  if (!Object.values(Environment).includes(env as Environment)) {
    throw new Error(`Invalid environment: ${env}`);
  }

  return env as Environment;
};
export const ENVIRONMENT: Environment = getEnvironment();
