import { Button } from '~/modules/common';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';

export function CenteredConnectWallet() {
  const { connect } = useConnectButton();
  return (
    <div className="absolute left-1/2 top-1/2 z-10 mx-auto my-auto -translate-x-1/2 -translate-y-1/2 transform">
      <Button className="mt-0 h-10 w-fit px-5" onClick={connect}>
        Connect Wallet
      </Button>
    </div>
  );
}
