import { useContext, useEffect, useState } from 'react';

import { StoreContext } from '~/modules/common';
import { TransactionType } from '~/modules/transactions/enums';

export const useTransactionsPage = () => {
  const { wallet } = useContext(StoreContext);
  const { swapCount, vaultInteractionCount } = wallet.transactions;

  const [selectedTab, setSelectedTab] = useState(TransactionType.SWAPS);

  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([wallet.transactions.fetchSwaps(), wallet.transactions.fetchVaultInteractions()]);
    };
    initializeData();
  }, [wallet.address]);

  return {
    swapCount,
    vaultInteractionCount,
    selectedTab,
    selectSwapsTab: () => setSelectedTab(TransactionType.SWAPS),
    selectVaultInteractionsTab: () => setSelectedTab(TransactionType.VAULT_INTERACTIONS),
  };
};
