import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { Web3OnboardProvider } from '@web3-onboard/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';

import router from '~/config/router.config';
import { store, StoreProvider } from '~/modules/common';
import reportWebVitals from '~/reportWebVitals';

import { web3Onboard } from './config/onboard.config';
import setupMonitoring from './setupMonitoring';

setupMonitoring();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <StoreProvider value={store}>
        <RouterProvider router={router} />
      </StoreProvider>
    </Web3OnboardProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
