import { Swap } from '@catalabs/catalyst-api-client';
import { useContext, useState } from 'react';

import { StoreContext } from '~/modules/common';

export const useSwapList = () => {
  const { wallet } = useContext(StoreContext);
  const trxStore = wallet.transactions;

  const [selectedSwap, setSelectedSwap] = useState<Swap | null>(null);

  const fetchSwapsOnScroll = async () => {
    if (trxStore.swaps.length === 0 || trxStore.swaps.length === trxStore.swapCount) {
      // Do not fetch before initial load or when all swaps are fetched
      return;
    }
    await trxStore.fetchSwaps();
  };

  return {
    swaps: trxStore.swaps,
    swapCount: trxStore.swapCount,
    isSwapsLoading: trxStore.isSwapsLoading,
    selectedSwap,
    isSwapSelected: selectedSwap !== null,
    selectSwap: (swap: Swap) => setSelectedSwap(swap),
    unselectSwap: () => setSelectedSwap(null),
    fetchSwapsOnScroll,
  };
};
