import { ReactComponent as Inactive } from '~/img/emptycircle.svg';

export interface PoolApprovalInactiveProps {
  tokenIdentifier: string;
}

export function PoolApprovalInactive({ tokenIdentifier }: PoolApprovalInactiveProps) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center rounded-[25px] bg-white p-5 md:w-[552px]">
        <Inactive />
        <span className={`ml-4 font-semibold text-xs uppercase leading-4 tracking-wide text-gray-600`}>
          Approve access to your {tokenIdentifier} tokens
        </span>
      </div>
      <div className="mt-4 flex items-center rounded-[25px] bg-white p-5 md:w-[552px]">
        <Inactive />
        <span className={`ml-4 font-semibold text-xs uppercase leading-4 tracking-wide text-gray-600`}>
          Sign the permit to authorize {tokenIdentifier} token transfer
        </span>
      </div>
    </div>
  );
}

export default PoolApprovalInactive;
