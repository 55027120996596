import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Complete } from '~/img/circle_check.svg';
import { ReactComponent as Inactive } from '~/img/emptycircle.svg';
import { PoolActionState } from '~/modules/pools';

export interface PoolChainSwapProps {
  actionState: PoolActionState;
  chainSwapText: string;
}

export function PoolChainSwap({ actionState, chainSwapText }: PoolChainSwapProps) {
  if (actionState === PoolActionState.Completed) {
    return null;
  }
  return (
    <div
      className="mb-4 mt-4 flex items-center rounded-[25px] bg-white p-5 md:w-[552px]"
      data-testid="pool-switch-chain"
    >
      {actionState === PoolActionState.Inactive && (
        <div className="flex items-center">
          <Inactive />
          <span className="ml-4 font-semibold text-xs uppercase leading-4 tracking-wide text-gray-600">
            {chainSwapText}
          </span>
        </div>
      )}
      {(actionState === PoolActionState.Pending || actionState === PoolActionState.Confirmed) && (
        <div className="flex w-full flex-col">
          <div className="flex">
            {actionState === PoolActionState.Pending ? <Active /> : <Complete className="h-8 w-8" />}
            <div className="ml-4 flex items-center">
              <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">
                {chainSwapText}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PoolChainSwap;
