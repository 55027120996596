import { PoolAsset } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { formatBalance, TokenBalance } from '~/config';
import { formatCurrency, TokenDisplay } from '~/modules/common';
import { AmountInput } from '~/modules/common/components/AmountInput';
import { BalanceDisplay } from '~/modules/common/components/BalanceDisplay';

import { DepositLiquiditySwapInfoItem } from '../interfaces';
import { getAssetKey } from '../utils';
import { AssetInputOptionsDropdown } from './AssetInputOptionsDropdown';
import { LiquiditySwapQuoteModal } from './LiquiditySwapQuoteModal';

export interface PoolTokenDepositPaneProps {
  asset: PoolAsset;
  walletBalance?: TokenBalance;
  gasTokenBalance?: TokenBalance;
  depositDisplay?: string;
  depositValue: number;
  depositWeight: number;
  updateInputText: (asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) => void;
  setMaxInput: (asset: PoolAsset) => void;
  handleDepositPaneClick: (asset: PoolAsset) => void;
  handleDepositPanelBlur: (asset: PoolAsset) => void;
  liquiditySwapQuote?: DepositLiquiditySwapInfoItem;
  allLiquiditySwapQuotes?: DepositLiquiditySwapInfoItem[];
  poolAssets: PoolAsset[];
  handleUseGasToken: (asset: PoolAsset, useGasToken: boolean) => void;
}

export interface PoolTokenDepositPaneHandle {
  focus: () => void;
  shake: () => void;
}

export type PoolTokenDepositRef = React.ElementRef<typeof PoolTokenDepositPane>;

const PoolTokenDepositPane = forwardRef<PoolTokenDepositPaneHandle, PoolTokenDepositPaneProps>(
  (
    {
      asset,
      walletBalance,
      gasTokenBalance,
      depositDisplay,
      depositValue,
      updateInputText,
      setMaxInput,
      handleDepositPaneClick,
      handleDepositPanelBlur,
      liquiditySwapQuote,
      poolAssets,
      allLiquiditySwapQuotes,
      handleUseGasToken,
    }: PoolTokenDepositPaneProps,
    ref,
  ) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [openLiquiditySwapQuoteModal, setOpenLiquiditySwapQuoteModal] = useState(false);
    const [useGasToken, setUseGasToken] = useState(false);

    const balanceRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleBlur = () => {
      setHasFocus(false);
      handleDepositPanelBlur(asset);
    };

    const userBalance = useGasToken
      ? formatBalance(gasTokenBalance?.amount || 0, gasTokenBalance?.decimals || 18)
      : formatBalance(walletBalance?.amount || 0, walletBalance?.decimals || 18);
    const hasError = Number(depositDisplay) > Number(userBalance);
    const borderClass = hasError ? 'border-red-600' : hasFocus ? 'border-primary-600' : 'border-gray';

    const liquiditySwapsForAsset = liquiditySwapQuote?.quoteDetails.filter((quoteDetail) => {
      return quoteDetail.swap.from.some(({ token }) => token.address === asset.address);
    });

    useImperativeHandle(ref, () => ({
      focus: () => {
        balanceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        inputRef?.current?.focus();
      },
      shake: () => {
        balanceRef.current?.classList.add('animate-shake');
        setTimeout(() => {
          balanceRef.current?.classList.remove('animate-shake');
        }, 1000);
      },
    }));

    const assetKey = getAssetKey(asset);
    const gasAssetKey = getAssetKey({ chainId: asset.chainId, address: GAS_TOKEN_IDENTIFIER });

    const assetOptions = [gasAssetKey, assetKey];

    return (
      <>
        {liquiditySwapQuote && allLiquiditySwapQuotes && (
          <LiquiditySwapQuoteModal
            open={openLiquiditySwapQuoteModal}
            onClose={() => {
              setOpenLiquiditySwapQuoteModal(false);
            }}
            quotes={allLiquiditySwapQuotes}
            poolAssetsKeys={poolAssets.map((asset) => getAssetKey(asset))}
          />
        )}
        <div className={`flex flex-col gap-1 ${borderClass} overflow-hidden rounded-[15px] border-[1.5px]`}>
          <div className={`flex h-[84px] flex-row justify-between  gap-2 p-4 md:w-full`}>
            <div className="flex flex-col items-start gap-2">
              <AssetInputOptionsDropdown
                assetKeys={assetOptions}
                onChange={(assetKey) => {
                  setUseGasToken(assetKey === gasAssetKey);
                  handleUseGasToken(asset, assetKey === gasAssetKey);
                }}
                selected={useGasToken ? gasAssetKey : assetKey}
              />
              <div ref={balanceRef}>
                <BalanceDisplay
                  balance={userBalance}
                  error={hasError}
                  onClick={() => {
                    setMaxInput(asset);
                  }}
                />
              </div>
            </div>
            <div className="flex h-full flex-col items-end justify-center">
              <AmountInput
                onBlur={handleBlur}
                onClick={() => handleDepositPaneClick(asset)}
                className={`font-subheader ${
                  hasError ? 'text-red-600' : 'text-gray-800'
                }  h-full bg-transparent text-right text-[32px]`}
                onChange={(e) => updateInputText(asset, e)}
                value={depositDisplay !== undefined ? depositDisplay : '0.00'}
                onFocus={() => setHasFocus(true)}
                inputRef={inputRef}
              />
              <div className="mt-1 flex justify-between">
                <span className="text-grey-600">{formatCurrency(depositValue)}</span>
              </div>
            </div>
          </div>

          {liquiditySwapsForAsset && liquiditySwapsForAsset.length > 0 && (
            <div
              className="flex cursor-pointer items-center justify-between bg-gray-100 p-2 hover:bg-gray-200"
              onClick={() => setOpenLiquiditySwapQuoteModal(true)}
            >
              <span className="text-xs font-normal text-gray-500">Liquidity Swaps</span>
              <div className="flex items-center">
                <TokenDisplay
                  chainId={asset.chainId.toString()}
                  token={asset.address}
                  showSymbol={false}
                  showChain={false}
                  showTooltip={false}
                  showChainIcon
                  size="sm"
                />
                <span className="mr-2 flex ">
                  <ArrowLongRightIcon className="h-4 w-4" />
                </span>
                {liquiditySwapsForAsset.map((quoteDetail) => {
                  return quoteDetail.swap.to.map(({ token }, index) => {
                    return (
                      <div key={index}>
                        <TokenDisplay
                          chainId={token.chainId.toString()}
                          token={token.address}
                          showSymbol={false}
                          showChain={false}
                          showTooltip={false}
                          showChainIcon
                          size="sm"
                        />
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default PoolTokenDepositPane;
