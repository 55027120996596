import { useEffect, useState } from 'react';

import { WalletStore } from '~/modules/common';
import { ReferralStore } from '~/modules/common/store/referral-store';

export function useOwnReferral(referralStore: ReferralStore, walletStore: WalletStore) {
  const { address } = walletStore;
  const { ownReferral } = referralStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!address) {
      return;
    }
    setIsLoading(true);

    referralStore.getReferralByAddress(address).then(() => {
      setIsLoading(false);
    });
  }, [address]);

  return {
    ownReferral,
    isLoading,
  };
}
