export const TOS_ATTESTATION_VALUE_STRUCT_TYPE = {
  Attestation: [
    { name: 'wallet', type: 'address' },
    { name: 'timestamp', type: 'uint256' },
  ],
};

export const TOS_ATTESTATION_DOMAIN = {
  name: 'Catalyst App',
};
