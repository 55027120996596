import { TokenInfo } from '@uniswap/token-lists';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';

import { formatBalance } from '~/config/utils/tokens.utils';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { getAnimationState, StoreContext } from '~/modules/common';
import { SwapSettings } from '~/modules/swap';
import { SwapQuote } from '~/modules/swap/interfaces';

export interface SlippageDetailsProps {
  targetAsset: TokenInfo;
  quote: SwapQuote;
  tokenValue: number;
}

export function SlippageDetails({ quote, targetAsset, tokenValue }: SlippageDetailsProps): JSX.Element {
  const { amountOut, netMinOut } = quote;
  const { symbol: toSymbol } = targetAsset;
  const minExpected = formatBalance(netMinOut, targetAsset.decimals);

  return (
    <div className="my-2 flex flex-col space-y-2 px-2">
      <div className="bggrey-100 flex h-[36px] items-center justify-between rounded-[15px] px-2 text-sm">
        <span className="text-grey-500">Min output</span>
        <div>
          <span className=" mr-4 text-grey">
            {minExpected.toLocaleString(undefined, { minimumFractionDigits: 4 })} {toSymbol}
          </span>
          <span className="text-grey">
            ${(minExpected * tokenValue).toLocaleString(undefined, { minimumFractionDigits: 4 })}
          </span>
        </div>
      </div>
      <div className="bggrey-100 flex h-[36px] items-center justify-between rounded-[15px] px-2 text-sm">
        <span className="text-grey-500">Expected output</span>
        <div>
          <span className="mr-4 text-grey">
            {amountOut.toLocaleString(undefined, { minimumFractionDigits: 4 })} {toSymbol}
          </span>
          <span className="text-grey">
            ${(amountOut * tokenValue).toLocaleString(undefined, { minimumFractionDigits: 4 })}
          </span>
        </div>
      </div>
    </div>
  );
}

export interface SwapSlippageProps {
  allowEdit?: boolean;
}

export const SwapSlippage = observer(({ allowEdit = false }: SwapSlippageProps) => {
  const { swap, catalyst } = useContext(StoreContext);

  const [showDetails, setShowDetails] = useState(false);
  const [open, setOpen] = useState(false);

  const { maxSlippage, lastQuote, targetAsset } = swap;
  const isSwapSlippage = lastQuote && targetAsset;

  return (
    <div className="flex w-full flex-col justify-center rounded-[20px] bg-white py-2 text-[14px] leading-5">
      <SwapSettings open={open} onClose={() => setOpen(false)} />
      <div className="flex justify-between">
        <div onClick={() => setShowDetails(!showDetails)} className="flex flex-grow cursor-pointer items-center">
          <span className="ml-4 font-medium text-gray-600">Slippage Tolerance</span>
          {isSwapSlippage && (
            <div
              className={`${getAnimationState(showDetails)} ml-2 cursor-pointer`}
              onClick={() => setShowDetails(!showDetails)}
            >
              <Caret className="rotate-90 text-gray-600" />
            </div>
          )}
        </div>
        {allowEdit && (
          <div
            onClick={() => setOpen(true)}
            className="font-xs mr-2 flex h-5 w-[65px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-white"
          >
            <span className="mr-[5px] text-sm">{maxSlippage}%</span>
            <Caret className="-rotate-90" />
          </div>
        )}
        {!allowEdit && <span className="mr-4 font-semibold text-sm leading-5">{maxSlippage}%</span>}
      </div>

      {lastQuote && targetAsset && showDetails && (
        <SlippageDetails quote={lastQuote} targetAsset={targetAsset} tokenValue={catalyst.getPrice(targetAsset)} />
      )}
    </div>
  );
});

export default SwapSlippage;
