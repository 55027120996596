import { FunctionComponent, SVGProps } from 'react';

export const PageHeader = ({
  title,
  subTitle,
  total,
  HeaderImage,
  MobileHeaderImage,
}: {
  title: string;
  subTitle?: string;
  total?: {
    text: string;
    value: string;
  };
  HeaderImage: FunctionComponent<SVGProps<SVGSVGElement>>;
  MobileHeaderImage: FunctionComponent<SVGProps<SVGSVGElement>>;
}) => {
  return (
    <div className="relative mt-10 flex h-[300px] w-full flex-col justify-end rounded-3xl text-white md:mt-20 md:h-[244px] md:w-[1224px] md:flex-row md:items-end md:justify-between">
      <div className="absolute -z-10 w-full">
        <HeaderImage className="hidden rounded-3xl md:block" />
        <MobileHeaderImage className="rounded-3xl md:hidden" />
      </div>
      <div className="flex flex-col px-6 pb-5 md:p-0 md:pb-[40px] md:pl-10">
        <span className="mb-2 font-header text-[32px] leading-[28px] md:mt-0">{title}</span>
        {subTitle && <span className="leading-5">{subTitle}</span>}
      </div>
      {total && (
        <div className="mx-6 mb-6 flex flex-col items-center space-y-4 text-xs text-gray-800 md:m-0 md:mx-10 md:pb-[40px] md:pr-10">
          <div className="flex h-[56px] w-full items-center justify-between rounded-2xl bg-white font-semibold md:w-[321px] ">
            <span className="pl-6 uppercase leading-[16px] tracking-wider">{total.text}</span>
            <span className="pr-6 leading-6">{total.value}</span>
          </div>
        </div>
      )}
    </div>
  );
};
