import * as HoverCard from '@radix-ui/react-hover-card';
import { useState } from 'react';

import { openNewTab } from '~/config';
import { ReactComponent as DropDown } from '~/img/header_dropdown.svg';

export interface InfoBoxOptionProps {
  title: string | JSX.Element;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void>;
}

export function InfoBoxOption({ title, href, onClick }: InfoBoxOptionProps): JSX.Element {
  const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (href) {
      openNewTab(href);
    } else if (onClick) {
      await onClick(e);
    }
  };

  return (
    <div className="cursor-pointer rounded-2xl px-3 py-2 tracking-wider hover:bg-gray-100" onClick={handleClick}>
      {title}
    </div>
  );
}

interface InfoBoxProps {
  options: InfoBoxOptionProps[];
  contentPosition?: {
    side?: 'top' | 'right' | 'bottom' | 'left';
    sideOffset?: number;
    align?: 'start' | 'center' | 'end';
    alignOffset?: number;
  };
  triggerClassName?: string;
  contentClassName?: string;
}

export function InfoBox({
  options,
  contentPosition = { side: 'bottom', sideOffset: 0, align: 'start', alignOffset: 0 },
  triggerClassName = '',
  contentClassName = '',
}: InfoBoxProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  return (
    <HoverCard.Root open={isOpen} openDelay={0} onOpenChange={(value) => handleOpenChange(value)}>
      <HoverCard.Trigger
        onClick={() => handleOpenChange(!isOpen)}
        className={`group relative flex h-9 w-9 items-center justify-center rounded-full transition ease-in-out hover:bg-primary hover:bg-opacity-10 ${triggerClassName}`}
      >
        <DropDown id="dropdown-trigger" />
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          onFocusOutside={(e) => e.stopPropagation()}
          className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
          {...contentPosition}
        >
          <div
            className={`absolute left-[0px] z-20 flex w-[212px] flex-col rounded-[25px] bg-white p-1 text-sm text-grey-700 shadow-2xl ${contentClassName}`}
          >
            {options.map((option, index) => (
              <InfoBoxOption key={index} {...option} />
            ))}
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
