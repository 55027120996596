export const PrivacyPolicy = () => {
  return (
    <div className="flex flex-grow flex-col items-center">
      <main className="px-10 py-10 md:px-20 lg:px-[15rem] xl:px-[25rem] ">
        <p className="mb-5 flex w-full justify-center font-bold text-[30px] uppercase">CATA LABS Privacy Policy</p>
        <p className="mb-5">
          <strong>Last Updated</strong>: April 1, 2024
        </p>
        <p className="mb-5">
          Catalyst Labs, Inc., a Delaware Company (“Catalyst”, “we,” “us,” or “our”) provides access to the Cata Labs
          Interface (the “Interface”) and associated products (together, the “Products”). Please read this Privacy
          Policy carefully as it contains important information on who we are and how and why we collect, store, use,
          and share your personal and other information in connection with the use of the Products.
        </p>
        <p className="mb-5">
          By using the Products, you agree to this Privacy Policy. This Privacy Policy is subject to and incorporated by
          reference to our Terms of Service posted on
          <a href="http://www.catalyst.exchange" className="mx-2 text-gray-800">
            catalyst.exchange
          </a>
          .
        </p>
        <ol className="flex list-decimal flex-col gap-5">
          <li>
            <h3 className="font-subheader uppercase">Information We Collect About You.</h3>
            <p className="mb-5">
              <em>Personal Information</em>: We do not administer or maintain user accounts in connection with your use
              of the Products, and as such, we do not independently collect and store personal information, such as your
              name, contact information, other unique identifiers, financial information, or information regarding your
              use of the Products, such as your email or IP address, that identifies, relates to, describes, is
              reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to you
              in connection with your use the Products. We may, however, receive, collect, and store the following
              personal information in connection with your use of the Products.
              <ol className="flex list-disc flex-col gap-2">
                <li>
                  <p className="mb-5">
                    We may receive your wallet address or information about transactions made through your use of the
                    Products from our service providers in order to comply with our legal obligations and prevent
                    fraudulent or other illicit activities via use of the Products.
                  </p>
                </li>
                <li>
                  If you participate in a survey or usability study with us, we will record any biographical information
                  you directly provide to us, including information that may be personal information, such as your name,
                  email, or job title, the responses you provide to us, and your interactions with the Products.
                </li>
                <li>
                  You are not required to provide any personal information to us in order to use the Products. However,
                  if you provide any communications and/or information directly to us via email, social media, or other
                  channels, we may receive, collect, and store any personal information provided therein for the
                  purposes or instructions provided by you when providing the information, but we will not attempt to
                  link any of the information provided to your wallet address, IP address, or other of your personal
                  data.
                </li>
                <li>
                  <p className="mb-5">
                    We may access and collect information from localStorage, mobile deviceID, cookies, web beacons,
                    geographical location tracking features (“geo-fencing”); and other similar technologies to provide
                    the Products and features of the Products. Information that may be collected from these technologies
                    may include information that, when used alone or when aggregated with other information, constitutes
                    personal information.
                  </p>
                </li>
              </ol>
            </p>

            <p className="mb-4">
              <em>Other Information Collected</em>: Catalyst collects non-identifiable information, such as public
              on-chain data, and limited off-chain data (e.g., the device type or browser version used to access and use
              the Interface or other Products), as necessary to provide the Products to you, as well as for internal
              evaluation and enhancement of our Products. When you connect your blockchain wallet to the Interface or
              any other Products, we may collect and store your publicly-available blockchain address, which is
              publicly-available information and by itself is not personal information, to evaluate your use of the
              Products and to screen your wallet for any prior illicit activity.
            </p>
          </li>

          <li>
            <h3 className="font-subheader uppercase"> How Your Information is Collected and Used. </h3>
            <p className="mb-1">
              We collect most of your information from your use of the Products. However, we may also collect
              information:
            </p>
            <ol className="mb-5 flex flex-col gap-3 [list-style:lower-alpha]">
              <li>From publicly accessible sources (e.g., on-chain data, or property records);</li>
              <li>Directly from a third party (e.g., screening or due diligence providers);</li>
              <li>From a third party with your consent;</li>
              <li>From cookies and geographical location tracking features (“geo-fencing”); and</li>
              <li>
                Automated monitoring of our Products and other technical systems, such as our computer networks and
                connections, communications systems, email and messaging systems.
              </li>
              <li>We use the personal information listed above for the following business purposes:</li>
              <li>To provide our Products and/or to review and update our Products;</li>
              <li>
                To comply with our legal and regulatory obligations, including in fulfilment of our anti-money
                laundering regulatory obligations;
              </li>
              <li>For our legitimate interests or those of a third party; and</li>
              <li>Where you have given consent.</li>
            </ol>

            <p className="mb-5">
              The table below explains what we use your personal information for and our reasons for doing so:
            </p>
            <div className="border-1 mb-5 rounded-2xl border-gray-400">
              <table>
                <thead>
                  <tr>
                    <th className="border-2 border-gray-400 p-4">
                      <strong>What we use your personal information for</strong>
                    </th>
                    <th className="border-2 border-gray-400 p-4">
                      <strong>Our reasons</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">To provide the Products to you</td>
                    <td className="border-2 border-gray-400 p-4">
                      To provide, monitor, maintain, customize, update, and improve the Products being utilized by you
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      To prevent and detect fraud against you, the Interface and/or our Products
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      For our legitimate interests or those of a third party, i.e. to minimize fraud that could be
                      damaging for us and for you
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      Screening for financial and other sanctions or embargoes
                      <br />
                      <br />
                      Other processing necessary to comply with professional, legal and regulatory obligations that
                      apply to our business
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      Gathering and providing information required by or relating to audits, inquiries or investigations
                      by regulatory bodies
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      Operational reasons, such as improving efficiency, training and quality control
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      For our legitimate interests or those of a third party, i.e. to be as efficient as we can so we
                      can deliver the best Products for you at the best price
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      Ensuring the confidentiality of commercially sensitive information
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      For our legitimate interests or those of a third party, i.e. to protect trade secrets and other
                      commercially valuable information
                      <br />
                      <br />
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      Preventing unauthorized access and modifications to systems
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      For our legitimate interests or those of a third party, i.e. to prevent and detect criminal
                      activity that could be damaging for us and for you
                      <br />
                      <br />
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">To address security risks</td>
                    <td className="border-2 border-gray-400 p-4">
                      To monitor the Interface and the Products for potential security issues and protect our users
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">External audits and quality checks</td>
                    <td className="border-2 border-gray-400 p-4">
                      For our legitimate interests or a those of a third party, i.e. to demonstrate we operate at the
                      highest standards
                      <br />
                      <br />
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>

          <li>
            <h2 className="font-subheader uppercase">Who We Share Your Personal Information With.</h2>
            <p className="mb-3">For the reasons identified above, we may share personal information with:</p>
            <ol className="ga-2 mb-5 flex list-disc flex-col">
              <li>Our affiliates;</li>
              <li>Service providers we use to help deliver our Products to you;</li>
              <li>Other third parties we use to help us run our business;</li>
              <li>
                Third parties when we believe it is necessary under applicable law; to enforce our Terms of Service; to
                engage in a business transaction to protect our rights, privacy, safety, or property of yours or others;
                and to respond to requests from courts, law enforcement agencies, regulatory agencies, and other
                governmental authorities.
              </li>
            </ol>
          </li>

          <li>
            <h2 className="font-subheader uppercase">How Long Your Personal Information Will Be Kept. </h2>
            <p className="mb-5">
              We will only keep your personal information for as long as necessary to fulfill the purposes for which it
              was collected, including for the purposes of satisfying any legal, regulatory, accounting, or reporting
              requirements.
            </p>
            <p className="mb-5">
              <em>Cross-Border Data Transfers</em>. We may transfer your personal information to a third party that is
              located in a jurisdiction other than the one from which we collected your personal information, including
              to countries that have not been deemed to have an adequate level of protection for the rights and freedoms
              of data subjects. If we do transfer your personal information to another jurisdiction, we will do so
              following due diligence and provided that the data recipient is subject to contractual agreements imposing
              obligations on it to ensure appropriate technical and organization measures are implemented and maintained
              at all times to prevent the unauthorized and unlawful processing of personal information, and the
              accidental loss or destruction of, or damage to, personal information, consistent with our obligations
              under applicable data protection laws.
            </p>
          </li>

          <li>
            <h2 className="font-subheader uppercase">Your Rights Under the GDPR.</h2>

            <p className="mb-5">
              If you are an EU or UK resident, you are entitled to certain rights under the GDPR and UK GDPR with
              respect to personal information that we have collected. We have listed below how these rights operate in
              the context of using our Products.
            </p>

            <div className="border-1 mb-5 rounded-2xl border-gray-400">
              <table>
                <tbody>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right to Access</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>The right to be provided with a copy of your personal information (the right of access)</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right to Rectification</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>The right to require us to correct any mistakes in your personal information</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right to be Forgotten</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>The right to require us to delete your personal information - in certain situations</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right to Restriction of Processing</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>
                        The right to require us to restrict processing of your personal information - in certain
                        circumstances, e.g. if you contest the accuracy of the data
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right to Data Portability</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>
                        The right to receive the personal information you provided to us, in a structured, commonly used
                        and machine-readable format and/or transmit that data to a third party - in certain situations
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right to Object</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>The right to object:</p>
                      <ul>
                        <li>
                          at any time to your personal information being processed for direct marketing (including
                          profiling);
                        </li>
                        <li>
                          in certain other situations to our continued processing of your personal information, e.g.
                          processing carried out for the purpose of our legitimate interests.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <p>Right Not to be Subject to Automated Individual Decision-Making</p>
                    </td>
                    <td className="border-2 border-gray-400 p-4">
                      <p>
                        The right not to be subject to a decision based solely on automated processing (including
                        profiling) that produces legal effects concerning you or similarly significantly affects you
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="mb-5">
              If you would like to exercise any of the above rights or if you have questions about your privacy rights,
              please use the contact details in the How to Exercise Your Rights section.
            </p>
          </li>

          <li>
            <h2 className="font-subheader uppercase">Your Rights Under In California.</h2>

            <p className="mb-5">
              <em>“Shine the Light” and “Eraser” Laws:</em> Residents of the State of California may request a list of
              all third parties to which we have disclosed certain information during the preceding year for those third
              parties’ direct marketing purposes.
            </p>
            <p className="mb-5">
              <em>Do-Not-Track Disclosures</em>: We do not support Do Not Track (“DNT”) requests. Do Not Track is a
              preference you can set in your web browser to inform websites that you do not want to be tracked. You can
              enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
            </p>
          </li>

          <li>
            <h2 className="font-subheader uppercase">Keeping Your Personal Information Secure. </h2>
            <p className="mb-5">
              We have appropriate security measures in place designed to prevent personal information from being
              accidentally lost or used or accessed in an unauthorized way. We limit access to your personal information
              to those who have a genuine business need to access it. Those processing your information will do so only
              in an authorized manner and are subject to a duty of confidentiality. We also have procedures in place to
              deal with any suspected data security breach. We will notify you and any applicable regulator of a
              suspected data security breach where we are legally required to do so.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase"> How to Exercise Your Rights. </h2>
            <p className="mb-5">
              If you would like to exercise any of your rights as described in this Privacy Policy, please email us at{' '}
              <a href="mailto:legal@catalabs.com">legal@catalabs.com</a>.
            </p>
          </li>
          <li>
            <h2 className="font-subheader uppercase"> GDPR Complaint. </h2>
            <p className="mb-5">
              We hope that we can resolve any query or concern you raise about our use of your information.
            </p>
            <p className="mb-5">
              The General Data Protection Regulation gives you right to lodge a complaint with a supervisory authority,
              in the European Union (or European Economic Area) state where you work, normally live, or where any
              alleged infringement of data protection laws occurred.
            </p>
          </li>

          <li>
            <h2 className="font-subheader uppercase"> Children. </h2>
            <p className="mb-5">
              Our Products are not directed to children under the age of 18 and we do not knowingly solicit, collect,
              use, or disclose Personal Information from children under the age of 18. If we become aware that a child
              has provided us with personal information in violation of applicable law, we will delete any personal
              information we have collected, unless we have a legal obligation to keep it.
            </p>
          </li>

          <li>
            <h2 className="font-subheader uppercase"> Changes to This Privacy Policy. </h2>
            <p className="mb-5">This privacy notice was published in April 2024 and last updated April 1, 2024.</p>
            <p className="mb-5">
              We may change this Privacy Policy from time to time – when we do, we will inform you via{' '}
              <a href="http://www.catalyst.exchange">catalyst.exchange</a> or other means of contact, such as discord.
            </p>
          </li>

          <li>
            <h2 className="font-subheader uppercase">How to Contact Us.</h2>
            <p className="mb-5">
              Please contact us by email if you have any questions about this Privacy Policy or the information we hold
              about you.
            </p>
            <p className="mb-5">Our contact details are shown below:</p>
            <div className="border-1 mb-5 rounded-2xl border-gray-400">
              <table>
                <thead>
                  <tr>
                    <th className="border-2 border-gray-400 p-4">Our contact details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-2 border-gray-400 p-4">
                      <a href="mailto:legal@catalabs.com">legal@catalabs.com</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
