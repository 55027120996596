import { XCircleIcon } from '@heroicons/react/24/solid';

export interface PendingPoolDepositBarProps {
  handleWithdraw: () => void;
  handleResume: () => void;
  handleCancel: () => void;
}

export const PendingPoolDepositBar = ({ handleResume, handleWithdraw, handleCancel }: PendingPoolDepositBarProps) => {
  return (
    <>
      <div className="relative flex w-full flex-col items-center justify-center gap-2 bg-red-500 py-4 md:flex-row md:gap-6">
        <p className="text-white">You have a partial deposit in a Pool.</p>
        <div className="flex items-center gap-2">
          <button
            onClick={handleWithdraw}
            className="flex items-center justify-center rounded-full border-[1px] border-white bg-red-500 px-[12px] py-[4px] text-xs text-white"
          >
            Withdraw Funds
          </button>
          <button
            onClick={handleResume}
            className="flex items-center justify-center rounded-full bg-white px-[12px] py-[4px] text-xs text-gray-900"
          >
            Resume Progress
          </button>
        </div>
        <div className="absolute right-3 cursor-pointer">
          <span onClick={handleCancel}>
            <XCircleIcon className="h-4 w-4 text-white" />
          </span>
        </div>
      </div>
    </>
  );
};

export default PendingPoolDepositBar;
