import { FeeDetail, Pool } from '@catalabs/catalyst-api-client';
import {
  EVM_ROUTER_ADDRESS,
  PERMIT2_ADDRESS,
  PermitData,
  prepareWithdrawWithLiquiditySwap,
  withdrawWithGasViaPermit,
} from '@catalabs/catalyst-sdk';
import { ethers } from 'ethers';
import localforage from 'localforage';
import { makeAutoObservable, runInAction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import { CatalystNetwork, delay } from '~/config';
import {
  captureException,
  ChainGasFeeData,
  RootStore,
  Store,
  WithdrawRequest,
  WithdrawWithLiquiditySwapRequest,
} from '~/modules/common';
import { PendingTnxStatus, PendingTnxType, PendingTxn } from '~/modules/lobby';

import { PoolActionState, PoolApprovalState, PoolInteractionStep } from '../enums';
import { ChainWithdraw } from '../interfaces';
import { sortAndfilterOutZeroWithdrawRequests } from '../utils/withdraw.utils';

export class PoolWithdrawStore implements Store {
  address?: string;
  pool?: Pool;

  // Pool Withdraw
  poolWithdrawStep?: PoolInteractionStep = undefined;
  poolWithdrawRequest?: ChainWithdraw[] = undefined;
  withdrawPortion?: number = undefined;
  withdrawValue?: number = undefined;
  runningWithdraw = false;

  poolWithdrawError?: string = undefined;
  hasPendingWithdraws: boolean = false;
  withLiquiditySwap: boolean = false;

  feeDetails: FeeDetail[] = [];
  gasData?: ChainGasFeeData = undefined;

  priceImpact: number = 0;

  constructor(private store: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'PoolWithdrawStore',
      properties: [
        'pool',
        'withdrawPortion',
        'withdrawValue',
        'address',
        'hasPendingWithdraws',
        'poolWithdrawStep',
        {
          key: 'poolWithdrawRequest',
          serialize: (value: ChainWithdraw[] | undefined) => value,
          deserialize: (value: ChainWithdraw[] | undefined) => {
            const requests = value?.map((r) => {
              return {
                ...r,
                request: r.request.map((req) => {
                  return {
                    ...req,
                    amount: BigInt(req.amount.toString()),
                  };
                }),
              };
            });
            return requests;
          },
        },
      ],
      storage: localforage,
      stringify: false,
    });
  }

  get showPendingWithdraw(): boolean {
    return this.hasPendingWithdraws && !this.runningWithdraw && this.address === this.store.wallet.address;
  }

  async withdraw(request: WithdrawRequest, withdrawPortion: number): Promise<void> {
    // get the user's address
    const { address } = this.store.wallet;
    const { pool } = this.store.pool;

    // get the source network of the swap
    const { sourceNetwork } = this.store.swap;
    // check if every request has a zero amount
    const hasNoWithdraws = request.every((r) => r.amount === 0n);

    // if the address is not set or there are no deposits, return
    if (!address || hasNoWithdraws || !pool) {
      return;
    }

    runInAction(() => {
      this.pool = pool;
      this.address = address;
      this.poolWithdrawError = undefined;
      this.withdrawPortion = withdrawPortion;
      this.withLiquiditySwap = false;
    });

    let sortedRequests: ChainWithdraw[] = sortAndfilterOutZeroWithdrawRequests({
      request: request,
      sourceNetwork,
    });

    // fetch vault token info for each request
    sortedRequests = await Promise.all(
      sortedRequests.map(async (chainRequest) => {
        const { vault, chainId } = chainRequest;
        const tokenInfo = await this.store.catalyst.getToken(chainId, vault);
        return {
          ...chainRequest,
          vaultTokenInfo: tokenInfo,
        };
      }),
    );

    runInAction(() => {
      this.poolWithdrawRequest = sortedRequests;
      this.poolWithdrawStep = PoolInteractionStep.Approval;
    });

    return this.executeWithdrawRequests();
  }

  async resumeWithdraw(): Promise<void> {
    return this.executeWithdrawRequests();
  }

  private async executeWithdrawRequests(): Promise<void> {
    // get the user's address
    const { address } = this.store.wallet;
    const { pool } = this.store.pool;

    // get the source network of the swap
    const { sourceNetwork } = this.store.swap;

    // check if every request has a zero amount
    const sortedRequests = this.poolWithdrawRequest;
    const hasNoWithdraws = sortedRequests?.every((r) => r.request.every((r) => r.amount === 0n));

    const withdrawPortion = this.withdrawPortion ?? 0;

    const chainIds = sortedRequests?.map((r) => r.chainId) || [];
    const gasData = await this.store.catalyst.getGasFeeData(chainIds);

    // if the address is not set or there are no deposits, return
    if (!address || hasNoWithdraws || !pool || !sortedRequests || !withdrawPortion) {
      return;
    }

    let requestIndex = 0;

    runInAction(() => {
      this.runningWithdraw = true;
    });

    for (const withdrawRequest of sortedRequests) {
      if (
        withdrawRequest.withdraw === PoolActionState.Completed ||
        withdrawRequest.withdraw === PoolActionState.Confirmed
      ) {
        requestIndex += 1;
        continue;
      }
      const baseRequest = withdrawRequest.request[0];

      const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = gasData[baseRequest.chainId];
      const supportsEip1559 = maxPriorityFeePerGas !== null || maxPriorityFeePerGas !== 0n;
      const gasOptions = {
        ...(!supportsEip1559 && { gasPrice: BigInt(gasPrice) || undefined }),
        ...(supportsEip1559 && {
          maxFeePerGas: BigInt(maxFeePerGas) || undefined,
          maxPriorityFeePerGas: BigInt(maxPriorityFeePerGas) || undefined,
        }),
      };

      const updatedRequest = sortedRequests.slice();
      if (baseRequest.chainId !== sourceNetwork) {
        updatedRequest[requestIndex].chainSwap = PoolActionState.Pending;
        runInAction(() => {
          this.poolWithdrawRequest = updatedRequest;
        });

        const connected = await this.store.wallet.connectNetwork(baseRequest.chainId);
        if (!connected) {
          console.error('Error changing networks, persist withdraw attempt for future resumption');
          runInAction(() => {
            this.poolWithdrawStep = PoolInteractionStep.Configure;
            this.runningWithdraw = false;
          });
          return;
        }

        updatedRequest[requestIndex].chainSwap = PoolActionState.Confirmed;
        runInAction(() => {
          this.store.swap.sourceAsset = undefined;
          this.store.swap.sourceNetwork = baseRequest.chainId;
          this.poolWithdrawRequest = updatedRequest;
        });

        await delay(1000);
      }

      updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
      runInAction(() => {
        this.poolWithdrawRequest = updatedRequest;
      });

      const { sdk } = CatalystNetwork.getCatalystNetwork(baseRequest.chainId);
      const userBalance = await sdk.checkBalance(withdrawRequest.vault, address);
      const withdrawAmount = (userBalance * BigInt(Math.floor(withdrawPortion * 10 ** 18))) / BigInt(10 ** 20);

      let permitData: PermitData | undefined;
      try {
        // Check allowance to permit2 address for vault token.
        const permit2Allowance = await sdk.checkAllowance(withdrawRequest.vault, address, PERMIT2_ADDRESS);
        if (permit2Allowance < withdrawAmount) {
          // Update the request to indicate pending approval to permit2 address on UI
          updatedRequest[requestIndex].approval = PoolApprovalState.PendingApprovalToPermit2;
          runInAction(() => {
            this.poolWithdrawRequest = updatedRequest;
          });
          // Approve permit2 address for vault token with max uint256 value
          const tx = await sdk.increaseAllowance(
            withdrawRequest.vault,
            PERMIT2_ADDRESS,
            BigInt(ethers.MaxUint256.toString()),
          );
          await tx.wait();
        }

        const permittedAmount = await sdk.checkPermitAmount(withdrawRequest.vault, address, EVM_ROUTER_ADDRESS);
        if (permittedAmount < withdrawAmount) {
          // Update the request to indicate pending permit signature on UI
          updatedRequest[requestIndex].approval = PoolApprovalState.PendingPermitSignature;
          runInAction(() => {
            this.poolWithdrawRequest = updatedRequest;
          });

          // Generate permit data for vault token
          permitData = await sdk.generatePermitData(withdrawRequest.vault, EVM_ROUTER_ADDRESS);

          // Fix for ledger devices
          const sixtyFourthBit = permitData.signature.slice(2 + 64 * 2, 2 + 64 * 2 + 2);
          const parsedSixtyFourthBit = parseInt(sixtyFourthBit, 16);
          if (parsedSixtyFourthBit < 27) {
            permitData.signature =
              permitData.signature.slice(0, 2 + 64 * 2) +
              (parsedSixtyFourthBit + 27).toString(16) +
              permitData.signature.slice(2 + 64 * 2 + 2);
          }

          updatedRequest[requestIndex].approval = PoolApprovalState.Confirmed;
          runInAction(() => {
            this.poolWithdrawRequest = updatedRequest;
          });
          await delay(2000);
        }
      } catch (e) {
        runInAction(() => {
          this.poolWithdrawStep = PoolInteractionStep.Configure;
          this.runningWithdraw = false;
        });
        return this.handleTxError(e);
      }

      updatedRequest[requestIndex].approval = PoolApprovalState.Completed;
      runInAction(() => {
        this.poolWithdrawRequest = updatedRequest;
      });

      updatedRequest[requestIndex].withdraw = PoolActionState.Pending;
      runInAction(() => {
        this.poolWithdrawRequest = updatedRequest;
      });

      const sortedWithdraws = withdrawRequest.request.sort((a, b) => a.index - b.index);
      const tokens = sortedWithdraws.map((r) => r.address);
      // w.amount.mul((100 - this.depositSlippage) * 100).div(100)
      // TODO: assign appropriate min outs with slippage
      const minOuts = sortedWithdraws.map((_) => BigInt('0'));

      const gasToken = baseRequest.useGasToken ? baseRequest.address : undefined;

      try {
        const [commands, inputs] = withdrawWithGasViaPermit(
          baseRequest.vault,
          withdrawAmount,
          tokens,
          minOuts,
          gasToken,
          permitData, // Permit data for vault token
        );
        const { hash, wait } = await sdk.sendAssetWithRouter({ commands, inputs }, undefined, gasOptions);

        updatedRequest[requestIndex].hash = hash;
        updatedRequest[requestIndex].withdraw = PoolActionState.Confirmed;
        runInAction(() => {
          this.poolWithdrawRequest = updatedRequest;
          this.hasPendingWithdraws = true;
        });
        await wait();
      } catch (error) {
        runInAction(() => {
          this.poolWithdrawStep = PoolInteractionStep.Configure;
          this.runningWithdraw = false;
        });
        return this.handleTxError(error);
      }

      updatedRequest[requestIndex].withdraw = PoolActionState.Completed;
      runInAction(() => {
        this.poolWithdrawRequest = updatedRequest;
        this.hasPendingWithdraws = requestIndex < sortedRequests.length - 1;
      });

      requestIndex += 1;
    }

    runInAction(() => {
      this.runningWithdraw = false;
    });
  }

  async withdrawWithLiquiditySwap(liquidityRequest: WithdrawWithLiquiditySwapRequest): Promise<void> {
    const { requests, txInputs } = liquidityRequest;

    // get the user's address
    const { address } = this.store.wallet;
    const { pool } = this.store.pool;
    // get the source network of the swap
    const { sourceNetwork } = this.store.swap;
    // check if every request has a zero amount
    const hasNoWithdraws = requests.every((r) => r.amount === 0n);

    // if the address is not set or there are no deposits, return
    if (!address || hasNoWithdraws || !pool) {
      return;
    }

    runInAction(() => {
      this.pool = pool;
      this.address = address;
      this.poolWithdrawError = undefined;
      this.withLiquiditySwap = true;
    });

    let sortedRequests: ChainWithdraw[] = sortAndfilterOutZeroWithdrawRequests({
      isLiquiditySwap: true,
      request: requests,
      sourceNetwork,
    });

    // fetch vault token info for each request
    sortedRequests = await Promise.all(
      sortedRequests.map(async (chainRequest) => {
        const { vault, chainId } = chainRequest;
        const tokenInfo = await this.store.catalyst.getToken(chainId, vault);
        return {
          ...chainRequest,
          vaultTokenInfo: tokenInfo,
        };
      }),
    );

    // if there are no gas data, fetch it from the API
    if (!this.gasData) {
      const chainIds = sortedRequests.map((r) => r.chainId);
      const gasData = await this.store.catalyst.getGasFeeData(chainIds);
      runInAction(() => {
        this.gasData = gasData;
      });
    }

    // if the address is not set or there are no deposits, return
    if (!address || hasNoWithdraws || !this.gasData) {
      return;
    }

    try {
      // generate the execution instructions for each chain
      const {
        userVaultTokens,
        userWithdrawals,
        chains,
        vaults,
        vaultAddresses,
        assetsAddresses,
        userAddresses,
        messageVerifyGasCosts,
        priceOfDeliveryGas,
        priceOfAckGas,
        refundGasTo,
        unWrapGas,
        routerAddresses,
      } = txInputs;

      const responses = prepareWithdrawWithLiquiditySwap(
        userVaultTokens,
        userWithdrawals,
        chains,
        vaults,
        vaultAddresses,
        assetsAddresses,
        userAddresses,
        messageVerifyGasCosts,
        priceOfDeliveryGas,
        priceOfAckGas,
        refundGasTo,
        routerAddresses,
        unWrapGas,
      );

      // filter out responses and requests without actionables
      const actionableRequestChains = responses.reduce((acc, res, i) => {
        if (res.routerArgs) {
          acc.push(chains[i].chainId);
        }
        return acc;
      }, [] as string[]);

      sortedRequests = sortedRequests.filter((req) => actionableRequestChains.includes(req.chainId));

      runInAction(() => {
        this.poolWithdrawRequest = sortedRequests;
        this.runningWithdraw = true;
        this.poolWithdrawStep = PoolInteractionStep.Approval;
      });

      let requestIndex = 0;
      for (const withdrawRequest of sortedRequests) {
        const baseRequest = withdrawRequest.request[0];

        const updatedRequest = sortedRequests.slice();
        updatedRequest[requestIndex].withdraw = PoolActionState.Active;
        runInAction(() => {
          this.poolWithdrawRequest = updatedRequest;
        });
        if (baseRequest.chainId !== sourceNetwork) {
          updatedRequest[requestIndex].chainSwap = PoolActionState.Pending;
          runInAction(() => {
            this.poolWithdrawRequest = updatedRequest;
          });

          try {
            await this.store.wallet.connectNetwork(baseRequest.chainId);
          } catch (error) {
            runInAction(() => {
              this.poolWithdrawStep = PoolInteractionStep.Configure;
            });
            return this.handleTxError(error);
          }
          updatedRequest[requestIndex].chainSwap = PoolActionState.Confirmed;
          runInAction(() => {
            this.store.swap.sourceAsset = undefined;
            this.store.swap.sourceNetwork = baseRequest.chainId;
            this.poolWithdrawRequest = updatedRequest;
          });

          await delay(1000);
        }

        updatedRequest[requestIndex].chainSwap = PoolActionState.Completed;
        runInAction(() => {
          this.poolWithdrawRequest = updatedRequest;
        });

        const { sdk } = CatalystNetwork.getCatalystNetwork(baseRequest.chainId);
        // const userBalance = await sdk.checkBalance(withdrawRequest.vault, address);

        const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = this.gasData[baseRequest.chainId];
        const supportsEip1559 = maxPriorityFeePerGas !== null || maxPriorityFeePerGas !== 0n;
        const gasOptions = {
          ...(!supportsEip1559 && { gasPrice: BigInt(gasPrice) }),
          ...(supportsEip1559 && {
            maxFeePerGas: BigInt(maxFeePerGas),
            maxPriorityFeePerGas: BigInt(maxPriorityFeePerGas),
          }),
        };

        const withdrawAmount = baseRequest.vaultTokenAmount;

        let permitData: PermitData | undefined;
        try {
          // Check allowance to permit2 address for vault token.
          const permit2Allowance = await sdk.checkAllowance(withdrawRequest.vault, address, PERMIT2_ADDRESS);
          if (permit2Allowance < withdrawAmount) {
            // Update the request to indicate pending approval to permit2 address on UI
            updatedRequest[requestIndex].approval = PoolApprovalState.PendingApprovalToPermit2;
            runInAction(() => {
              this.poolWithdrawRequest = updatedRequest;
            });
            // Approve permit2 address for vault token with max uint256 value.
            const tx = await sdk.increaseAllowance(
              withdrawRequest.vault,
              PERMIT2_ADDRESS,
              BigInt(ethers.MaxUint256.toString()),
            );
            await tx.wait();
          }

          const permittedAmount = await sdk.checkPermitAmount(withdrawRequest.vault, address, EVM_ROUTER_ADDRESS);

          if (permittedAmount < withdrawAmount) {
            // Update the request to indicate pending permit signature on UI
            updatedRequest[requestIndex].approval = PoolApprovalState.PendingPermitSignature;
            runInAction(() => {
              this.poolWithdrawRequest = updatedRequest;
            });

            // Generate permit data for vault token
            permitData = await sdk.generatePermitData(withdrawRequest.vault, EVM_ROUTER_ADDRESS);

            // Fix for ledger devices
            const sixtyFourthBit = permitData.signature.slice(2 + 64 * 2, 2 + 64 * 2 + 2);
            const parsedSixtyFourthBit = parseInt(sixtyFourthBit, 16);
            if (parsedSixtyFourthBit < 27) {
              permitData.signature =
                permitData.signature.slice(0, 2 + 64 * 2) +
                (parsedSixtyFourthBit + 27).toString(16) +
                permitData.signature.slice(2 + 64 * 2 + 2);
            }

            updatedRequest[requestIndex].approval = PoolApprovalState.Confirmed;
            runInAction(() => {
              this.poolWithdrawRequest = updatedRequest;
            });
            await delay(2000);
          }
        } catch (error) {
          runInAction(() => {
            this.poolWithdrawStep = PoolInteractionStep.Configure;
          });
          return this.handleTxError(error);
        }

        updatedRequest[requestIndex].approval = PoolApprovalState.Completed;
        runInAction(() => {
          this.poolWithdrawRequest = updatedRequest;
        });

        try {
          const response = responses.find(
            (_, i) => chains[i].chainId.toString() === sortedRequests[requestIndex].chainId,
          );

          if (!response) {
            return;
          }

          const { routerArgs, transferDetails, gasUsage } = response;

          const { executionInstructions } = routerArgs
            ? routerArgs.transferWithPermitForWithdrawWithLiquiditySwap(transferDetails, gasUsage, permitData)
            : { executionInstructions: undefined };

          if (executionInstructions) {
            updatedRequest[requestIndex].withdraw = PoolActionState.Pending;
            runInAction(() => {
              this.poolWithdrawRequest = updatedRequest;
            });
            const { estimatedRoutingPayment, estimatedGasUsedOnLocal } = executionInstructions.gas;
            const baseGas = estimatedRoutingPayment === 0n ? estimatedGasUsedOnLocal : estimatedRoutingPayment;
            const gasAmount = (baseGas * 12n) / 10n;
            const { hash, wait } = await sdk.sendAssetWithRouter(executionInstructions, gasAmount, gasOptions);
            updatedRequest[requestIndex].hash = hash;
            updatedRequest[requestIndex].withdraw = PoolActionState.Confirmed;
            runInAction(() => {
              this.poolWithdrawRequest = updatedRequest;
            });
            await wait();
          }

          updatedRequest[requestIndex].withdraw = PoolActionState.Confirmed;
          runInAction(() => {
            this.poolWithdrawRequest = updatedRequest;
          });
        } catch (error) {
          runInAction(() => {
            this.poolWithdrawStep = PoolInteractionStep.Configure;
            this.runningWithdraw = false;
          });
          return this.handleTxError(error);
        }
        if (!baseRequest.withLiquiditySwap) {
          updatedRequest[requestIndex].withdraw = PoolActionState.Completed;
          runInAction(() => {
            this.poolWithdrawRequest = updatedRequest;
          });
        }

        requestIndex += 1;
      }
      this.addTxToLobby();
      runInAction(() => {
        this.runningWithdraw = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async addTxToLobby(): Promise<void> {
    const { pool } = this.store.pool;
    if (this.poolWithdrawRequest && pool) {
      const requestWithLiquiditySwap = this.poolWithdrawRequest.find((request) =>
        request.request.some((r) => r.withLiquiditySwap),
      );
      if (!requestWithLiquiditySwap) {
        return;
      }
      const hash = requestWithLiquiditySwap?.hash;
      if (hash) {
        const pendingTxn: PendingTxn = {
          hash,
          type: PendingTnxType.Withdraw,
          status: PendingTnxStatus.Pending,
          submittedAt: new Date(),
          withdrawalDetails: {
            requests: this.poolWithdrawRequest,
            poolAssets: pool.assets || [],
            withLiquiditySwap: true,
            progress: 0,
            priceImpact: this.priceImpact,
            feeDetails: this.feeDetails,
            poolId: pool.id,
          },
        };
        this.store.lobby.addPendingTxn(hash, pendingTxn);
      }
    }
  }

  reset(): void {
    this.poolWithdrawStep = undefined;
    this.poolWithdrawRequest = undefined;
    this.feeDetails = [];
    this.priceImpact = 0;
    this.poolWithdrawError = undefined;
    this.hasPendingWithdraws = false;

    clearPersistedStore(this);
  }

  private async handleTxError(e: unknown): Promise<void> {
    console.error(e);
    captureException(e as Error);
  }
}
