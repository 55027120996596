import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { TokenInfo } from '@uniswap/token-lists';
import { BigNumberish, ethers } from 'ethers';

export function formatBalance(amount: BigNumberish, decimals: BigNumberish = 18): number {
  return Number(ethers.formatUnits(amount, decimals));
}

export function parseBalance(amount: string, decimals: BigNumberish = 18): bigint {
  return ethers.parseUnits(amount, decimals);
}

export function getAssetLookupAddress(asset: { address: string; symbol: string }): string {
  return asset.address === '0x0' ? asset.symbol : asset.address;
}

export function toUnits(amount: number | string, decimals: number): bigint {
  const [wholeAmount, decimalAmount] = amount.toString().split('.');
  let decimalString: string;
  if (decimalAmount) {
    if (decimalAmount.length >= decimals) {
      decimalString = decimalAmount.slice(0, decimals);
    } else {
      const missingDecimals = decimals - decimalAmount.length;
      decimalString = decimalAmount.concat('0'.repeat(missingDecimals));
    }
  } else {
    decimalString = '0'.repeat(decimals);
  }
  return BigInt(wholeAmount.concat(decimalString));
}

export function isGasToken(token: TokenInfo): boolean {
  return token.address === GAS_TOKEN_IDENTIFIER;
}

export function isAddressZero(address: string): boolean {
  return ethers.ZeroAddress === address;
}

export function convertPercentageToBigInt(percentage: number, decimalPlaces: number = 18): bigint {
  const decimal = percentage / 100;
  const scaleFactor = Math.pow(10, decimalPlaces); // Scale factor based on desired precision
  const scaledValue = Math.floor(decimal * scaleFactor); // Scale and round down
  return BigInt(scaledValue);
}

export function roundDownToDecimals(value: number, decimalPlaces: number): number {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(value * factor) / factor;
}
