interface TokenAmountInputLoaderProps {
  onClick: () => void;
}

export const TokenAmountInputLoader = ({ onClick }: TokenAmountInputLoaderProps) => {
  return (
    <div
      onClick={onClick}
      className="border-gray flex h-[89px] w-full items-center justify-between rounded-[12px] border px-6"
    >
      <div className="flex items-center gap-4 text-lg text-grey">
        <div className="flex h-10 items-center">
          <div className="h-8 w-8 rounded-full bg-grey-100"></div>
          <div className="-ml-3 h-[2.75rem] w-[2.75rem] rounded-full border-[2.8px] border-white bg-grey-200"></div>
        </div>
        <span className="text-lg text-grey-300">Token 1</span>
      </div>
      <div className="flex flex-col pl-4 text-grey-300">
        <span className="font-semibold text-[32px]">0.00</span>
        <span className="text-sm">$0.00</span>
      </div>
    </div>
  );
};

export default TokenAmountInputLoader;
