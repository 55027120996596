import { FeeDetail, Pool } from '@catalabs/catalyst-api-client';
import { GAS_TOKEN_IDENTIFIER, prepareDepositWithLiquiditySwaps } from '@catalabs/catalyst-sdk';
import { formatUnits } from 'ethers';

import { CatalystNetwork, formatBalance } from '~/config';
import { ChainGasFeeData, DepositRequest } from '~/modules/common';
import {
  ChainDeposit,
  DepositLiquiditySwapInfoItem,
  DepositWithLiquiditySwapInputs,
  getAssetKey,
  sortAndfilterOutZeroDepositRequests,
} from '~/modules/pools';

const getBaseDepositFeeDetails = ({
  sortedRequests,
  gasTokenPrices,
  gasData,
  pool,
}: {
  sortedRequests: ChainDeposit[];
  gasData: ChainGasFeeData;
  gasTokenPrices: Map<string, number>;
  pool: Pool;
}): FeeDetail[] => {
  const feeDetails: FeeDetail[] = [];
  let executionFee: number = 0;
  let depositFee: number = 0;
  const vaults = Object.values(pool.vaults);
  for (const depositRequest of sortedRequests) {
    const chainDetails = CatalystNetwork.getCatalystNetwork(depositRequest.chainId);
    const baseRequest = depositRequest.request[0];
    const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = gasData[baseRequest.chainId];

    const supportsEip1559 = maxPriorityFeePerGas !== null || maxPriorityFeePerGas !== 0n;
    const feeInGas = supportsEip1559 ? maxFeePerGas : gasPrice;
    const gasTokenPrice =
      gasTokenPrices.get(
        getAssetKey({
          address: GAS_TOKEN_IDENTIFIER,
          chainId: chainDetails.config.chainId,
        }),
      ) || 1;
    const totalGasPrice = BigInt('190000') * BigInt(feeInGas);
    const feeinUSD = Number(formatUnits(totalGasPrice)) * gasTokenPrice;
    executionFee += feeinUSD;

    const vault = vaults.find((v) => v.chain === depositRequest.chainId);
    const vaultDepositFee = vault?.swapFee ? Number(formatUnits(vault.swapFee)) : 0;
    const depositFeeInUSD = vaultDepositFee > 0 ? vaultDepositFee * baseRequest.value : 0;
    depositFee += depositFeeInUSD;
  }
  feeDetails.push({
    amount: executionFee,
    currency: 'USD',
    name: 'Execution Fee',
    value: executionFee,
  } as FeeDetail);

  feeDetails.push({
    amount: depositFee,
    currency: 'USD',
    name: 'Deposit Fee',
    value: depositFee,
  } as FeeDetail);
  return feeDetails;
};

export const getDepositFeeDetails = ({
  depositRequest,
  gasData,
  gasTokenPrices,
  pool,
}: {
  depositRequest: DepositRequest;
  gasData: ChainGasFeeData;
  gasTokenPrices: Map<string, number>;
  pool: Pool;
}): FeeDetail[] => {
  const sortedRequestsByChain = sortAndfilterOutZeroDepositRequests({ request: depositRequest, pool });
  return getBaseDepositFeeDetails({
    sortedRequests: sortedRequestsByChain,
    gasData,
    gasTokenPrices,
    pool,
  });
};

export const getDepositWithLiquiditySwapFeeDetail = ({
  depositRequest,
  gasTokenPrices,
  pool,
  txInputs,
  address,
  swapQuote,
  gasData,
}: {
  depositRequest: DepositRequest;
  gasTokenPrices: Map<string, number>;
  pool: Pool;
  txInputs: DepositWithLiquiditySwapInputs;
  address: string;
  swapQuote: Map<string, DepositLiquiditySwapInfoItem>;
  gasData: ChainGasFeeData;
}): FeeDetail[] => {
  const {
    userDeposits,
    chains,
    vaults,
    vaultAddresses,
    assetsAddresses,
    userAddresses,
    refundGasTo,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    wrapGasValues,
  } = txInputs;

  const responses = prepareDepositWithLiquiditySwaps(
    userDeposits,
    chains,
    vaults,
    vaultAddresses,
    assetsAddresses,
    userAddresses,
    messageVerifyGasCosts,
    priceOfDeliveryGas,
    priceOfAckGas,
    refundGasTo,
    undefined,
    wrapGasValues,
  );

  const sortedRequestsByChain = sortAndfilterOutZeroDepositRequests({ request: depositRequest, pool });

  const feeDetails: FeeDetail[] = getBaseDepositFeeDetails({
    sortedRequests: sortedRequestsByChain,
    gasData,
    gasTokenPrices,
    pool,
  });
  let routingFee: number = 0;

  for (const request of sortedRequestsByChain) {
    const response = responses.find((_, i) => chains[i].chainId.toString() === request.chainId);

    if (!response) {
      throw new Error('Invalid routerArgs, gasUsage, or transferDetails');
    }

    const requestsThatUseGasTokenIndex = request.request.filter((r) => r.useGasToken).map((r) => r.index);
    const { routerArgs, gasUsage, transferDetails } = response;

    const transferDetailsForWrappedAssets = transferDetails.filter((_, i) => !requestsThatUseGasTokenIndex.includes(i));

    const { executionInstructions } = routerArgs.transferWithPermitForDepositWithLiquiditySwaps(
      transferDetailsForWrappedAssets,
      address,
      gasUsage,
    );

    const { estimatedRoutingPayment } = executionInstructions.gas;

    const sourceGasTokenKey = getAssetKey({
      address: GAS_TOKEN_IDENTIFIER,
      chainId: request.chainId,
    });
    const sourceGasTokenPrice = gasTokenPrices.get(sourceGasTokenKey) || 1;

    if (request.liquiditySwap && swapQuote.get(request.chainId)) {
      const formattedSwapFeeAmount = formatBalance((estimatedRoutingPayment * 12n) / 10n);
      routingFee += sourceGasTokenPrice * formattedSwapFeeAmount;
    }
  }

  feeDetails.push({
    amount: routingFee,
    currency: 'USD',
    name: 'Routing Fee',
    value: routingFee,
  } as FeeDetail);
  return feeDetails;
};
