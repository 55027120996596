import { TokenInfo } from '@uniswap/token-lists';

import { CatalystNetwork } from '~/config';
import { ReactComponent as Close } from '~/img/close.svg';
import { ReactComponent as SourcePlaceholder } from '~/img/source-placeholder.svg';

export const TokensNetworksFilterDisplay = ({
  filteredTokens,
  filteredNetworks,
  clearToken,
  clearNetwork,
}: {
  filteredTokens: TokenInfo[];
  filteredNetworks: CatalystNetwork[];
  clearToken: (token: TokenInfo) => void;
  clearNetwork: (network: CatalystNetwork) => void;
}) => {
  return (
    (filteredTokens.length > 0 || filteredNetworks.length > 0) && (
      <div className="flex flex-wrap gap-y-4">
        {filteredNetworks.map((n) => (
          <div
            key={`token-filter-${n.config.chainId}`}
            className="mr-3 flex items-center rounded-[100px] border border-gray-200 px-2 py-[7px]"
          >
            <img
              alt={`${n.config.chainId} Icon`}
              className="h-7 w-7 rounded-full"
              src={`/img/${n.config.chainId}.webp`}
            />
            <div className="ml-2 text-sm leading-none">{n.config.name}</div>
            <div className="cursor-pointer p-2" onClick={() => clearNetwork(n)}>
              <Close className="h-2 w-2" />
            </div>
          </div>
        ))}
        {filteredTokens.map((t) => (
          <div
            key={`token-filter-${t.symbol}`}
            className="mr-3 flex items-center rounded-[100px] border border-gray-200 px-2 py-[7px]"
          >
            {t.logoURI ? (
              <img className="h-7 w-7 rounded-full" src={t.logoURI} />
            ) : (
              <div className="h-7 w-7 rounded-full">
                <SourcePlaceholder />
              </div>
            )}
            <div className="ml-2 text-sm uppercase leading-none">{t.symbol}</div>
            <div className="cursor-pointer p-2" onClick={() => clearToken(t)}>
              <Close className="h-2 w-2" />
            </div>
          </div>
        ))}
      </div>
    )
  );
};
