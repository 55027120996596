import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { getPoolRoute } from '~/config/utils/routes.utils';
import { formatCurrency, TokenDisplay } from '~/modules/common';
import { getAssetKey, PoolInteractionStep } from '~/modules/pools';
import PoolDepositModal from '~/modules/pools/components/PoolDepositModal';

import { PendingTxn } from '../interfaces';

interface PendingDepositCardProps {
  info?: string;
  txnDetails: PendingTxn;
  flat?: boolean;
}

export const PendingDepositCard: React.FC<PendingDepositCardProps> = ({
  info,
  txnDetails,
  flat,
}: PendingDepositCardProps) => {
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { depositDetails } = txnDetails;
  if (!depositDetails) {
    return null;
  }

  const {
    poolAssets,
    requests,
    depositInfo,
    withLiquiditySwap,
    liquiditySwapFinalSwapAmounts,
    liquiditySwapFinalValues,
    progress,
  } = depositDetails;

  if (!requests || !requests.length || !depositInfo) {
    return null;
  }

  const totalAmount = requests.reduce((acc, txn) => {
    return (
      acc +
      txn.request.reduce((acc, req) => {
        return acc + req.value;
      }, 0)
    );
  }, 0);

  const handleCloseModal = () => {
    setIsDetailsOpen(false);
  };

  const poolDepositRequest = requests;

  const poolId = depositDetails.requests[0].request[0].poolId;

  return (
    <>
      <div className={`flex w-full justify-center rounded-[20px] bg-white px-6 py-4 ${flat ? '' : 'shadow-2xl'}`}>
        <div className="flex w-full flex-col gap-3">
          <div className="flex justify-between ">
            <p className="text-xs text-gray-500"> Deposit in progress</p>
            <div
              onClick={() => {
                setIsDetailsOpen(true);
              }}
              className="flex h-[20px] w-[47px] cursor-pointer items-center justify-center rounded-[18px] bg-primary text-xs text-white"
            >
              Open
            </div>
          </div>

          <div className="flex flex-col">
            <div className="relative mb-2 h-[2px] w-full rounded-full bg-grey-200">
              <progress className="absolute h-[2px] w-full rounded-full" value={progress} max={100} />
            </div>
            <div className="flex justify-between text-[14px] text-grey">
              <div className="gap flex text-xs">
                {poolAssets.map((token) => (
                  <TokenDisplay
                    chainId={token.chainId}
                    key={getAssetKey(token)}
                    token={token.address}
                    size="sm"
                    showSymbol={false}
                  />
                ))}
              </div>
              <div className="text-xs text-gray-800">{formatCurrency(totalAmount, 2)} </div>
            </div>
          </div>
          {info && <p className="rounded-xl bg-gray-100 px-4 py-2 text-xs text-gray-500 ">{info}</p>}
        </div>
      </div>
      {createPortal(
        <PoolDepositModal
          poolAssets={poolAssets}
          poolDepositRequest={poolDepositRequest}
          open={isDetailsOpen}
          handleCloseModal={handleCloseModal}
          partialDeposit={false}
          withLiquiditySwap={Boolean(withLiquiditySwap)}
          depositInfo={depositInfo}
          liquiditySwapFinalAmounts={liquiditySwapFinalSwapAmounts}
          liquiditySwapFinalValues={liquiditySwapFinalValues}
          poolDepositStep={PoolInteractionStep.Completed}
          handleCompleteDeposit={() => {
            navigate(getPoolRoute(poolId));
            handleCloseModal();
          }}
        />,
        document.body,
      )}
    </>
  );
};
