import { ReactComponent as SwapTime } from '~/img/time.svg';
import { ONE_MIN_MS, ONE_SEC_MS } from '~/modules/common';

import { SwapSpeed } from '../enums';
import { SwapQuote } from '../interfaces';

export interface SwapDurationEstimateProps {
  quote: SwapQuote;
  fullWidth?: boolean;
  swapSpeed?: SwapSpeed;
}

export function SwapDurationEstimate({ quote: _, fullWidth, swapSpeed }: SwapDurationEstimateProps): JSX.Element {
  const fastSwapEST = {
    min: 1000,
    max: 5000,
  };

  const regularSwapEST = {
    min: 60 * 1000 * 20,
    max: 60 * 1000 * 30,
  };

  const { min, max } = swapSpeed === SwapSpeed.FAST ? fastSwapEST : regularSwapEST;

  let durationUnitsMin = 'MIN';
  let durationUnitsMax = 'MIN';

  let durationMinValue = min / ONE_MIN_MS;
  if (durationMinValue < 1) {
    durationUnitsMin = 'SEC';
    durationMinValue = min / ONE_SEC_MS;
  }

  let durationMaxValue = max / ONE_MIN_MS;
  if (durationMaxValue < 1) {
    durationUnitsMax = 'SEC';
    durationMaxValue = max / ONE_SEC_MS;
  }

  const durationMinUnits = durationUnitsMin !== durationUnitsMax ? durationUnitsMin : undefined;

  let durationDisplay;

  if (min !== max) {
    durationDisplay = `~${durationMinValue.toFixed()}${
      durationMinUnits ? ` ${durationMinUnits}` : ''
    }-${durationMaxValue.toFixed()} ${durationUnitsMax}`;
  } else {
    durationDisplay = `${min.toFixed()} ${durationUnitsMin}`;
  }

  return (
    <div
      className={`flex justify-between rounded-[20px] bg-white px-4 py-2 text-[14px] ${fullWidth ? 'w-full' : 'w-1/2'}`}
    >
      <div className="flex">
        <SwapTime />
        <span className="pl-3 text-gray-600">Est</span>
      </div>
      <span className="font-subheader text-gray-800">{durationDisplay}</span>
    </div>
  );
}

export default SwapDurationEstimate;
