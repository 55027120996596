import * as Sentry from '@sentry/react';

export const setupSentry = () =>
  Sentry.init({
    dsn: 'https://705a02e453dce790d99cbce3a0410d2b@o4507284219887616.ingest.us.sentry.io/4507284223950848',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/((?:[a-zA-Z0-9-]+\.)*)catalyst\.exchange/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV !== 'development',
  });

export default setupSentry;
