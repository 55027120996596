import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Inactive } from '~/img/emptycircle.svg';
import { PoolApprovalState } from '~/modules/pools';

export interface PoolApprovalPendingProps {
  approval: PoolApprovalState.PendingApprovalToPermit2 | PoolApprovalState.PendingPermitSignature;
  tokenIdentifier: string;
}

export function PoolApprovalPending({ approval, tokenIdentifier }: PoolApprovalPendingProps) {
  return (
    <div className="flex flex-col">
      {approval === PoolApprovalState.PendingApprovalToPermit2 && (
        <div className="flex flex-col rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="flex">
            <Active />
            <div className="ml-4 flex flex-col">
              <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">
                Approve access to your {tokenIdentifier} tokens
              </span>
              <span className="w-3/4 text-sm leading-5 text-gray-600">
                Permit2 smart contract needs permission to access your tokens. You only need to approve once per token.
              </span>
            </div>
          </div>
          <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            Waiting approval on your wallet
          </div>
        </div>
      )}

      <div className="mt-4 flex flex-col rounded-[25px] bg-white p-5 md:w-[552px]">
        <div className="flex">
          {approval === PoolApprovalState.PendingPermitSignature ? <Active /> : <Inactive />}
          <div className="ml-4 flex flex-col">
            <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">
              Sign the permit to authorize {tokenIdentifier} token transfer
            </span>
            <span className="w-3/4 text-sm leading-5 text-gray-600">
              Authorize the token transfer by signing the permit. You only need to sign once every 30 days.
            </span>
          </div>
        </div>
        {approval === PoolApprovalState.PendingPermitSignature && (
          <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            Waiting signature on your wallet
          </div>
        )}
      </div>
    </div>
  );
}

export default PoolApprovalPending;
