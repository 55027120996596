import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import { REWARDS_SEASON_ACTIVE, SHOW_REWARDS } from '~/config';
import { InfoBanner, StoreContext } from '~/modules/common';
import NotFound from '~/modules/common/pages/404';
import { PageHeader } from '~/modules/rewards/components/PageHeader';

import { LoyaltyScore } from '../components/LoyaltyScore';
import { OwnReferralLink } from '../components/OwnReferralLink/OwnReferralLink';
import { UseReferralLink } from '../components/ReferralLink';

export const RewardsPage = observer(() => {
  const { referral: referralStore, wallet: walletStore } = useContext(StoreContext);

  if (!SHOW_REWARDS) {
    return <NotFound />;
  }

  useEffect(() => {
    const hasDisconnected = !walletStore.isConnected && (referralStore.utilizedReferral || referralStore.ownReferral);

    if (hasDisconnected) {
      referralStore.reset();
    } else {
      referralStore.getAccountEggData();
    }
  }, [walletStore.isConnected, referralStore.utilizedReferral, referralStore.ownReferral]);

  return (
    <div className="mx-auto mb-24 flex w-full max-w-[1224px] flex-col items-center justify-center gap-10 px-4 pt-10">
      {!REWARDS_SEASON_ACTIVE && (
        <InfoBanner
          title="Catalyst Season 1 has ended on August 2, 2024."
          content={
            <>
              Thanks for participating! Egg holders will receive more information in the coming weeks. Read the recap{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://blog.catalyst.exchange/catalyst-eggs-season-1-recap/"
                className="font-bold underline"
              >
                here
              </a>
              .
            </>
          }
        />
      )}

      <PageHeader />

      <div className="flex w-full flex-col gap-10 lg:flex-row">
        <div className="h-[291px] w-full lg:w-1/2">
          <LoyaltyScore />
        </div>
        <div className="h-[291px] w-full  lg:w-1/2">
          <UseReferralLink />
        </div>
      </div>
      <div className="w-full">
        <OwnReferralLink />
      </div>
    </div>
  );
});
