import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { StoreContext } from '~/modules/common/store/context';
import { StorageKey } from '~/modules/common/store/enums/storage-key.enum';

export const SwapPending = observer(() => {
  const { swap } = useContext(StoreContext);
  const { recentPendingSwap } = swap;
  const displayProgressPrompt = localStorage.getItem(StorageKey.DisplayProgressPrompt) !== 'false';

  if (!recentPendingSwap || !displayProgressPrompt) {
    return null;
  }

  return (
    <div className="absolute bottom-14 right-14 z-10 flex h-[292px] w-[274px] flex-col items-center justify-center rounded-3xl bg-white shadow">
      <div className="mb-1 flex h-[20px] w-[76px] items-center justify-between rounded-full bg-black pl-[6px] pr-[6px]">
        <div className="bg-opacity-15four h-2 w-2 rounded-full bg-primary" />
        <div className="h-2 w-2 rounded-full bg-emerald" />
        <div className="absolute w-[60px] animate-slide">
          <div className="h-1 w-1 animate-color rounded-full" />
        </div>
      </div>
      <div className="mt-4 flex justify-center text-xl">
        <span>Swap in progress</span>
      </div>
      <div className="ml-[36px] mr-[36px] mt-3 flex text-center text-sm opacity-60">
        <span>You can see your transaction progress using the menu on the top.</span>
      </div>
      <div className="mt-6 flex justify-center">
        <div
          className="flex h-[40px] w-[122px] cursor-pointer items-center justify-center rounded-3xl bg-primary text-white"
          onClick={() => {
            runInAction(() => {
              localStorage.setItem(StorageKey.DisplayProgressPrompt, 'false');
              swap.recentPendingSwap = undefined;
            });
          }}
        >
          Got it
        </div>
      </div>
    </div>
  );
});

export default SwapPending;
