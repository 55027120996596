import * as HoverCard from '@radix-ui/react-hover-card';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import TokenDisplay from '~/modules/common/components/TokenDisplay';

interface Asset {
  chain: string;
  address: string;
}

export const TableAssetsDisplay = ({
  assets,
  maxTokenDisplay,
  showSymbol = true,
  className,
}: {
  assets: Asset[];
  maxTokenDisplay: number;
  showSymbol?: boolean;
  className?: string;
}) => {
  const [isAdditionalAssetsVisible, setIsAdditionalAssetsVisible] = useState(false);

  return (
    <div className={twMerge('col-span-5 flex flex-wrap items-center gap-[23px] font-normal', className)}>
      {assets.slice(0, maxTokenDisplay).map((asset) => {
        return (
          <div
            className="flex h-[40px] items-center justify-center"
            key={`catalyst-asset-${[asset.chain, asset.address].join('-')}`}
          >
            <TokenDisplay chainId={asset.chain} token={asset.address} showSymbol={showSymbol} />
          </div>
        );
      })}
      {assets.length > maxTokenDisplay && (
        <HoverCard.Root
          open={isAdditionalAssetsVisible}
          openDelay={0}
          onOpenChange={(value) => {
            return setIsAdditionalAssetsVisible(value);
          }}
        >
          <HoverCard.Trigger
            onClick={() => {
              return setIsAdditionalAssetsVisible(!isAdditionalAssetsVisible);
            }}
          >
            <div className="group relative flex h-9 w-9 items-center justify-center rounded-full transition ease-in-out hover:bg-primary hover:bg-opacity-10">
              <div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-primary font-bold text-[12px] text-white">{`+${
                assets.length - maxTokenDisplay
              }`}</div>
            </div>
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content
              onFocusOutside={(e) => {
                e.stopPropagation();
              }}
              side="bottom"
              align="start"
              className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
            >
              <div className="flex flex-col gap-2 rounded-3xl bg-white p-5 shadow-xl">
                {assets.slice(maxTokenDisplay).map((asset) => {
                  return (
                    <div key={`catalyst-asset-${[asset.chain, asset.address].join('-')}`}>
                      <TokenDisplay chainId={asset.chain} token={asset.address} showSymbol={showSymbol} />
                    </div>
                  );
                })}
              </div>
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      )}
    </div>
  );
};

export default TableAssetsDisplay;
