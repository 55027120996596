import { TokenInfo } from '@uniswap/token-lists';
import { useEffect, useState } from 'react';

import { CatalystNetwork } from '~/config';
import { CatalystStore } from '~/modules/common';

interface useTokenDisplayProps {
  catalystStore: CatalystStore;
  chainId: string;
  token: string;
}

export function useTokenDisplay({ catalystStore, chainId, token }: useTokenDisplayProps) {
  const [symbol, setSymbol] = useState<string>('');
  const [logoURI, setLogoURI] = useState<string | undefined>();
  const [showAssets, setShowAssets] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | undefined>(undefined);

  const {
    config: { name },
  } = CatalystNetwork.getCatalystNetwork(chainId);

  useEffect(() => {
    async function fetchTokenInfo() {
      try {
        const tokenInfo = await catalystStore.getToken(chainId, token);
        if (tokenInfo) {
          setTokenInfo(tokenInfo);
          setSymbol(tokenInfo.symbol);
          setLogoURI(tokenInfo.logoURI);
        }
      } catch (err) {
        console.error('Error fetching token info', err);
      }
    }
    fetchTokenInfo();
  }, [chainId, token]);

  return {
    tokenInfo,
    name,
    showAssets,
    symbol,
    logoURI,
    handlers: {
      showAssets: () => {
        setShowAssets(true);
      },
      hideAssets: () => {
        setShowAssets(false);
      },
    },
  };
}
