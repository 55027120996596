import { getReferralUpdateMessage } from '@catalabs/catalyst-api-client';
import { useState } from 'react';

import { WalletStore } from '~/modules/common';
import { ReferralStore } from '~/modules/common/store/referral-store';

export function useUpdateSharePercentage(referralStore: ReferralStore, walletStore: WalletStore) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  async function updateSharePercentage(sharePercentage: number) {
    try {
      if (!walletStore.address || !referralStore.ownReferral) {
        setError('Error updating share percentage. Please try again.');
        return;
      }
      setIsLoading(true);
      const updateRefferalMessage = getReferralUpdateMessage(
        walletStore.address,
        referralStore.ownReferral.code,
        sharePercentage,
      );
      const signature = await walletStore.signMessage(updateRefferalMessage);
      await referralStore.updateOwnReferral({
        address: walletStore.address,
        code: referralStore.ownReferral.code,
        signature,
        sharePercentage,
      });
    } catch (e: unknown) {
      const isUserRejectedRequestError = (e as { code?: number })?.code === 4001;
      if (isUserRejectedRequestError) {
        return;
      }
      setError('Error updating share percentage. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }

  return {
    updateSharePercentage,
    isLoading,
    error,
    reset: () => setError(null),
  };
}
