import { PoolAsset } from '@catalabs/catalyst-api-client';
import { observer } from 'mobx-react-lite';

import { getAssetKey, PoolWithdrawType } from '~/modules/pools';

import { WithdrawBalancedPane } from './WithdrawBalancedPane';
import { WithdrawSinglePane } from './WithdrawSinglePane';

export interface CustomWithdrawPaneProps {
  withdrawType: PoolWithdrawType;
  assets: PoolAsset[];
  withdrawAmounts: Map<string, string>;
  withdrawValues: Map<string, number>;
  assetBalances: Map<string, number>;
  updateInputText: (asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) => void;
  setMaxInput: (asset: PoolAsset) => void;
  handleWithdrawPaneClick: (asset: PoolAsset) => void;
  handleWithdrawPanelBlur: (asset: PoolAsset) => void;
  handleChangeSingleWithdrawAsset: (asset: PoolAsset) => void;
  handleUseGasToken: (asset: PoolAsset, useGasToken: boolean) => void;
}

const CustomWithdrawPane = observer(
  ({
    assets,
    withdrawAmounts,
    withdrawValues,
    assetBalances,
    updateInputText,
    setMaxInput,
    handleWithdrawPaneClick,
    handleWithdrawPanelBlur,
    handleChangeSingleWithdrawAsset,
    withdrawType,
    handleUseGasToken,
  }: CustomWithdrawPaneProps) => {
    return (
      <div className="flex flex-col gap-6 md:gap-4">
        {withdrawType === PoolWithdrawType.Balanced
          ? assets.map((a) => {
              const assetKey = getAssetKey(a);
              const displayAmount = withdrawAmounts.get(assetKey) || '0';
              const displayValue = withdrawValues.get(assetKey) || 0;
              const balance = assetBalances.get(assetKey) ?? 0;
              return (
                <WithdrawBalancedPane
                  amount={displayAmount}
                  asset={a}
                  balance={balance}
                  handleBlur={handleWithdrawPanelBlur}
                  handleChange={updateInputText}
                  handleClickMax={setMaxInput}
                  handleDepositPaneClick={handleWithdrawPaneClick}
                  handleUseGasToken={handleUseGasToken}
                  value={displayValue}
                  key={`withdraw-balanced-input-${assetKey}`}
                />
              );
            })
          : null}
        {withdrawType === PoolWithdrawType.Single ? (
          <WithdrawSinglePane
            handleChangeAsset={handleChangeSingleWithdrawAsset}
            assetBalances={assetBalances}
            assets={assets}
            handleBlur={handleWithdrawPanelBlur}
            handleClick={handleWithdrawPaneClick}
            setMaxInput={setMaxInput}
            withdrawAmounts={withdrawAmounts}
            withdrawValues={withdrawValues}
            handleInputChange={updateInputText}
          />
        ) : null}
      </div>
    );
  },
);

export default CustomWithdrawPane;
