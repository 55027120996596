import { ApiError, PriceQuote, Swap, SwapState, Token } from '@catalabs/catalyst-api-client';
import {
  EVM_ROUTER_ADDRESS,
  GAS_TOKEN_IDENTIFIER,
  PERMIT2_ADDRESS,
  PermitData,
  swapByRouteViaPermit,
} from '@catalabs/catalyst-sdk';
import { TokenInfo } from '@uniswap/token-lists';
import { AxiosError } from 'axios';
import { ethers } from 'ethers';
import localforage from 'localforage';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { CatalystNetwork } from '~/config/network/catalyst-network';
import { formatBalance, toUnits } from '~/config/utils/tokens.utils';
import type { RootStore } from '~/modules/common';
import {
  captureException,
  getErrorMessage,
  getMaxFeePerGas,
  isCrossChainSwap,
  isWrapUnwrap,
  StorageKey,
} from '~/modules/common';
import { DUMMY_PERMIT_DATA } from '~/modules/common/constants';
import { getAssetKey } from '~/modules/pools';
import { ChainSelectMode, SwapError, SwapSlippage, SwapSpeed, SwapStep } from '~/modules/swap/enums';
import { FeeDetail, PendingSwap, SwapQuote } from '~/modules/swap/interfaces';

export class SwapStore {
  static DEFAULT_SLIPPAGE = 0.1;

  // Swap Parameters
  chainSelectMode = ChainSelectMode.None;
  pendingNetwork?: string;
  sourceNetwork?: string;
  targetNetwork?: string;
  sourceAsset?: TokenInfo = undefined;
  targetAsset?: TokenInfo = undefined;
  lastQuote?: SwapQuote = undefined;
  maxSlippage: number = SwapStore.DEFAULT_SLIPPAGE;
  swapError?: SwapError = undefined;
  swapSpeed: SwapSpeed = SwapSpeed.FAST;
  fastSwapFee = 0.1;

  // Swap Display
  swapSlippage = SwapSlippage.LOW;
  swapInput?: string = undefined;
  displaySettings = false;
  fetchingQuote = false;
  quoteNumber = 0;
  quotes = new Map<number, SwapQuote>();
  toAccount?: string = undefined;

  // Swap Tracking
  swapStep = SwapStep.Quoting;
  swapTransactionHash?: string = undefined;
  swapInfo?: Swap = undefined;
  recentPendingSwap?: PendingSwap = undefined;
  pendingSwaps = new Map<string, PendingSwap>();
  completedSwaps: { swap: Swap; quote: SwapQuote }[] = [];

  constructor(private store: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'SwapStore',
      properties: ['pendingSwaps', 'completedSwaps', 'recentPendingSwap'],
      storage: localforage,
      stringify: false,
    });
  }

  resetSwap() {
    runInAction(() => {
      const pendingSwap = this.swapTransactionHash ? this.pendingSwaps.get(this.swapTransactionHash) : undefined;
      if (pendingSwap) {
        this.recentPendingSwap = pendingSwap;
      } else {
        this.recentPendingSwap = undefined;
      }
      this.swapStep = SwapStep.Quoting;
      this.swapInput = undefined;
      this.swapTransactionHash = undefined;
      this.swapInfo = undefined;
      this.lastQuote = undefined;
      this.swapError = undefined;
      this.toAccount = undefined;
    });
  }

  get hasSufficientGas(): boolean {
    if (!this.lastQuote || !this.sourceNetwork) {
      return true;
    }
    // TODO: make this more robust for future
    const gasFeeDetails = this.lastQuote.feeDetails.find((d) => d.name.includes('Origin'));

    let totalGasFee = gasFeeDetails ? gasFeeDetails.amount : 0;

    if (this.lastQuote.fromAsset === GAS_TOKEN_IDENTIFIER) {
      totalGasFee += this.lastQuote.fromAmount;
    }

    const userGasBalance = this.store.wallet.getBalance(this.sourceNetwork, GAS_TOKEN_IDENTIFIER);
    return formatBalance(userGasBalance.amount) > totalGasFee;
  }

  get hasSufficientTokens(): boolean {
    if (!this.sourceNetwork || !this.sourceAsset || !this.swapInput) {
      return true;
    }
    const balance = this.store.wallet.getBalance(this.sourceNetwork, this.sourceAsset.address);
    return formatBalance(balance.amount, this.sourceAsset.decimals) >= Number(this.swapInput);
  }

  get securityLimitActivated(): boolean {
    if (!this.lastQuote) {
      return false;
    }
    return this.lastQuote.securityLimitActivated;
  }

  /**
   *
   * @param chainId '
   * @param asset
   */
  async setSourceParameters(chainId: string, asset: TokenInfo) {
    if (this.store.wallet.isConnected) {
      await this.store.wallet.connectNetwork(chainId);
    }

    runInAction(() => {
      this.pendingNetwork = chainId;
      this.sourceNetwork = chainId;
      this.sourceAsset = asset;
    });
  }

  /**
   *
   * @param chainId
   * @param asset
   */
  async setTargetParameters(chainId: string, asset: TokenInfo) {
    runInAction(() => {
      this.targetNetwork = chainId;
      this.targetAsset = asset;
    });
  }

  /**
   *
   */
  async swapNetworks() {
    const currentSourceNetwork = this.sourceNetwork;
    const currentSourceAsset = this.sourceAsset;
    const currentTargetAsset = this.targetAsset;
    if (!currentSourceNetwork || !currentSourceAsset || !currentTargetAsset) {
      return;
    }
    const shouldSwap =
      getAssetKey({
        chainId: currentSourceAsset.chainId.toString(),
        address: currentSourceAsset.address,
      }) !==
      getAssetKey({
        chainId: currentTargetAsset.chainId.toString(),
        address: currentTargetAsset.address,
      });

    if (this.targetNetwork && shouldSwap) {
      const connected = await this.store.wallet.connectNetwork(this.targetNetwork);
      if (connected) {
        runInAction(() => {
          this.sourceNetwork = this.targetNetwork;
          this.sourceAsset = this.targetAsset;
          this.targetNetwork = currentSourceNetwork;
          this.targetAsset = currentSourceAsset;
        });
      }
    }
  }

  /**
   *
   * @param amount
   * @returns
   */
  async quote(amount: bigint): Promise<void> {
    const { address } = this.store.wallet;

    if (!this.sourceNetwork || !address || !this.sourceAsset || !this.targetAsset || amount <= 0n) {
      return;
    }

    this.fetchingQuote = true;
    this.lastQuote = undefined;
    const ticketNumber = this.quoteNumber;
    runInAction(() => {
      this.quoteNumber++;
      this.swapError = undefined;
    });

    try {
      // generate quote
      const quote = await this.store.client.getQuote({
        fromChainId: this.sourceAsset.chainId.toString(),
        fromAsset: this.sourceAsset.address,
        toChainId: this.targetAsset.chainId.toString(),
        toAsset: this.targetAsset.address,
        fromAmount: amount.toString(),
        toAccount: this.toAccount ?? address,
        depth: 4,
        slippage: this.maxSlippage / 100,
        fastQuote: true,
        underwriterFeePercent: this.fastSwapFee,
      });

      await this.store.wallet.connectNetwork(this.sourceNetwork);

      const {
        toAccount,
        fromAsset,
        toAsset,
        toChainId,
        fromChainId,
        channelId,
        route,
        toAssetIndex,
        netMinOut,
        amount: amountFromQuote,
        priceOfDeliveryGas,
        targetDelta,
        additionalCosts,
      } = quote;

      const messageVerifyGasCost = additionalCosts.amount;

      const quoteAmount = BigInt(amountFromQuote);
      const catalyst = CatalystNetwork.getCatalystNetwork(this.sourceNetwork);

      let permitData: PermitData | undefined;
      if (this.sourceAsset.address !== GAS_TOKEN_IDENTIFIER) {
        const permittedAmount = await catalyst.sdk.checkPermitAmount(
          this.sourceAsset.address,
          address,
          EVM_ROUTER_ADDRESS,
        );
        if (permittedAmount < quoteAmount) {
          // Use dummy permit data for source token
          permitData = DUMMY_PERMIT_DATA;
        }
      }

      const gasFeeDataByChainId = await this.store.catalyst.getGasFeeData([this.sourceNetwork]);
      const priceOfAckGas = getMaxFeePerGas(gasFeeDataByChainId[this.sourceNetwork]);

      // underwriter incentive is a percentage of the swap amount
      const incentiveAmount = (1 << 16) * (this.fastSwapFee / 100);
      const underwritingIncentive = BigInt(Math.floor(incentiveAmount));

      // call swapByRouteViaPermit to get gas amounts
      const {
        executionInstructions: { gas: gasAmounts },
      } = swapByRouteViaPermit({
        toAccount,
        fromAsset,
        toAsset,
        toChainId,
        fromChainId,
        channelId,
        route,
        toAssetIndex,
        minOut: BigInt(netMinOut),
        amount: BigInt(amountFromQuote),
        refundGasTo: address,
        priceOfDeliveryGas: BigInt(priceOfDeliveryGas),
        messageVerifyGasCost: BigInt(messageVerifyGasCost),
        targetDelta: BigInt(targetDelta),
        priceOfAckGas,
        underwritingIncentive,
        permitData,
      });

      // generate fee details
      const feeDetails = await this.getFeeDetailsForQuote(quote, gasAmounts, {
        priceOfAckGas,
        priceOfDeliveryGas: BigInt(priceOfDeliveryGas),
      });

      // create swap quote with quote from API, fee details
      const swapQuote: SwapQuote = {
        ...quote,
        feeDetails,
      };

      if (this.quoteNumber - 1 === ticketNumber) {
        runInAction(() => {
          this.lastQuote = swapQuote;
          this.fetchingQuote = false;
        });
      }
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.fetchingQuote = false;
      });
      if (error instanceof ApiError) {
        const { status } = error;
        if (status === 422) {
          runInAction(() => {
            this.swapError = SwapError.FAILURE;
          });
        }
        if (status === 404) {
          runInAction(() => {
            this.swapError = SwapError.NO_ROUTE;
          });
        }
      } else if (error instanceof AxiosError) {
        const { code } = error;
        if (code === 'ERR_NETWORK') {
          runInAction(() => {
            this.swapError = SwapError.FAILURE;
          });
        }
      } else {
        captureException(error as Error);
      }
    }
  }

  private async getFeeDetailsForQuote(
    quote: PriceQuote,
    gasAmounts: {
      estimatedGasUsedOnLocal: bigint;
      estimatedGasUsedOnRemote: bigint;
      estimatedGasUsedOnLocalAck: bigint;
      estimatedRoutingPayment: bigint;
      estimatedRefundOnAck: bigint;
    },
    gasPrices: { priceOfAckGas: bigint; priceOfDeliveryGas: bigint },
  ): Promise<FeeDetail[]> {
    const { estimatedGasUsedOnLocal, estimatedRoutingPayment } = gasAmounts;
    const { priceOfAckGas } = gasPrices;

    const sourceGasToken = await this.store.catalyst.getToken(quote.fromChainId, GAS_TOKEN_IDENTIFIER);
    const sourceCurrency = sourceGasToken.symbol;
    const sourceGasTokenPrice = this.store.catalyst.getPrice(sourceGasToken);

    const executionFee = formatBalance(estimatedGasUsedOnLocal * priceOfAckGas);
    const feeDetails = [
      {
        name: 'Execution Fee',
        amount: executionFee,
        currency: sourceCurrency,
        value: sourceGasTokenPrice * executionFee,
      },
    ];

    if (isCrossChainSwap(quote)) {
      // Note: The 'estimatedRoutingPayment' is the sum of gas used on ack, gas used on remote and the base verification cost. This is being done on the SDK.
      // TODO: Consider why a 120% of the estimatedRoutingPayment is being used on swaps. If this is not required, it should be removed.
      const routingFee = formatBalance((estimatedRoutingPayment * 12n) / 10n);
      feeDetails.push({
        name: 'Routing Fee',
        amount: routingFee,
        currency: sourceCurrency,
        value: sourceGasTokenPrice * routingFee,
      });

      // TODO: Consider adding 'swapFee' directly to 'quote' object on API instead of 'feeDetails' array.
      const swapFeeDetail = quote.feeDetails.find((detail) => detail.name === 'Swap Fee');
      if (swapFeeDetail) {
        feeDetails.push(swapFeeDetail);
      }

      const destinationGasToken = await this.store.catalyst.getToken(quote.toChainId, GAS_TOKEN_IDENTIFIER);
      const destinationCurrency = destinationGasToken.symbol;
      const destinationGasTokenPrice = this.store.catalyst.getPrice(destinationGasToken);

      const underwriterFeeAmount = (this.fastSwapFee * quote.expectedOut) / 100;
      feeDetails.push({
        name: 'Underwriter Fee',
        amount: this.swapSpeed === SwapSpeed.FAST ? underwriterFeeAmount : 0,
        currency: destinationCurrency,
        value: this.swapSpeed === SwapSpeed.FAST ? underwriterFeeAmount * destinationGasTokenPrice : 0,
      });
    }

    return feeDetails;
  }

  async getTokenInfo(chainId: string, address: string): Promise<Token | undefined> {
    try {
      return this.store.client.tokens.getChainToken(chainId, address);
    } catch (error) {
      console.error('Failed to fetch data', error);
      return undefined;
    }
  }

  async swap(): Promise<void> {
    const { address } = this.store.wallet;

    if (!this.sourceNetwork || !this.sourceAsset || !this.targetAsset || !this.lastQuote || !address) {
      return;
    }

    await this.store.wallet.connectNetwork(this.sourceNetwork);

    const referenceQuote = this.lastQuote;
    const targetNetwork = this.sourceNetwork;
    const catalyst = CatalystNetwork.getCatalystNetwork(this.sourceNetwork);
    const { sdk } = catalyst;
    const quoteAmount = BigInt(this.lastQuote.amount);

    let permitData: PermitData | undefined;

    if (this.sourceAsset.address !== GAS_TOKEN_IDENTIFIER) {
      try {
        // Check allowance to permit2 address for source token.
        const permit2Allowance = await sdk.checkAllowance(this.sourceAsset.address, address, PERMIT2_ADDRESS);
        if (permit2Allowance < quoteAmount) {
          runInAction(() => {
            // Update swap step to indicate approval to permit2 address on UI
            this.swapStep = SwapStep.ApprovalToPermit2;
          });
          // Approve permit2 address for source token with max uint256 value.
          const tx = await sdk.increaseAllowance(
            this.sourceAsset.address,
            PERMIT2_ADDRESS,
            BigInt(ethers.MaxUint256.toString()),
          );
          await tx.wait();
        }

        const permittedAmount = await sdk.checkPermitAmount(this.sourceAsset.address, address, EVM_ROUTER_ADDRESS);
        if (permittedAmount < quoteAmount) {
          runInAction(() => {
            // Update swap step to indicate permit signing on UI
            this.swapStep = SwapStep.PermitSignature;
          });
          // Generate permit data for source token
          permitData = await sdk.generatePermitData(this.sourceAsset.address, EVM_ROUTER_ADDRESS);
          // Fix for ledger devices
          const sixtyFourthBit = permitData.signature.slice(2 + 64 * 2, 2 + 64 * 2 + 2);
          const parsedSixtyFourthBit = parseInt(sixtyFourthBit, 16);
          if (parsedSixtyFourthBit < 27) {
            permitData.signature =
              permitData.signature.slice(0, 2 + 64 * 2) +
              (parsedSixtyFourthBit + 27).toString(16) +
              permitData.signature.slice(2 + 64 * 2 + 2);
          }
        }
      } catch (err) {
        captureException(err as Error);
        console.error(err);
        runInAction(() => {
          this.swapStep = SwapStep.Failure;
        });
        return;
      }
    }

    runInAction(() => {
      this.swapStep = SwapStep.Swap;
    });

    const swapAmount = toUnits(this.swapInput ?? 0, this.sourceAsset.decimals);
    const balanceOf = this.store.wallet.getBalance(this.sourceNetwork, this.sourceAsset.address);
    if (balanceOf.amount < swapAmount) {
      runInAction(() => {
        this.swapStep = SwapStep.Quoting;
      });
      return;
    }

    try {
      const {
        toAccount,
        fromAsset,
        toAsset,
        toChainId,
        fromChainId,
        channelId,
        route,
        toAssetIndex,
        netMinOut,
        amount,
        priceOfDeliveryGas,
        messageVerifyGasCost,
        targetDelta,
      } = referenceQuote;

      const gasFeeDataByChainId = await this.store.catalyst.getGasFeeData([targetNetwork]);
      const priceOfAckGas = getMaxFeePerGas(gasFeeDataByChainId[targetNetwork]);

      const feeData = {
        gasPrice: BigInt(gasFeeDataByChainId[targetNetwork].gasPrice),
        maxFeePerGas: BigInt(gasFeeDataByChainId[targetNetwork].maxFeePerGas),
        maxPriorityFeePerGas: BigInt(gasFeeDataByChainId[targetNetwork].maxPriorityFeePerGas),
      };
      const supportsEip1559 = feeData.maxPriorityFeePerGas !== 0n;
      const gasOptions = {
        ...(!supportsEip1559 && { gasPrice: feeData.gasPrice }),
        ...(supportsEip1559 && {
          maxFeePerGas: feeData.maxFeePerGas,
          maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
        }),
      };

      // underwriter incentive is a percentage of the swap amount
      const incentiveAmount = (1 << 16) * (this.fastSwapFee / 100);
      const underwritingIncentive = BigInt(Math.floor(incentiveAmount));

      const {
        executionInstructions: {
          commands,
          inputs,
          gas: { estimatedRoutingPayment },
        },
      } = swapByRouteViaPermit({
        toAccount,
        fromAsset,
        toAsset,
        toChainId,
        fromChainId,
        channelId,
        route,
        toAssetIndex,
        minOut: BigInt(netMinOut),
        amount: BigInt(amount),
        refundGasTo: address,
        priceOfDeliveryGas: BigInt(priceOfDeliveryGas),
        messageVerifyGasCost: BigInt(messageVerifyGasCost),
        targetDelta: BigInt(targetDelta),
        priceOfAckGas,
        underwritingIncentive,
        permitData, // Permit data for source token
      });

      const isWrapOrUnwrap = isWrapUnwrap(referenceQuote);
      let gasAmount = BigInt('0');
      if (fromAsset === GAS_TOKEN_IDENTIFIER) {
        gasAmount = gasAmount + BigInt(amount);
      }
      if (!isWrapOrUnwrap) {
        gasAmount = gasAmount + (estimatedRoutingPayment * 12n) / 10n;
      }

      const { wait, hash } = await sdk.sendAssetWithRouter({ commands, inputs }, gasAmount, gasOptions);
      runInAction(() => {
        this.pendingSwaps.set(hash, {
          quote: referenceQuote,
          hash,
          sourceNetwork: targetNetwork,
          submittedAt: new Date(),
          totalDuration: 0,
        });
        this.swapStep = SwapStep.InProgress;
        this.swapTransactionHash = hash;
      });
      await wait();
    } catch (err) {
      const { code } = err as { code: string };

      if (code && code === 'ACTION_REJECTED') {
        runInAction(() => {
          this.swapStep = SwapStep.Quoting;
        });
      } else {
        if (getErrorMessage(err).includes('execution reverted')) {
          runInAction(() => {
            this.swapError = SwapError.REVERTED;
          });
        } else {
          runInAction(() => {
            this.swapError = SwapError.FAILURE;
          });
        }
        console.error(err);
        runInAction(() => {
          this.swapStep = SwapStep.Failure;
        });
      }
    } // purposeful no-op, allow the api to inform the transaction status
  }

  async updateSwapInfo(): Promise<void> {
    if (this.pendingSwaps.size === 0) {
      return;
    }
    const displayProgressPrompt = localStorage.getItem(StorageKey.DisplayProgressPrompt) !== 'false';
    await Promise.all(
      Array.from(this.pendingSwaps.entries()).map(async (entry) => {
        const [hash, pendingSwap] = entry;
        const { sourceNetwork } = pendingSwap;
        try {
          const swapInfo = await this.store.client.getSwap(sourceNetwork, hash);
          const isConfirmed = swapInfo.state === SwapState.COMPLETED || swapInfo.state === SwapState.CONFIRMED;
          const isFailed = swapInfo.state === SwapState.REVERTED || swapInfo.state === SwapState.TIMED_OUT;
          // verify if the transaction we are checking is the one we are currently waiting on
          const isWaitingTransaction = swapInfo.fromHash === this.swapTransactionHash;
          if (isWaitingTransaction) {
            runInAction(() => {
              this.swapInfo = swapInfo;
            });
            if (swapInfo.state !== SwapState.PENDING) {
              runInAction(() => {
                this.swapStep = isConfirmed ? SwapStep.Confirmation : SwapStep.Failure;
                this.pendingSwaps.delete(hash);
              });
              if (displayProgressPrompt) {
                localStorage.setItem(StorageKey.DisplayProgressPrompt, 'false');
              }
            }
            this.store.wallet.updateChainBalances([swapInfo.fromChainId, swapInfo.toChainId]);
          } else if (isConfirmed || isFailed) {
            if (this.recentPendingSwap && swapInfo.fromHash === this.recentPendingSwap.hash) {
              runInAction(() => {
                this.recentPendingSwap = undefined;
              });
            }
            runInAction(() => {
              this.pendingSwaps.delete(hash);
              if (this.completedSwaps.length >= 3) {
                this.completedSwaps = this.completedSwaps.slice(0, 2);
              }
              this.completedSwaps.push({ swap: swapInfo, quote: pendingSwap.quote });
            });
            if (displayProgressPrompt) {
              localStorage.setItem(StorageKey.DisplayProgressPrompt, 'false');
            }
            this.store.wallet.updateChainBalances([swapInfo.fromChainId, swapInfo.toChainId]);
          }
        } catch {}
      }),
    );
  }

  async reset() {
    this.resetSwap();
  }
}
