import { observer } from 'mobx-react-lite';

import { EmptyTable, Modal } from '~/modules/common/components';
import { useInfiniteScroll } from '~/modules/common/hooks';
import { SwapReceipt } from '~/modules/swap';
import { SwapListItem, SwapListItemSkeleton } from '~/modules/transactions/components';
import { useSwapList } from '~/modules/transactions/hooks';

export const SwapList = observer(() => {
  const {
    swaps,
    swapCount,
    isSwapsLoading,
    selectedSwap,
    isSwapSelected,
    selectSwap,
    unselectSwap,
    fetchSwapsOnScroll,
  } = useSwapList();

  const observerRef = useInfiniteScroll({ fetchData: fetchSwapsOnScroll });

  return (
    <>
      {swapCount === 0 ? (
        <EmptyTable>You don't have any swap transaction</EmptyTable>
      ) : (
        <>
          {swaps.map((swap, index) => (
            <SwapListItem
              key={`swap-list-item-${swap.fromHash}`}
              swap={swap}
              isLast={swaps.length - 1 === index}
              onClick={() => selectSwap(swap)}
            />
          ))}
          {isSwapsLoading && <SwapListItemSkeleton />}
        </>
      )}

      {/* For infinite scroll */}
      <div ref={observerRef} />

      {/* Swap receipt modal */}
      <Modal open={isSwapSelected} onClose={unselectSwap}>
        {selectedSwap !== null && <SwapReceipt receiptData={selectedSwap} />}
      </Modal>
    </>
  );
});
