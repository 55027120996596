import { CatalystNetwork as Environment } from '@catalabs/catalyst-sdk';

import { ARBITRUM_SUPPORT, ENVIRONMENT } from '~/config/environment';

import type { CatalystNetwork } from './catalyst-network';
import { Base, Blast, Optimism } from './evm';
import { Arbitrum } from './evm/arbitrum';
import { EvmNetwork } from './evm-network';
import { ArbitrumSepolia, BaseSepolia, BlastSepolia, OptimismSepolia } from './evm-testnet';

export const EVM_NETWORKS: EvmNetwork[] = [];
switch (ENVIRONMENT) {
  case Environment.Mainnet:
    EVM_NETWORKS.push(new Optimism(), new Base(), new Blast(), ...(ARBITRUM_SUPPORT ? [new Arbitrum()] : []));
    break;
  case Environment.Testnet:
    EVM_NETWORKS.push(new OptimismSepolia(), new BaseSepolia(), new BlastSepolia(), new ArbitrumSepolia());
    break;
}

export const CATALYST_NETWORKS: CatalystNetwork[] = EVM_NETWORKS;
