import type { Asset, AssetList, Chain } from '@chain-registry/types';
import { Coin } from '@cosmjs/stargate';
import { TokenInfo } from '@uniswap/token-lists';
import { assets, chains } from 'chain-registry';

import { Network, NetworkType } from '~/config/enums';
import { NetworkNotFound, TokenNotFound } from '~/config/errors';
import { TokenBalance } from '~/config/interfaces';
import { NetworkConfig } from '~/config/network';

const assetDecimalLookup = new Map<string, number>();
const assetLookup = new Map<string, Asset>();

assets.forEach((c) =>
  c.assets.forEach((a) => {
    assetDecimalLookup.set(a.denom_units[0].denom, a.denom_units[a.denom_units.length - 1].exponent);
    assetLookup.set(a.denom_units[0].denom, a);
  }),
);

export function loadTinderminChainConfig(network: Network): Chain {
  const byChainEntries: [string, Chain][] = chains.map((c) => [c.chain_name, c]);
  const byChaiRecord = Object.fromEntries(byChainEntries);
  const config = byChaiRecord[getRecordKey(network)];
  if (!config) {
    throw new NetworkNotFound(network);
  }
  return config;
}

export function loadTindermintConfig(network: Network): NetworkConfig {
  const config = loadTinderminChainConfig(network);

  let rpcUrl = '';
  if (config.apis?.rpc) {
    rpcUrl = config.apis.rpc[0].address;
  }

  const assets = loadTindermintTokens(network);

  return {
    name: config.pretty_name,
    chainId: config.chain_id,
    currencySymbol: assets[0].symbol,
    networkType: NetworkType.TinderMint,
    // TODO: chain this up
    explorerUrl: config.explorers ? config.explorers[0].url ?? '' : '',
    rpcUrl,
  };
}

export function loadTindermintTokens(network: Network): TokenInfo[] {
  const byChainAssets: [string, AssetList][] = assets.map((c) => [c.chain_name, c]);
  const byChainAssetRecord = Object.fromEntries(byChainAssets);
  const chainAssets = byChainAssetRecord[getRecordKey(network)].assets;
  return chainAssets.map((a) => {
    const denominations = a.denom_units.find((u) => u.denom === a.symbol.toLowerCase());
    return {
      name: a.name,
      symbol: a.symbol,
      // TODO: we may need to modify the token info object slightly as chain id in tindermint may be strings
      chainId: 0,
      decimals: denominations?.exponent ?? 18,
      address: a.address ?? '0x0',
      logoURI: a.logo_URIs?.svg ?? a.logo_URIs?.png,
    };
  });
}

export function toTindermintTokenBalance(coin: Coin): TokenBalance {
  const { amount, denom } = coin;
  const asset = assetLookup.get(denom);
  if (!asset) {
    throw new TokenNotFound(denom);
  }
  const decimals = assetDecimalLookup.get(denom) ?? 6;
  return {
    name: asset.name,
    symbol: asset.symbol,
    address: asset.address ?? '0x0',
    decimals,
    amount: BigInt(amount),
  };
}

function getRecordKey(network: Network): string {
  let recordKey = network.toLowerCase();
  for (const [key, value] of Object.entries(Network)) {
    if (value === network) {
      recordKey = key.toLowerCase();
      break;
    }
  }
  return recordKey;
}
