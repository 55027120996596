import { CatalystNetwork, roundDownToDecimals } from '~/config';
import { ReactComponent as Active } from '~/img/circle.svg';
import { ReactComponent as Complete } from '~/img/circle_check.svg';
import { ReactComponent as Inactive } from '~/img/emptycircle.svg';
import { formatCurrency, TokenDisplay, WithdrawRequest } from '~/modules/common';
import { ChainWithdraw, PoolActionState } from '~/modules/pools';

export interface PoolWithdrawDisplayProps {
  index: number;
  req: ChainWithdraw;
  withdrawText: string;
  poolWithdrawRequest: ChainWithdraw[];
  allWithdrawItems: WithdrawRequest;
}

export function PoolWithdrawDisplay({
  req,
  withdrawText,
  poolWithdrawRequest,
  allWithdrawItems,
  index,
}: PoolWithdrawDisplayProps) {
  let headerText = `Approve ${withdrawText} withdraw`;
  let subHeaderText = `This action will convert your LP tokens into ${withdrawText}`;
  const liquiditySwapReq = req.request.find((r) => r.withLiquiditySwap);
  if (liquiditySwapReq && liquiditySwapReq.lSwapToChain) {
    const fromChainName = CatalystNetwork.getCatalystNetwork(req.chainId).config.name;
    const toChainName = CatalystNetwork.getCatalystNetwork(liquiditySwapReq.lSwapToChain).config.name;
    headerText = `Approve swap of LP tokens to ${withdrawText} on ${toChainName} `;
    subHeaderText = `This action would initiate a liquidity swap and withdrawal of LP tokens from ${fromChainName} to ${withdrawText} on ${toChainName} `;
  }

  return (
    <div
      className="mt-4 flex w-full items-center rounded-[25px] bg-white p-5 shadow md:w-[552px]"
      data-testid="pool-withdrawal-request"
      data-chain-id={req.chainId}
    >
      {(req.withdraw === PoolActionState.Inactive || req.withdraw === PoolActionState.Active) && (
        <div className="flex items-center">
          <Inactive />
          <div className="ml-4 flex flex-col">
            <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-gray-600">{headerText}</span>
            <span className="text-sm leading-5 text-gray-600">
              {index === poolWithdrawRequest.length - 1
                ? 'This is the last approval before we finish.'
                : 'This action will trigger the withdrawal of tokens.'}
            </span>
          </div>
        </div>
      )}
      {(req.withdraw === PoolActionState.Pending || req.withdraw === PoolActionState.Confirmed) && (
        <div className="flex w-full flex-col">
          <div className="flex gap-4">
            {req.withdraw === PoolActionState.Pending ? (
              <div className="w-8">
                <Active className="h-8 w-8" />
              </div>
            ) : (
              <div className="w-8">
                <Complete className="h-8 w-8" />
              </div>
            )}
            <div className="flex flex-col">
              <span className="font-semibold text-xs uppercase leading-4 tracking-wide text-primary">{headerText}</span>
              <span className="text-sm leading-5 text-gray-600">{subHeaderText}</span>
              {/* <span className="text-sm leading-5 text-gray-600">
                Withdraw {index + 1} out of {poolWithdrawRequest.length}.
              </span> */}
            </div>
          </div>
          {!liquiditySwapReq && allWithdrawItems.some((item) => item.amount > 0n) && (
            <div className="mt-2 flex flex-col gap-4">
              {allWithdrawItems.map((item) => {
                if (item.amount <= 0n) {
                  return null;
                }
                return (
                  <div
                    key={`withdraw-item-${item.chainId}-${item.address}`}
                    className={`flex items-center rounded-[15px] border-2 px-4 py-5 ${
                      item.chainId === req.chainId ? 'border-primary' : 'border-gray-300'
                    }`}
                  >
                    <TokenDisplay chainId={item.chainId} token={item.address} showSymbol={false} showChain={false} />
                    <div className="ml-4 mt-1 flex flex-col">
                      <span className="font-semibol text-[32px] leading-[28px]">
                        {roundDownToDecimals(Number(item.displayAmount), 6)}
                      </span>
                      <span className="text-[14px] font-normal text-gray-600">{formatCurrency(item.value)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {liquiditySwapReq &&
          liquiditySwapReq.lSwapToChain &&
          liquiditySwapReq.lSwapToTokenInfo &&
          liquiditySwapReq.finalLiquiditySwapAmount &&
          liquiditySwapReq.finalLiquiditySwapValue ? (
            <div className="mt-2 flex flex-col gap-4">
              <div
                className={`flex items-center rounded-[15px] border-2 border-primary px-4 
                   py-5
                `}
              >
                <TokenDisplay
                  chainId={liquiditySwapReq.lSwapToChain}
                  token={liquiditySwapReq.lSwapToTokenInfo.address}
                  showSymbol={false}
                  showChain={false}
                />
                <div className="ml-4 mt-1 flex flex-col">
                  <span className="font-semibol text-[32px] leading-[28px]">
                    {roundDownToDecimals(Number(liquiditySwapReq.finalLiquiditySwapAmount), 6)}
                  </span>
                  <span className="text-[14px] font-normal text-gray-600">
                    {formatCurrency(liquiditySwapReq.finalLiquiditySwapValue)}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="approval mt-8 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-center text-white">
            {req.withdraw === PoolActionState.Pending ? 'Waiting for approval on your wallet' : 'Approved'}
          </div>
        </div>
      )}
    </div>
  );
}

export default PoolWithdrawDisplay;
