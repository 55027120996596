export const PortfolioListHeader = () => {
  return (
    <>
      <div key="pool" className="col-span-3 flex items-center">
        POOL
      </div>
      <div key="position" className="col-span-3 flex items-center">
        POSITION
      </div>
      <div key="total-liquidity" className="col-span-3 flex items-center">
        TOTAL LIQUIDITY
      </div>
      <div key="apr" className="col-span-2 flex items-center">
        APR
      </div>
      <div key="options" className="col-span-1 flex items-center" />
    </>
  );
};
