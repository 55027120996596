import { runInAction } from 'mobx';
import { useNavigate } from 'react-router-dom';

import { routes } from '~/config';
import { getPoolRoute } from '~/config/utils/routes.utils';
import { RootStore } from '~/modules/common';
import { PoolActionType, PoolCreateStep, PoolInteractionStep } from '~/modules/pools';

export interface usePendingActionsType {
  handlers: {
    handleWithdrawDeposit: () => void;
    handleResumeDeposit: () => void;
    handleCancelDeposit: () => void;
    handleResumeCreate: () => void;
    handleHideCreate: () => void;
    handleCancelCreate: () => void;
    handleCreateWithdraw: () => void;
    handleCancelWithdraw: () => void;
    handleResumeCreateDeposit: () => void;
    handleResumeWithdraw: () => void;
  };
}

export interface usePendingActionsProps {
  store: RootStore;
}

export function usePendingActions({ store }: usePendingActionsProps): usePendingActionsType {
  const { pool: poolStore } = store;
  const { depositStore, withdrawStore } = poolStore;
  const navigate = useNavigate();

  // deposit handlers
  const withdrawDeposit = () => {
    if (!depositStore.poolDepositRequest) return;

    const poolId = depositStore?.poolDepositRequest[0]?.request[0].poolId;

    navigate(getPoolRoute(poolId));
    poolStore.updatePool(poolId).then(() => {
      runInAction(() => {
        poolStore.poolAction = PoolActionType.Widthdraw;
      });
    });
  };

  const resumeDeposit = async () => {
    if (!depositStore.poolDepositRequest) {
      depositStore.reset();
      return;
    }
    const poolId = depositStore?.poolDepositRequest[0]?.request[0].poolId;
    navigate(getPoolRoute(poolId));
    await poolStore.updatePool(depositStore.poolDepositRequest[0].request[0].poolId);
    runInAction(() => {
      poolStore.poolAction = PoolActionType.Deposit;
      depositStore.poolDepositStep = PoolInteractionStep.Approval;
    });
    poolStore.depositStore.resumeDeposit();
  };

  const handleCancelDeposit = () => {
    depositStore.reset();
    // refresh page
    window.location.reload();
  };

  const handleResumeWithdraw = async () => {
    const { pool, poolWithdrawRequest } = withdrawStore;
    if (!poolWithdrawRequest || !pool) {
      withdrawStore.reset();
      return;
    }
    navigate(getPoolRoute(pool.id));
    await poolStore.updatePool(pool.id);
    runInAction(() => {
      poolStore.poolAction = PoolActionType.Widthdraw;
      withdrawStore.poolWithdrawStep = PoolInteractionStep.Approval;
    });
    withdrawStore.resumeWithdraw();
  };

  const handleCancelWithdraw = () => {
    withdrawStore.reset();
    // refresh page
    window.location.reload();
  };

  // creation handlers
  const hideCreate = () => {
    poolStore.hidePendingPoolConfigBanner();
  };

  const navigateCreate = () => {
    navigate(routes.poolCreate);
  };

  // creation deposit handlers
  const withdrawCreateDeposit = () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Withdraw;
    });
    poolStore.withdrawFromIncompletePool();
    navigate(routes.poolCreate);
  };

  const resumeCreateDeposit = () => {
    runInAction(() => {
      poolStore.poolCreateStep = PoolCreateStep.Paused;
    });
    navigate(routes.poolCreate);
  };

  return {
    handlers: {
      handleWithdrawDeposit: withdrawDeposit,
      handleResumeDeposit: resumeDeposit,
      handleResumeCreate: navigateCreate,
      handleHideCreate: hideCreate,
      handleCancelCreate: navigateCreate,
      handleCreateWithdraw: withdrawCreateDeposit,
      handleResumeCreateDeposit: resumeCreateDeposit,
      handleResumeWithdraw,
      handleCancelDeposit,
      handleCancelWithdraw,
    },
  };
}
