import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { CatalystNetwork } from '~/config';
import { ReactComponent as SourcePlaceholder } from '~/img/source-placeholder.svg';
import { StoreContext, useTokenDisplay } from '~/modules/common';

export interface TokenDisplayProps {
  chainId: string;
  token: string;
  showChain?: boolean;
  showChainIcon?: boolean;
  showSymbol?: boolean;
  showTooltip?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

type className = React.ComponentProps<'div'>['className'];

export const TokenDisplay = observer(
  ({ chainId, token, showChain = false, showSymbol = true, showChainIcon = true, size = 'md' }: TokenDisplayProps) => {
    const store = useContext(StoreContext);

    const { logoURI, symbol } = useTokenDisplay({
      chainId,
      token,
      catalystStore: store.catalyst,
    });
    const chainName = CatalystNetwork.getCatalystNetwork(chainId.toString()).config.name;

    const logoSizeClassesMap: Record<string, className> = {
      xs: 'h-4 w-4',
      sm: 'h-5 w-5',
      md: 'h-8 w-8',
      lg: 'h-12 w-12',
    };

    const chainIconSizeClassesMap: Record<string, className> = {
      xs: 'h-2 w-2',
      sm: 'h-3 w-3',
      md: 'h-[19px] w-[19px]',
      lg: 'h-8 w-8',
    };

    return (
      <div className="flex items-end  justify-center">
        <>
          {logoURI ? (
            <img className={logoSizeClassesMap[size]} src={logoURI} alt={symbol} />
          ) : (
            <SourcePlaceholder className={logoSizeClassesMap[size]} />
          )}
        </>

        {showChainIcon && (
          <img
            alt={chainId}
            className={`z-2 -ml-2 rounded-full border border-white ${chainIconSizeClassesMap[size]}`}
            src={`/img/${chainId}_bright.webp`}
          />
        )}

        <div className="self-center pl-2">
          {showSymbol && <div className="text-sm leading-4 text-grey-800">{symbol}</div>}
          {showChain && <div className="text-[12px] leading-3 text-grey-500">On {chainName}</div>}
        </div>
      </div>
    );
  },
);

export default TokenDisplay;
