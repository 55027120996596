import { TokenInfo } from '@uniswap/token-lists';

import { PoolApprovalState } from '~/modules/pools';

import PoolApprovalInactive from './PoolApprovalInactive';
import PoolApprovalPending from './PoolApprovalPending';

export interface PoolApprovalProps {
  approval: PoolApprovalState;
  token: TokenInfo;
  chainId: string;
  liquidityToken?: boolean;
}

export function PoolApproval({ approval, token, chainId, liquidityToken = false }: PoolApprovalProps) {
  if (approval === PoolApprovalState.Completed) {
    return null;
  }

  const tokenIdentifier = liquidityToken ? 'liquidity' : token.symbol;

  return (
    <div
      key={`approval-${token.chainId}-${token.address}`}
      className="flex flex-col items-center"
      data-testid="pool-approval"
      data-chainid={chainId}
    >
      {approval === PoolApprovalState.Inactive && <PoolApprovalInactive tokenIdentifier={tokenIdentifier} />}

      {(approval === PoolApprovalState.PendingApprovalToPermit2 ||
        approval === PoolApprovalState.PendingPermitSignature) && (
        <PoolApprovalPending approval={approval} tokenIdentifier={tokenIdentifier} />
      )}
    </div>
  );
}

export default PoolApproval;
