import capsuleModule, { Environment, OAuthMethod, Theme } from '@web3-onboard/capsule';
import coinbaseModule from '@web3-onboard/coinbase';
import type { Chain } from '@web3-onboard/common';
import frameModule from '@web3-onboard/frame';
import injectedModule from '@web3-onboard/injected-wallets';
import { init } from '@web3-onboard/react';
import walletConnectModule from '@web3-onboard/walletconnect';
import { ethers } from 'ethers';

import { EVM_NETWORKS } from '~/config/network';
import Logo from '~/img/logo_light_web.svg';

import { CAPSULE_API_KEY, WALLETCONNECT_PROJECT_ID } from './environment';

export const chains: Chain[] = EVM_NETWORKS.map((net) => ({
  id: ethers.toQuantity(Number(net.chain.id)),
  token: net.config.currencySymbol,
  label: net.chain.name,
  rpcUrl: net.chain.rpcUrls.default.http[0],
}));

const frame = frameModule();
const injected = injectedModule();
const coinbase = coinbaseModule();
const walletConnect = walletConnectModule({
  projectId: WALLETCONNECT_PROJECT_ID,
  optionalChains: chains.map((chain) => Number(chain.id)),
});
// initialize the module with options
const capsule = capsuleModule({
  environment: Environment.PROD,
  apiKey: CAPSULE_API_KEY,
  modalProps: {
    theme: Theme.dark,
    branding: {
      colors: {
        primaryButton: {
          surface: {
            default: '#4553d3',
          },
        },
      },
    },
    oAuthMethods: [OAuthMethod.GOOGLE, OAuthMethod.TWITTER],
    logo: Logo,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  } as any,
  constructorOpts: {
    portalBackgroundColor: '#000000',
    portalPrimaryButtonColor: '#4553d3',
    portalPrimaryButtonTextColor: '#ffffff',
    portalTextColor: '#ffffff',
    emailPrimaryColor: '#4553d3',
    githubUrl: 'https://github.com/catalystdao',
    xUrl: 'https://twitter.com/CatalystAMM',
    homepageUrl: 'https://catalyst.exchange/',
  },
});

export const web3Onboard = init({
  wallets: [injected, frame, coinbase, walletConnect, capsule],
  chains,
  connect: {
    autoConnectLastWallet: true,
  },
  appMetadata: {
    name: 'Catalyst',
    description: 'Catalyst UI',
    icon: '/img/logo_icon_blue.svg',
  },
  accountCenter: {
    desktop: {
      enabled: false,
    },
    mobile: {
      enabled: false,
    },
  },
});
