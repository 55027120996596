import { Token } from '@catalabs/catalyst-api-client';
import {
  // assertIsBroadcastTxSuccess,
  SigningStargateClient,
} from '@cosmjs/stargate';
import { Keplr as KeplrWallet } from '@keplr-wallet/types';

import { WalletType } from '~/config';
import { WalletNotFound } from '~/config/errors';
import { CatalystWallet, ConnectionData, TokenBalance } from '~/config/interfaces';
import { NetworkConfig } from '~/config/network';
import { toTindermintTokenBalance } from '~/config/utils';

export class Keplr implements CatalystWallet {
  walletType = WalletType.Keplr;

  signer?: SigningStargateClient = undefined;
  address?: string = undefined;

  async connect(config: NetworkConfig): Promise<ConnectionData> {
    const { chainId } = config;
    const keplr = this.#getKeplr();
    try {
      await keplr.enable(chainId);
    } catch (err) {
      console.error(err);
      // await keplr.experimentalSuggestChain();
    }
    const offlineSigner = keplr.getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    const [{ address }] = accounts;

    const signer = await SigningStargateClient.connectWithSigner(config.rpcUrl, offlineSigner);

    this.signer = signer;
    this.address = address;

    return {
      address,
      signer,
    };
  }

  disconnect() {
    console.log('call disconnect');
  }

  getWalletType(): WalletType {
    return this.walletType;
  }

  getAddress(): string | undefined {
    return this.address;
  }

  async getBalances(_tokens: Token[]): Promise<TokenBalance[]> {
    if (!this.signer || !this.address) {
      return [];
    }

    const accountBalances = await this.signer.getAllBalances(this.address);
    return accountBalances.map((b) => toTindermintTokenBalance(b));
  }

  #getKeplr(): KeplrWallet {
    if (!window.keplr) {
      throw new WalletNotFound(this.walletType);
    }
    return window.keplr;
  }
}
