import { observer } from 'mobx-react-lite';

export const SwapBadge = observer(() => {
  return (
    <div className="flex h-[292px] w-[274px] flex-col justify-center rounded-3xl bg-white shadow">
      <div className="mt-4 flex justify-center ">
        <div className="badge h-24 w-24 rounded-full border-2 border-grey-300 bg-blue-700"></div>
      </div>
      <div className="mt-3 flex justify-center text-xs opacity-60">
        <span>WELL DONE</span>
      </div>
      <div className="flex justify-center text-xl">
        <span>First Swap Badge</span>
      </div>
    </div>
  );
});

export default SwapBadge;
