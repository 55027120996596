import { CreatePoolType } from '~/modules/pools';

export const poolTypesDetails = {
  [CreatePoolType.CLASSIC]: {
    name: 'Classic',
    description: 'Good for non correlated assets',
  },
  [CreatePoolType.STABLESWAP]: {
    name: 'Stableswap',
    description: 'Best for assets that should be equal in value.',
  },
};
