import { Pool, PoolSummary } from '@catalabs/catalyst-api-client';
import { useNavigate } from 'react-router-dom';

import { formatBalance } from '~/config';
import { getPoolRoute } from '~/config/utils/routes.utils';
import { TableAssetsDisplay } from '~/modules/common';
import { formatCurrency, formatPercentage } from '~/modules/common/utils';
import { PoolAprBreakdown } from '~/modules/pools';

export interface PoolsListItemProps {
  pool: PoolSummary;
  final?: boolean;
  maxTokenDisplay?: number;
  index?: number;
}

export function PoolsListItem({ pool, final = false, maxTokenDisplay = 3, index }: PoolsListItemProps): JSX.Element {
  const navigate = useNavigate();
  const assets = pool.assets.map((x) => ({ chain: x.chainId, address: x.address }));

  const poolVaultFee = Object.values((pool as Pool).vaults)[0].swapFee;

  const poolFee = formatBalance(BigInt(poolVaultFee)) * 100;

  return (
    <div
      onClick={() => navigate(getPoolRoute(pool.id))}
      className={`border-gray grid h-20 grid-cols-16 border-t bg-white px-5 hover:cursor-pointer hover:bg-gray-100 ${
        final ? 'rounded-b-2xl' : ''
      }`}
      data-testid={`pool-item-${index}`}
    >
      <TableAssetsDisplay assets={assets} maxTokenDisplay={maxTokenDisplay} />
      <span className="col-span-3 flex items-center">{formatPercentage(poolFee)}</span>
      <span className="col-span-3 flex items-center">{formatCurrency(pool.tvl)}</span>
      <span className="col-span-3 flex items-center">{formatCurrency(pool.dailyVolume)}</span>
      <PoolAprBreakdown pool={pool} />
    </div>
  );
}
