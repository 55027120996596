import { AccountEggData, Referral } from '@catalabs/catalyst-api-client';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore, Store } from '~/modules/common';

export class ReferralStore implements Store {
  utilizedReferral: { code: string; sharePercentage: number } | null = null;
  ownReferral: Referral | null = null;
  eggData: AccountEggData | null = null;

  constructor(private store: RootStore) {
    makeAutoObservable(this);
  }

  async getAccountEggData() {
    if (!this.store.wallet.address) {
      return;
    }
    try {
      const accountEggData = await this.store.client.accounts.getEggData(this.store.wallet.address);
      runInAction(() => {
        this.eggData = accountEggData;
      });
    } catch {
      // TODO: report some error or keep a loading state
    }
  }

  async getReferralByCode(code: string, checkRecipient = false) {
    try {
      const referral = await this.store.client.referrals.getReferral(
        code,
        checkRecipient ? this.store.wallet.address : undefined,
      );
      return referral;
    } catch (e) {
      return null;
    }
  }

  async getUtilizedReferral(connectedWalletAddress: string) {
    const utilizedReferral = await this.store.client.referrals.getUtilizedReferralForAddress(connectedWalletAddress);
    if (!utilizedReferral) {
      return null;
    }

    runInAction(() => {
      this.utilizedReferral = utilizedReferral;
    });

    return utilizedReferral;
  }

  async getOwnReferral(_connectedWalletAddress: string) {
    return null;
  }

  async acceptReferral(args: { walletAddress: string; code: string; signature: string }) {
    const referral = await this.store.client.referrals.acceptReferral(args.walletAddress, args.code, args.signature);
    runInAction(() => {
      this.utilizedReferral = {
        code: referral.code,
        sharePercentage: referral.sharePercentage,
      };
    });
    return referral;
  }

  async createReferralLink(args: { walletAddress: string; code: string; signature: string }) {
    const referral = await this.store.client.referrals.createReferral(
      args.walletAddress,
      args.code.toLocaleUpperCase(),
      args.signature,
    );

    runInAction(() => {
      this.ownReferral = referral;
    });

    return referral;
  }

  async getReferralByAddress(address: string) {
    const referral = await this.store.client.referrals.getReferralByAddress(address);
    runInAction(() => {
      this.ownReferral = referral;
    });
    return referral;
  }

  async getUtilizedReferralForAddress(address: string) {
    const referral = await this.store.client.referrals.getUtilizedReferralForAddress(address);
    runInAction(() => {
      this.utilizedReferral = {
        code: referral.code,
        sharePercentage: referral.sharePercentage,
      };
    });
    return referral;
  }

  async updateOwnReferral({
    address,
    code,
    sharePercentage,
    signature,
  }: {
    address: string;
    code: string;
    sharePercentage: number;
    signature: string;
  }) {
    const updatedReferral = await this.store.client.referrals.updateReferral(address, code, sharePercentage, signature);
    runInAction(() => {
      this.ownReferral = updatedReferral;
    });
  }

  reset(): void {
    this.utilizedReferral = null;
    this.ownReferral = null;
  }
}
