import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { isGasToken } from '~/config';
import { CATALYST_NETWORKS } from '~/config/network/network.config';
import { ReactComponent as Checkbox } from '~/img/checkbox.svg';
import { ReactComponent as EmptyCheckbox } from '~/img/empty_checkbox.svg';
import { ReactComponent as Search } from '~/img/search.svg';
import { Input } from '~/modules/common/components/Input';
import { StoreContext } from '~/modules/common/store/context';

interface TokenFilterProps {
  hideGasTokens?: boolean;
}

export const TokenFilter = observer(({ hideGasTokens }: TokenFilterProps) => {
  const { catalyst, pool } = useContext(StoreContext);
  const [selectedTokens, setSelectedTokens] = useState<TokenInfo[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');

  const allTokens = CATALYST_NETWORKS.flatMap((n) => catalyst.getTokenList(n.config.chainId));

  const includedSymbols = new Set();
  const displayTokens = allTokens.filter((t) => {
    if (hideGasTokens && isGasToken(t)) {
      return false;
    }
    if (includedSymbols.has(t.symbol)) {
      return false;
    }
    includedSymbols.add(t.symbol);
    return true;
  });

  const [tokenOptions, setTokenOptions] = useState(displayTokens);

  useEffect(() => {
    if (selectedTokens.length === 0 && pool.filteredTokens.length === 0) {
      return;
    }
    runInAction(() => {
      pool.filteredTokens = selectedTokens;
    });
  }, [selectedTokens]);

  useEffect(() => {
    if (pool.filteredTokens.length === 0) {
      setSelectedTokens([]);
    }
  }, [pool.filteredTokens]);

  useEffect(() => {
    if (searchFilter.length > 0) {
      const input = searchFilter.toLowerCase();
      const newTokenOptions = displayTokens.slice().filter((t) => {
        const matchesSymbol = t.symbol.toLowerCase().includes(input);
        const matchesName = t.name.toLowerCase().includes(input);
        return matchesSymbol || matchesName;
      });
      setTokenOptions(newTokenOptions);
    } else {
      setTokenOptions(displayTokens);
    }
  }, [searchFilter]);

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchFilter(e.target.value);
  }

  return (
    <div className="h-[42px]  rounded-[8px] border border-gray-300 md:w-[200px]">
      <Listbox value={selectedTokens} onChange={setSelectedTokens} multiple>
        <div className="relative w-full">
          <Listbox.Button
            as="div"
            className="w-full cursor-default p-3 text-left text-sm leading-tight text-gray-600 focus:outline-none"
          >
            <span>Filter by Tokens</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="absolute z-20 mt-1 max-h-[275px] w-full rounded-[8px] bg-white text-base shadow">
              <Listbox.Options className="mx-3 max-h-[220px] space-y-3 overflow-auto px-1 pb-3 focus:outline-none">
                <div className="pt-3">
                  <div className="flex h-[42px] items-center rounded-[8px] border border-gray-300 px-4 py-2">
                    <div className="mr-2 h-[18px] w-[18px]">
                      <Search />
                    </div>
                    <Input
                      value={searchFilter}
                      onChange={handleSearch}
                      className="mr-2 h-[18px] text-sm placeholder-grey-500"
                      placeholder="Search"
                    />
                  </div>
                </div>
                {tokenOptions.map((t) => (
                  <Listbox.Option key={`${t.symbol}-${t.address}`} value={t}>
                    {({ selected }) => (
                      <div className="flex items-center text-sm hover:cursor-pointer">
                        <div className="mr-2">{selected ? <Checkbox /> : <EmptyCheckbox />}</div>
                        <img alt={`${t.name} Icon`} className="mr-2 h-7 w-7 rounded-full" src={t.logoURI} />
                        <span>{t.symbol}</span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
                {tokenOptions.length === 0 && (
                  <div className="flex justify-center text-xs text-gray-600">Token not found</div>
                )}
              </Listbox.Options>
              <hr />
              <div
                className={`flex h-[32px] items-center justify-center text-[12px] ${
                  tokenOptions.length > 0 ? 'cursor-pointer text-primary' : 'text-gray-200'
                }`}
                onClick={() => setSelectedTokens([])}
              >
                Clear all
              </div>
            </div>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
});

export default TokenFilter;
