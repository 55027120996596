import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Fragment, useContext, useEffect, useState } from 'react';

import { StoreContext } from '~/modules/common';
import { CreatePoolType } from '~/modules/pools';
import { poolTypesDetails } from '~/modules/pools/config';

interface SelectPoolTypeProps {
  selectedPool?: CreatePoolType;
  onSelectPool?: (poolType: CreatePoolType) => void;
}

export const SelectPoolType = observer((props: SelectPoolTypeProps) => {
  const [selectedPool, setSelectedPool] = useState<CreatePoolType | undefined>(props.selectedPool);
  const { pool: poolStore } = useContext(StoreContext);

  useEffect(() => {
    if (props.selectedPool) setSelectedPool(props.selectedPool);
  }, [props.selectedPool]);

  const handleOnchange = (pool: CreatePoolType) => {
    setSelectedPool(pool);
    runInAction(() => (poolStore.poolCreateType = pool));
    if (props?.onSelectPool) props.onSelectPool(pool);
  };

  return (
    <div className="flex h-[148px] w-full flex-row rounded-[20px] bg-white md:w-[700px] ">
      <div className="flex w-full flex-col">
        <div className="bg-opacity-15two ml-[26px] mt-6 flex items-center justify-between pl-2 font-semibold text-sm leading-5 tracking-wide text-grey-900">
          <span className="font-subheader uppercase leading-4">Select type</span>
        </div>
        <div className="border-gray mx-6 mt-4 flex h-[52px] cursor-pointer items-center justify-between rounded-[12px] border md:w-[654px]">
          <Listbox value={selectedPool || ''} onChange={handleOnchange}>
            <div className="relative mt-1 w-full">
              <Listbox.Button className="relative w-full cursor-pointer bg-white px-6 py-2 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate">
                  {poolTypesDetails[selectedPool as CreatePoolType]?.name || 'Select Pool Type'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5">
                  <ChevronDownIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {Object.values(CreatePoolType).map((pool, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-10 
                        pr-4
                        transition-colors
                        ${active ? 'bg-primary-600 text-white' : 'text-gray-900'}`
                      }
                      value={pool}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`text-md block truncate font-medium text-gray-600 transition-colors
                              ${active ? 'text-white' : 'text-gray-900'}
                              `}
                          >
                            {poolTypesDetails[pool as CreatePoolType]?.name || ''}
                          </span>
                          <span
                            className={`block font-light text-xs transition-colors ${
                              active ? 'text-gray-300' : 'text-gray-600'
                            }`}
                          >
                            {poolTypesDetails[pool as CreatePoolType]?.description || ''}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 transition-colors ${
                                active ? 'text-white' : 'text-primary'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
  );
});

export default SelectPoolType;
