import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ReactComponent as ActiveItem } from '~/img/circle.svg';
import { Modal } from '~/modules/common';
import { StoreContext } from '~/modules/common/store/context';
import SwapReview from '~/modules/swap/components/SwapReview';
import { SwapStep } from '~/modules/swap/enums';

export const SwapConfirmation = observer(() => {
  const store = useContext(StoreContext);
  const { swap, catalyst } = store;
  const { lastQuote, sourceAsset } = swap;

  const open = !(swap.swapStep !== SwapStep.Swap || !lastQuote);

  function onClose() {
    swap.resetSwap();
  }

  const tokenPrice = catalyst.getPrice(sourceAsset);

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="mx-3 flex flex-col items-center">
        <div className="w-full rounded-[25px] bg-white p-5 md:w-[552px]">
          <div className="flex">
            <ActiveItem />
            <div className="flex flex-col pl-4 md:w-1/2">
              <span className="font-semibold text-[12px] uppercase text-primary">Sign the transaction</span>
              <span className="text-[14px] text-grey-600">This is the last approval before you start the swap.</span>
            </div>
          </div>
          <div className="approval mt-6 flex h-[78px] animate-approval items-center justify-center rounded-[145px] text-white">
            Sign transaction on your wallet
          </div>
        </div>
        <div className="flex w-full md:mx-auto md:w-[552px]">
          <SwapReview quote={lastQuote} tokenPrice={tokenPrice} />
        </div>
      </div>
    </Modal>
  );
});

export default SwapConfirmation;
