import { twMerge } from 'tailwind-merge';

import TokenDisplaySkeleton from '~/modules/common/components/TokenDisplaySkeleton';

export const TableAssetsDisplaySkeleton = ({
  showSymbol = true,
  className,
}: {
  showSymbol?: boolean;
  className?: string;
}) => {
  return (
    <div className={twMerge('col-span-6 flex flex-wrap items-center gap-[23px]', className)}>
      <TokenDisplaySkeleton showSymbol={showSymbol} />
      <TokenDisplaySkeleton showSymbol={showSymbol} />
      <TokenDisplaySkeleton showSymbol={showSymbol} />
    </div>
  );
};

export default TableAssetsDisplaySkeleton;
