interface InputProperties
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  numeric?: boolean;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const Input: React.FC<InputProperties> = ({ numeric, className, inputRef, ...props }) => {
  return (
    <input
      {...props}
      type={numeric ? 'number' : 'text'}
      className={`${className} w-full min-w-0 outline-none`}
      ref={inputRef}
    />
  );
};
