import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Fragment, useContext, useEffect, useState } from 'react';

import { CatalystNetwork } from '~/config/network/catalyst-network';
import { CATALYST_NETWORKS } from '~/config/network/network.config';
import { ReactComponent as Checkbox } from '~/img/checkbox.svg';
import { ReactComponent as EmptyCheckbox } from '~/img/empty_checkbox.svg';
import { ReactComponent as Search } from '~/img/search.svg';
import { Input } from '~/modules/common/components/Input';
import { StoreContext } from '~/modules/common/store/context';

export const NetworkFilter = observer(() => {
  const { pool } = useContext(StoreContext);
  const [selectedNetworks, setSelectedNetworks] = useState<CatalystNetwork[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [networkOptions, setNetworkOptions] = useState(CATALYST_NETWORKS);

  useEffect(() => {
    if (selectedNetworks.length === 0 && pool.filteredNetworks.length === 0) {
      return;
    }
    runInAction(() => {
      pool.filteredNetworks = selectedNetworks;
    });
  }, [selectedNetworks]);

  useEffect(() => {
    if (pool.filteredNetworks.length === 0) {
      setSelectedNetworks([]);
    }
  }, [pool.filteredNetworks]);

  useEffect(() => {
    if (searchFilter.length > 0) {
      const input = searchFilter.toLowerCase();
      const newTokenOptions = CATALYST_NETWORKS.slice().filter((n) => {
        const matchesName = n.config.name.toLowerCase().includes(input);
        return matchesName;
      });
      setNetworkOptions(newTokenOptions);
    } else {
      setNetworkOptions(CATALYST_NETWORKS);
    }
  }, [searchFilter]);

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchFilter(e.target.value);
  }

  return (
    <div className="h-[42px]  rounded-[8px] border border-gray-300 md:w-[200px]">
      <Listbox value={selectedNetworks} onChange={setSelectedNetworks} multiple>
        <div className="relative w-full">
          <Listbox.Button
            as="div"
            className="w-full cursor-default p-3 text-left text-sm leading-tight text-gray-600 focus:outline-none"
          >
            <span>Filter by Networks</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="absolute z-20 mt-1 max-h-[275px] w-full rounded-[8px] bg-white text-base shadow">
              <Listbox.Options className="mx-3 max-h-[220px] space-y-3 overflow-auto px-1 pb-3 focus:outline-none">
                <div className="w-full pt-3">
                  <div className="flex h-[42px] items-center rounded-[8px] border border-gray-300 px-4 py-2">
                    <div className="mr-2 h-[18px] w-[18px]">
                      <Search />
                    </div>
                    <Input
                      value={searchFilter}
                      onChange={handleSearch}
                      className="mr-2 h-[18px] text-sm placeholder-grey-500"
                      placeholder="Search"
                    />
                  </div>
                </div>
                {networkOptions.map((n) => (
                  <Listbox.Option key={n.config.chainId} value={n}>
                    {({ selected }) => (
                      <div className="flex items-center text-sm hover:cursor-pointer">
                        <div className="mr-2">{selected ? <Checkbox /> : <EmptyCheckbox />}</div>
                        <img
                          alt={`${n.config.name} Icon`}
                          className="mr-2 h-7 w-7 rounded-full"
                          src={`/img/${n.config.chainId}.webp`}
                        />
                        <span>{n.config.name}</span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
                {networkOptions.length === 0 && (
                  <div className="flex justify-center text-xs text-gray-600">Network not found</div>
                )}
              </Listbox.Options>
              <hr />
              <div
                className={`flex h-[32px] items-center justify-center  text-[12px] ${
                  networkOptions.length > 0 ? 'cursor-pointer text-primary' : 'text-gray-200'
                }`}
                onClick={() => setSelectedNetworks([])}
              >
                Clear all
              </div>
            </div>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
});

export default NetworkFilter;
