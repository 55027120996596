import { LiquiditySwap, poll, SwapState } from '@catalabs/catalyst-api-client';
import { getToken } from '@catalabs/catalyst-token-lists';
import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { CatalystNetwork } from '~/config/network/catalyst-network';
import { type RootStore, displayDurationMs } from '~/modules/common';
import { PoolActionState } from '~/modules/pools';
import { PendingSwapSummary } from '~/modules/swap/interfaces';

import { ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION } from '../constants';
import { PendingTnxStatus, PendingTnxType, PendingTxn } from '../interfaces';

export class LobbyStore {
  pendingTxns: Map<string, PendingTxn> = new Map();
  completedTxns: Map<string, PendingTxn> = new Map();

  constructor(private store: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'LobbyStore',
      properties: ['pendingTxns', 'completedTxns'],
      storage: localforage,
      stringify: false,
    }).then((lobbyStore) => {
      if (lobbyStore.isHydrated) {
        this.updatePendingTxns();
      }
    });
  }

  addPendingTxn(id: string, txn: PendingTxn) {
    if (!this.pendingTxns.has(id)) {
      switch (txn.type) {
        case PendingTnxType.Deposit:
          if (!this.isValidDepositTxn(txn)) {
            return;
          }
          this.pendingTxns.set(id, txn);
          this.watchPendingDepositLiquiditySwaps(id);
          break;
        case PendingTnxType.Withdraw:
          if (!this.isValidWithdrawTxn(txn)) {
            return;
          }
          this.pendingTxns.set(id, txn);
          this.watchPendingWithdrawLiquiditySwaps(id);
          break;
        default:
          this.pendingTxns.set(id, txn);
          break;
      }
    }
  }

  private isValidDepositTxn(txn: PendingTxn) {
    return (
      txn.type === PendingTnxType.Deposit &&
      txn.depositDetails &&
      txn.depositDetails.requests.length > 0 &&
      txn.depositDetails?.depositInfo
    );
  }

  private isValidWithdrawTxn(txn: PendingTxn) {
    return txn.type === PendingTnxType.Withdraw && txn.withdrawalDetails && txn.withdrawalDetails.requests.length > 0;
  }

  get pendingDeposits() {
    return Array.from(this.pendingTxns.values()).filter(
      (txn) => txn.type === PendingTnxType.Deposit && this.isValidDepositTxn(txn),
    );
  }

  get pendingWithdrawals() {
    return Array.from(this.pendingTxns.values()).filter(
      (txn) => txn.type === PendingTnxType.Withdraw && this.isValidWithdrawTxn(txn),
    );
  }

  get pendingSwaps() {
    return Array.from(this.store.swap.pendingSwaps.values()).map((s): PendingTxn => {
      const fromToken = getToken(s.quote.fromChainId, s.quote.fromAsset);
      const toToken = getToken(s.quote.toChainId, s.quote.toAsset);
      const {
        config: { name: fromChain },
      } = CatalystNetwork.getCatalystNetwork(s.quote.fromChainId);
      const {
        config: { name: toChain },
      } = CatalystNetwork.getCatalystNetwork(s.quote.toChainId);
      const maxDuration = s.quote.durationEstimate.max;
      const timeRemaining = Math.max(s.quote.durationEstimate.max - s.totalDuration, 0);
      const progress = (1 - timeRemaining / maxDuration) * 100;
      const swapSummary: PendingSwapSummary = {
        from: `${s.quote.fromAmount.toFixed(4)} ${fromToken.symbol}`,
        to: `${s.quote.amountOut.toFixed(4)} ${toToken.symbol}`,
        fromChain,
        toChain,
        duration: displayDurationMs(timeRemaining),
        hash: s.hash,
        quote: s.quote,
        swap: s.swap,
        progress: isNaN(progress) ? 0 : progress,
      };

      return {
        hash: s.hash,
        type: PendingTnxType.Swap,
        swap: swapSummary,
        status: PendingTnxStatus.Pending,
        submittedAt: s.submittedAt,
      };
    });
  }

  get allPendingTxns() {
    const pendingDeposits = this.pendingDeposits;
    const pendingSwaps = this.pendingSwaps;
    const pendingWithdrawals = this.pendingWithdrawals;

    const allTxns = [...pendingDeposits, ...pendingSwaps, ...pendingWithdrawals];

    return allTxns.sort((a, b) => b.submittedAt.getTime() - a.submittedAt.getTime());
  }

  get allCompletedTxns() {
    return Array.from(this.completedTxns.values());
  }

  getPendingPoolDeposits(poolId: number) {
    return this.pendingDeposits.filter((txn) =>
      txn.depositDetails?.requests.some((d) => d.request.some((r) => r.poolId === poolId)),
    );
  }

  getPendingPoolWithdrawals(poolId: number) {
    return this.pendingWithdrawals.filter((txn) => txn.withdrawalDetails?.poolId === poolId);
  }

  updatePendingTxns() {
    this.pendingTxns.forEach((txn, hash) => {
      if (txn.type === PendingTnxType.Deposit) {
        this.watchPendingDepositLiquiditySwaps(hash);
      }
      if (txn.type === PendingTnxType.Withdraw) {
        this.watchPendingWithdrawLiquiditySwaps(hash);
      }
    });
  }

  private watchPendingDepositLiquiditySwaps(hash: string) {
    const txn = this.pendingTxns.get(hash);
    if (!txn || txn.type !== PendingTnxType.Deposit) {
      return;
    }
    const { depositDetails } = txn;
    if (!depositDetails) {
      return;
    }
    const { requests } = depositDetails;
    const chainId = requests[0].chainId;

    const handlePollResult = (lSwap: LiquiditySwap | null) => {
      if (!lSwap) {
        return true;
      }
      const { state, fromHash } = lSwap;
      if (fromHash !== hash) {
        return false;
      }
      switch (state) {
        case SwapState.CONFIRMED:
        case SwapState.COMPLETED:
          txn.status = PendingTnxStatus.Success;
          txn.depositDetails?.requests.forEach((r) => {
            r.deposit = PoolActionState.Completed;
          });
          this.completedTxns.set(hash, txn);
          this.pendingTxns.delete(hash);
          return false;
        case SwapState.REVERTED:
        case SwapState.TIMED_OUT:
          txn.status = PendingTnxStatus.Failed;
          txn.depositDetails?.requests.forEach((r) => {
            r.deposit = PoolActionState.Completed;
          });
          this.completedTxns.set(hash, txn);
          this.pendingTxns.delete(hash);
          return false;
        default:
          return true;
      }
    };

    poll(
      () => this.store.client.swaps.getLiquiditySwap(chainId, hash),
      (result) => handlePollResult(result),
      {
        interval: 60 * 1000,
        timeout: ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION,
      },
    );
  }

  private watchPendingWithdrawLiquiditySwaps(hash: string) {
    const txn = this.pendingTxns.get(hash);
    if (!txn || txn.type !== PendingTnxType.Withdraw) {
      return;
    }
    const { withdrawalDetails } = txn;
    if (!withdrawalDetails) {
      return;
    }
    const { requests } = withdrawalDetails;
    const fromChainRequest = requests.find((req) => req.request.some((r) => r.withLiquiditySwap));

    if (!fromChainRequest) {
      return;
    }
    const toChainId = fromChainRequest?.chainId;

    const handlePollResult = (lSwap: LiquiditySwap | null) => {
      if (!lSwap) {
        return true;
      }
      const { state, fromHash } = lSwap;
      if (fromHash !== hash) {
        return false;
      }
      switch (state) {
        case SwapState.CONFIRMED:
        case SwapState.COMPLETED:
          txn.status = PendingTnxStatus.Success;
          txn.withdrawalDetails?.requests.forEach((r) => {
            r.withdraw = PoolActionState.Completed;
          });

          this.completedTxns.set(hash, txn);
          this.pendingTxns.delete(hash);
          return false;
        case SwapState.REVERTED:
        case SwapState.TIMED_OUT:
          txn.status = PendingTnxStatus.Failed;
          txn.withdrawalDetails?.requests.forEach((r) => {
            r.withdraw = PoolActionState.Completed;
          });
          this.completedTxns.set(hash, txn);
          this.pendingTxns.delete(hash);
          return false;
        default:
          return true;
      }
    };

    poll(
      () => this.store.client.swaps.getLiquiditySwap(toChainId, hash),
      (result) => handlePollResult(result),
      {
        interval: 60 * 1000,
        timeout: ESTIMATED_DEPOSIT_W_LIQ_SWAP_DURATION,
      },
    );
  }

  removeCompletedTxn(hash: string) {
    this.completedTxns.delete(hash);
  }

  deletePendingTxn(hash: string) {
    this.pendingTxns.delete(hash);
  }

  removeTxnFromLobby(hash: string) {
    this.pendingTxns.delete(hash);
    this.completedTxns.delete(hash);
  }

  reset() {
    this.pendingTxns.clear();
  }
}
