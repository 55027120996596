import { useConnectWallet, useSetChain } from '@web3-onboard/react';
import { ethers } from 'ethers';
import { runInAction } from 'mobx';
import { useCallback, useContext, useEffect, useState } from 'react';

import { EVM_NETWORKS } from '~/config';
import { TOS_ATTESTATION_DOMAIN, TOS_ATTESTATION_VALUE_STRUCT_TYPE } from '~/modules/common/constants';
import { db } from '~/modules/common/db';
import { StoreContext } from '~/modules/common/store/context';

import { TosAttestation, TosAttestationValue } from '../../common/interfaces/tos-attestation.interface';

const useConnectButton = () => {
  const { wallet, swap, pool } = useContext(StoreContext);
  const { displayAddress, isConnected, address } = wallet;
  const { sourceNetwork } = swap;

  const [{ wallet: onBoardWallet }, onboardConnect, onBoardDisconnect] = useConnectWallet();
  const [{ connectedChain }, setChain] = useSetChain();

  const [isTosAttestationModalOpen, setIsTosAttestationModalOpen] = useState(false);
  const [isTosAttestationComplete, setIsTosAttestationComplete] = useState(false);

  const switchNetwork = useCallback(
    () => async (chainId: number) => {
      return await setChain({ chainId: ethers.toQuantity(chainId) });
    },
    [],
  );

  useEffect(() => {
    if (connectedChain) {
      runInAction(() => {
        wallet.walletChainId = Number(connectedChain.id);
      });
    }
  }, [connectedChain]);

  useEffect(() => {
    if (onBoardWallet === null) {
      if (isConnected) {
        wallet.disconnect();
      }
      return;
    }

    const initializeWalletSigner = async () => {
      const provider = new ethers.BrowserProvider(onBoardWallet.provider);
      const signer = await provider.getSigner();
      await wallet.syncEvmSigner(signer, switchNetwork());

      if (!wallet.address) {
        return;
      }

      const tosAttestation: TosAttestation | null = await db.tosAttestation.getItem(wallet.address);

      // ToS attestation does not exist
      if (tosAttestation === null) {
        setIsTosAttestationComplete(false);
        return setIsTosAttestationModalOpen(true);
      }

      const recoveredAddress = await ethers.verifyTypedData(
        TOS_ATTESTATION_DOMAIN,
        TOS_ATTESTATION_VALUE_STRUCT_TYPE,
        tosAttestation.value,
        tosAttestation.signature,
      );

      // ToS attestation is invalid
      if (recoveredAddress !== wallet.address) {
        setIsTosAttestationComplete(false);
        return setIsTosAttestationModalOpen(true);
      }

      // ToS attestation is valid
      setIsTosAttestationComplete(true);
      setIsTosAttestationModalOpen(false);
    };
    initializeWalletSigner();
  }, [onBoardWallet, switchNetwork]);

  useEffect(() => {
    wallet.fetchAccount();
    pool.init();
  }, [address]);

  const connect = () => {
    if (!isConnected) {
      onboardConnect().then((wallets) => {
        const wallet = wallets[0];
        if (!wallet) return;

        if (sourceNetwork) {
          setChain({ chainId: ethers.toQuantity(Number(sourceNetwork)) });
        } else {
          setChain({ chainId: ethers.toQuantity(Number(EVM_NETWORKS[0].chain.id)) });
        }
      });
    }
  };

  const onTosSignature = async () => {
    try {
      if (!wallet.address) {
        throw new Error('Wallet address not found.');
      }

      const value: TosAttestationValue = { wallet: wallet.address, timestamp: Date.now() };
      const signature = await wallet.signTypedData(TOS_ATTESTATION_DOMAIN, TOS_ATTESTATION_VALUE_STRUCT_TYPE, value);

      const tosAttestation: TosAttestation = { value, signature };
      await db.tosAttestation.setItem(wallet.address, tosAttestation);

      setIsTosAttestationComplete(true);
    } catch (err) {
      console.error('Error signing ToS consent, please try again.', err);
    }
  };

  const disconnect = async () => {
    wallet.disconnect();
    if (onBoardWallet) {
      await onBoardDisconnect(onBoardWallet);
    }
  };

  return {
    isConnected,
    displayAddress,
    sourceNetwork,
    wallet,
    isTosAttestationModalOpen,
    setIsTosAttestationModalOpen,
    isTosAttestationComplete,
    onTosSignature,
    connect,
    disconnect,
  };
};

export default useConnectButton;
