import { Pool, PoolSummary } from '@catalabs/catalyst-api-client';
import { TokenInfo } from '@uniswap/token-lists';
import { runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CATALYST_NETWORKS, CatalystNetwork, formatBalance, routes, SHOW_POOL_CREATION } from '~/config';
import { CatalystStore, SortDirection } from '~/modules/common';
import { getAssetKey, PoolsSort, PoolStore } from '~/modules/pools';

const POOLS_PER_PAGE = 10;

export function usePools(poolStore: PoolStore, catalystStore: CatalystStore) {
  const {
    pools: { pools },
    filteredNetworks,
    filteredTokens,
  } = poolStore;

  useEffect(() => {
    poolStore.updatePools();
  }, []);

  const navigate = useNavigate();
  const [allCatalystPools, setAllCatalystPools] = useState<PoolSummary[]>([]);
  const [catalystPools, setCatalystPools] = useState<PoolSummary[]>([]);
  const [pagesLoaded, setPagesLoaded] = useState(1);
  const [allPoolsDisplayed, setAllPoolsDisplayed] = useState(true);
  const [poolsSort, setPoolsSort] = useState(PoolsSort.TVL);
  const [poolsSortDirection, setPoolsSortDirection] = useState(SortDirection.Descending);

  function navigateToCreate() {
    if (SHOW_POOL_CREATION) {
      navigate(routes.poolCreate);
    }
  }

  function clearFilters() {
    runInAction(() => {
      poolStore.filteredNetworks = [];
      poolStore.filteredTokens = [];
    });
  }

  function clearNetwork(network: CatalystNetwork) {
    runInAction(() => {
      const networkIndex = filteredNetworks.indexOf(network);
      const newFilteredNetwork = filteredNetworks.slice();
      poolStore.filteredNetworks = newFilteredNetwork
        .slice(0, networkIndex)
        .concat(newFilteredNetwork.slice(networkIndex + 1));
    });
  }

  function clearToken(token: TokenInfo) {
    runInAction(() => {
      const tokenIndex = filteredTokens.indexOf(token);
      const newFilteredTokens = filteredTokens.slice();
      poolStore.filteredTokens = newFilteredTokens.slice(0, tokenIndex).concat(newFilteredTokens.slice(tokenIndex + 1));
    });
  }

  useEffect(() => {
    async function applyFilters() {
      const filteredTokenSymbols = new Set(filteredTokens.map((t) => t.symbol));
      const filteredChainIds = new Set(filteredNetworks.map((n) => n.config.chainId));

      const allPoolAssetInfo: Record<string, TokenInfo> = {};
      await Promise.all(
        pools.map(async (p) =>
          p.assets.map(async (a) => {
            const assetKey = getAssetKey(a);
            allPoolAssetInfo[assetKey] = await catalystStore.getToken(a.chainId, a.address);
          }),
        ),
      );

      const newDisplayPools = pools
        .slice()
        .filter((p) => {
          const supportedChainIds = new Set(CATALYST_NETWORKS.map((n) => n.config.chainId));
          const hasUnsupportedChain = p.assets.some((a) => !supportedChainIds.has(a.chainId));
          if (hasUnsupportedChain) {
            return false;
          }
          return p.assets.some((a) => {
            const tokenInfo = allPoolAssetInfo[getAssetKey(a)];
            if (filteredTokenSymbols.size === 0 && filteredChainIds.size === 0) {
              return true;
            }
            if (filteredTokenSymbols.size > 0 && filteredChainIds.size > 0) {
              return filteredTokenSymbols.has(tokenInfo.symbol) && filteredChainIds.has(a.chainId);
            }
            if (filteredTokenSymbols.size > 0) {
              return filteredTokenSymbols.has(tokenInfo.symbol);
            }
            if (filteredChainIds.size > 0) {
              return filteredChainIds.has(a.chainId);
            }
            return false;
          });
        })
        .sort((a, b) => {
          const aFee = formatBalance(BigInt(Object.values((a as Pool).vaults)[0].swapFee));
          const bFee = formatBalance(BigInt(Object.values((b as Pool).vaults)[0].swapFee));
          if (poolsSort === PoolsSort.Volume) {
            return poolsSortDirection === SortDirection.Ascending
              ? a.dailyVolume - b.dailyVolume
              : b.dailyVolume - a.dailyVolume;
          }
          if (poolsSort === PoolsSort.APR) {
            return poolsSortDirection === SortDirection.Ascending ? a.apr - b.apr : b.apr - a.apr;
          }
          if (poolsSort === PoolsSort.Fee) {
            return poolsSortDirection === SortDirection.Ascending ? aFee - bFee : bFee - aFee;
          }
          return poolsSortDirection === SortDirection.Ascending ? a.tvl - b.tvl : b.tvl - a.tvl;
        });

      setPagesLoaded(1);
      setAllCatalystPools(newDisplayPools);
      setCatalystPools(newDisplayPools.slice(0, POOLS_PER_PAGE * pagesLoaded));
      if (newDisplayPools.length >= POOLS_PER_PAGE * pagesLoaded) {
        setAllPoolsDisplayed(false);
      } else {
        setAllPoolsDisplayed(true);
      }
    }
    applyFilters();
  }, [filteredNetworks, filteredTokens, pools, poolsSortDirection, poolsSort]);

  useEffect(() => {
    const newPools = allCatalystPools.slice(0, POOLS_PER_PAGE * pagesLoaded);
    setCatalystPools(newPools);

    if (newPools.length === allCatalystPools.length) {
      setAllPoolsDisplayed(true);
    } else {
      setAllPoolsDisplayed(false);
    }
  }, [pagesLoaded]);

  return {
    filteredNetworks,
    filteredTokens,
    poolsSort,
    poolsSortDirection,
    catalystPools,
    allCatalystPools,
    allPoolsDisplayed,
    pagesLoaded,
    handlers: {
      navigateToCreate,
      setPoolsSortDirection,
      setPoolsSort,
      setPagesLoaded,
      clearFilters,
      clearNetwork,
      clearToken,
    },
  };
}

export default usePools;
