import { NavigateFunction } from 'react-router-dom';

export function createPoolCompositeKey(address: string, chainId: string): string {
  return [address, chainId].join('-');
}

/**
 * Open url in a new tab
 * @param url address of page to open
 */
export function openNewTab(url: string): void {
  window.open(url, '_blank', 'noopener');
}

/**
 * Copies text to clipboard
 * @param txt text to copy to system
 */
export function copyToClipboard(txt: string): void {
  navigator.clipboard.writeText(txt);
}

/**
 * Sleep utility
 * @param delay duration in ms to sleep
 */
export function delay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

/**
 * Open url in a new tab or path
 * @param url address of page to open or path
 */
export function openNewTabOrNavigate(url: string, navigate: NavigateFunction): void {
  if (url.startsWith('https')) {
    openNewTab(url);
  } else {
    navigate(url);
  }
}
