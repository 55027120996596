export const NotFound = () => {
  return (
    <div className="notfound absolute left-0 top-0 z-20 flex h-full max-h-screen w-full items-center justify-center overflow-auto bg-white text-gray-800 backdrop-blur-3xl">
      <div className="flex h-full w-full flex-col justify-between rounded-[25px] bg-white py-5 shadow md:h-[434px] md:w-[518px] md:py-10">
        <div className="flex w-full justify-between px-5 md:px-10">
          <div>
            <img className="" src={'/img/logo_dark_web.svg'} alt="Catalyst Logo" height={150} width={150} />
          </div>

          <div className="">
            <img className="" src={'/img/gradient_404.svg'} alt="404" height={85} />
          </div>
        </div>

        <div>
          <div className="mt-[81px] flex flex-col items-center justify-center">
            <span className="font-subheader text-[23px] text-primary">Sorry, we couldn’t find</span>
            <span className="font-subheader text-[23px] text-primary">the page you need.</span>
          </div>

          <div className="mt-6 flex justify-center">
            <button className="flex h-[37px] w-[171px] cursor-pointer  items-center justify-center rounded-full bg-primary text-white">
              <a href="/swap">Go to Swap page</a>
            </button>
          </div>
        </div>

        <div className="mt-20 flex cursor-pointer flex-col items-center gap-3 text-sm tracking-wider text-grey-500 md:flex-row md:justify-center md:gap-5">
          <a
            href="https://catalabs.notion.site/Catalyst-Demo-How-To-Guide-2cb3042381bc44c2b3a4ece56fd134e9"
            target="_blank"
          >
            How To Guide
          </a>

          <a href="https://github.com/catalystdao" target="_blank">
            GitHub
          </a>

          <a href="/terms-of-service" target="_blank">
            Terms of Service
          </a>

          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
