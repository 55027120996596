import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as Spinner } from '~/img/spinner.svg';
import { Button, Input, StoreContext } from '~/modules/common';
import { useDebounce } from '~/modules/common/hooks/useDebounce';

import { useCreateReferralCode } from '../../hooks/useCreateReferralCode';
import { useIsLinkUnique } from '../../hooks/useIsLinkUnique';
import { InputValidationPopover } from '../InputValidationPopover';

export type CreateLinkValidationItemStatus = 'idle' | 'success' | 'error' | 'loading';

export function CreateOwnReferralLink({ inputRef }: { inputRef: React.RefObject<HTMLInputElement> }) {
  const { referral: referralStore, wallet: walletStore } = useContext(StoreContext);
  const { isLinkUnique, isLoading, checkIfLinkUnique } = useIsLinkUnique(referralStore);
  const { createReferralCode, isLoading: isCreatingReferralCodeLoading } = useCreateReferralCode(
    referralStore,
    walletStore,
  );
  const [inputtedLink, setInputtedLink] = useState<string | null>(null);
  const debouncedLink = useDebounce(inputtedLink, 500);

  const [isInputtedLinkValidating, setIsInputtedLinkValidating] = useState(false);
  const errors = computeErrorMap({
    isLinkUnique,
    inputtedLink,
    isLinkUniqueLoading: isLoading || isInputtedLinkValidating,
  });
  const hasAnyError = Object.values(errors).some((status) => status === 'error');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputtedLink) return;
    await createReferralCode(inputtedLink);
  };

  useEffect(() => {
    if (!debouncedLink) return;
    checkIfLinkUnique(debouncedLink);
    setIsInputtedLinkValidating(false);
  }, [debouncedLink]);

  if (isCreatingReferralCodeLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner className="animate-spin" />
      </div>
    );
  }

  return (
    <>
      <span className="text-xs text-grey-500">https://app.catalyst.exchange/join/{inputtedLink?.toUpperCase()}</span>
      <form className="flex flex-col gap-4 md:flex-row" onSubmit={handleSubmit}>
        <div
          className={clsx(
            'hover:[not(:focus-within):border-gray-400] group relative flex w-full items-center  rounded-lg border border-gray-300 focus-within:border-indigo-500',
            {
              'border-red-60 border-red-600 bg-red-100 focus-within:border-red-600': hasAnyError,
            },
          )}
        >
          <div className="w-full">
            <Input
              ref={inputRef}
              type="search"
              className={clsx('peer order-2 h-[42px] w-full rounded-lg px-4 py-3', {
                ' bg-red-100 text-red-600': hasAnyError,
              })}
              placeholder="CREATEYOURLINK"
              onChange={async (val) => {
                setIsInputtedLinkValidating(true);
                setInputtedLink(val.target.value);
              }}
              required
              onClick={() => {
                inputRef.current?.focus();
              }}
            />
          </div>
          <div className="absolute right-2">
            <InputValidationPopover {...errors} />
          </div>
        </div>
        <div>
          <Button size="small" className="mt-0 w-full min-w-[120px]" type="submit">
            {hasAnyError ? 'Invalid Link' : 'Create'}
          </Button>
        </div>
      </form>
    </>
  );
}

function computeErrorMap({
  isLinkUnique,
  inputtedLink,
  isLinkUniqueLoading,
}: {
  isLinkUnique: boolean | null;
  inputtedLink: string | null;
  isLinkUniqueLoading: boolean;
}): {
  unique: CreateLinkValidationItemStatus;
  onlyLetters: CreateLinkValidationItemStatus;
  max12Chars: CreateLinkValidationItemStatus;
  noSpecialChar: CreateLinkValidationItemStatus;
} {
  if (inputtedLink === null || inputtedLink.length === 0) {
    return {
      unique: 'idle',
      onlyLetters: 'idle',
      max12Chars: 'idle',
      noSpecialChar: 'idle',
    };
  }

  const errors = {
    unique: isLinkUniqueLoading ? 'loading' : isLinkUnique === false ? 'error' : 'success',
    onlyLetters: /^[a-zA-Z]+$/.test(inputtedLink) ? 'success' : 'error',
    max12Chars: inputtedLink.length <= 12 ? 'success' : 'error',
    noSpecialChar: /^[a-zA-Z]+$/.test(inputtedLink) ? 'success' : 'error',
  } as const;

  return errors;
}
