import { observer } from 'mobx-react-lite';

import { Button, cn, EmptyTable, Table, useInfiniteScroll } from '~/modules/common';
import useConnectButton from '~/modules/navigation/hooks/useConnectButton';
import { PortfolioListHeader, PortfolioListItem, PortfolioListItemSkeleton } from '~/modules/portfolio/components';
import { LpType } from '~/modules/portfolio/enums';
import { usePortfolioList } from '~/modules/portfolio/hooks';

interface PortfolioListProps {
  selectedTab: LpType;
}

export const PortfolioList = observer(({ selectedTab }: PortfolioListProps) => {
  const { isConnected, connect } = useConnectButton();

  const { hasLpsToDisplay, displayedLps, isLoadingLps, poolAssetsById, fetchLpsOnScroll } = usePortfolioList({
    selectedTab,
  });

  const observerRef = useInfiniteScroll({ fetchData: fetchLpsOnScroll });

  return (
    <div className="w-full overflow-x-scroll rounded-3xl">
      <Table
        headersPanelStyle={cn('rounded-t-3xl grid grid-cols-12', {
          hidden: !hasLpsToDisplay,
        })}
        header={hasLpsToDisplay ? <PortfolioListHeader /> : <></>}
      >
        {!isConnected ? (
          // Wallet not connected
          <div className="flex flex-col items-center justify-center py-9">
            <Button className="mt-0 w-fit" onClick={connect} size="extra-small">
              Connect Wallet
            </Button>
          </div>
        ) : isLoadingLps ? (
          // Loading Lps
          <PortfolioListItemSkeleton />
        ) : !hasLpsToDisplay ? (
          // No Lps to display
          <EmptyTable>You don't have any pool deposits.</EmptyTable>
        ) : (
          // Display Lps
          <>
            {displayedLps.map((position, index) => (
              <PortfolioListItem
                key={`portfolio-list-item-${position.poolId}`}
                position={position}
                poolAssets={poolAssetsById.get(position.poolId) || []}
                isLast={index === displayedLps.length - 1}
              />
            ))}
          </>
        )}
      </Table>

      {/* For infinite scroll */}
      <div ref={observerRef} />
    </div>
  );
});
