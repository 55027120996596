import './toggle.css';

import clsx from 'clsx';
import { useState } from 'react';

interface InputProperties
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  label?: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelClassName?: string;
}

export const Toggle: React.FC<InputProperties> = (props) => {
  const { label, id, onChange, checked, labelClassName, ...inputProps } = props;
  const [toggled, setToggled] = useState<boolean>(checked || false);

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggled(e.target.checked);
    onChange(e);
  };

  const labelClasses = clsx(`text-sm`, labelClassName, toggled ? 'text-primary-600 font-semi-bold' : 'text-gray-500');

  return (
    <div className="toggle">
      <label htmlFor={id}>
        <input id={id} type="checkbox" checked={toggled} {...inputProps} onChange={handleOnchange} />
        <span className={labelClasses}>{label}</span>
      </label>
    </div>
  );
};
