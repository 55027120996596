import { Pool, PoolSummary } from '@catalabs/catalyst-api-client';
import { useNavigate } from 'react-router-dom';

import { formatBalance } from '~/config';
import { getPoolRoute } from '~/config/utils/routes.utils';
import { formatCurrency, formatPercentage, TableAssetsDisplay } from '~/modules/common';
import { PoolAprBreakdown } from '~/modules/pools';

export interface PoolsListItemProps {
  pool: PoolSummary;
  maxTokenDisplay?: number;
}

export function PoolsListItemMobile({ pool, maxTokenDisplay = 3 }: PoolsListItemProps): JSX.Element {
  const navigate = useNavigate();
  const assets = pool.assets.map((x) => ({ chain: x.chainId, address: x.address }));

  const poolFee = formatBalance(BigInt(Object.values((pool as Pool).vaults)[0].swapFee)) * 100;

  return (
    <div
      onClick={() => navigate(getPoolRoute(pool.id))}
      className="flex flex-col gap-1 rounded-3xl bg-white p-5 text-sm hover:cursor-pointer hover:bg-gray-100"
    >
      <div className="font-semibold">Assets</div>
      <div className="mb-4 mt-3">
        <TableAssetsDisplay assets={assets} maxTokenDisplay={maxTokenDisplay} />
      </div>
      <div className="flex items-center justify-between">
        APR
        <PoolAprBreakdown pool={pool} />
      </div>
      <div className="flex items-center justify-between">
        FEE
        <span>{formatPercentage(poolFee)}</span>
      </div>
      <div className="flex items-center justify-between">
        TVL
        <span>{formatCurrency(pool.tvl)}</span>
      </div>
      <div className="flex items-center justify-between">
        24H VOL
        <span className="flex items-center">{formatCurrency(pool.dailyVolume)}</span>
      </div>
    </div>
  );
}
