import { GAS_TOKEN_IDENTIFIER } from '@catalabs/catalyst-sdk';
import { getToken } from '@catalabs/catalyst-token-lists';
import { BoltIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { CatalystNetwork, roundDownToDecimals, routes } from '~/config';
import { CircleLoader, formatCurrency, Modal, PriceImpact, SwapReviewLine, TokenDisplay } from '~/modules/common';
import { ChainWithdraw, getDepositText, InlineLink, LIQUIDITY_SWAP_DOCS_URL, PoolActionState } from '~/modules/pools';
import PoolApproval from '~/modules/pools/components/PoolApproval';
import PoolChainSwap from '~/modules/pools/components/PoolChainSwap';
import PoolInteractionProgressBar from '~/modules/pools/components/PoolInteractionProgressBar';
import PoolWithdrawDisplay from '~/modules/pools/components/PoolWithdrawDisplay';
import { SwapCosts } from '~/modules/swap';
import { FeeDetail } from '~/modules/swap/interfaces';

interface PoolWithdrawModalProps {
  handleCompleteWithdraw: () => void;
  withdrawValue: number;
  poolWithdrawRequest: ChainWithdraw[];
  open: boolean;
  canCloseModal?: boolean;
  handleCloseModal: () => void;
  feeDetails: FeeDetail[];
  priceImpact: number;
  withLiquiditySwap: boolean;
}

export const PoolWithdrawModal = observer(
  ({
    handleCloseModal,
    handleCompleteWithdraw,
    withdrawValue,
    feeDetails,
    open,
    poolWithdrawRequest,
    priceImpact,
    canCloseModal = true,
    withLiquiditySwap,
  }: PoolWithdrawModalProps) => {
    const navigate = useNavigate();
    const allWithdrawItems = poolWithdrawRequest.flatMap((r) => r.request);

    const hasCompletedWithdraw = poolWithdrawRequest.every((r) => r.withdraw === PoolActionState.Completed);

    const hasConfirmedOrCompletedAllWithdraws = poolWithdrawRequest.every(
      (r) => r.withdraw === PoolActionState.Completed || r.withdraw === PoolActionState.Confirmed,
    );

    const reqWithLSwap = allWithdrawItems.find((r) => r.withLiquiditySwap);

    return (
      <Modal open={open} onClose={handleCloseModal} canClose={canCloseModal}>
        <div className="mx-3 flex w-full flex-col items-center">
          {withLiquiditySwap && hasConfirmedOrCompletedAllWithdraws && !hasCompletedWithdraw && (
            <div className="mb-4 w-full rounded-[25px] bg-white p-4 md:w-[552px]">
              <div className="flex flex-col gap-2">
                <span className=" text-[14px] text-primary">Withdraw with Liquidity swap</span>
                <div className="flex items-start">
                  <span className="text-[12px] leading-5 text-gray-800">
                    Your withdrawal includes liquidity swaps, this can take a while.
                    <br />
                    Liquidity swaps take ~30 minutes to complete. <br />
                    In that period, you'll have missing amounts from your withdrawal displayed on the Ul.
                    <br />
                    Rest assured that they'll be reflected correctly after ~30 minutes have elapsed.
                    <InlineLink href={LIQUIDITY_SWAP_DOCS_URL} text="Learn more." size="xs" />
                  </span>
                  <div className="relative flex items-center justify-center text-primary">
                    <CircleLoader loading className="text-gray-200" />
                    <BoltIcon className="absolute h-4 w-4" />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mb-4 flex w-full flex-col items-center rounded-[25px] bg-white p-5 md:w-[552px]">
            <span
              className="mb-4 w-full font-semibold text-xs uppercase leading-4 tracking-wide text-gray-900"
              data-testid="withdraw-modal-title"
            >
              {hasCompletedWithdraw ? 'Withdraw Completed' : 'In Progress'}
            </span>
            <div className="flex w-full flex-col gap-2">
              {poolWithdrawRequest.map((r) => {
                const targetNetwork = CatalystNetwork.getCatalystNetwork(r.chainId);
                const allTokens = r.request.map((req) => {
                  const token = getToken(req.chainId, req.useGasToken ? GAS_TOKEN_IDENTIFIER : req.address);
                  return token.symbol;
                });
                const explorerUrl = r.hash ? targetNetwork.getTransactionUrl(r.hash) : undefined;
                const reqWithLSwap = r.request.find((r) => r.withLiquiditySwap);
                let interactionText = `Withdraw ${getDepositText(allTokens)} on ${targetNetwork.config.name} `;

                if (reqWithLSwap && reqWithLSwap.lSwapToChain) {
                  const toToken = getToken(reqWithLSwap.lSwapToChain, GAS_TOKEN_IDENTIFIER);
                  const toNetwork = CatalystNetwork.getCatalystNetwork(reqWithLSwap.lSwapToChain);
                  interactionText = `Swap LP tokens from ${targetNetwork.config.name} to ${toToken.symbol} on ${toNetwork.config.name}`;
                }
                return (
                  <PoolInteractionProgressBar
                    key={`withdraw-progress-bar-${r.chainId}`}
                    interactionText={interactionText}
                    state={r.withdraw}
                    explorerUrl={explorerUrl}
                  />
                );
              })}
            </div>
          </div>

          {hasConfirmedOrCompletedAllWithdraws && (
            <>
              <div className="mt-5 flex w-full flex-col gap-4 md:w-[552px]">
                <div className="flex flex-col gap-6 rounded-[25px] bg-white p-6">
                  {withLiquiditySwap &&
                    reqWithLSwap &&
                    reqWithLSwap.lSwapToChain &&
                    reqWithLSwap.finalLiquiditySwapAmount &&
                    reqWithLSwap.finalLiquiditySwapValue && (
                      <div className="flex space-x-4">
                        <div className="flex w-full rounded-[15px] border border-gray-200 p-5">
                          <TokenDisplay
                            showSymbol={false}
                            chainId={reqWithLSwap.lSwapToChain}
                            token={GAS_TOKEN_IDENTIFIER}
                            showChain={false}
                          />
                          <div className="ml-4 mt-1 flex flex-col">
                            <span className="font-semibol text-[32px] leading-[28px]">
                              {roundDownToDecimals(Number(reqWithLSwap.finalLiquiditySwapAmount), 6)}
                            </span>
                            <span className="text-[14px] font-normal text-gray-600">
                              {formatCurrency(reqWithLSwap.finalLiquiditySwapValue)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  {!withLiquiditySwap &&
                    allWithdrawItems.map((d) => {
                      if (d.amount <= 0n) {
                        return null;
                      }
                      return (
                        <div key={`deposit-completed-display-${d.chainId}-${d.address}`} className="flex space-x-4">
                          <div className="flex w-full rounded-[15px] border border-gray-200 p-5">
                            <TokenDisplay
                              showSymbol={false}
                              chainId={d.chainId}
                              token={d.useGasToken ? GAS_TOKEN_IDENTIFIER : d.address}
                              showChain={false}
                            />
                            <div className="ml-4 mt-1 flex flex-col">
                              <span className="font-semibol text-[32px] leading-[28px]">
                                {roundDownToDecimals(Number(d.displayAmount), 6)}
                              </span>
                              <span className="text-[14px] font-normal text-gray-600">{formatCurrency(d.value)}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <SwapReviewLine title="Total Liquidity" value={formatCurrency(withdrawValue)} />
                <PriceImpact value={priceImpact} />
                <SwapCosts feeDetails={feeDetails} />
              </div>
              {hasCompletedWithdraw && (
                <div
                  onClick={handleCompleteWithdraw}
                  className="itemx-center mt-16 flex w-full cursor-pointer justify-center rounded-[145px] bg-primary px-12 py-8 text-white md:w-[552px]"
                >
                  View Position
                </div>
              )}
            </>
          )}
          <div className="flex flex-col gap-4">
            {poolWithdrawRequest.map((r, i) => {
              if (r.withdraw === PoolActionState.Completed || r.withdraw === PoolActionState.Confirmed) {
                return null;
              }

              if (r.vaultTokenInfo === undefined) {
                return null;
              }

              const targetNetwork = CatalystNetwork.getCatalystNetwork(r.chainId);
              const chainSwapText = `Switch to ${targetNetwork.config.name}`;
              const allTokens = r.request.map((req) => req.tokenInfo.symbol);

              return (
                <div key={`pool-withdraw-${r.chainId}`} data-testid="pool-withdraw" data-chain-id={r.chainId}>
                  <span
                    data-testid="pool-chain-swap"
                    data-chain-id={r.chainId}
                    data-pending-chain-swap={r.chainSwap === PoolActionState.Pending}
                  />
                  {/* Chain Swap Display */}
                  {r.chainSwap !== PoolActionState.Completed && (
                    <PoolChainSwap chainSwapText={chainSwapText} actionState={r.chainSwap} />
                  )}

                  {/* Token Approvals Display */}
                  <div className={`${i > 0 ? 'my-4' : ''}`}>
                    <PoolApproval
                      key={`pool-withdraw-approval-${r.chainId}`}
                      approval={r.approval}
                      token={r.vaultTokenInfo}
                      chainId={r.chainId}
                      liquidityToken={true}
                    />
                  </div>

                  {/* Token Deposit Display */}
                  <PoolWithdrawDisplay
                    index={i}
                    allWithdrawItems={allWithdrawItems}
                    req={r}
                    withdrawText={getDepositText(allTokens)}
                    poolWithdrawRequest={poolWithdrawRequest}
                  />
                </div>
              );
            })}
          </div>
          {withLiquiditySwap && hasConfirmedOrCompletedAllWithdraws && canCloseModal && !hasCompletedWithdraw && (
            <div className="mt-6 w-full rounded-[25px] bg-white p-4 md:w-[552px]">
              <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
                <div className="text-[14px] text-grey-500">Continue to navigate while we finish here...</div>
                <div className="flex h-6 gap-2 text-[12px] text-white">
                  <div
                    onClick={() => {
                      if (canCloseModal) {
                        handleCloseModal?.();
                      }
                    }}
                    className="flex cursor-pointer items-center rounded-xl bg-primary px-3"
                  >
                    View Position
                  </div>
                  <div
                    onClick={() => {
                      if (canCloseModal) {
                        handleCompleteWithdraw?.();
                        navigate(routes.swap);
                      }
                    }}
                    className="flex cursor-pointer items-center rounded-xl bg-primary px-3"
                  >
                    Swap Assets
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  },
);

export default PoolWithdrawModal;
