import { PoolAccountBalance, Vault } from '@catalabs/catalyst-api-client';
import { VaultInformation } from '@catalabs/catalyst-sdk';
import { parseUnits } from 'ethers';

import { TokenBalance } from '~/config';

import { getAssetKey } from './pools.utils';

export const formatVaultInfoDeposit = ({
  depositAmounts,
  isAssetReplacedWithGasToken,
  vaults,
  assetBalances,
  gasTokenBalances,
}: {
  depositAmounts: Map<string, string>;
  isAssetReplacedWithGasToken: Map<string, boolean>;
  vaults: Vault[];
  assetBalances: Map<string, TokenBalance>;
  gasTokenBalances: Map<string, TokenBalance>;
}) => {
  const userDeposits: bigint[][] = [];
  const userBalances: bigint[][] = [];
  for (const vault of vaults) {
    const values = vault.assets.map((asset) => {
      const assetKeyObj = {
        address: asset.token,
        chainId: asset.chain,
      };
      const assetKey = getAssetKey(assetKeyObj);
      const depositAmount = depositAmounts.get(assetKey)?.trim() || '0';
      return BigInt(parseUnits(depositAmount.toString()).toString());
    });
    userDeposits.push(values);

    const balances = vault.assets.map((asset) => {
      const assetKey = getAssetKey({
        address: asset.token,
        chainId: asset.chain,
      });
      const useGasToken = isAssetReplacedWithGasToken.get(assetKey);
      const balance = useGasToken ? gasTokenBalances.get(assetKey) : assetBalances.get(assetKey);
      return balance?.amount || 0n;
    });
    userBalances.push(balances);
  }

  return {
    vaultsInfo: formatVaultInfo({ vaults }),
    userDeposits,
    userBalances,
  };
};

export const formatVaultInfoWithdraw = ({
  vaults,
  poolAccountBalance,
  withdrawAmounts,
  withdrawPortion,
}: {
  vaults: Vault[];
  poolAccountBalance: PoolAccountBalance;
  withdrawAmounts: Map<string, string>;
  withdrawPortion: number;
}) => {
  const vaultTokens: bigint[] = [];
  const vaultTokensToWithdraw: bigint[] = [];
  const userWithdrawals: bigint[][] = [];
  for (const vault of vaults) {
    const vaultToken = BigInt(poolAccountBalance.liquidity[vault.chain]);
    vaultTokens.push(vaultToken);

    const vaultTokenToWithdraw = (Number(poolAccountBalance.liquidity[vault.chain]) * Number(withdrawPortion)) / 100;
    vaultTokensToWithdraw.push(BigInt(Math.trunc(vaultTokenToWithdraw)));

    const userWithdrawal: bigint[] = [];
    vault.assets.forEach((asset) => {
      const assetKey = getAssetKey({
        chainId: asset.chain,
        address: asset.token,
      });
      const withdrawAmount = withdrawAmounts.get(assetKey) ?? '0';
      userWithdrawal.push(BigInt(parseUnits(withdrawAmount).toString()));
    });
    userWithdrawals.push(userWithdrawal);
  }
  return {
    vaultsInfo: formatVaultInfo({ vaults }),
    vaultTokens,
    userWithdrawals,
    vaultTokensToWithdraw,
  };
};

export const formatVaultInfo = ({ vaults }: { vaults: Vault[] }) => {
  const vaultsInfo: VaultInformation[] = [];
  for (const vault of vaults) {
    const vaultInfo: VaultInformation = {
      amplification: BigInt(vault.amplification),
      balances: vault.assets.map((asset) => BigInt(asset.amount)),
      escrowedTokens: vault.escrow.map((escrow) => BigInt(escrow.amount)),
      escrowedVaultTokens: BigInt(vault.escrowedVaultTokens),
      totalSupply: BigInt(vault.totalSupply),
      unitTracker: BigInt(vault.unitTracker),
      vaultFee: BigInt(vault.swapFee),
      weights: vault.assets.map((asset) => BigInt(asset.weight)),
    };
    vaultsInfo.push(vaultInfo);
  }
  return vaultsInfo;
};
