import { useEffect, useRef } from 'react';

interface UseInfiniteScrollProps {
  fetchData: () => void;
}

export const useInfiniteScroll = ({ fetchData }: UseInfiniteScrollProps) => {
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0].isIntersecting) {
          await fetchData();
        }
      },
      { threshold: 1 },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef]);

  return observerRef;
};
