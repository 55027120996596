import { SHOW_POOL_CREATION } from '~/config/environment';

export const CreatePoolButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="flex w-full items-center">
      <button
        onClick={onClick}
        className={`flex h-[41px] w-full items-center justify-center rounded-3xl bg-primary text-sm text-white md:h-[32px] md:w-[134px] ${
          SHOW_POOL_CREATION ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <span>Create Pool</span>
      </button>
    </div>
  );
};
