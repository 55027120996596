import { clsx } from 'clsx';
import { matchPath, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

export interface NavItemProps {
  value: string;
  href: string;
  matches?: string[];
}

export function NavItem({ value, href, matches }: NavItemProps) {
  const { pathname } = useLocation();
  let isActive = matchPath(href, pathname);

  if (!isActive && matches) {
    for (const potentialMatch of matches) {
      isActive = matchPath(potentialMatch, pathname);
      if (isActive) {
        break;
      }
    }
  }

  return (
    <NavLink to={href}>
      <div
        className={clsx(
          'flex h-[36px] cursor-pointer items-center justify-center rounded-[100px] px-3 font-semibold text-sm leading-5',
          {
            'bg-primary bg-opacity-15 text-primary': isActive,
            'hover:bg-primary hover:bg-opacity-10': !isActive,
          },
        )}
      >
        <span className="w-full">{value}</span>
      </div>
    </NavLink>
  );
}
