import { PoolAsset } from '@catalabs/catalyst-api-client';

import { ChainDeposit, ChainDepositInfo, ChainWithdraw } from '~/modules/pools';
import { FeeDetail, PendingSwapSummary } from '~/modules/swap/interfaces';

export enum PendingTnxType {
  Swap = 'swap',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export enum PendingTnxStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export interface PendingDepositSummary {
  requests: ChainDeposit[];
  depositInfo?: ChainDepositInfo;
  partialDeposit?: boolean;
  withLiquiditySwap?: boolean;
  liquiditySwapFinalSwapAmounts?: Map<string, number>;
  liquiditySwapFinalValues?: Map<string, number>;
  poolAssets: PoolAsset[];
  progress: number;
}

export interface PendingWithdrawSummary {
  requests: ChainWithdraw[];
  feeDetails: FeeDetail[];
  priceImpact: number;
  poolAssets: PoolAsset[];
  progress: number;
  withLiquiditySwap?: boolean;
  poolId: number;
}

export interface PendingTxn {
  hash: string;
  type: PendingTnxType;
  swap?: PendingSwapSummary;
  depositDetails?: PendingDepositSummary;
  withdrawalDetails?: PendingWithdrawSummary;
  status: PendingTnxStatus;
  submittedAt: Date;
}
