import { XMarkIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '~/config';
import { ReactComponent as Caret } from '~/img/caret.svg';
import { StoreContext } from '~/modules/common';
import { PoolCreateModal } from '~/modules/pools/components/PoolCreateModal';
import { PoolCreatePageButton } from '~/modules/pools/components/PoolCreatePageButton';
import { PoolCreateSummary } from '~/modules/pools/components/PoolCreateSummary';
import { PoolCreateWithdrawModal } from '~/modules/pools/components/PoolCreateWithdrawModal';
import SelectInteroperabilityProtocol from '~/modules/pools/components/SelectInteroperabilityProtocol';
import SelectPoolType from '~/modules/pools/components/SelectPoolType';
import TokenAmountInput from '~/modules/pools/components/TokenAmountInput';
import TokenAmountInputLoader from '~/modules/pools/components/TokenAmountInputLoader';
import TokenWeightInput from '~/modules/pools/components/TokenWeightInput';
import { usePoolCreate } from '~/modules/pools/hooks/usePoolCreate';
import { ChainSelector } from '~/modules/swap';

export const PoolCreatePage = observer(() => {
  const navigate = useNavigate();

  const { wallet, pool, catalyst } = useContext(StoreContext);
  const {
    isInitialized,
    canClickCreate,
    handlers,
    selectTokenModalOpen,
    selectedTokensAndChain,
    selectedTokenWeights,
    maxTokens,
    totalTokenWeight,
    totalDepositValue,
    hasSavedPoolCreation,
    assetWeights,
    assetAmounts,
    interopProtocol,
    poolType,
  } = usePoolCreate({ poolStore: pool, walletStore: wallet, catalystStore: catalyst });

  return (
    <div>
      <PoolCreateModal
        poolCreateRequests={pool.poolCreateRequests}
        onCancel={handlers.cancelCreate}
        createPoolStep={pool.poolCreateStep}
        totalLiquidity={totalDepositValue}
        totalCost={[]}
        completeDeposit={handlers.completePoolCreate}
        resumeDeposit={handlers.resumePoolCreation}
        onWithdraw={handlers.withdrawFunds}
        hasSavedPoolCreation={pool.hasPendingPoolCreateRequests}
      />
      <ChainSelector
        onSelect={handlers.addToken}
        open={selectTokenModalOpen}
        onClose={handlers.closeSelectTokenModal}
        hideNativeAssets={true}
      />
      <PoolCreateWithdrawModal
        poolWithdrawRequests={pool.poolWithdrawRequests}
        createPoolStep={pool.poolCreateStep}
        onCancel={handlers.cancelCreate}
        onResume={handlers.showResumePoolCreation}
      />
      <div className="mx-auto mb-[80px] mt-[80px] flex flex-col justify-center px-3 md:w-[1224px]">
        <div className="flex w-[362px] flex-col ">
          <div onClick={() => navigate(routes.pools)} className="flex cursor-pointer flex-row items-center space-x-2">
            <div className="pl-1">
              <Caret className="text-grey-500" />
            </div>
            <div className="text-xs text-grey-500">BACK TO POOLS</div>
          </div>
          <span className="mt-[24px] font-subheader text-[24px]">Create Pool</span>
        </div>

        <div className="mt-[40px] flex flex-col gap-6 md:flex-row md:items-start md:gap-24">
          <div className="flex flex-col gap-8">
            {hasSavedPoolCreation && (
              <div className="flex w-full flex-row justify-between gap-2 rounded-[20px] bg-primary-600  p-4 shadow-2xl md:w-[700px] ">
                <div className="flex flex-col gap-2 ">
                  <p className="font-subheader text-sm text-white">Your progess is saved</p>
                  <p className="text-sm text-white">Continue from where you stop or reset and start again</p>
                  <div>
                    <button
                      onClick={handlers.resetPoolCreate}
                      className="rounded-full border-[1px] border-white bg-primary px-[12px] py-[4px] text-xs text-white"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div>
                  <button>
                    <XMarkIcon className="h-5 w-5 text-white" />
                  </button>
                </div>
              </div>
            )}

            <div className="flex w-full flex-row rounded-[20px] bg-white shadow-2xl md:w-[700px] ">
              <div className="mb-6 flex w-full flex-col">
                <div className="bg-opacity-15two mt-6 flex gap-6 px-6 py-0 font-bold text-sm tracking-wide text-gray-900">
                  <span className="w-5/6 font-subheader leading-4">SELECT TOKENS</span>
                  <span className="w-1/6 font-subheader leading-4"> SET WEIGHT </span>
                </div>

                {!isInitialized && (
                  <TokenWeightInput.Loader
                    onClick={handlers.openSelectTokenModal}
                    nextTokenNumber={selectedTokensAndChain.length + 1}
                  />
                )}

                {isInitialized &&
                  selectedTokensAndChain.map(([token, chainId]) => (
                    <TokenWeightInput
                      key={JSON.stringify(token)}
                      token={token}
                      chainId={chainId}
                      onChange={handlers.setTokenWeight}
                      onRemoveToken={handlers.removeToken}
                      onClick={handlers.openSelectTokenModal}
                      value={assetWeights.get(token)}
                    />
                  ))}

                {isInitialized && maxTokens && totalTokenWeight < 100 && (
                  <TokenWeightInput.Loader
                    onClick={handlers.openSelectTokenModal}
                    nextTokenNumber={selectedTokensAndChain.length + 1}
                  />
                )}
              </div>
            </div>

            <div className="flex w-full flex-row rounded-[20px] bg-white shadow-2xl md:w-[700px] ">
              <div className="flex w-full flex-col gap-4 p-6 pb-6">
                <div className="bg-opacity-15two flex font-semibold text-sm leading-5 tracking-wide text-grey-900">
                  <span className="font-subheader leading-4">DEPOSIT LIQUIDITY</span>
                </div>

                {!isInitialized && <TokenAmountInputLoader onClick={handlers.openSelectTokenModal} />}

                {isInitialized &&
                  selectedTokensAndChain.map(([token]) => {
                    const assetBalance = wallet.getBalance(token.chainId.toString(), token.address);

                    return (
                      <TokenAmountInput
                        token={token}
                        balance={assetBalance}
                        onChange={(amount) => handlers.setTokenAmount(token, amount)}
                        disabled={false}
                        key={JSON.stringify(token)}
                        amount={assetAmounts.get(token)}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="flex h-[148px] w-full flex-row rounded-[20px] bg-white shadow-2xl md:w-[700px] ">
              <SelectPoolType selectedPool={poolType} onSelectPool={(poolType) => handlers.setPoolType(poolType)} />
            </div>

            <div className="flex h-[148px] w-full flex-row rounded-[20px] bg-white shadow-2xl md:w-[700px] ">
              <SelectInteroperabilityProtocol
                selectedProtocol={interopProtocol}
                onSelectProtocol={(protocol) => handlers.setInteropProtocol(protocol)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-6 md:flex-col-reverse">
            <PoolCreatePageButton enabled={canClickCreate} onClick={handlers.startPoolCreation} />

            <PoolCreateSummary
              selectedTokens={selectedTokensAndChain}
              selectedWeights={selectedTokenWeights}
              totalLiq={totalDepositValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PoolCreatePage;
