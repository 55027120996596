import { ReactNode } from 'react';

interface EmptyTableProps {
  children: ReactNode;
}

export const EmptyTable = ({ children }: EmptyTableProps) => {
  return (
    <div className="my-1 flex flex-col items-center justify-center py-9">
      <span className="rounded-xl bg-gray-100 px-6 py-2 text-sm">{children}</span>
    </div>
  );
};
