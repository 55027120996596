import { XMarkIcon } from '@heroicons/react/20/solid';

import { routes } from '~/config';

export interface PendingPoolCreationBarProps {
  handleCancel: () => void;
  handleResume: () => void;
  handleHide: () => void;
}

export const PendingPoolCreationBar = ({ handleCancel, handleResume, handleHide }: PendingPoolCreationBarProps) => {
  // if page is pool create page, don't show this banner
  if (window.location.pathname === routes.poolCreate) {
    return <></>;
  }

  return (
    <>
      <div className="flex w-full bg-primary-600 py-4">
        <div className="flex w-11/12 flex-shrink-0 items-center justify-center  gap-6">
          <p className="text-white"> You haven't finished your pool creation yet. </p>
          <button
            onClick={handleCancel}
            className="flex items-center justify-center rounded-full border-[1px] border-white bg-primary px-[12px] py-[4px] text-xs text-white"
          >
            Cancel Pool Creation
          </button>
          <button
            onClick={handleResume}
            className="flex items-center justify-center rounded-full bg-white px-[12px] py-[4px] text-xs text-gray-900"
          >
            Resume Pool Creation
          </button>
        </div>
        <button className="w-1/12 px-4" onClick={handleHide}>
          <XMarkIcon className="ml-auto h-5 w-5 text-white" />
        </button>
      </div>
    </>
  );
};

export default PendingPoolCreationBar;
