export interface InlineLinkProps {
  text: string;
  href: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

export function InlineLink({ text, href, size = 'sm' }: InlineLinkProps) {
  const sizeClass = `text-${size}`;
  return (
    <span
      className={`cursor-pointer font-medium text-primary underline ${sizeClass}`}
      onClick={() => window.open(href, '_blank')}
    >
      {text}
    </span>
  );
}

export interface ImbalancedDepositProps {
  handleBalance: () => void;
  isImbalanced?: boolean;
}
function ImbalancedDeposit({ handleBalance, isImbalanced }: ImbalancedDepositProps) {
  if (!isImbalanced) {
    return null;
  }

  return (
    <div>
      <div className="flex rounded-[15px] bg-[#4553D30D]">
        <div className="flex flex-col gap-2 py-4 pl-5">
          <span className="font-subheader text-sm text-primary">
            You are inputting an uneven distribution of assets.{' '}
          </span>
          <span className="pb-2 text-sm">
            We will swap into an even amount for you. Learn more about Liquidity Swaps{' '}
            <InlineLink text="here" href="" />.
          </span>
          <button
            onClick={handleBalance}
            className={
              'flex h-5 w-[166px] cursor-pointer items-center justify-center rounded-full bg-primary text-xs text-white'
            }
          >
            Balance token distribution
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImbalancedDeposit;
