import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';

import { protocols } from '~/config';
import { Tooltip } from '~/modules/common/components/Tooltip';
import { InteroperabilityProtocol } from '~/modules/pools';

interface SelectInteroperabilityProtocolProps {
  selectedProtocol?: InteroperabilityProtocol;
  onSelectProtocol: (protocol: InteroperabilityProtocol) => void;
}

export const SelectInteroperabilityProtocol = (props: SelectInteroperabilityProtocolProps) => {
  const [selectedProtocol, setSelectedProtocol] = useState<InteroperabilityProtocol | undefined>(
    props.selectedProtocol,
  );

  useEffect(() => {
    if (props.selectedProtocol) setSelectedProtocol(props.selectedProtocol);
  }, [props.selectedProtocol]);

  const handleOnchange = (protocol: InteroperabilityProtocol) => {
    setSelectedProtocol(protocol);
    props.onSelectProtocol(protocol);
  };

  return (
    <div className="flex h-[148px] w-full flex-row rounded-[20px] bg-white md:w-[700px] ">
      <div className="mx-3 flex w-full flex-col md:mx-6">
        <div className="bg-opacity-15two mt-6 flex items-center justify-between font-semibold text-sm leading-5 tracking-wide text-grey-900">
          <span className="font-subheader uppercase leading-4">Interoperability protocol</span>
          <Tooltip
            tooltipStyle="p-4 w-[320px] rounded-[24px] shadow-lg"
            tooltip="Interoperability Protocol is used to facilitate cross-chain data transfers between Catalyst vaults. Every Protocol has different design tradeoffs and trust assumptions; it is recommended to research which Protocol best suits your needs."
          >
            <span className="h-5 w-5 text-primary-500">
              <InformationCircleIcon />
            </span>
          </Tooltip>
        </div>
        <div className="border-gray mt-4 flex h-[52px] cursor-pointer items-center justify-between rounded-[12px] border">
          <Listbox value={selectedProtocol || ''} onChange={handleOnchange}>
            <div className="relative mt-1 w-full">
              <Listbox.Button className="relative w-full cursor-pointer bg-white px-6 py-2 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate">{selectedProtocol?.name || 'Select Protocol'}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5">
                  <ChevronDownIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {protocols.map((protocol, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-10 
                        pr-4
                        transition-colors
                        ${active ? 'bg-primary-600 text-white' : 'text-gray-900'}`
                      }
                      value={protocol}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`text-md block truncate font-medium text-gray-600 transition-colors
                            ${active ? 'text-white' : 'text-gray-900'}
                            `}
                          >
                            {protocol.name}
                          </span>
                          <span
                            className={`block font-light text-xs transition-colors ${
                              active ? 'text-gray-300' : 'text-gray-600'
                            }`}
                          >
                            {protocol.description}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 transition-colors ${
                                active ? 'text-white' : 'text-primary'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default SelectInteroperabilityProtocol;
