export const GeoFencing = () => {
  return (
    <div className="restricted absolute left-0 top-0 z-20 flex h-full max-h-screen w-full items-center justify-center overflow-auto bg-red-100 text-gray-800 backdrop-blur-3xl">
      <div className="flex h-full w-full flex-col justify-between rounded-[25px] bg-white py-5 shadow-lg md:h-[434px] md:w-[518px] md:py-10">
        <div className="flex w-full justify-between px-5 md:px-10">
          <div>
            <img className="" src={'/img/logo_dark_web.svg'} alt="Catalyst Logo" height={150} width={150} />
          </div>

          <div className="">
            <img className="" src={'/img/gradient_403.svg'} alt="403" height={85} />
          </div>
        </div>

        <div>
          <div className="mt-[81px] flex flex-col items-center justify-center">
            <span className="mb-4 font-semibold font-subheader text-[24px] text-red-700">Restricted Access Region</span>
            <span className="mx-auto w-[80%] text-center text-[14px] text-gray-500">
              Catalyst is not available in your location. For more information, please review our Terms of Service.
            </span>
          </div>
        </div>

        <div className="mt-20 flex cursor-pointer flex-col items-center gap-3 text-sm tracking-wider text-grey-500 md:flex-row md:justify-center md:gap-5">
          <a
            href="https://catalabs.notion.site/Catalyst-Demo-How-To-Guide-2cb3042381bc44c2b3a4ece56fd134e9"
            target="_blank"
          >
            How To Guide
          </a>

          <a href="https://github.com/catalystdao" target="_blank">
            GitHub
          </a>

          <a href="/terms-of-service" target="_blank">
            Terms of Service
          </a>

          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default GeoFencing;
