import { TokenInfo } from '@uniswap/token-lists';

export const CANTO_TOKENS: TokenInfo[] = [
  {
    name: 'Matrix',
    symbol: 'Matrix',
    chainId: 7700,
    decimals: 18,
    address: '0x8b6e422f7eF6C5DCC0Ad0C3dEA66efaCBb2b78e3',
    logoURI: 'https://raw.githubusercontent.com/xIceyyx/tokenlist/main/BLACKbg.png',
  },
  {
    name: 'wCanto',
    address: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B',
    symbol: 'WCANTO',
    decimals: 18,
    chainId: 7700,
    logoURI: 'https://raw.githubusercontent.com/Canto-Network/list/main/logos/token-logos/token/canto.png',
  },
  {
    name: 'COGE',
    address: '0x7ac10527052ECDb55D4c7aD901Db47642C8Ea099',
    symbol: 'COGE',
    decimals: 4,
    chainId: 7700,
    logoURI:
      'https://cdn.discordapp.com/attachments/771433993744678972/1034768272681345024/photo_2022-10-26_10-59-29.jpg',
  },
  {
    name: 'Otto Rothmund',
    symbol: 'OTTO',
    chainId: 7700,
    decimals: 18,
    address: '0xC2b0225E402aDC2BB02d15BFD6FF6fA6c992D0aa',
    logoURI: 'https://raw.githubusercontent.com/xIceyyx/tokenlist/main/photo_2022-08-24_19-48-28_2.jpg',
  },
  {
    name: 'Note',
    address: '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
    symbol: 'NOTE',
    decimals: 18,
    chainId: 7700,
    logoURI: 'https://raw.githubusercontent.com/Canto-Network/list/main/logos/token-logos/token/note.svg',
  },
  {
    name: 'CANTO INU',
    address: '0x7264610A66EcA758A8ce95CF11Ff5741E1fd0455',
    symbol: 'cINU',
    decimals: 18,
    chainId: 7700,
    logoURI: 'https://raw.githubusercontent.com/Canto-Network/list/main/logos/token-logos/token/CantoInu.png',
  },
  {
    name: 'CANTO MOON',
    address: '0x0a79EB2C804e1e9f2A6a09768c03Ff9C1B2D8028',
    symbol: 'CMOON',
    decimals: 18,
    chainId: 7700,
    logoURI: 'https://raw.githubusercontent.com/CryptoP00R/CantoMoon/main/Images/CantoMoon-NonTransparent.png',
  },
];
