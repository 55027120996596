import { PoolAsset, Vault } from '@catalabs/catalyst-api-client';
import {
  computeEstimatedOutGivenPercentageWithdrawal,
  estimateImbalanceLossWithdraw,
  GAS_TOKEN_IDENTIFIER,
} from '@catalabs/catalyst-sdk';
import { runInAction } from 'mobx';
import { useCallback, useEffect, useState } from 'react';

import { convertPercentageToBigInt, formatBalance, parseBalance, TokenBalance, toUnits } from '~/config';
import { PoolActionType, PoolInteractionStep, PoolWithdrawType } from '~/modules/pools/enums';
import {
  formatVaultInfo,
  formatVaultInfoWithdraw,
  getAssetKey,
  getWithdrawFeeDetails,
  getWithdrawWithLiquiditySwapsFeeDetails,
} from '~/modules/pools/utils';
import { FeeDetail } from '~/modules/swap/interfaces';

import {
  CatalystStore,
  formatValue,
  isValidNumeric,
  WalletStore,
  WithdrawRequestItem,
  WithdrawWithLiquiditySwapInputs,
  WithdrawWithLiquiditySwapRequest,
} from '../../common';
import { PoolStore } from '../store';
import { findWithdrawLiquiditySwapRoute, getLiquiditySwapTxInputsWithdraw } from '../utils/withdraw.utils';

export const MAX_PERCENTAGE_WITHDRAWABLE_SINGLE = 99;
export const MAX_PERCENTAGE_WITHDRAWABLE_BALANCED = 100;

export interface PoolWithdrawHelpers {
  withdrawType: PoolWithdrawType;
  withdrawAmounts: Map<string, string>;
  withdrawWeights: Map<string, number>;
  withdrawValues: Map<string, number>;
  withdrawPortion?: number;
  depositValue: number;
  withdrawValue: number;
  additionalSlippage: number;
  customPortionValue?: number;
  customPortion?: boolean;
  poolAssets: PoolAsset[];
  assetBalances: Map<string, number>;
  insufficientBalanceError: boolean;
  handlers: {
    setWithdrawType: (withdrawType: PoolWithdrawType) => void;
    handleWithdraw: () => void;
    updateCustomPortionValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setWithdrawPortion: (portion: number) => void;
    completeWithdraw: () => void;
    selectSingleToken: (asset: PoolAsset) => void;
    cancelWithdraw: () => void;
    handleWithdrawInputChange: (asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleWithdrawInputBlur: (asset: PoolAsset) => void;
    handleWithdrawInputClick: (asset: PoolAsset) => void;
    handleMaxClick: (asset: PoolAsset) => void;
    handleOneTokenClick: () => void;
    handleBalancedWithdrawClick: () => void;
    handleChangeSingleWithdrawAsset: (asset: PoolAsset) => void;
    handleUseGasToken: (asset: PoolAsset, useGasToken: boolean) => void;
  };
}

export function usePoolWithdraw(
  catalystStore: CatalystStore,
  walletStore: WalletStore,
  poolStore: PoolStore,
): PoolWithdrawHelpers | null {
  const { pool } = poolStore;
  const { withdrawStore, poolAccountBalance } = poolStore;

  if (!pool || !poolAccountBalance) {
    return null;
  }

  const targetPool = pool;
  const poolAssets = targetPool.assets;
  const targetAccountBalance = poolAccountBalance.get(targetPool.id);
  const [withdrawType, setWithdrawType] = useState<PoolWithdrawType>(PoolWithdrawType.Balanced);
  const [customPortion, setCustomPortion] = useState<boolean>();
  const [customPortionValue, setCustomPortionValue] = useState<number | undefined>(undefined);
  const [withdrawPortion, setWithdrawPortion] = useState<number | undefined>();
  const [withdrawAmounts, setWithdrawAmounts] = useState<Map<string, string>>(new Map());
  const [withdrawValues, setWithdrawValues] = useState<Map<string, number>>(new Map());
  const [withdrawWeights, setWithdrawWeights] = useState<Map<string, number>>(new Map());
  const [additionalSlippage, setAdditionalSlippage] = useState(0);
  const [assetBalances, setAssetBalances] = useState<Map<string, number>>(new Map());
  const [withdrawValue, setWithdrawValue] = useState(0);
  const [withdrawRequests, setWithdrawRequests] = useState<Map<string, WithdrawRequestItem>>(new Map());
  const [tokenPrices, setTokenPrices] = useState<Map<string, number>>(new Map());
  const [singleWithdrawAsset, setSingleWithdrawAsset] = useState(poolAssets[0]);
  const [isAssetReplacedWithGasToken, setIsAssetReplacedWithGasToken] = useState<Map<string, boolean>>(new Map());

  const insufficientBalanceError = Array.from(withdrawAmounts.entries()).some(([assetKey, amount]) => {
    if (amount === '') {
      return false;
    }
    const assetBalance = assetBalances.get(assetKey);
    if (!assetBalance) {
      return true;
    }
    return Number(amount) > assetBalance;
  });

  const MAX_PERCENTAGE_WITHDRAWABLE =
    withdrawType === PoolWithdrawType.Single
      ? MAX_PERCENTAGE_WITHDRAWABLE_SINGLE
      : MAX_PERCENTAGE_WITHDRAWABLE_BALANCED;

  /**
   * This function updates the withdrawAmounts according to changes in other parameters including:
   *  withdrawType,
   *  withdrawPortion,
   *  singleWithdrawAsset,
   *  withdrawWeights,
   *  targetPool,
   *  targetAccountBalance,
   */
  const updateWithdrawInformation = useCallback(async (): Promise<Map<string, number>> => {
    const tempWithdawValueMap = new Map();
    const tempWithdrawAmountMap = new Map();

    if (targetPool && targetAccountBalance && withdrawPortion) {
      if (withdrawType === PoolWithdrawType.Balanced) {
        targetPool.assets.forEach((a) => {
          const assetKey = getAssetKey(a);
          const { amount, value } = targetAccountBalance.balances[a.chainId][a.address];

          if (withdrawPortion && withdrawPortion === MAX_PERCENTAGE_WITHDRAWABLE) {
            const withdawValue = (value * MAX_PERCENTAGE_WITHDRAWABLE) / 100;

            const withdrawAmount = (amount * MAX_PERCENTAGE_WITHDRAWABLE) / 100;

            tempWithdawValueMap.set(assetKey, withdawValue);
            tempWithdrawAmountMap.set(assetKey, formatValue(withdrawAmount));
          } else {
            const withdawValue = (value * withdrawPortion) / 100;
            const withdrawAmount = (amount * withdrawPortion) / 100;
            tempWithdawValueMap.set(assetKey, withdawValue);
            tempWithdrawAmountMap.set(assetKey, formatValue(withdrawAmount));
          }
        });
      }

      if (withdrawType === PoolWithdrawType.Single) {
        // get active asset
        const asset = singleWithdrawAsset;
        const assetKey = getAssetKey(asset);
        const assetPrice = tokenPrices.get(assetKey);

        if (targetAccountBalance && withdrawPortion && assetPrice) {
          const valueToWithdraw = (targetAccountBalance.value * withdrawPortion) / 100;
          const vaults = Object.values(targetPool.vaults);
          const vaultIndex = vaults.findIndex((vault) => vault.chain === asset.chainId);
          const assetIndex = vaults[vaultIndex].assets.findIndex((vaultAsset) => vaultAsset.token === asset.address);
          const vaultsInfo = formatVaultInfo({ vaults });
          const percentage = convertPercentageToBigInt(
            withdrawPortion < MAX_PERCENTAGE_WITHDRAWABLE ? withdrawPortion : MAX_PERCENTAGE_WITHDRAWABLE,
          );
          const userPoolTokens = vaults.map((vault) => BigInt(targetAccountBalance.liquidity[vault.chain]));

          const tokenAmountToWithdraw = computeEstimatedOutGivenPercentageWithdrawal(
            vaultsInfo,
            userPoolTokens,
            percentage,
            vaultIndex,
            assetIndex,
          );
          const amountToWithdraw = formatBalance(tokenAmountToWithdraw);

          tempWithdrawAmountMap.set(assetKey, amountToWithdraw.toString());
          tempWithdawValueMap.set(assetKey, valueToWithdraw);
        }
      }

      setWithdrawValues(tempWithdawValueMap);
      setWithdrawAmounts(tempWithdrawAmountMap);

      const totalWithdraw = Array.from(tempWithdawValueMap.values()).reduce((total, value) => (total += value), 0);
      setWithdrawValue(totalWithdraw);
    }

    return tempWithdawValueMap;
  }, [withdrawType, withdrawPortion, singleWithdrawAsset, targetPool, targetAccountBalance]);

  useEffect(() => {
    updateWithdrawInformation();
  }, [withdrawPortion, singleWithdrawAsset]);

  useEffect(() => {
    fetchBalances();
    fetchTokenPrices();
  }, [poolAssets, singleWithdrawAsset, withdrawType]);

  useEffect(() => {
    generateWithdrawRequests();
  }, [withdrawAmounts]);

  useEffect(() => {
    if (!withdrawStore.runningWithdraw && !withdrawStore.hasPendingWithdraws) {
      withdrawStore.reset();
    }
  }, []);

  useEffect(() => {
    if (withdrawType === PoolWithdrawType.Single) {
      selectSingleToken(pool.assets[0]);
    }
    if (withdrawType === PoolWithdrawType.Balanced) {
      const tempAssetWeights = new Map(withdrawWeights);
      const tempIsAssetReplaceWithGasToken = new Map(isAssetReplacedWithGasToken);

      pool.assets.map((asset) => {
        const assetKey = getAssetKey(asset);
        tempAssetWeights.set(assetKey, asset.weight);
        tempIsAssetReplaceWithGasToken.set(assetKey, true);
      });
      setWithdrawWeights(tempAssetWeights);
      setIsAssetReplacedWithGasToken(tempIsAssetReplaceWithGasToken);
    }
    resetWithdraw();
  }, [withdrawType]);

  useEffect(() => {
    generateWithdrawRequests();
  }, [withdrawAmounts, isAssetReplacedWithGasToken]);

  // fetch the user balance that can be withdrawn for each asset based on withdraw type
  const fetchBalances = useCallback(async () => {
    const balances = new Map<string, number>();
    const newGasTokenBalance = new Map<string, TokenBalance>();

    if (targetPool && targetAccountBalance) {
      if (withdrawType === PoolWithdrawType.Single) {
        const asset = singleWithdrawAsset;
        const tokenPrice = tokenPrices.get(getAssetKey(asset));
        if (tokenPrice) {
          const totalAmount = getTokenAmountWithdrawableBasedOnPercentage(99, asset);
          const gasTokenBalance = walletStore.getBalance(asset.chainId.toString(), GAS_TOKEN_IDENTIFIER);
          newGasTokenBalance.set(getAssetKey(asset), gasTokenBalance);
          balances.set(getAssetKey(asset), totalAmount);
        }
      }
      if (withdrawType === PoolWithdrawType.Balanced) {
        for (const asset of targetPool.assets) {
          const balance = targetAccountBalance.balances[asset.chainId][asset.address].amount;
          const gasTokenBalance = walletStore.getBalance(asset.chainId.toString(), GAS_TOKEN_IDENTIFIER);
          balances.set(getAssetKey(asset), balance);
          newGasTokenBalance.set(getAssetKey(asset), gasTokenBalance);
        }
      }

      setAssetBalances(balances);
    }
  }, [poolAssets, singleWithdrawAsset, withdrawType]);

  const fetchTokenPrices = useCallback(async () => {
    const tokenInfoPromises = poolAssets.map((asset) => {
      return catalystStore.getToken(asset.chainId, asset.address);
    });
    const gasTokenInfoPromises = Array.from(new Set(poolAssets.map((asset) => asset.chainId.toString()))).map(
      (chainId) => {
        return catalystStore.getToken(chainId, GAS_TOKEN_IDENTIFIER);
      },
    );
    const tokenInfos = await Promise.all([...tokenInfoPromises, ...gasTokenInfoPromises]);
    const tokenPrices = tokenInfos.map((tokenInfo) => {
      return catalystStore.getPrice(tokenInfo);
    });
    const tokenPriceMap = new Map<string, number>();
    tokenInfos.forEach((tokenInfo, index) => {
      tokenPriceMap.set(
        getAssetKey({
          address: tokenInfo.address,
          chainId: tokenInfo.chainId.toString(),
        }),
        tokenPrices[index],
      );
    });
    setTokenPrices(tokenPriceMap);
  }, [poolAssets]);

  const generateWithdrawRequests = useCallback(async () => {
    const address = walletStore.address;
    if (!targetAccountBalance || !withdrawPortion || !address || !targetPool) {
      return;
    }

    const vaults = Object.values(targetPool.vaults);
    const requests: Map<string, WithdrawRequestItem> = new Map();

    for (const asset of targetPool.assets) {
      const assetKey = getAssetKey(asset);
      const withdrawAmount = withdrawAmounts.get(assetKey) ?? '0';
      const useGasToken = isAssetReplacedWithGasToken.get(assetKey) || false;
      const assetInfo = await catalystStore.getToken(asset.chainId, useGasToken ? GAS_TOKEN_IDENTIFIER : asset.address);
      const price = await catalystStore.getPrice(assetInfo);
      const vaultTokenAmount =
        (Number(targetAccountBalance.liquidity[asset.chainId]) * Number(withdrawPortion ?? 1)) / 100;
      const withdrawRequest: WithdrawRequestItem = {
        address: asset.address,
        vault: targetPool.vaults[asset.chainId].address,
        chainId: asset.chainId,
        amount: toUnits(withdrawAmount, assetInfo.decimals),
        index: asset.index,
        displayAmount: withdrawAmount,
        value: price * Number(withdrawAmount),
        tokenInfo: assetInfo,
        withLiquiditySwap: false,
        vaultTokenAmount: BigInt(Math.trunc(vaultTokenAmount)),
        useGasToken: withdrawType === PoolWithdrawType.Single ? true : useGasToken,
      };
      requests.set(assetKey, withdrawRequest);
    }

    const chainIds = Array.from(new Set(targetPool?.assets.map((a) => a.chainId) ?? []));
    const gasData = await catalystStore.getGasFeeData(chainIds);

    let feeDetails: FeeDetail[] = [];
    if (withdrawType === PoolWithdrawType.Balanced) {
      feeDetails = getWithdrawFeeDetails({
        withdrawRequest: Array.from(requests.values()),
        gasData,
        gasTokenPrices: tokenPrices,
        pool: targetPool,
      });
    }
    if (withdrawType === PoolWithdrawType.Single) {
      const vaultCciVersion = targetPool.arbitraryMessagingBridge;
      const chainFeeData = await catalystStore.getFeeData(
        vaultCciVersion,
        vaults.map((v) => v.chain),
      );
      const txInputs = getLiquiditySwapTxInputsWithdraw({
        address,
        pool: targetPool,
        poolAccountBalance: targetAccountBalance,
        tokenPrices,
        vaults,
        withdrawAmounts,
        withdrawPortion,
        chainFeeData,
      });
      feeDetails = getWithdrawWithLiquiditySwapsFeeDetails({
        txInputs,
        address,
        gasData,
        gasTokenPrices: tokenPrices,
        pool: targetPool,
        withdrawRequest: Array.from(requests.values()),
      });
      const liquiditySwaps = findWithdrawLiquiditySwapRoute({
        poolAccountBalance: targetAccountBalance,
        vaults,
        withdrawAmounts,
        withdrawPortion,
      });

      for (const [_, request] of requests) {
        const chain = request.chainId;
        const hasLiquiditySwap = liquiditySwaps.get(chain);
        if (hasLiquiditySwap) {
          const toChain = hasLiquiditySwap.toVault.chain;
          const withdrawAssetAmount = withdrawAmounts.get(getAssetKey(singleWithdrawAsset));
          const withdrawAssetValue = withdrawValues.get(getAssetKey(singleWithdrawAsset));
          request.withLiquiditySwap = true;
          request.lSwapToChain = toChain;
          request.lSwapToTokenInfo = await catalystStore.getToken(toChain, GAS_TOKEN_IDENTIFIER);
          request.finalLiquiditySwapAmount = withdrawAssetAmount;
          request.finalLiquiditySwapValue = withdrawAssetValue;
        }
      }
    }

    const { vaultsInfo } = formatVaultInfoWithdraw({
      vaults,
      poolAccountBalance: targetAccountBalance,
      withdrawAmounts,
      withdrawPortion,
    });
    const vaultTokens: bigint[] = getVaultsTokensToWithdraw();
    const withdrawRatios: bigint[][] = getWithdrawRatios();
    if (!vaultTokens.some((token) => token <= 0n)) {
      const imbalanceLoss: number = formatBalance(
        estimateImbalanceLossWithdraw(vaultsInfo, vaultTokens, withdrawRatios),
      );
      const priceImpact = 100 - 100 * imbalanceLoss;
      setAdditionalSlippage(priceImpact);
      runInAction(() => {
        withdrawStore.priceImpact = priceImpact;
      });
    }

    setWithdrawRequests(requests);

    runInAction(() => {
      withdrawStore.feeDetails = feeDetails;
    });

    return requests;
  }, [withdrawPortion, withdrawAmounts, targetAccountBalance, targetPool, isAssetReplacedWithGasToken]);

  const getTokenAmountWithdrawableBasedOnPercentage = useCallback(
    (percentage: number, asset: PoolAsset) => {
      if (targetAccountBalance) {
        const vaults = Object.values(targetPool.vaults);
        const vaultIndex = vaults.findIndex((vault) => vault.chain === asset.chainId);
        const assetIndex = vaults[vaultIndex].assets.findIndex((vaultAsset) => vaultAsset.token === asset.address);
        const vaultsInfo = formatVaultInfo({ vaults });
        const percentageInDecimal = convertPercentageToBigInt(percentage);
        const userPoolTokens = vaults.map((vault) => BigInt(targetAccountBalance.liquidity[vault.chain]));

        const tokenAmountToWithdraw = computeEstimatedOutGivenPercentageWithdrawal(
          vaultsInfo,
          userPoolTokens,
          percentageInDecimal,
          vaultIndex,
          assetIndex,
        );
        const amountToWithdraw = formatBalance(tokenAmountToWithdraw);

        return amountToWithdraw;
      }
      return 0;
    },
    [targetPool, targetAccountBalance],
  );

  function resetWithdraw() {
    setWithdrawValues(new Map());
    setWithdrawAmounts(new Map());
    setWithdrawWeights(new Map());
    setSingleWithdrawAsset(poolAssets[0]);
    setWithdrawPortion(undefined);
    setCustomPortion(false);
    setCustomPortionValue(undefined);
    runInAction(() => {
      withdrawStore.priceImpact = 0;
      withdrawStore.feeDetails = [];
    });
  }

  function completeWithdraw() {
    if (targetPool) {
      resetWithdraw();
      poolStore.updatePoolAccountBalance(targetPool.id);
      withdrawStore.reset();
      runInAction(() => {
        poolStore.poolAction = PoolActionType.None;
      });
    }
  }

  function cancelWithdraw() {
    if (!withdrawStore.hasPendingWithdraws) {
      withdrawStore.reset();
    }
    runInAction(() => {
      withdrawStore.poolWithdrawStep = PoolInteractionStep.Interact;
    });
  }

  async function handleWithdraw() {
    if (!withdrawPortion && insufficientBalanceError) {
      return;
    }

    if (withdrawType === PoolWithdrawType.Balanced) {
      return await defaultWithdraw();
    }

    if (withdrawType === PoolWithdrawType.Single) {
      return await withdrawWithLiquiditySwaps();
    }
  }

  async function defaultWithdraw() {
    if (!withdrawPortion || !targetPool) {
      return;
    }
    if (withdrawStore.hasPendingWithdraws) {
      return;
    }

    runInAction(() => {
      withdrawStore.withdrawValue = withdrawValue;
    });
    withdrawStore.withdraw(Array.from(withdrawRequests.values()), withdrawPortion);
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  async function withdrawWithLiquiditySwaps() {
    if (
      walletStore.address === undefined ||
      !targetPool ||
      !assetBalances ||
      !targetAccountBalance ||
      !withdrawPortion
    ) {
      return;
    }

    const vaults = Object.values(targetPool.vaults);

    const address = walletStore.address;
    const vaultCciVersion = targetPool.arbitraryMessagingBridge;
    const chainFeeData = await catalystStore.getFeeData(
      vaultCciVersion,
      vaults.map((v) => v.chain),
    );

    const withdrawWithLiquiditySwapInputs: WithdrawWithLiquiditySwapInputs = getLiquiditySwapTxInputsWithdraw({
      address,
      withdrawAmounts,
      pool,
      poolAccountBalance: targetAccountBalance,
      tokenPrices,
      vaults,
      withdrawPortion,
      chainFeeData,
    });

    const withdrawRequests = await generateWithdrawRequests();

    if (!withdrawRequests) {
      return;
    }

    const withdrawRequestsArray = Array.from(withdrawRequests.values());

    const withdrawWithLiquiditySwapRequest: WithdrawWithLiquiditySwapRequest = {
      requests: withdrawRequestsArray,
      txInputs: withdrawWithLiquiditySwapInputs,
    };

    withdrawStore.withdrawWithLiquiditySwap(withdrawWithLiquiditySwapRequest);
  }

  const getVaultsTokensToWithdraw = () => {
    const vaultTokens: bigint[] = [];
    const vaultMap = new Map<string, Vault>(Object.entries(targetPool.vaults));
    for (const [_, vault] of vaultMap) {
      if (!targetAccountBalance) {
        vaultTokens.push(0n);
        break;
      }
      const vaultToken = (Number(targetAccountBalance.liquidity[vault.chain]) * Number(withdrawPortion ?? 0)) / 100;
      vaultTokens.push(BigInt(Math.trunc(vaultToken)));
    }
    return vaultTokens;
  };

  const getWithdrawRatios = () => {
    const withdrawRatios: bigint[][] = [];
    const vaultMap = new Map<string, Vault>(Object.entries(targetPool.vaults));
    for (const [_, vault] of vaultMap) {
      const withdrawRatio: bigint[] = [];
      vault.assets.forEach((asset) => {
        const assetKey = getAssetKey({
          chainId: asset.chain,
          address: asset.token,
        });
        const withdrawWeight = withdrawWeights.get(assetKey) ?? 0;
        if (withdrawWeight === 0 || Number.isNaN(withdrawWeight)) {
          withdrawRatio.push(BigInt(1n));
        } else {
          withdrawRatio.push(BigInt(parseInt(withdrawWeight.toString())));
        }
      });
      withdrawRatios.push(withdrawRatio);
    }
    return withdrawRatios;
  };

  function selectSingleToken(asset: PoolAsset) {
    if (!targetPool) {
      return;
    }
    const assetKey = getAssetKey(asset);
    const newWithdrawAmount = new Map();
    const newWithdrawWeights = new Map();
    const newIsAssetReplacedWithGasToken = new Map();

    newWithdrawAmount.set(assetKey, '0');
    newWithdrawWeights.set(assetKey, 100);
    for (const poolAsset of targetPool.assets) {
      const poolAssetKey = getAssetKey(poolAsset);
      newIsAssetReplacedWithGasToken.set(poolAssetKey, true);
    }

    setSingleWithdrawAsset(asset);
    setWithdrawAmounts(newWithdrawAmount);
    setWithdrawWeights(newWithdrawWeights);
    setIsAssetReplacedWithGasToken(newIsAssetReplacedWithGasToken);
  }

  function updateCustomPortionValue(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    if (isValidNumeric(value)) {
      const numericValue = Math.min(MAX_PERCENTAGE_WITHDRAWABLE, Math.max(0, Number(value)));
      setWithdrawPortion(numericValue);
      setCustomPortionValue(numericValue);
      setCustomPortion(true);
    } else if (value.length === 0) {
      setWithdrawPortion(undefined);
      setCustomPortionValue(undefined);
      setCustomPortion(false);
    }
  }

  function handleWithdrawInputClick(asset: PoolAsset): void {
    const assetKey = getAssetKey(asset);
    if (!withdrawAmounts.get(assetKey)) {
      const tempWithdrawAmounts = new Map(withdrawAmounts);
      tempWithdrawAmounts.set(assetKey, '');
      setWithdrawAmounts(tempWithdrawAmounts);
    }
  }

  function handleWithdrawInputChange(asset: PoolAsset, event: React.ChangeEvent<HTMLInputElement>) {
    const amount = event.target.value;
    if (isValidNumeric(amount)) {
      const tempInputMap = new Map(withdrawAmounts);
      const assetKey = getAssetKey(asset);
      tempInputMap.set(assetKey, amount);
      setWithdrawAmounts(tempInputMap);
    }
  }

  function handleWithdrawInputBlur(asset: PoolAsset) {
    if (withdrawType === PoolWithdrawType.Balanced) {
      const newData = optimizeWithdrawAmountsBasedOnOneAmount({
        asset,
        withdrawAmounts,
        withdrawValues,
        withdrawWeights,
      });
      if (newData) {
        setWithdrawAmounts(newData.withdrawAmounts);
        setCustomPortion(true);
        setCustomPortionValue(newData.withdrawPortion);
        setWithdrawPortion(newData.withdrawPortion);
      }
    }
  }

  function handleMaxClick(asset: PoolAsset) {
    if (!targetAccountBalance) {
      return;
    }
    const assetKey = getAssetKey(asset);
    const assetBalance = assetBalances.get(assetKey);
    if (!poolAccountBalance || !assetBalance) {
      return;
    }

    let maxWithdrawalAmount;

    try {
      const adjustedPoolAccountBalance = assetBalance - formatBalance(parseBalance('1', 'gwei'));
      if (adjustedPoolAccountBalance < 0) {
        throw new Error('Insufficient balance to proceed with the withdrawal.');
      }
      maxWithdrawalAmount = formatValue(adjustedPoolAccountBalance);
    } catch (e) {
      maxWithdrawalAmount = '0';
    }

    const tempWithdrawAmounts = new Map(withdrawAmounts);
    tempWithdrawAmounts.set(assetKey, maxWithdrawalAmount);

    if (withdrawType === PoolWithdrawType.Single) {
      setWithdrawAmounts(tempWithdrawAmounts);
      setWithdrawPortion(MAX_PERCENTAGE_WITHDRAWABLE);
    }

    if (withdrawType === PoolWithdrawType.Balanced) {
      const newData = optimizeWithdrawAmountsBasedOnOneAmount({
        asset,
        withdrawAmounts: tempWithdrawAmounts,
        withdrawValues,
        withdrawWeights,
      });
      if (newData) {
        setWithdrawAmounts(newData.withdrawAmounts);
        setCustomPortion(true);
        setCustomPortionValue(newData.withdrawPortion);
        setWithdrawPortion(newData.withdrawPortion);
      }
    }
  }

  function handleBalancedWithdrawClick() {
    setWithdrawType(PoolWithdrawType.Balanced);
  }

  function handleOneTokenClick() {
    setWithdrawType(PoolWithdrawType.Single);
  }

  function handleChangeSingleWithdrawAsset(asset: PoolAsset) {
    setSingleWithdrawAsset(asset);
  }

  function handleUseGasToken(asset: PoolAsset, useGasToken: boolean) {
    const assetKey = getAssetKey(asset);
    const tempIsAssetReplaceWithGasToken = new Map(isAssetReplacedWithGasToken);

    tempIsAssetReplaceWithGasToken.set(assetKey, Boolean(useGasToken));

    setIsAssetReplacedWithGasToken(tempIsAssetReplaceWithGasToken);
  }

  // helper functions
  const optimizeWithdrawAmountsBasedOnOneAmount = ({
    asset,
    withdrawAmounts,
  }: {
    asset: PoolAsset;
    withdrawAmounts: Map<string, string>;
    withdrawValues: Map<string, number>;
    withdrawWeights: Map<string, number>;
  }) => {
    if (!targetAccountBalance) {
      return;
    }

    const tempWithdrawAmounts = new Map(withdrawAmounts);

    // from one withdraw amount, update the percentage, and other withdraw amounts based on the we
    // get the corresponding percentage of the withdraw amount and update the other withdraw amounts
    const assetKey = getAssetKey(asset);
    const withdrawValue = withdrawAmounts.get(assetKey);

    const poolAccountBalance = assetBalances.get(assetKey);

    if (!withdrawValue || !poolAccountBalance || !poolAccountBalance) {
      return;
    }
    const withdrawPercentage = Math.floor((Number(withdrawValue) / poolAccountBalance) * 100);

    // calculate the new withdraw amounts based on the new percentage
    // @TODO: this is a temporary solution, we are current calculating the new withdraw amounts based on the new percentage, we need to consider the weights
    targetPool.assets.forEach((a) => {
      const assetKey = getAssetKey(a);
      const poolAccountBalance = targetAccountBalance.balances[a.chainId][a.address];
      const newWithdrawAmount = (poolAccountBalance.amount * withdrawPercentage) / 100;
      tempWithdrawAmounts.set(assetKey, formatValue(newWithdrawAmount, 8));
    });

    return {
      withdrawAmounts: tempWithdrawAmounts,
      withdrawPortion: withdrawPercentage,
    };
  };

  const updateWithdrawPortion = (portion: number) => {
    setWithdrawPortion(portion);
    setCustomPortion(false);
  };

  return {
    withdrawType,
    withdrawAmounts,
    withdrawValues,
    withdrawWeights,
    withdrawPortion,
    depositValue: targetAccountBalance?.value ?? 0,
    customPortion,
    customPortionValue,
    withdrawValue,
    additionalSlippage,
    assetBalances,
    poolAssets: targetPool.assets,
    insufficientBalanceError,
    handlers: {
      handleWithdrawInputChange,
      setWithdrawType,
      updateCustomPortionValue,
      setWithdrawPortion: updateWithdrawPortion,
      handleWithdraw,
      completeWithdraw,
      selectSingleToken,
      cancelWithdraw,
      handleWithdrawInputBlur,
      handleWithdrawInputClick,
      handleMaxClick,
      handleBalancedWithdrawClick,
      handleOneTokenClick,
      handleChangeSingleWithdrawAsset,
      handleUseGasToken,
    },
  };
}
